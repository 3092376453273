import { useEffect, useState } from "react";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import {
  Button,
  Form,
  Input,
  Select,
  Steps,
  Modal,
  message,
  InputNumber,
  Popconfirm,
  Radio
} from "antd";
import CreateUser from "../Users/CreateUsers";
import { useTranslation } from "react-i18next";
import {
  createUser,
  getAllUser,
  getAllUserPages,
} from "../../../api-core/User";
import { getAllVendors } from "../../../api-core/Vendor";
import { getAllRoles } from "../../../api-core/RolesPermissions";
import {
  createCurrentEvent,
  eventUploadImageFile,
} from "../../../api-core/Events";
import ImportCSV from "../../ImportCSV";
import { HotelDetail } from "./forms";
import {
  EventType,
  FoodType,
  GuestInvited,
  GuestOutstation,
  InvitationType,
} from "../../../types";
import { generalchecklistData } from "./GenralChecklistData";
import { useFetchHotels } from "src/hooks";
import TextArea from "antd/lib/input/TextArea";
import { viewCurrentDates } from "src/utils/helper";
import { Link } from "react-router-dom";
import EventClientCreate from "../Users/EventClientCreate";

const CreateCurrentEvent = () => {
  const [currentStep, setCurrentStep] = useState(0);
  const [eventDetails, setEventDetails] = useState(null);
  const [hotelDetails, setHotelDetails] = useState([]);
  const [ghmsDetails, setGHMSDetails] = useState(null);
  const [vendorDetails, setVendorDetails] = useState(null);
  const [foodBevDetails, setFoodBevDetails] = useState(null);
  const [prodDecorDetails, setProdDecorDetails] = useState(null);
  const [ghmsListData, setghmsListData] = useState([]);
  const [foodBevListData, setFoodBevListData] = useState([]);
  const [prodListData, setProdListData] = useState([]);
  const [vendorListData, setVendorListData] = useState([]);
  const [menuImage, setMenuImage] = useState("");
  const [docerImage, setDecorImage] = useState("");
  const [form] = Form.useForm();
  const [eventStatus, setEventStatus] = useState(false);
  const [hotelStatus, setHotelStatus] = useState(false);
  const [stepDisable, setStepDisable] = useState(true);
  const [hotelDisable, setHotelDisable] = useState(true);
  // const [selectedCoordinator, setSelectedCoordinator] = useState([]);
  const [selectedCoordinators, setSelectedCoordinators] = useState([]);
  const { t } = useTranslation();

  const callBack = (payload) => {
    setSelectedCoordinators(payload);
  };

  useEffect(() => {
    if (eventStatus && hotelStatus) {
      setStepDisable(false);
    }
  }, [eventStatus, hotelStatus]);

  const {
    handleHotelCreateSuccess,
    handleRoomCreateSuccess,
    hotelRooms,
    hotels,
  } = useFetchHotels();

  const onFinishEventDetailForm = (values) => {
    // console.log("payload event", values);
    const payload = {
      ...values,
      coordinator_ids: selectedCoordinators,
      // client_id: values.client_id,
      // event_descp: values.event_descp,
      // event_end_date: values.event_end_date,
      // event_remark: values.event_remark,
      // event_start_date: values.event_start_date,
      // event_title: values.event_title,
      // event_type: values.event_type,
    };

    setEventDetails(payload);
    setCurrentStep(1);
    setEventStatus(true);
    setHotelDisable(false);
  };
  const onFinishHotelDetailForm = (values) => {
    setHotelDetails(values);
    setCurrentStep(2);
    setHotelStatus(true);
  };
  const onFinishGHMSDetailForm = (values) => {
    setGHMSDetails(values);
    setghmsListData([...ghmsListData, values]);
    form.resetFields();
  };

  const handleCSVToTableSave = (values) => {
    setghmsListData([...values]);
  };

  const onFinishVendorDetailForm = (values) => {
    setVendorDetails(values);
    setVendorListData([...vendorListData, values]);
    form.resetFields();
  };
  const onFinishFoodBevDetailForm = (values) => {
    setFoodBevDetails(values);
    setFoodBevListData([...foodBevListData, values]);
    form.resetFields();
  };
  const onFinishProdDecorDetailForm = (values) => {
    setProdDecorDetails(values);
    setProdListData([...prodListData, values]);
    form.resetFields();
  };

  const handleMenuImageUpload = async (e) => {
    if (e.target.files[0] && e.target.files[0].size > 2 * 1024 * 1024) {
      e.target.value = null;
      return message.error("File exceeds the limit of 2MB");
    }
    const formData = new FormData();
    formData.append("img_files", e.target.files[0]);
    const res = await eventUploadImageFile(formData);
    if (res.success === true) {
      setMenuImage(res.files[0].file);
    }
  };

  const handleDecorImageUpload = async (e) => {
    if (e.target.files[0] && e.target.files[0].size > 2 * 1024 * 1024) {
      e.target.value = null;
      return message.error("File exceeds the limit of 2MB");
    }
    const formData = new FormData();
    formData.append("img_files", e.target.files[0]);
    const res = await eventUploadImageFile(formData);
    if (res.success === true) {
      setDecorImage(res.files[0].file);
    }
  };
  const handleSubmit = async () => {
    const event_foodbev = (foodBevListData || []).map((food) => ({
      ...food,
      menu: [
        {
          file: menuImage,
        },
      ],
    }));
    const event_proddecor = (prodListData || []).map((decor) => ({
      ...decor,
      decor_img: [
        {
          file: docerImage,
        },
      ],
    }));
    const apiDataToSend = {
      event: {
        ...eventDetails,
        hotels: hotelDetails,
        // event_vendors: [...vendorListData],
        event_vendors: {
          vendors: [...vendorListData],
        },
        event_foodbev,
        event_proddecor: event_proddecor,
      },
      ghms: {
        guestlist: [...ghmsListData],
      },
      checklist: generalchecklistData,
    };

    if (Object.keys(apiDataToSend).length > 0) {
      const res = await createCurrentEvent(apiDataToSend);
      if (res.success === true) {
        message.success("Submitted Successfully");
        window.location = "/admin/event";
      }
    }
  };

  const onDeleteGuestListData = (i) => {
    const filteredArr = ghmsListData.filter((_, idx) => {
      return idx !== i;
    });
    setghmsListData(filteredArr);
  };

  const onDeleteVendorListData = (i) => {
    const filteredArr = vendorListData.filter((_, idx) => {
      return idx !== i;
    });
    setVendorListData(filteredArr);
  };

  const onDeleteFoodBevList = (i) => {
    const filteredArr = foodBevListData.filter((_, idx) => {
      return idx !== i;
    });
    setFoodBevListData(filteredArr);
  };

  const onDeleteprodListData = (i) => {
    const filteredArr = prodListData.filter((_, idx) => {
      return idx !== i;
    });
    setProdListData(filteredArr);
  };
  const forms = [
    <EventDetail
      onFinish={onFinishEventDetailForm}
      initialValues={eventDetails}
      selectedCoordinator={selectedCoordinators}
      callBack={callBack}
    />,
    <HotelDetail
      defaultHotels={hotelDetails}
      handleHotelCreateSuccess={handleHotelCreateSuccess}
      handleRoomCreateSuccess={handleRoomCreateSuccess}
      hotelRooms={hotelRooms}
      hotelOptions={hotels}
      onFinish={onFinishHotelDetailForm}
    />,
    <GHMSDetail
      onFinish={onFinishGHMSDetailForm}
      initialValues={ghmsDetails}
      setCurrentStep={setCurrentStep}
      ghmsListData={ghmsListData}
      form={form}
      handleCSVToTableSave={handleCSVToTableSave}
      onDeleteGuestListData={onDeleteGuestListData}
    />,
    <VendorDetail
      onFinish={onFinishVendorDetailForm}
      initialValues={vendorDetails}
      setCurrentStep={setCurrentStep}
      vendorListData={vendorListData}
      form={form}
      onDeleteVendorListData={onDeleteVendorListData}
    />,
    <FoodBevDetail
      onFinish={onFinishFoodBevDetailForm}
      initialValues={foodBevDetails}
      handleMenuImageUpload={handleMenuImageUpload}
      setCurrentStep={setCurrentStep}
      foodBevListData={foodBevListData}
      form={form}
      onDeleteFoodBevList={onDeleteFoodBevList}
    />,
    <ProdDecorDetail
      onFinish={onFinishProdDecorDetailForm}
      initialValues={prodDecorDetails}
      handleDecorImageUpload={handleDecorImageUpload}
      setCurrentStep={setCurrentStep}
      prodListData={prodListData}
      handleSubmit={handleSubmit}
      form={form}
      onDeleteprodListData={onDeleteprodListData}
    />,
  ];
  return (
    <div className=" w-full overflow-y-hidden min-h-[80] z-50">
      {t("CREATE_EVENT")}
      <Steps
        style={{ padding: "32px 32px" }}
        onChange={setCurrentStep}
        current={currentStep}
      >
        <Steps.Step title={t("EVENT")} />
        <Steps.Step disabled={hotelDisable} title={t("HOTEL")} />
        <Steps.Step disabled={stepDisable} title={t("GHMS")} />
        <Steps.Step disabled={stepDisable} title={t("VENDORS")} />
        <Steps.Step disabled={stepDisable} title={t("FOOD_AND_BEVERAGE")} />
        <Steps.Step disabled={stepDisable} title={t("PRODUCTION")} />
      </Steps>
      {forms[currentStep]}
    </div>
  );
};

const EventDetail = ({
  onFinish,
  initialValues,
  selectedCoordinator,
  callBack,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [clientData, setClientData] = useState([]);
  const [coordinatorData, setCoordinatorData] = useState([]);
  const [endDate, setEndDate] = useState("");
  const [open, setOpen] = useState(false);
  const [clientID, setSetClientID] = useState("");
  const [form] = Form.useForm();
  const [userTitle, setUserTitle] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  let client_role;
  let coordinator_role;

  const fetchAllRoles = async () => {
    const res = await getAllRoles();
    client_role = res.role.filter((role) => role.role_name === "Client");
    coordinator_role = res.role.filter(
      (role) => role.role_name === "Coordinator"
    );
    function roleSetter(arr, roleName) {
      const roles = arr.filter((items) => items.role_name === roleName)[0]?._id;
      return roles;
    }
    setSetClientID(roleSetter(res?.role, "Client"));

    if (userTitle === "Create Client") {
      setSetClientID(roleSetter(res?.role, "Client"));
    } else if (userTitle === "Create Coordinator") {
      setSetClientID(roleSetter(res?.role, "Coordinator"));
    }
  };

  const fetchAllUser = async () => {
    const resRoles = await getAllRoles();
    client_role = resRoles.role.filter((role) => role.role_name === "Client");
    coordinator_role = resRoles.role.filter(
      (role) => role.role_name === "Coordinator"
    );

    const res = await getAllUserPages(1000, 1);
    setClientData(
      res.user.filter((client) => client.role_id._id === client_role[0]._id)
    );
    setCoordinatorData(
      res.user.filter((coor) => coor.role_id._id === coordinator_role[0]._id)
    );
  };

  useEffect(() => {
    fetchAllUser();
  }, [isModalOpen]);

  useEffect(() => {
    fetchAllRoles();
  }, [userTitle]);

  const coordinatorOptions = coordinatorData.map((coor) => {
    return {
      label: coor.name,
      value: coor._id?.toString(),
    };
  });

  const handleSave = async (values) => {
    const res = await createUser(values);
    if (res.success) {
      if (userTitle === "Create Coordinator") {
        message.success("Coordinator created successfully");
      } else if (userTitle === "Create Client") {
        message.success("Client Created Successfully");
      }
      setOpen(false);
      await fetchAllUser(); // Fetch all users again to update the clientData dropdown
    }
    if (res.response && res.response.status === 400) {
      message.error(res.response.data.msgText);
    }
  };

  const validateCoordinatorSelection = () => {
    if (selectedCoordinator.length > 0) {
      return Promise.resolve();
    }
    return Promise.reject("Coordinator Name is required");
  };

  const handleSelectChange = (value) => {
    // setSelectedCoordinators(value);

    // console.log(value, "val");

    // selectedCoordinator.push(...value);
    // console.log(selectedCoordinator, "coordinatorids");
    callBack(value);
  };

  return (
    <div className="relative">
      <div className="">
        <button
          onClick={() => {
            setOpen(true);
            setUserTitle("Create Client");
          }}
          className="absolute right-0 z-10 p-2 text-white bg-blue-500 cursor-pointer top-6 whitespace-nowrap max-w-fit"
        >
          Add Client
        </button>
      </div>
      <Form form={form} onFinish={() => handleSave()}>
        <EventClientCreate
          open={open}
          handleClose={handleClose}
          clientID={clientID}
          handleSave={handleSave}
          title={userTitle}
        />
      </Form>

      <Form layout="vertical" onFinish={onFinish} initialValues={initialValues}>
        <div className="grid grid-cols-2 gap-x-4">
          <Form.Item
            label={"Event Type"}
            name="event_type"
            rules={[
              {
                required: true,
                message: "Event type is required",
              },
            ]}
          >
            <Select placeholder="Select event type">
              <Select.Option value="">Select Option</Select.Option>
              <Select.Option value={EventType.Birthday}>Birthday</Select.Option>
              <Select.Option value={EventType.WeekendParty}>
                Weekend Party
              </Select.Option>
              <Select.Option value={EventType.Anniversary}>
                Anniversary
              </Select.Option>
              <Select.Option value={EventType.Wedding}>Wedding</Select.Option>
              <Select.Option value={EventType.ReceptionParty}>
                Reception Party
              </Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={"Client Name"}
            name="client_id"
            rules={[
              {
                required: true,
                message: "Client Name is required",
              },
            ]}
          >
            <Select style={{ width: "65%" }} placeholder="Select Client Name">
              <Select.Option value="">Select Option</Select.Option>
              {clientData.map((client) => (
                <Select.Option key={client._id} value={client._id}>
                  {client.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={"Coordinator Name"}
            name="coordinator_ids"
            rules={[
              {
                validator: validateCoordinatorSelection,
                required: true,
              },
            ]}
          >
            <Select
              mode="multiple"
              allowClear
              options={coordinatorOptions}
              style={{ width: "65%" }}
              onChange={handleSelectChange}
              value={selectedCoordinator}
            />
            <button
              onClick={() => {
                setOpen(true);
                setUserTitle("Create Coordinator");
              }}
              className=" ml-4 gap-1 right-0 z-10 p-2 text-white bg-blue-500 cursor-pointer top-6 whitespace-nowrap max-w-fit"
            >
              Add Coordinator
            </button>
            <Form form={form} onFinish={() => handleSave()}>
              <EventClientCreate
                open={open}
                handleClose={handleClose}
                clientID={clientID}
                handleSave={handleSave}
                title={userTitle}
              />
            </Form>
          </Form.Item>

          <Form.Item
            label={"Event Title"}
            name="event_title"
            rules={[
              {
                required: true,
                message: "Event title is required",
              },
              {
                validator: (_, value) => {
                  if (value && value.length < 3) {
                    return Promise.reject("Must be atleast 3 chars");
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input placeholder="enter event title" />
          </Form.Item>
          <Form.Item
            label={"Event Description"}
            name="event_descp"
            rules={[
              {
                required: true,
                message: "Event description is required",
              },
              {
                validator: (_, value) => {
                  if (value && value.length < 3) {
                    return Promise.reject("Must be alteast 3 chars");
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <TextArea placeholder="enter event description" />
          </Form.Item>
          <Form.Item
              label="Hospitality Checklist Visibilty"
              name="is_hospitality_checklist_visible"
              rules={[
                {
                  required: true,
                  message: "Checklist is required",
                },
              ]}
            >
               <Radio.Group >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
          </Form.Item>
          <div className="flex">
            <Form.Item
              label={"Event Start Date"}
              name="event_start_date"
              rules={[
                {
                  required: true,
                  message: "Event Start date is required",
                },
              ]}
            >
              <Input
                type="date"
                onChange={(e) => setEndDate(e.target.value)}
                // required
                min={viewCurrentDates}
              />
            </Form.Item>
          </div>
          <Form.Item label={"Event End Date"} name="event_end_date">
            <Input type="date" min={endDate} />
          </Form.Item>
          <Form.Item
            label={"Event Remarks"}
            name="event_remark"
            rules={[
              {
                validator: (_, value) => {
                  if (value && value.length < 3) {
                    return Promise.reject("Must be atleast 3 chars");
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <TextArea placeholder="Enter remarks" />
          </Form.Item>
        </div>
        <div className="flex justify-end">
          <Button type="primary" htmlType="submit">
            Continue
          </Button>
        </div>
      </Form>
    </div>
  );
};

const GHMSDetail = ({
  onFinish,
  initialValues,
  setCurrentStep,
  ghmsListData,
  form,
  handleCSVToTableSave,
  onDeleteGuestListData,
}) => {
  const [hideGuestInvited, setHideGuestInvited] = useState("");
  return (
    <div>
      <div className="flex justify-end mr-48 mb-2">
        <label>Import Guestlist</label>
      </div>
      <div className="flex justify-between">
        <h1>Guestlist/Invitation</h1>
        <ImportCSV handleCSVToTableSave={handleCSVToTableSave} />
      </div>
      <>
        <Form
          layout="vertical"
          onFinish={onFinish}
          // initialValues={initialValues}
          form={form}
        >
          <div className="grid grid-cols-2 gap-x-2">
            <Form.Item
              label={"Guest Name"}
              name="guest_name"
              rules={[
                {
                  required: true,
                  message: "Guest name is required",
                },
                {
                  validator: (_, value) => {
                    if (value && value.length < 3) {
                      return Promise.reject("Must be alteast 3 chars");
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <Input placeholder="enter guest name" />
            </Form.Item>
            {/* <Form.Item
              label={"Email Address"}
              name="guest_email"
              rules={[
                {
                  type: "email",
                  message: "Enter a valid E-mail!",
                },
              ]}
            >
              <Input placeholder="enter guest email" />
            </Form.Item>
            <Form.Item
              label={"Address"}
              name="guest_add"
              rules={[
                {
                  required: true,
                  message: "Address is required",
                },
                {
                  validator: (_, value) => {
                    if (value && value.length < 3) {
                      return Promise.reject("Must be alteast 3 chars");
                    } else {
                      return Promise.resolve();
                    }
                  },
                },
              ]}
            >
              <TextArea placeholder="enter address" />
            </Form.Item> */}
            <Form.Item
              label={"Mobile Number"}
              name="guest_mobile"
              rules={[
                {
                  required: true,
                  message: "Mobile number is required",
                },
                {
                  validator: (_, value) => {
                    if (value && value.toString().length === 10) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject("Enter a valid number");
                    }
                  },
                },
              ]}
            >
              <Input type="number" placeholder="enter mobile number" />
            </Form.Item>
            <Form.Item
              label={"Guest Outstation"}
              name="guest_outstation"
              rules={[
                {
                  required: true,
                  message: "Guest Outstation is required",
                },
              ]}
            >
              <Select placeholder="Select Guest Outstation">
                <Select.Option value="">Select Option</Select.Option>
                <Select.Option value={GuestOutstation.Local}>
                  Local
                </Select.Option>
                <Select.Option value={GuestOutstation.Outstation}>
                  Out Station
                </Select.Option>
              </Select>
            </Form.Item>
            {/* <Form.Item
              label={"Guest Invited"}
              name="guest_invited"
              rules={[
                {
                  required: true,
                  message: "Invite type is required",
                },
              ]}
            >
              <Select
                placeholder="Select Invite Type"
                onChange={(e) => setHideGuestInvited(e)}
              >
                <Select.Option value="">Select Option</Select.Option>
                <Select.Option value={GuestInvited.Family}>
                  Family
                </Select.Option>
                <Select.Option value={GuestInvited.Individual}>
                  Individual
                </Select.Option>
              </Select>
            </Form.Item> */}
            {/* <Form.Item
              label={"Number Of Guest Expected"}
              name="guest_expected_nos"
              rules={[
                {
                  required: hideGuestInvited === "Individual" ? "" : true,
                  message: "Guest number is required",
                },
              ]}
            >
              <Input
                disabled={hideGuestInvited === "Individual" ? true : ""}
                type="number"
                min={"1"}
                placeholder="enter  expected guest number"
              />
            </Form.Item> */}
            <Form.Item
              label={"Digital Invitation Type"}
              name="digital_invitation"
              rules={[
                {
                  required: true,
                  message: "Invitation type is required",
                },
              ]}
            >
               <Radio.Group >
                <Radio value={true}>Yes</Radio>
                <Radio value={false}>No</Radio>
              </Radio.Group>
              {/* <Select placeholder="Select Digital Invitation Type">
                <Select.Option value="">Select Option</Select.Option>
                <Select.Option value={InvitationType.Digitally}>
                  Digitally
                </Select.Option>
                <Select.Option value={InvitationType.Courier}>
                  Courier
                </Select.Option>
                <Select.Option value={InvitationType.Personally}>
                  Personally
                </Select.Option>
              </Select> */}
            </Form.Item>
            <Form.Item
              label={"Notes"}
              name="notes"
              rules={[
                {
                  required: true,
                  message: "notes is required",
                },
              ]}
            >
              <Input type="text" />
            </Form.Item>
          </div>
          <div className="flex justify-end">
            <Button type="primary" htmlType="submit">
              Save into Table
            </Button>
            <Button onClick={() => setCurrentStep(3)}>Continue</Button>
          </div>
        </Form>
      </>
      <table className="w-full mt-4 text-sm text-left text-gray-500 dark:text-gray-400">
        <tr>
          <th>SN.</th>
          <th>Guest Name</th>
          <th>Mobile Number</th>
          <th>OutStation</th>
          <th>Digital Invitation</th>
          <th>Notes</th>
          {/* <th>Guest Email</th>
          <th>Guest Address</th> */}
          {/* <th>Guest Invited</th> */}
          {/* <th>Number Of Guest Expected</th> */}
          {/* <th>Guest Date Of Arrival</th> */}
        </tr>
        {console.log("guestlsit",ghmsListData)}
        {ghmsListData.map((guest, i) => (
          <tr
            key={i}
            className="text-black border-b dark:bg-gray-200 dark:border-gray-700"
          >
            <td>{i + 1}</td>
            <td>{guest.guest_name}</td>
            <td>{guest.guest_mobile}</td>
            <td>{guest.guest_outstation}</td>
            <td>{guest.digital_invitation ? "Yes": "No"}</td>
            <td>{guest.notes}</td>
            {/* <td>{guest.guest_email}</td>
            <td>{guest.guest_add}</td> */}
            {/* <td>{guest.guest_invited}</td>
            <td>{guest.guest_expected_nos}</td> */}
            {
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => onDeleteGuestListData(i)}
              >
                <td>
                  {" "}
                  <span className="text-red-500 cursor-pointer">Delete</span>
                </td>
              </Popconfirm>
            }
          </tr>
        ))}
      </table>
    </div>
  );
};

const VendorDetail = ({
  onFinish,
  vendorListData,
  setCurrentStep,
  form,
  onDeleteVendorListData,
}) => {
  const [vendorData, setVendorData] = useState([]);

  useEffect(() => {
    const fetchAllVendors = async () => {
      const res = await getAllVendors();
      setVendorData(res.vendor);
    };
    fetchAllVendors();
  }, []);

  return (
    <div>
      <Form layout="vertical" onFinish={onFinish} form={form}>
        <div className="grid grid-cols-2 gap-x-4">
          {/* <Form.Item
            label={"Vendor Name"}
            name="vendor_name"
            rules={[
              {
                required: true,
                message: "Vendor name is required",
              },
            ]}
          >
            <Select>
              <Select.Option value="">Select Option</Select.Option>
              {vendorData.map((vend) => (
                <Select.Option key={vend._id} value={vend._id}>
                  {vend.vendor_name}
                </Select.Option>
              ))}
            </Select>
              </Form.Item> */}
          <Form.Item
            label={"Vendor Name"}
            name="vendor_name"
            rules={[
              {
                required: true,
                message: "Vendor Name is required",
              },
              {
                validator: (_, value) => {
                  if (value && value.length < 3) {
                    return Promise.reject("Must be atleast 3 chars");
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input type="text" placeholder="Enter vendor name" />
          </Form.Item>

          <Form.Item
            label={"Vendor Work"}
            name="vendor_work"
            rules={[
              {
                required: true,
                message: "Vendor work is required",
              },
              {
                validator: (_, value) => {
                  if (value && value.length < 3) {
                    return Promise.reject("Must be atleast 3 chars");
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input type="text" placeholder="Enter vendor work" />
          </Form.Item>
          <Form.Item
            label={"Vendor Mobile"}
            name="vendor_mobile"
            rules={[
              {
                required: true,
                message: "Vendor Mobile is required",
              },
              {
                validator: (_, value) => {
                  if (value && value.toString().length !== 10) {
                    return Promise.reject("Enter a valid number");
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input type="number" placeholder="Enter vendor mobile" />
          </Form.Item>

          <Form.Item
            label={"Vendor Scope Of Work"}
            name="scope_of_work"
            rules={[
              {
                required: true,
                message: "Vendor Scope of work is required",
              },
              {
                validator: (_, value) => {
                  if (value && value.length < 3) {
                    return Promise.reject("Must be atleast 3 chars");
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <TextArea placeholder="enter vendor scope of work" />
          </Form.Item>
          <Form.Item label={"Due Amount"} name="due_amount">
            <Input type="number" placeholder="enter due amount" />
          </Form.Item>
          <Form.Item label={"Paid Amount"} name="paid_amount">
            <Input type="number" placeholder="enter paid amount" />
          </Form.Item>
          <Form.Item label={"Total Package"} name="total_package">
            <Input type="number" placeholder="enter total package" />
          </Form.Item>
          <Form.Item
            label={"Arriving Time"}
            name="arriving_time"
            // rules={[
            //   {
            //     required: true,
            //     message: "Arriving Time is required",
            //   },
            // ]}
          >
            <Input type="time" required />
          </Form.Item>
        </div>
        <div className="flex justify-end">
          <Button type="primary" htmlType="submit">
            Save into Table
          </Button>
          <Button onClick={() => setCurrentStep(4)}>Continue</Button>
        </div>
      </Form>
      <table className="w-full mt-4 text-sm text-left text-gray-500 dark:text-gray-400">
        <tr>
          <th>SN.</th>
          <th>Vendor Name</th>
          <th>Vendor Work</th>
          <th>Vendor Mobile</th>
          <th>Vendor Scope Of Work</th>
          <th>Due Amount</th>
          <th>Paid Amount</th>
          <th>Total Package</th>
          <th>Arriving Time</th>
        </tr>

        {vendorListData.map((vendor, i) => (
          <tr
            key={i}
            className="text-black border-b dark:bg-gray-200 dark:border-gray-900"
          >
            <td>{i + 1}</td>
            {/* <td>
              {vendorData.find((i) => i._id === vendor.vendor_id)?.vendor_name}
            </td> */}
            <td>{vendor.vendor_name}</td>
            <td>{vendor.vendor_work}</td>
            <td>{vendor.vendor_mobile}</td>
            <td>{vendor.scope_of_work}</td>
            <td>{vendor.due_amount}</td>
            <td>{vendor.paid_amount}</td>
            <td>{vendor.total_package}</td>
            <td>{vendor.arriving_time}</td>
            {
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => onDeleteVendorListData(i)}
              >
                <td>
                  {" "}
                  <span className="text-red-500 cursor-pointer">Delete</span>
                </td>
              </Popconfirm>
            }
          </tr>
        ))}
      </table>
    </div>
  );
};
const FoodBevDetail = ({
  onFinish,
  handleMenuImageUpload,
  foodBevListData,
  setCurrentStep,
  form,
  onDeleteFoodBevList,
}) => {
  return (
    <div>
      <h1>Menu</h1>
      <label>Menu Image</label>
      <br></br>

      <Form form={form} layout="vertical" onFinish={onFinish}>
        <input
          required
          type="file"
          accept="image/png, image/jpeg"
          onChange={(e) => handleMenuImageUpload(e)}
        />
        <div className="grid grid-cols-2 gap-x-4">
          <Form.Item
            label={"Food Type"}
            name="food_type"
            rules={[
              {
                required: true,
                message: "Food Type is required",
              },
            ]}
          >
            <Select placeholder="Select Food Type">
              <Select.Option value="">Select Option</Select.Option>
              <Select.Option value={FoodType.Breakfast}>
                Breakfast
              </Select.Option>
              <Select.Option value={FoodType.Dinner}>Dinner</Select.Option>
              <Select.Option value={FoodType.HiTea}>Hi Tea</Select.Option>
              <Select.Option value={FoodType.Lunch}>Lunch</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={"Serve Date"}
            name="serve_date"
            rules={[
              {
                required: true,
                message: "Serve Date is required",
              },
            ]}
          >
            <Input type="date" required min={viewCurrentDates} />
          </Form.Item>
          <Form.Item
            label={"Serve Start Time"}
            name="serve_start_time"
            rules={[
              {
                required: false,
                message: "Serve Start Time is required",
              },
            ]}
          >
            <Input type="time" required />
          </Form.Item>
          <Form.Item
            label={"Serve End Time"}
            name="serve_end_time"
            rules={[
              {
                required: false,
                message: "Serve End Time is required",
              },
            ]}
          >
            <Input type="time" required />
          </Form.Item>
          <Form.Item label={"Plates Guaranteed"} name="plates_guaranteed">
            <Input type="number" placeholder="enter guaranteed plates" />
          </Form.Item>
          <Form.Item label={"Plates Added"} name="plates_added">
            <Input type="number" placeholder="enter plates added" />
          </Form.Item>
          <Form.Item label={"Plates Remaining"} name="plates_remaining">
            <Input type="number" placeholder="enter plates remaining" />
          </Form.Item>
          <Form.Item label={"Plates Used"} name="plates_used">
            <Input type="number" placeholder="enter plates used" />
          </Form.Item>
        </div>
        <div className="flex justify-end">
          <Button type="primary" htmlType="submit">
            Save into Table
          </Button>
          <Button onClick={() => setCurrentStep(5)}>Continue</Button>
        </div>
      </Form>
      <table className="w-full mt-4 text-sm text-left text-gray-500 dark:text-gray-400">
        <tr>
          <th>SN.</th>
          <th>Image</th>
          <th>Food Type</th>
          <th>Serve Date</th>
          <th>Serve S Time</th>
          <th>Serve E Time</th>
          <th>pts Grtd</th>
          <th>pts Added</th>
          <th>pts rem.</th>
          <th>pts used</th>
        </tr>

        {foodBevListData?.map((food, i) => (
          <tr
            key={i}
            className="text-black border-b dark:bg-gray-200 dark:border-gray-700"
          >
            <td>{i + 1}</td>
            <td>{"Image"}</td>
            <td>{food.food_type}</td>
            <td>{food.serve_date}</td>
            <td>{food.serve_start_time}</td>
            <td>{food.serve_end_time}</td>
            <td>{food.plates_guaranteed}</td>
            <td>{food.plates_added}</td>
            <td>{food.plates_remaining}</td>
            <td>{food.plates_used}</td>
            {
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => onDeleteFoodBevList(i)}
              >
                <td>
                  {" "}
                  <span className="text-red-500 cursor-pointer">Delete</span>
                </td>
              </Popconfirm>
            }
          </tr>
        ))}
      </table>
    </div>
  );
};
const ProdDecorDetail = ({
  onFinish,
  handleDecorImageUpload,
  prodListData,
  handleSubmit,
  form,
  onDeleteprodListData,
}) => {
  return (
    <div>
      <h1>Prod Decor details</h1>
      <label>Decoration Image</label>
      <br></br>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <input
          required
          type="file"
          accept="image/png, image/jpeg"
          onChange={(e) => handleDecorImageUpload(e)}
        />
        <div className="grid grid-cols-2 gap-x-4">
          <Form.Item
            label={"Decor Title"}
            name="decor_title"
            rules={[
              {
                required: true,
                message: "Decor Title is Required",
              },
              {
                validator: (_, value) => {
                  if (value && value.length < 3) {
                    return Promise.reject("Must be atleast 3 chars");
                  } else {
                    return Promise.resolve();
                  }
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={"Decor Date"}
            name="decor_date"
            rules={[
              {
                required: true,
                message: "Decor Date is required",
              },
            ]}
          >
            <Input type="date" required min={viewCurrentDates} />
          </Form.Item>
          <Form.Item
            label={"Expected Decor Time"}
            name="expected_decor_time"
            rules={[
              {
                required: true,
                message: "Expected Decor Time is required",
              },
            ]}
          >
            <Input type="time" required />
          </Form.Item>
        </div>
        <div className="flex justify-end">
          <Button type="primary" htmlType="submit">
            Save into Table
          </Button>
          <Button type="primary" onClick={() => handleSubmit()}>
            Submit
          </Button>
        </div>
      </Form>
      <table className="w-full mt-4 text-sm text-left text-gray-500 dark:text-gray-400">
        <tr>
          <th>SN.</th>
          <th>Image</th>
          <th>Decor Title</th>
          <th>Decor Date</th>
          <th>Expected Decor Time</th>
        </tr>

        {prodListData?.map((prod, i) => (
          <tr
            key={i}
            className="text-black border-b dark:bg-gray-200 dark:border-gray-700"
          >
            <td>{i + 1}</td>
            <td>image</td>
            <td>{prod.decor_title}</td>
            <td>{prod.decor_date}</td>
            <td>{prod.expected_decor_time}</td>
            {
              <Popconfirm
                title="Sure to delete?"
                onConfirm={() => onDeleteprodListData(i)}
              >
                <td>
                  {" "}
                  <span className="text-red-500 cursor-pointer">Delete</span>
                </td>
              </Popconfirm>
            }
          </tr>
        ))}
      </table>
    </div>
  );
};

export default SidebarHeaderLayout(CreateCurrentEvent);
