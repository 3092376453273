import React from "react";
import { Button, Input, Popconfirm, Table, Typography } from "antd";
import { IEvent, IPrioritization } from "src/types";
import EditPriortization, { Priority } from "./EditPriortization";
import {
  createPriotization,
  deletePriortizationList,
  updatePriortizationList,
} from "src/api-core/Priotization";
import { ShouldRender } from "src/components/ShouldRender";
import moment from "moment";
import { TIME_FORMATS } from "src/constants";

interface Props {
  event: IEvent | undefined;
  priortization?: IPrioritization[];
  loading: boolean;
  update: () => Promise<void>;
}

export const PriortizationTable = ({
  event,
  priortization = [],
  loading,
  update,
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const [recordIndex, setRecordIndex] = React.useState(-1);
  const [searchedText, setSearchedText] = React.useState("");

  const handleEdit = (index: number) => {
    setOpen(true);
    setRecordIndex(index);
  };

  const handleClose = () => {
    setOpen(false);
    setRecordIndex(-1);
  };

  const handleOpen = () => setOpen(true);

  const handleUpdate = (data: Partial<Priority>) => {
    if (event) {
      const { client_id, _id: event_id } = event;
      const payload = {
        // client_id, 
        event_id,
        ...data,
      };
      if (recordIndex !== -1) {
        return updatePriortizationList(payload, priortization[recordIndex]._id)
          .then(update)
          .then(handleClose);
      } else {
        return createPriotization(payload).then(update).then(handleClose);
      }
    }
  };

  const handleSave = async (values: Priority) => {
    const { title, deadline_date, descp, contact } = values;
    const data = {
      contact: contact?.toString(),
      deadline_date,
      descp,
      title,
    };
    await handleUpdate(data as any);
  };

  const handleDelete = (id: string) => {
    const ids = [id];
    deletePriortizationList(ids).then(update);
  };

  const defaultColumns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      editable: true,
    },
    {
      title: "Description",
      dataIndex: "descp",
      key: "descp",
      editable: true,
    },
    {
      title: "Deadline Date",
      dataIndex: "deadline_date",
      key: "deadline_date",
      editable: true,
      render: (_: any, record: IPrioritization) => {
        return moment(record.deadline_date).format(TIME_FORMATS.DATE);
      },
    },
    {
      title: "Contact",
      dataIndex: "contact",
      key: "contact",
      editable: true,
      filteredValue: [searchedText],
      onFilter: (value:any, record:any)=>{
        return  String(record.title)
                .toLowerCase()
                .includes(value.toLowerCase()) ||
                String(record.descp)
                .toLowerCase()
                .includes(value.toLowerCase()) ||
                String(record.deadline_date)
                .toLowerCase()
                .includes(value.toLowerCase())
      },
      render: (contact: IPrioritization) => (
        <a href={`tel: ${contact}`}>{`${contact}`}</a>
      )
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: IPrioritization) => {
        const index = priortization.findIndex(
          (item) => item._id === record._id
        );
        return (
          <span>
            <Typography.Link
              disabled={recordIndex !== -1}
              onClick={() => handleEdit(index)}
              style={{ marginRight: 8 }}
            >
              Edit
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record._id)}
            >
              <Typography.Link>Delete</Typography.Link>
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="flex justify-end mb-3">
        <Button onClick={handleOpen}>Add Data</Button>
      </div>
      <Input.Search size="large" placeholder="search here..."
             style={{marginBottom: 8, width: 280}}
             onSearch={(value)=>{
              setSearchedText(value);
             }}
             onChange={(e)=>{
              setSearchedText(e.target.value);
             }}/>
      <Table
        columns={defaultColumns}
        dataSource={priortization}
        key="_id"
        loading={loading}
      />
      <ShouldRender check={open}>
        <EditPriortization
          open={open}
          priority={priortization[recordIndex]}
          title={recordIndex === -1 ? "Add Prioritisation" : "Edit Prioritisation"}
          handleClose={handleClose}
          handleSave={handleSave}
        />
      </ShouldRender>
    </React.Fragment>
  );
};
