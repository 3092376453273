import React, { useEffect, useState } from "react";

import SidebarHeaderLayout from "../HOC/SidebarHeaderLayout";
import { useParams } from "react-router-dom";
import { Table, Descriptions, Image } from "antd";
import { formatDate } from "src/utils/helper";
import { getSinglePriotization } from "../../api-core/Priotization";

const SinglePriotization = () => {
  const { id } = useParams();
  const [data, setData] = useState<any[]>([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    const fetchSingleVendor = async () => {
      setloading(true);
      const res = await getSinglePriotization(id);    
      setData(res.priortizationlist);
      setloading(false);
    };
    fetchSingleVendor();
  }, []);
  const datas = data.map((item) => ({
    key: item._id,
    client_name: item.client_name,
    event_name: item.event_name,
    title: item.title,
    descp: item.descp,
    contact: item.contact,
    deadline_date: formatDate(item.deadline_date, "ddd-mm-YYYY"),
  }));

const columns = [
    {
        title: "Serial No.",
        dataIndex: "id",
        key: "id",
        sorter: (a: { id: number; }, b: { id: number; }) => a.id - b.id,
        render: (id: any, record: any, index: number) => {
          ++index;
          return index;
        },
        showSorterTooltip: false,
      },
      {
        title: "Client Name",
        dataIndex: "client_name",
        key: "client_name",
        ellipsis: true,
      },
      {
        title: "Event Name",
        dataIndex: "event_name",
        key: "event_name",
        ellipsis: true,
      },
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
        ellipsis: true,
      },
      {
        title: "Description",
        dataIndex: "descp",
        key: "descp",
        ellipsis: true,
      },
      {
        title: "Contact Number",
        dataIndex: "contact",
        key: "contact",
      },
      {
        title: "Deadline Date",
        dataIndex: "deadline_date",
        key: "deadline_date",
      },
]
  return (
    <div>
      <div>
        <Descriptions
          title="Prioritisation Details"
          bordered
          layout="horizontal"
          column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
        >
          <Descriptions.Item
            label="Client's Name"
            labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
          >
            <span className="text-lg"> {datas[0]?.client_name}</span>
          </Descriptions.Item>

          <Descriptions.Item
            label="Event Name"
            labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
          >
            <span className="text-lg"> {datas[0]?.event_name}</span>
          </Descriptions.Item>
          
          <Descriptions.Item
            label="Title"
            labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
          >
            <span className="text-lg"> {datas[0]?.title}</span>
          </Descriptions.Item>

          <Descriptions.Item
            label="Description"
            labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
          >
            <span className="text-lg">{datas[0]?.descp}</span>
          </Descriptions.Item>
          <Descriptions.Item
            label="Contact Number"
            labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
          >
            <span className="text-lg">
              <a href={`tel:${datas[0]?.contact}`} >{datas[0]?.contact}</a>
            </span>
          </Descriptions.Item>
          <Descriptions.Item
            label="Deadline Date"
            labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
          >
            <span className="text-lg">{datas[0]?.deadline_date}</span>
          </Descriptions.Item>
        </Descriptions>
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(SinglePriotization);
