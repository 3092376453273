import React, { useEffect, useState } from "react";
import { message, Popconfirm, Table } from "antd";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link } from "react-router-dom";

import { deleteEvent, getAllEvents } from "../../../api-core/Events";

const ListOfAllEvents = () => {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    fetchAllEvents();
  }, []);
  const fetchAllEvents = async () => {
    setloading(true);
    const res = await getAllEvents();
    setdata(res.event);
    setloading(false);
  };
  const onDeleteRecord = async (recordId) => {
    const res = await deleteEvent(recordId);
    if (res.success === true) {
      message.success(res.msgText);
      fetchAllEvents();
    }
    // console.log("deData", res);
  };

  const datas = data?.map((item) => ({
    key: item._id,
    eventTitle: item.event_title,
    eventDesc: item.event_descp,
    eventImage: item.event_img,
  }));
  // console.log("datas", datas);

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Event Title",
      dataIndex: "eventTitle",
      key: "eventTitle",
      ellipsis: true,
    },

    {
      title: "Event Desc",
      dataIndex: "eventDesc",
      key: "eventDesc",

      ellipsis: true,
    },
    {
      title: "Event Image",
      dataIndex: "eventImage",
      key: "eventImage",

      ellipsis: true,
      render: (_, record) => (
        <div className="w-10 h-10">
          <img alt="event" src={record.eventImage} />
        </div>
      ),
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex gap-x-2 ">
          {/* {console.log("rec", record)} */}
          <Link to={`/admin/singleevent/${record.key}`}>
            <button className="text-blue-600 cursor-pointer">View</button>
          </Link>

          <Link to={`/admin/updateevent/${record.key}`}>
            <button className="text-yellow-400 cursor-pointer">Edit</button>
          </Link>

          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => onDeleteRecord(record.key)}
          >
            <span className="text-red-500 cursor-pointer">Delete</span>
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div className="">
      <Link to="/admin/createevent">
        <button className="p-3 mx-3 text-white bg-blue-600">
          Create Event
          
        </button>
      </Link>
      <h1 className="text-center">List Of All Events</h1>
      <div className="">
        <Table
          dataSource={datas}
          columns={columns}
          loading={loading}
          // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        />
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(ListOfAllEvents);
