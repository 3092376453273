import Axios from "../Axios";

export const getAllPriotization = async () => {
  try {
    const response = await Axios.post(`/admin/priortizationlist`);
    return response.data;
  } catch (error) {
    return error;
  }
};
/*Get all priortization according to pages */
export const getAllPriotizationPages = async (row, pages) => {
  try {
    const response = await Axios.post(
      `/admin/priortizationlist?r=${row}&p=${pages}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const createPriotization = async (data) => {
  try {
    const response = await Axios.post(`/admin/priortizationlist/create`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getSinglePriotization = async (id) => {
  try {
    const response = await Axios.get(`/admin/priortizationlist/read/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deletePriotization = async (id) => {
  try {
    const response = await Axios.post(`/admin/priortizationlist/delete`, id);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updatePriortizationList = async (data, id) => {
  try {
    const response = await Axios.post(
      `/admin/priortizationlist/update/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deletePriortizationList = async (ids) => {
  const data = {
    ids,
  };
  try {
    const response = await Axios.post("/admin/priortizationlist/delete", data);
    return response.data;
  } catch (error) {
    return error;
  }
};
