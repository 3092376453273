import React, { useEffect, useState } from "react";
import { Button, Input, message, Popconfirm, Table } from "antd";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link } from "react-router-dom";
import {
  deleteFreelancer,
  getAllFreelancer,
  getAllFreelancerPages,
} from "../../../api-core/Freelancer";

const ListOfFreelancer = () => {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [row, setRow] = useState(10);
  const [pages, setPages] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [open, setOpen] = useState(false);
  const [searchedText, setSearchedText] = useState("");

  useEffect(() => {
    fetchAllFreelancer();
  }, [row, pages]);
  const fetchAllFreelancer = async () => {
    setloading(true);
    const res = await getAllFreelancerPages(row, pages);
    setRow(res?.count);

    setdata(res?.freelancer);
    setloading(false);
  };

  const datas = data?.map((item) => ({
    key: item._id,
    name: item.name,
    deptId: item.department_type,
    whatsAppCon: item.wa_contact_no,
    mobileNo: item.alt_contact_no,
    gender: item.gender,

    // city: item.city,
    // // currentCity: item.current_city,
    // experience: item.experience,
    // photo: item.pass_size_pic,
    // tShirtSize: item.tshirt_size,
    // course: item.course,
    // coordination: item.coordination,
    // workOfShadow: item.work_of_shadow,
  }));

  const onDeleteRecord = async (record, id) => {
    const delData = {
      ids: [record],
    };
    const res = await deleteFreelancer(delData.ids[0]);
    if (res.success == true) {
      message.success("deleted succefully");
      fetchAllFreelancer();
      // const updatedData = data.filter((items)=>items._id !== record)
      // setdata(updatedData)
    }
  };
  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filteredValue: [searchedText],
      onFilter: (value, record)=>{
        return String(record.name)
                .toLowerCase()
                .includes(value.toLowerCase()) ||
                String(record.deptId)
                .toLowerCase()
                .includes(value.toLowerCase()) ||
                String(record.whatsAppCon)
                .toLowerCase()
                .includes(value.toLowerCase()) ||
                String(record.mobileNo)
                .toLowerCase()
                .includes(value.toLowerCase())

      }
    },
    {
      title: "Dept Name",
      dataIndex: "deptId",
      key: "deptId",
      ellipsis: true,
    },
    {
      title: "WhatsApp No",
      dataIndex: "whatsAppCon",
      key: "whatsAppCon",
      ellipsis: true,
      render:(whatsAppCon) => (
        <a href={`tel:${whatsAppCon}`} >{whatsAppCon}</a>
      )
    },
    {
      title: "Mobile No",
      dataIndex: "mobileNo",
      key: "mobileNo",
      ellipsis: true,
      render:(mobileNo) => (
        <a href={`tel:${mobileNo}`} >{mobileNo}</a>
      )
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      ellipsis: true,
    },
    // {
    //   title: "City",
    //   dataIndex: "city",
    //   key: "city",
    // },
    // {
    //   title: "Current City",
    //   dataIndex: "currentCity",
    //   key: "currentCity",

    //   ellipsis: true,
    //   render: (_, record) => (
    //     <div className="w-10 h-10">
    //       <img src={record.bannerimage} />
    //     </div>
    //   ),
    // },
    // {
    //   title: "Experience",
    //   dataIndex: "experience",
    //   key: "experience",
    // },
    // {
    //   title: "Photo",
    //   dataIndex: "photo",
    //   key: "photo",
    //   render: (_, record) => (
    //     <div className="w-10 h-10">
    //       <img src={record.photo} />
    //     </div>
    //   ),
    // },
    // {
    //   title: "T-Shirt-Size",
    //   dataIndex: "tShirtSize",
    //   key: "tShirtSize",
    // },

    // {
    //   title: "Course",
    //   dataIndex: "course",
    //   key: "course",
    // },
    // {
    //   title: "Coordination",
    //   dataIndex: "coordination",
    //   key: "coordination",
    // },
    // {
    //   title: "Work Of Shadow",
    //   dataIndex: "workOfShadow",
    //   key: "workOfShadow",
    // },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex gap-x-2 ">
          <Link to={`/admin/singlefreelancer/${record.key}`}>
            <button className="text-blue-600 cursor-pointer">View</button>
          </Link>

          <Link to={`/admin/updatefreelancer/${record.key}`}>
            <button className="text-yellow-400 cursor-pointer">Edit</button>
          </Link>

          <Popconfirm
            title="Sure to delete?"
            onConfirm={(e) => onDeleteRecord(record.key, e)}
          >
            <span className="text-red-500 cursor-pointer">Delete</span>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };


  const deleteAllList = async () => {
    if (selectedRowKeys) {
      const deletePayload = {
        ids: [...selectedRowKeys],
      };
      if (deletePayload) {
        const res = await deleteFreelancer(deletePayload);
        // console.log("res Deleted", res);
        if (res.success == true) {
          message.success(
            `${selectedRowKeys.length} items deleted successfully`
          );
          setSelectedRowKeys(0);
          fetchAllFreelancer();
        }
      }
    }
  };

  return (
    <div className="w-full overflow-auto">
      <Link to="/admin/createfreelancer">
        <button className="bg-blue-600 text-white p-3 mx-3">
          Add Freelancer
        </button>
      </Link>
{/*       
      {selectedRowKeys.length > 1 && (
                <>
                  <Popconfirm
                    title={`Do you really wanted to delete ${selectedRowKeys.length} items`}
                    onConfirm={() => deleteAllList()}
                  >
                    {selectedRowKeys.length > 1 ? (
                      <Button danger type="primary">
                        Delete All ({selectedRowKeys.length})
                      </Button>
                    ) : (
                      ""
                    )}
                  </Popconfirm>
                </>
              )} */}
      <h1 className="text-center">List Of Freelancers</h1>
      <div className="overflow-y-hidden w-full">
      <Input.Search size="large" placeholder="search here..."
             style={{marginBottom: 8, width: 280}}
             onSearch={(value)=>{
              setSearchedText(value);
             }}
             onChange={(e)=>{
              setSearchedText(e.target.value);
             }}/>
        <Table
          // rowSelection={rowSelection}
          dataSource={datas}
          columns={columns}
          loading={loading}
          size="large"
          scroll
        />
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(ListOfFreelancer);
