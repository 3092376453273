import React from "react";

interface GHMSTypeValue {
  guest_name: string;
  // guest_email: string;
  // guest_add: string;
  guest_mobile: string;
  guest_outstation: string;
  // guest_invited: string;
  // guest_expected_nos: string;
  // guest_invitation_type: string;
  // guest_date_of_arrival: string;
  digital_invitation: string;
  notes: string;
}

interface Props {
  handleCSVToTableSave: (values: GHMSTypeValue[]) => void;
}
enum HEADER_TO_KEY {
  "guest_name" = "guest_name",
  "guest_mobile" = "guest_mobile",
  // "guest_email" = "guest_email",
  // "guest_add" = "guest_add",
  "guest_outstation" = "guest_outstation",
  // guest_invited = "guest_invited",
  // guest_expected_nos = "guest_expected_nos",
  // guest_invitation_type = "guest_invitation_type",
  // guest_date_of_arrival = "guest_date_of_arrival",
  digital_invitation = "digital_invitation",
  notes = "notes",
}
const ImportCSV: React.FC<Props> = ({ handleCSVToTableSave }) => {
  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      const file = e.target.files[0];
      const fileReader = new FileReader();
      if (file) {
        fileReader.onload = function (event) {
          if (event.target) {
            const csvOutput = event.target.result as string;
            const length = csvOutput.length;
            const headerEndIndex = csvOutput.indexOf("\n");
            const csvHeader = csvOutput.slice(0, headerEndIndex).split(",");
            let index = headerEndIndex;
            const csvRows: string[][] = csvOutput
              .slice(index + 1, length - 1)
              .split("\n")
              .map((item) => item.split(","));
            const result: GHMSTypeValue[] = [];
            csvRows.forEach((item) => {
              const ghms = {} as GHMSTypeValue;
              Object.entries(HEADER_TO_KEY).forEach(([key, attribute]) => {
                const index = csvHeader.findIndex((header) => header === key);
                if (index !== -1) {
                  ghms[attribute] = item[index];
                }
              });
              result.push(ghms);
            });
            handleCSVToTableSave(result);
          }
        };

        fileReader.readAsText(file);
      }
    }
  };
  return (
    <div>
      <input type="file" accept=".csv" onChange={handleOnChange} />
    </div>
  );
};

export default ImportCSV;
