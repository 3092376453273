export enum GuestInvited {
  Family = "Family",
  Individual = "Individual",
}

export enum GuestOutstation {
  Local = "Local",
  Outstation = "Outstation",
}

export enum InvitationType {
  Courier = "Courier",
  Personally = "Personally",
  Digitally = "Digitally",
}

export enum DeliveryType {
  Courier = "Courier",
  //   Pending = "pending",
  Self = "Self",
}

export enum CommThrough {
  Self = "Self",
  PrivateCar = "PrivateCar",
  RentalCar = "RentalCar",
}

interface GHMSBaseType {
  _id: string;
  car_id?: string;
  client_id: string;
  event_id: string;
  guest_id: string;
  no_of_guest_arrived?: number;
  special_note?: string | undefined;
}

export interface IArrival extends GHMSBaseType {
  arrived_at: string ;
  date_of_arrival: string ;
  arrival_location: string;
  conveyance_through: CommThrough;
  details?: string;
  // expected_arrival_time: string;
  // mode_of_arrival: string;
  // welcome_checklist: string;
}

export interface IDeparture extends GHMSBaseType {
  // date_of_departure: string;
  departure_time: string;
  departure_location: string;
  departure_conveyance: CommThrough;
  details?: string;
    // car_id: Joi.string().required(),
  // mode_of_departure: string;
  // return_checklist: string;
}

export interface ILostAndFound {
  _id: string;
  client_id: string;
  event_id: string;
  guest_id: string;
  delivery_type: string;
  lost_item: string;

  // deliver_type: DeliveryType | null;
  // found_by: string;
  // found_place: string;
  // item_name: string;
  // item_identification: string;
  // lost_place: string;
}

export interface IGuestList {
  _id: string;
  client_id: string;
  event_id: string;
  // guest_add: string;
  // guest_date_of_arrival: string;
  // guest_email: string;
  // guest_expected_nos: number;
  // guest_invitation_type: InvitationType;
  // guest_invited: GuestInvited;
  guest_mobile: string;
  guest_name: string;
  guest_outstation: GuestOutstation;
  digital_invitation: boolean;
  notes: string;
}

export interface IOutstation {
  _id: string;
  client_id: string;
  event_id: string;
  guest_id: string;
  arrival_location: string;
  date_and_time: string;
  notes?: string;
}

export interface IGHMS {
  arrival: IArrival[];
  departure: IDeparture[];
  guestlist: IGuestList[];
  lostandfound: ILostAndFound[];
  outstation: IOutstation[];
}
