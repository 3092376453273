import { Button, DatePicker, Form, Input, Modal, Select, message } from "antd";
import { ErrorMessage, Formik } from "formik";
import { useState } from "react";
import { FormInput } from "src/components/FormInput";
import * as Yup from "yup";
import { useFormik } from "formik";
import { phoneRegExp } from "src/utils/helper";
import { createUser } from "src/api-core/User";

const validationSchema = Yup.object({
  name: Yup.string()
    .required("name is required")
    .min(3, "must contains 3 char"),
  email: Yup.string()
    .email()
    .test("tld", "Invalid email. Supported TLD are .com/.in", function (value) {
      if (!value) return true; // Skip validation if the value is empty

      const tldRegex = /^(com|in)$/i; // Add more TLDs as needed

      const emailParts = value.split("@");
      const domainParts = emailParts[1] && emailParts[1].split(".");

      if (domainParts && domainParts.length > 1) {
        const tld = domainParts[domainParts.length - 1];
        return tldRegex.test(tld);
      }

      return true; // Validation will fail if the email format is incorrect
    })
    .required("email is required"),
  mobile: Yup.string()
    .required("Mobile number is required")
    .matches(phoneRegExp, "Mobile number is not valid")
    .min(10, "too short")
    .max(10, "too long"),
  password: Yup.string()
    .required("password is required")
    .min(6, "Password is too short - should be 6 chars minimum."),
  // role_id: Yup.string().required("role is required"),
});

const EventClientCreate = ({
  handleClose,
  handleSave,
  open,
  title,
  clientID,
}) => {
  // const initalValues = {};
  const initalValues = {
    name: "",
    email: "",
    mobile: "",
    password: "",
    role_id: clientID,
  };

  const [form] = Form.useForm();

  return (
    <Formik
      initialValues={initalValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSave({
          name: values.name,
          email: values.email,
          mobile: values.mobile,
          password: values.password,
          role_id: clientID,
        });
      }}
    >
      {({ handleSubmit, values, setFieldValue, errors, resetForm }) => {
        const save = () => {
          handleSave({
            name: values.name,
            email: values.email,
            mobile: values.mobile,
            password: values.password,
            role_id: clientID,
          });
        };

        return (
          <Modal
            centered
            open={open}
            title={title}
            onCancel={handleClose}
            onOk={() => handleSubmit()}
            footer={[
              <Button
                form="myForm"
                key="submit"
                htmlType="submit"
                onClick={() => {
                  if (Object.keys(errors).length <= 0) {
                    save();
                    resetForm();
                  }
                }}
              >
                OK
              </Button>,
              <Button
                form="myForm"
                key="cancel"
                htmlType="cancel"
                onClick={handleClose}
              >
                Cancel
              </Button>,
            ]}
          >
            <div className="flex flex-col justify-center items-center  md:justify-between md:flex-row gap-x-5 flex-wrap">
              <div className="mb-6 w-[95%] md:w-[45%] ">
                <FormInput
                  className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                  label={{ text: "Name", required: true }}
                  name="name"
                  placeholder="Name"
                />
              </div>

              <div className="mb-6 w-[95%] md:w-[45%] ">
                <FormInput
                  className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                  label={{ required: true, text: "Email" }}
                  name="email"
                  placeholder="Enter Email"
                />
              </div>
              <div className="mb-6 w-[95%] md:w-[45%] ">
                <FormInput
                  className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                  label={{ required: true, text: "Mobile Number" }}
                  name="mobile"
                  placeholder="Mobile number"
                />
              </div>
              <div className="mb-6 w-[95%] md:w-[45%] ">
                <FormInput
                  className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                  label={{ required: true, text: "Password" }}
                  name="password"
                  type="password"
                  placeholder="Password"
                />
              </div>
            </div>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default EventClientCreate;
