import React, { useEffect, useState } from "react";
import { message, Popconfirm, Table } from "antd";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link } from "react-router-dom";
import { deleteRole, getAllRoles } from "../../../api-core/RolesPermissions";

const ListOfRoles = () => {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    fetchAllRoles();
  }, []);
  const fetchAllRoles = async () => {
    setloading(true);
    const res = await getAllRoles();
    setdata(res.role);
    setloading(false);
  };
  const onDeleteRecord = async (record, id) => {
    const recordId = {
      ids: [record],
    };
    const res = await deleteRole(recordId);
    if (res.success == true) {
      message.success(res.msgText);
      fetchAllRoles();
    }
  };

  const datas = data.map((item) => ({
    key: item._id,
    roleName: item.role_name,
  }));

  const columns = [
    {
      title: "Sr",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Role Name",
      dataIndex: "roleName",
      key: "roleName",
      ellipsis: true,
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex gap-x-2 ">
          {/* <Link to={`/admin/singlerole/${record.key}`}>
            <button className="text-blue-600 cursor-pointer">View</button>
          </Link> */}

          <Link to={`/admin/updaterole/${record.key}`}>
            <button className="text-yellow-400 cursor-pointer">Edit</button>
          </Link>

          <Popconfirm
            title="Sure to delete?"
            onConfirm={(e) => onDeleteRecord(record.key, e)}
          >
            <span className="text-red-500 cursor-pointer">Delete</span>
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div className="">
      <Link to="/admin/createrole">
        <button className="bg-blue-600 text-white p-3 mx-3">
          Create Roles
        </button>
      </Link>
      <h1 className="text-center">List Of Roles</h1>
      <div className="">
        <Table
          dataSource={datas}
          columns={columns}
          loading={loading}
          // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        />
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(ListOfRoles);
