import React, { useEffect, useState } from "react";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";

import { getAllSubEvents } from "../../../api-core/Events";
import {
  createHotelRoomAllotment,
  getAllHotelRoom,
  getAllHotelRoomAllotment,
} from "../../../api-core/Hotel";
import { getAllUser } from "../../../api-core/User";
import { useFormik } from "formik";
import * as Yup from "yup";
const CreateHotelRoomAllotment = () => {
  const [hotelRoomData, setHotelRoomData] = useState([]);
  const [guestId, setGuestId] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [subEventData, setSubEventData] = useState([]);
  const initialValues = {
    client_id: "",
    sub_event_id: "",
    hotel_room_id: "",
    guest_id: "",
    remarks: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      client_id: Yup.string().required("Hotel name is required"),
      sub_event_id: Yup.string().required("Hotel Address is required"),
      hotel_room_id: Yup.string().required("Hotel Mobile is required"),
      guest_id: Yup.string().required("Hotel Mobile is required"),
      remarks: Yup.string().required("Hotel Mobile is required"),
    }),
    onSubmit: async (values, action) => {
      console.log("values", values);

      if (values) {
        const res = await createHotelRoomAllotment(values);
        console.log("resHotelRoom", res);
        action.resetForm();
      } else {
        console.log("error");
      }
    },
  });

  useEffect(() => {
    const fetchAllHotelRoom = async () => {
      const res = await getAllHotelRoom();
      // console.log("res", res);
      setHotelRoomData(res?.hotelroom);
    };
    fetchAllHotelRoom();
  }, []);
  useEffect(() => {
    const fetchAllUser = async () => {
      const res = await getAllUser();
      console.log("res", res);
      setClientData(res?.user);
    };
    fetchAllUser();
  }, []);
  useEffect(() => {
    const fetchAllSubEvents = async () => {
      const res = await getAllSubEvents();
      // console.log("res", res);
      setSubEventData(res?.subevent);
    };
    fetchAllSubEvents();
  }, []);

  useEffect(() => {
    const fetchAllRoomAllotment = async () => {
      const res = await getAllHotelRoomAllotment();
      // console.log("res", res);
      setGuestId(res?.roomallotment);
    };
    fetchAllRoomAllotment();
  }, []);
  // console.log("roomTypeId", roomTypeId);

  return (
    <div className="w-[90%] mx-auto ">
      <h1>Create Hotel Room Allotment</h1>
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <div className="flex flex-col justify-center items-center  md:justify-between md:flex-row gap-x-5 flex-wrap">
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Client Name
            </label>
            <select
              value={formik.values.client_id}
              name="client_id"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={""}>Select Client Name</option>
              {clientData.map((value, i) => (
                <option value={value?._id} key={i}>
                  {value?.name}
                </option>
              ))}
            </select>
            {formik.errors.client_id && (
              <span className="text-red-500">{formik.errors.client_id}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Sub Event Title
            </label>
            <select
              value={formik.values.sub_event_id}
              name="sub_event_id"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={""}>Select Event</option>
              {subEventData.map((value) => (
                <option value={value?._id} key={value?._id}>
                  {value.subevent_title}
                </option>
              ))}
            </select>
            {formik.errors.sub_event_id && (
              <span className="text-red-500">{formik.errors.sub_event_id}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Hotel Room No
            </label>
            <select
              value={formik.values.hotel_room_id}
              name="hotel_room_id"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={""}>Select Hotel Room</option>
              {hotelRoomData?.map((value) => (
                <option value={value?._id} key={value?._id}>
                  {value?.hotel_id?.hotel_name +
                    `(RN- ${value?.room_no} )` +
                    `(FL- ${value?.floor_no} )`}
                </option>
              ))}
            </select>
            {formik.errors.hotel_room_id && (
              <span className="text-red-500">
                {formik.errors.hotel_room_id}
              </span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Guest Name
            </label>
            <select
              value={formik.values.guest_id}
              name="guest_id"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={""}>Select Guest Name</option>
              {guestId.map((value) => (
                <option value={value.guest_id?._id} key={value?._id}>
                  {value?.guest_id?.guest_name}
                </option>
              ))}
            </select>
            {formik.errors.guest_id && (
              <span className="text-red-500">{formik.errors.guest_id}</span>
            )}
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Remarks
            </label>
            <input
              type="text"
              name="remarks"
              value={formik.values.remarks}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter Remarks"
            />
            {formik.errors.remarks && (
              <span className="text-red-500">{formik.errors.remarks}</span>
            )}
          </div>
        </div>
        <button
          type="submit"
          className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default SidebarHeaderLayout(CreateHotelRoomAllotment);
