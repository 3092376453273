import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  en: {
    translation: {
      BLUE_BIRD_EVENTS: "Blue Bird Events",
      DASHBOARD: "Dashboard",
      LIST_OF_EVENTS: "List Of Events",
      CREATE_EVENT: "Create Event",
      SEARCH_HERE: "search here...",
      SERIAL_NO: "Serial No.",
      CLIENT_NAME: "Client Name",
      EVENT_TYPE: "Event Type",
      EVENT_TITLE: "Event Title",
      EVENT_START_DATE: "Event Start Date",
      EVENT_END_DATE: "Event End Date",
      HOTELS: "Hotels",
      ACTION: "Action",
      EVENT: "Event",
      HOTEL: "Hotel",
      GHMS: "GHMS",
      VENDORS: "Vendors",
      FOOD_AND_BEVERAGE: "Food & Bev",
      PRODUCTION: "Production",
    },
  },
  fr: {
    translation: {
      welcomeMessage: "Bienvenue à React et react-i18next",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en",
    lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
