import React, { useEffect, useState } from "react";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";

import { getAllSubEvents } from "../../../api-core/Events";
import {
  getAllHotelRoom,
  getAllHotelRoomAllotment,
  getSingleHotelRoomAllotment,
  updateHotelRoomAllotment,
} from "../../../api-core/Hotel";
import { getAllUser } from "../../../api-core/User";
import { useParams } from "react-router-dom";
const UpdateHotelRoomAllotment = () => {
  const { id } = useParams();
  const [hotelRoomData, setHotelRoomData] = useState([]);
  const [guestId, setGuestId] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [subEventData, setSubEventData] = useState([]);
  const [loading, setloading] = useState(false);
  const [inputs, setInputs] = useState({
    client_id: "",
    sub_event_id: "",
    hotel_room_id: "",
    guest_id: "",
    remarks: "",
  });

  useEffect(() => {
    const fetchSingleHotelRoomAllotment = async () => {
      setloading(true);
      const res = await getSingleHotelRoomAllotment(id);
      setInputs({
        client_id: res.roomallotment[0]?.client_id?._id,
        sub_event_id: res.roomallotment[0]?.sub_event_id?._id,
        hotel_room_id: res.roomallotment[0]?.hotel_room_id?._id,
        guest_id: res.roomallotment[0]?.guest_id?._id,
        remarks: res.roomallotment[0]?.remarks,
      });
      setloading(false);
    };
    fetchSingleHotelRoomAllotment();
  }, []);
  useEffect(() => {
    const fetchAllHotelRoom = async () => {
      const res = await getAllHotelRoom();
      // console.log("res", res);
      setHotelRoomData(res.hotelroom);
    };
    fetchAllHotelRoom();
  }, []);
  useEffect(() => {
    const fetchAllUser = async () => {
      const res = await getAllUser();
      // console.log("res", res);
      setClientData(res.user);
    };
    fetchAllUser();
  }, []);
  useEffect(() => {
    const fetchAllSubEvents = async () => {
      const res = await getAllSubEvents();
      // console.log("res", res);
      setSubEventData(res.subevent);
    };
    fetchAllSubEvents();
  }, []);

  useEffect(() => {
    const fetchAllRoomAllotment = async () => {
      const res = await getAllHotelRoomAllotment();
      // console.log("res", res);
      setGuestId(res.roomallotment);
    };
    fetchAllRoomAllotment();
  }, []);
  // console.log("roomTypeId", roomTypeId);
  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("inputs", inputs);

    if (inputs) {
      const res = await updateHotelRoomAllotment(inputs, id);
      console.log("resHotelRoom", res);
    } else {
      console.log("error");
    }
  };
  return (
    <div className="w-[90%] mx-auto ">
      <h1>Update Hotel Room Allotment</h1>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="flex flex-col justify-center items-center  md:justify-between md:flex-row gap-x-5 flex-wrap">
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Client Name
            </label>
            <select
              value={inputs.client_id}
              name="client_id"
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              {clientData.map((value, i) => (
                <option value={value._id} key={i}>
                  {value.name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Sub Event Title
            </label>
            <select
              value={inputs.sub_event_id}
              name="sub_event_id"
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              {subEventData.map((value) => (
                <option value={value._id} key={value._id}>
                  {value.subevent_title}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Hotel Room No
            </label>
            <select
              value={inputs.hotel_room_id}
              name="hotel_room_id"
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              {hotelRoomData.map((value) => (
                <option value={value._id} key={value._id}>
                  {value.hotel_id.hotel_name +
                    `(RN- ${value.room_no} )` +
                    `(FL- ${value.floor_no} )`}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Guest Name
            </label>
            <select
              value={inputs.guest_id}
              name="guest_id"
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              {guestId?.map((value) => (
                <option value={value.guest_id?._id} key={value?._id}>
                  {value?.guest_id?.guest_name}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Remarks
            </label>
            <input
              type="text"
              name="remarks"
              value={inputs.remarks}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter Remarks"
            />
          </div>
        </div>
        <button
          type="submit"
          className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Update
        </button>
      </form>
    </div>
  );
};

export default SidebarHeaderLayout(UpdateHotelRoomAllotment);
