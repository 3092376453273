import React, { useEffect, useState } from "react";

import SidebarHeaderLayout from "../HOC/SidebarHeaderLayout";
import { useParams } from "react-router-dom";
import { Table, Descriptions, Image } from "antd";
import { formatDate } from "src/utils/helper";
import { getSingleVendor } from "../../api-core/Vendor";

const SingleBlackList = () => {
  const { id } = useParams();
  const [data, setData] = useState<any[]>([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    const fetchSingleVendor = async () => {
      setloading(true);
      const res = await getSingleVendor(id);
      setData(res.vendor);
      setloading(false);
    };
    fetchSingleVendor();
  }, []);
  const datas = data.map((item) => ({
    key: item._id,
    vendor_name: item.vendor_name,
    vendor_mobile: item.vendor_mobile,
    vendor_work: item.vendor_work,
    reason_for_blacklist: item.reason_for_blacklist,
  }));

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      sorter: (a: { id: number; }, b: { id: number; }) => a.id - b.id,
      render: (id: any, record: any, index: number) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Vendor Name",
      dataIndex: "vendor_name",
      key: "vendor_name",
      // ellipsis: true,
    },
    {
      title: "Vendor Mobile",
      dataIndex: "vendor_mobile",
      key: "vendor_mobile",
      // ellipsis: true,
    },
    {
        title: "Vendor Work",
        dataIndex: "vendor_work",
        key: "vendor_work",
        // ellipsis: true,
      },
      {
        title: "Reason for Blacklisted",
        dataIndex: "reason_for_blacklist",
        key: "reason_for_blacklist",
        // ellipsis: true,
      },
  ];
  return (
    <div>
      <div>
        <Descriptions
          title="Blacklisted Vendor's Details"
          bordered
          layout="horizontal"
          column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
        >
          <Descriptions.Item
            label="Vendor's Name"
            labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
          >
            <span className="text-lg"> {datas[0]?.vendor_name}</span>
          </Descriptions.Item>

          <Descriptions.Item
            label="Vendor's Mobile"
            labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
          >
            <span className="text-lg"> 
              <a href={`tel:${datas[0]?.vendor_mobile}`} >{datas[0]?.vendor_mobile}</a>
            </span>
          </Descriptions.Item>
          <Descriptions.Item
            label="Vendor's Work"
            labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
          >
            <span className="text-lg"> {datas[0]?.vendor_work}</span>
          </Descriptions.Item>

          <Descriptions.Item
            label="Reason for Blacklisted"
            labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
          >
            <span className="text-lg">{datas[0]?.reason_for_blacklist}</span>
          </Descriptions.Item>
        </Descriptions>
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(SingleBlackList);
