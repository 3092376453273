import React, { useEffect, useState } from "react";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { phoneRegExp } from "src/utils/helper";
import { getAllEvents } from "../../../api-core/Events";
import { getAllUser } from "../../../api-core/User";
import { useFormik } from "formik";
import * as Yup from "yup";
import { message } from "antd";
import { createPriotization } from "../../../api-core/Priotization";
import { viewCurrentDates } from "src/utils/helper";
import { Link, useNavigate } from "react-router-dom";

const CreatePriotization = () => {
  const [eventData, setEventData] = useState([]);
  // const [clientData, setClientData] = useState([]);
  const navigate = useNavigate();

  const initialValues = {
    event_id: "",
    // client_id: "",
    title: "",
    descp: "",
    deadline_date: "",
    contact: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      event_id: Yup.string().required("event name is required"),
      // client_id: Yup.string().required("Client Name is required"),
      title: Yup.string().required("Title is required").min(3),
      descp: Yup.string().min(3),
      deadline_date: Yup.date().required("Deadline date is required"),
      contact: Yup.string()
        .required("Contact number is required")
        .matches(phoneRegExp, "Contact number is not valid")
        .min(10, "too short")
        .max(10, "too long"),
    }),
    onSubmit: async (values, action) => {
      const formData = new FormData();
      formData.append("event_id", values.event_id);
      formData.append("title", values.title);
      formData.append("descp", values.descp);
      formData.append("deadline_date", values.deadline_date);
      formData.append("contact", values.contact);

      if (formData) {
        const res = await createPriotization(formData);

        action.resetForm();
        message.success("Created Succesfully");
        navigate("/admin/listofprioritisation");
      } else {
        message.error("Something went wrong");
      }
    },
  });
  useEffect(() => {
    const fetchAllEvents = async () => {
      const res = await getAllEvents(1, 1000);
      setEventData(res?.event);
    };
    fetchAllEvents();
  }, []);

  return (
    <div className="w-[90%] mx-auto ">
      <h1>Create Prioritisation</h1>
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <div className="flex flex-col justify-center items-center  md:justify-between md:flex-row gap-x-5 flex-wrap">
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Event Name
            </label>
            <select
              value={formik.values.event_id}
              name="event_id"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={""}>Select Event</option>
              {eventData.map((value) => (
                <option value={value._id} key={value._id}>
                  {value.event_title}
                </option>
              ))}
            </select>
            {formik.errors.event_id && (
              <span className="text-red-500">{formik.errors.event_id}</span>
            )}
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Date
            </label>
            <input
              type="date"
              name="deadline_date"
              min={viewCurrentDates}
              value={formik.values.deadline_date}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter enter date"
            />
            {formik.errors.deadline_date && (
              <span className="text-red-500">
                {formik.errors.deadline_date}
              </span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Title
            </label>
            <input
              type="text"
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter title"
            />
            {formik.errors.title && (
              <span className="text-red-500">{formik.errors.title}</span>
            )}
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Desciption
            </label>
            <textarea
              type="text"
              name="descp"
              value={formik.values.descp}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter  description"
            />
            {formik.errors.descp && (
              <span className="text-red-500">{formik.errors.descp}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Contact
            </label>
            <input
              type="number"
              name="contact"
              value={formik.values.contact}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter contact nmber"
            />
            {formik.errors.contact && (
              <span className="text-red-500">{formik.errors.contact}</span>
            )}
          </div>
        </div>
        <Link to={"/admin/listofprioritisation"}>
          <button
            type="button"
            className="mr-2 text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
          >
            Cancel
          </button>
        </Link>
        <button
          type="submit"
          className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default SidebarHeaderLayout(CreatePriotization);
