import { useEffect, useState } from "react";
import { message, Popconfirm, Table } from "antd";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link } from "react-router-dom";

import { deleteSubEvent, getAllSubEvents } from "../../../api-core/Events";
import { formatDate } from "../../../utils/helper";

const ListOfSubAllEvents = () => {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    fetchAllOfferBanner();
  }, []);
  const fetchAllOfferBanner = async () => {
    setloading(true);
    const res = await getAllSubEvents();
    setdata(res.subevent);
    setloading(false);
  };
  const onDeleteRecord = async (record) => {
    // const recordId = {
    //   ids: [record],
    // };
    const res = await deleteSubEvent(record);

    if (res.success === true) {
      message.success(res.msgText);
      fetchAllOfferBanner();
    }
  };

  const datas = data?.map((item) => ({
    key: item._id,
    subEventDate: formatDate(item.subevent_date, "DD-MMM-YYYY"),
    subEventTitle: item.subevent_title,
    subEventDesc: item.subevent_descp,
    eventTitle: item.event_id.event_title,
    clientName: item.client_id?.name,
  }));

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Current Event Date",
      dataIndex: "subEventDate",
      key: "subEventDate",
      ellipsis: true,
    },
    {
      title: " Event Title",
      dataIndex: "eventTitle",
      key: "eventTitle",
      ellipsis: true,
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      key: "clientName",
      ellipsis: true,
    },
    {
      title: "Hotel Name",
      dataIndex: "hotelName",
      key: "hotelName",
      ellipsis: true,
    },
    {
      title: "Hotel Rooms",
      dataIndex: "hotelRooms",
      key: "hotelRooms",
      ellipsis: true,
    },
    {
      title: "Current Event Title",
      dataIndex: "subEventTitle",
      key: "subEventTitle",
      ellipsis: true,
    },

    {
      title: "Current Event Desc",
      dataIndex: "subEventDesc",
      key: "subEventDesc",
      ellipsis: true,
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex gap-x-2 ">
          {/* {console.log("rec", record)} */}
          <Link to={`/admin/singlesubevent/${record.key}`}>
            <button className="text-blue-600 cursor-pointer">View</button>
          </Link>

          <Link to={`/admin/updatesubevent/${record.key}`}>
            <button className="text-yellow-400 cursor-pointer">Edit</button>
          </Link>

          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => onDeleteRecord(record.key)}
          >
            <span className="text-red-500 cursor-pointer">Delete</span>
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div className="">
      <Link to="/admin/createsubevent">
        <button className="p-3 mx-3 text-white bg-blue-600">
          Create Current Event
        </button>
      </Link>
      <h1 className="text-center">List Of Current Events</h1>
      <div className="">
        <Table
          dataSource={datas}
          columns={columns}
          loading={loading}
          style={{}}
        />
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(ListOfSubAllEvents);
