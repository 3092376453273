import React from "react";
import { Button, Image, Input, Popconfirm, Table, Typography } from "antd";
import EditFoodBev from "./EditFoodBev";
import { ShouldRender } from "src/components/ShouldRender";
import { IEvent, IEventFoodNBeverages } from "src/types";
import moment from "moment";
import { TIME_FORMATS } from "src/constants";
import { updateSingleEvent } from "src/api-core/Events";
import { clonseJSON } from "src/utils/json";

interface Props {
  event?: IEvent;
  update: () => Promise<void>;
  loading: boolean;
}

export const FoodBevTable = ({ event, update, loading }: Props) => {
  const { event_foodbev = [] } = event || {};
  const [open, setOpen] = React.useState(false);
  const [recordIndex, setRecordIndex] = React.useState<number>(-1);
  const [searchedText, setSearchedText] = React.useState("");

  const handleEdit = (index: number) => {
    setOpen(true);
    setRecordIndex(index);
  };

  const handleClose = () => {
    setOpen(false);
    setRecordIndex(-1);
  };

  const handleOpen = () => setOpen(true);

  const handleUpdate = (data: Partial<IEvent>) => {
    if (event) {
      const { _id, client_id, event_type, event_title, event_start_date, is_hospitality_checklist_visible  } =
        event;
      const payload = {
        ...data,
        client_id,
        event_type,
        event_title,
        event_start_date,
        is_hospitality_checklist_visible,
      };
      
      return updateSingleEvent(_id, payload).then(update).then(handleClose);
    }
  };

  const handleSave = (values: IEventFoodNBeverages) => {
    
    let updatedEventFoodBev = clonseJSON(event_foodbev);
    if (recordIndex === -1) {
      updatedEventFoodBev.push(values);
    } else {
      updatedEventFoodBev[recordIndex] = values;
    }
    const data = {
      event_foodbev: updatedEventFoodBev.map((item) => ({
        ...item,
        menu: [
          {
            file: item.menu[0].filename,
          },
        ],
      })) as any,
    };
    
    handleUpdate(data)?.then(update).then(handleClose);
  };

  const handleDelete = (index: number) => {
    const updatedEventFoodBev = clonseJSON(event_foodbev).filter(
      (_, i) => i !== index
    );
    const data = {
      event_foodbev: updatedEventFoodBev.length ? updatedEventFoodBev.map((item) => ({
        ...item,
        menu: [
          {
            file: item.menu[0].filename,
          },
        ],
      })) as any : [],
    };
    handleUpdate(data)?.then(update);
  };

  const defaultColumns = [
    {
      title: "Food Type",
      dataIndex: "food_type",
      key: "food_type",
    },
    {
      title: "Menu Image",
      dataIndex: "menu",
      key: "menu",
      ellipsis: true,
      render: (_: any, record: IEventFoodNBeverages) => (
        <div className="w-20">
          <Image
            alt="menu"
            rootClassName="event-image-preview"
            src={record.menu[0]?.file}
          />
        </div>
      ),
    },
    {
      title: "Serve Date",
      dataIndex: "serve_date",
      key: "serve_date",
      render: (_: any, record: IEventFoodNBeverages) => {
        return moment(record.serve_date).format(TIME_FORMATS.DATE);
      },
    },
    {
      title: "Serve Start Time",
      dataIndex: "serve_start_time",
      key: "serve_start_time",
    },
    {
      title: "Serve End Time",
      dataIndex: "serve_end_time",
      key: "serve_end_time",
    },
    {
      title: "Plates Guarnteed",
      dataIndex: "plates_guaranteed",
      key: "plates_guaranteed",
    },
    {
      title: "Plated Used",
      dataIndex: "plates_used",
      key: "plates_used",
      filteredValue: [searchedText],
      onFilter: (value:any, record:any)=>{
        return  String(record.food_type)
                .toLowerCase()
                .includes(value.toLowerCase()) ||
                String(record.serve_start_time)
                .toLowerCase()
                .includes(value.toLowerCase()) ||
                String(record.serve_date)
                .toLowerCase()
                .includes(value.toLowerCase())
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: IEventFoodNBeverages) => {
        const index = event_foodbev.findIndex(
          (item) => JSON.stringify(item) === JSON.stringify(record)
        );
        return (
          <span>
            <Typography.Link
              onClick={() => handleEdit(index)}
              style={{ marginRight: 8 }}
            >
              Edit
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(index)}
            >
              <Typography.Link>Delete</Typography.Link>
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="flex justify-end mb-3">
        <Button onClick={handleOpen}>Add Data</Button>
      </div>
      <Input.Search size="large" placeholder="search here..."
             style={{marginBottom: 8, width: 280}}
             onSearch={(value)=>{
              setSearchedText(value);
             }}
             onChange={(e)=>{
              setSearchedText(e.target.value);
             }}/>
      <Table
        columns={defaultColumns}
        dataSource={event_foodbev}
        loading={loading}
      />
      <ShouldRender check={open}>
        <EditFoodBev
          open={open}
          data={event_foodbev[recordIndex]}
          title={recordIndex === -1 ? "Add Food & Bev" : "Edit Food & Bev"}
          handleClose={handleClose}
          handleSave={handleSave}
        />
      </ShouldRender>
    </React.Fragment>
  );
};
