import React, { useEffect, useState } from "react";

import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { useParams } from "react-router-dom";
import { Table, Descriptions, Image } from "antd";
import { formatDate } from "src/utils/helper";
import { getSingleTrivia } from "../../../api-core/Trivia";

const SingleTrivia = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    const fetchSingleTrivia = async () => {
      setloading(true);
      const res = await getSingleTrivia(id);
      setData(res.trivia);
      setloading(false);
    };
    fetchSingleTrivia();
  }, []);
  const datas = data.map((item) => ({
    key: item._id,
    triviatitle: item.title,
    triviadescp: item.descp,
  }));

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Trivia Title",
      dataIndex: "triviatitle",
      key: "triviatitle",
      // ellipsis: true,
    },
    {
      title: "Trivia descp",
      dataIndex: "triviadescp",
      key: "triviadescp",
      // ellipsis: true,
    },
  ];
  return (
    <div>
      <div>
        <Descriptions
          title="Trivia Details"
          bordered
          layout="horizontal"
          column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
        >
          <Descriptions.Item
            label="Trivia Title"
            labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
          >
            <span className="text-lg"> {datas[0]?.triviatitle}</span>
          </Descriptions.Item>

          <Descriptions.Item
            label="Trivia Description"
            labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
          >
            <span className="text-lg">{datas[0]?.triviadescp}</span>
          </Descriptions.Item>
        </Descriptions>
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(SingleTrivia);
