import jwtDecode from "jwt-decode";
import { Navigate, Outlet } from "react-router-dom";
import Moment from "react-moment";
import "moment-timezone";
import moment from "moment";

export const Auth = () => {
  const userToken = JSON.parse(localStorage.getItem("token"));

  const user = { loggedIn: userToken ? userToken.token : null };

  // if (user.loggedIn) {
  //   const decodedToken = jwtDecode(user.loggedIn);

  //   const { exp, iat } = decodedToken;

  //   if (!exp) {
  //     return (window.location.href = "/");
  //   }
  //   const expTime = exp * 1000 - 60000;

  //   const expTimeConverted = moment.tz(exp, "GMT");
  //   const dateNowConverted = moment.tz(Date.now(), "GMT");

  //   if (dateNowConverted > expTimeConverted) {
  //     localStorage.removeItem("token");
  //     window.location.href = "/";
  //   }
  // }

  return user && user.loggedIn;
};
const ProtectedRoutes = () => {
  var isAuth = Auth();

  return isAuth ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoutes;
