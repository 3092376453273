import React, { useEffect, useState } from "react";
import { Table } from "antd";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import {useParams } from "react-router-dom";
import { getSingleHotelRoomType } from "../../../api-core/Hotel";

const SingleHotelRoomType = () => {
  const { id } = useParams();
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    const fetchSingleHotelRoomType = async () => {
      setloading(true);
      const res = await getSingleHotelRoomType(id);
      // console.log("res", res.hotelroomtype);

      setdata(res.hotelroomtype);
      setloading(false);
    };
    fetchSingleHotelRoomType();
  }, []);

  const datas = data.map((item) => ({
    key: item._id,
    hotelName: item.hotel_id.hotel_name,
    roomTypeName: item.room_type_name,
    beds: item.beds,
    maxOccupancy: item.max_occupancy,
    costPerNight: item.cost_per_night,
  }));
  // console.log("datas", datas);

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Hotel Name",
      dataIndex: "hotelName",
      key: "hotelName",
      ellipsis: true,
    },

    {
      title: "Room Type Name",
      dataIndex: "roomTypeName",
      key: "roomTypeName",

      ellipsis: true,
    },
    {
      title: "Beds",
      dataIndex: "beds",
      key: "beds",
    },
    {
      title: "Max Occupancy",
      dataIndex: "maxOccupancy",
      key: "maxOccupancy",
    },
    {
      title: "Cost Per Night",
      dataIndex: "costPerNight",
      key: "costPerNight",
    },
  ];
  return (
    <div className="">
      <h1 className="text-center">List Of Single Hotel Room Type</h1>
      <div className="">
        <Table
          dataSource={datas}
          columns={columns}
          loading={loading}

          // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        />
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(SingleHotelRoomType);
