import Axios from "../Axios";
export const userLogin = async (data) => {
  try {
    const response = await Axios.post(`/admin/user/login`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getAllUser = async () => {
  try {
    const response = await Axios.get(`/admin/user`);
    return response.data;
  } catch (error) {
    return error;
  }
};

/*Get all users according to the pages */
export const getAllUserPages = async (row, pages) => {
  try {
    const response = await Axios.get(`/admin/user?r=${row}&p=${pages}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getSingleUser = async (id) => {
  try {
    const response = await Axios.get(`/admin/user/read/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const createUser = async (data) => {
  try {
    const response = await Axios.post(`/admin/user/create`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
//For Updating User
export const updateUser = async (id, data) => {
  try {
    const response = await Axios.post(`/admin/user/update/${id}`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await Axios.post(`/admin/user/delete`, id);
    return response.data;
  } catch (error) {
    return error;
  }
};
