import React, { useEffect, useState } from "react";
import { Table } from "antd";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link, useParams } from "react-router-dom";
import { getSingleLostAndFound } from "../../../api-core/Ghms";

const SingleLostAndFound = () => {
  const { id } = useParams();
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    const fetchSingleLostAndFound = async () => {
      setloading(true);
      const res = await getSingleLostAndFound(id);
      console.log("res", res.ghmslostfound);

      setdata(res.ghmslostfound);
      setloading(false);
    };
    fetchSingleLostAndFound();
  }, []);

  const datas = data.map((item) => ({
    key: item._id,
    subEventTitle: item.sub_event_id.subevent_title,
    guestName: item.guest_id.guest_name,
    itemName: item.item_name,
    itemIdentification: item.item_identification,
    lostPlace: item.lost_place,
    foundPlace: item.found_place,
    foundBy: item.found_by,
    deliverTpye: item.deliver_type,
  }));
  // console.log("datas", datas);

  const columns = [
    {
      title: "Sr.",
      dataIndex: "number",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Sub Event Title",
      dataIndex: "subEventTitle",
      key: "subEventTitle",
      ellipsis: true,
    },
    {
      title: "Guest Name",
      dataIndex: "guestName",
      key: "guestName",
      ellipsis: true,
    },

    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
      ellipsis: true,
    },

    {
      title: "Item Identification",
      dataIndex: "itemIdentification",
      key: "itemIdentification",
      ellipsis: true,
    },

    {
      title: "Lost Place",
      dataIndex: "lostPlace",
      key: "lostPlace",
      ellipsis: true,
    },

    {
      title: "Found Place",
      dataIndex: "foundPlace",
      key: "foundPlace",
      ellipsis: true,
    },

    {
      title: "Found By",
      dataIndex: "foundBy",
      key: "foundBy",
      ellipsis: true,
    },

    {
      title: "Deliver Type",
      dataIndex: "deliverTpye",
      key: "deliverTpye",
      ellipsis: true,
    },
  ];
  return (
    <div className="">
      <h1 className="text-center">List Of Lost And Found</h1>
      <div className="">
        <Table
          dataSource={datas}
          columns={columns}
          loading={loading}
          scroll={{
            y: "auto",
            x: "auto",
          }}
          // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        />
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(SingleLostAndFound);
