import React, { useEffect, useState } from "react";
import { message, Popconfirm, Table } from "antd";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link } from "react-router-dom";
import { deleteHotel, getAllHotel } from "../../../api-core/Hotel";
import {
  deleteVendorCar,
  getAllVendorCar,
  getAllVendors,
} from "../../../api-core/Vendor";

const ListOfVendorCar = () => {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    fetchAllVendors();
  }, []);
  const fetchAllVendors = async () => {
    setloading(true);
    const res = await getAllVendorCar();
    setdata(res.vendorcar);
    setloading(false);
  };
  const onDeleteRecord = async (record, id) => {
    const recordId = {
      ids: [record],
    };
    const res = await deleteVendorCar(recordId);
    if (res.success == true) {
      message.success(res.msgText);
      fetchAllVendors();
    }
  };

  const datas = data.map((item) => ({
    key: item._id,
    vendorName: item.vendor_id?.vendor_name,
    vendorType: item.vendor_id?.vendor_type,
    carName: item.car_name,
    carModel: item.car_model,
    carNumber: item.car_number,
  }));
  // console.log("datas", datas);

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Vendor Name",
      dataIndex: "vendorName",
      key: "vendorName",
      ellipsis: true,
    },
    {
      title: "Vendor Type",
      dataIndex: "vendorType",
      key: "vendorType",
      ellipsis: true,
    },
    {
      title: "Car Name",
      dataIndex: "carName",
      key: "carName",

      ellipsis: true,
    },
    {
      title: "Car Model",
      dataIndex: "carModel",
      key: "carModel",

      ellipsis: true,
    },
    {
      title: "Car Number",
      dataIndex: "carNumber",
      key: "carNumber",

      ellipsis: true,
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex gap-x-2 ">
          {/* {console.log("rec", record)} */}
          <Link to={`/admin/singlevendorcar/${record.key}`}>
            <button className="text-blue-600 cursor-pointer">View</button>
          </Link>

          <Link to={`/admin/updatevendorcar/${record.key}`}>
            <button className="text-yellow-400 cursor-pointer">Edit</button>
          </Link>

          <Popconfirm
            title="Sure to delete?"
            onConfirm={(e) => onDeleteRecord(record.key, e)}
          >
            <span className="text-red-500 cursor-pointer">Delete</span>
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div className="">
      <Link to="/admin/createvendorcar">
        <button className="bg-blue-600 text-white p-3 mx-3">
          Create Vendors Car
        </button>
      </Link>
      <h1 className="text-center">List Of Vendors Car</h1>
      <div className="">
        <Table
          dataSource={datas}
          columns={columns}
          loading={loading}
          // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        />
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(ListOfVendorCar);
