import React, { useEffect, useState } from "react";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { useParams } from "react-router-dom";
import {
  createGuestlist,
  getAllGuestlist,
  getSingleGuestlist,
  updateGuestlist,
} from "../../../api-core/Ghms";
import { formatDate } from "../../../utils/helper";
const UpdateGuestlist = () => {
  const { id } = useParams();
  const [guestIdData, setGuestIdData] = useState([]);
  const [loading, setloading] = useState(false);
  const [inputs, setInputs] = useState({
    sub_event_id: "",
    guest_name: "",
    guest_email: "",
    guest_add: "",
    guest_mobile: "",
    guest_invited: "",
    guest_outstation: "",
    guest_expected_nos: "",
    guest_invitation_type: "",
    guest_date_of_arrival: "",
  });
  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    const fetchSingleGuestList = async () => {
      setloading(true);
      const res = await getSingleGuestlist(id);
      // console.log("res", res.ghmsguestlist);

      // setdata(res.ghmsguestlist);
      setInputs({
        sub_event_id: res.ghmsguestlist[0].sub_event_id._id,
        guest_name: res.ghmsguestlist[0].guest_name,
        guest_email: res.ghmsguestlist[0].guest_email,
        guest_add: res.ghmsguestlist[0].guest_add,
        guest_mobile: res.ghmsguestlist[0].guest_mobile,
        guest_invited: res.ghmsguestlist[0].guest_invited,
        guest_outstation: res.ghmsguestlist[0].guest_outstation,
        guest_expected_nos: res.ghmsguestlist[0].guest_expected_nos,
        guest_invitation_type: res.ghmsguestlist[0].guest_invitation_type,
        guest_date_of_arrival: formatDate(
          res.ghmsguestlist[0].guest_date_of_arrival,
          "yyyy-MM-DD"
        ),
      });
      setloading(false);
    };
    fetchSingleGuestList();
  }, []);
  useEffect(() => {
    const fetchAllGuestList = async () => {
      setloading(true);
      const res = await getAllGuestlist();
      // console.log("res", res.ghmsguestlist);

      setGuestIdData(res.ghmsguestlist);
      setloading(false);
    };
    fetchAllGuestList();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("inputs", inputs);

    if (inputs) {
      const res = await updateGuestlist(inputs,id);
      console.log("resFormData", res);
    } else {
      console.log("error");
    }
  };
  return (
    <div className="w-[90%] mx-auto ">
      <h1>Update Guest List</h1>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="flex flex-col justify-center items-center  md:justify-between md:flex-row gap-x-5 flex-wrap">
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Sub Event Title
            </label>
            <select
              value={inputs.sub_event_id}
              name="sub_event_id"
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              {guestIdData.map((value) => (
                <option value={value.sub_event_id._id} key={value._id}>
                  {value.sub_event_id.subevent_title}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Guest Name
            </label>
            <input
              type="text"
              name="guest_name"
              value={inputs.guest_name}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter guest name"
            />
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Guest Email
            </label>
            <input
              type="text"
              name="guest_email"
              value={inputs.guest_email}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter guest email "
            />
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Guest Address
            </label>
            <input
              type="text"
              name="guest_add"
              value={inputs.guest_add}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter guest add"
            />
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Guest Mobile
            </label>
            <input
              type="text"
              name="guest_mobile"
              value={inputs.guest_mobile}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter guest mobile"
            />
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Guest invited
            </label>
            <input
              type="text"
              name="guest_invited"
              value={inputs.guest_invited}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter guest invited"
            />
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Guest Outstation
            </label>
            <input
              type="text"
              name="guest_outstation"
              value={inputs.guest_outstation}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter guest outstation"
            />
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Guest Expected no
            </label>
            <input
              type="text"
              name="guest_expected_nos"
              value={inputs.guest_expected_nos}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter  guest_expected_nos"
            />
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Guest Invitation Type
            </label>
            <input
              type="text"
              name="guest_invitation_type"
              value={inputs.guest_invitation_type}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter guest invitation type"
            />
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Guest Date of Arrival
            </label>
            <input
              type="date"
              name="guest_date_of_arrival"
              value={inputs.guest_date_of_arrival}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter guest date of arrival"
            />
          </div>
        </div>
        <button
          type="submit"
          className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Update
        </button>
      </form>
    </div>
  );
};

export default SidebarHeaderLayout(UpdateGuestlist);
