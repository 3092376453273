import { useEffect, useState } from "react";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { message } from "antd";
// import { getAllUser } from "../../../api-core/User";
import {
  createFreelancerAssignee,
  getAllFreelancer,
  getSingleFreelancerAssignee,
  updateFreelancerAssignee,
} from "../../../api-core/Freelancer";
import { getAllEvents } from "../../../api-core/Events";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import { departmentData } from "src/constants";

const UpdateFreelancerAssignee = () => {
  // const [clientData, setClientData] = useState([]);
  // const [currentEventData, setCurrentEventData] = useState([]);
  const [freelancerData, setFreelancerData] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [initialValues, setInitialValues] = useState({
    event_id: "",
    freelancer_id: "",
    department_type: "",
    expected_working_hours: "",
    hours_worked: "",
  });
  const { id } = useParams();
  const navigate = useNavigate();

  // useEffect(() => {
  //   const fetchAllUser = async () => {
  //     const res = await getAllUser();

  //     setClientData(res.user);
  //   };
  //   fetchAllUser();
  // }, []);

  useEffect(() => {
    const fetchAllSubEvents = async () => {
      const eventsRes = await getAllEvents(1, 1000);
      const res = await getSingleFreelancerAssignee(id);
      setInitialValues({
        event_id: res?.freelancerassignedevent[0].event_id._id,
        freelancer_id: res?.freelancerassignedevent[0].freelancer_id?._id,
        department_type: res?.freelancerassignedevent[0].department_type,
        expected_working_hours:
          res?.freelancerassignedevent[0].expected_working_hours,
        hours_worked: res?.freelancerassignedevent[0].hours_worked,
      });
      setAllEvents(eventsRes?.event);
    };
    fetchAllSubEvents();
  }, []);

  // useEffect(() => {
  //   const fetchAllEvents = async () => {
  //     const res = await getAllEvents2();

  //     setAllEvents(res.event);
  //   };
  //   fetchAllEvents();
  // }, [clientData]);

  useEffect(() => {
    const fetchgetAllFreelancer = async () => {
      const res = await getAllFreelancer();

      setFreelancerData(res.freelancer);
    };
    fetchgetAllFreelancer();
  }, []);

  

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      event_id: Yup.string().required("Event Name is required"),
      freelancer_id: Yup.string().required("Freelancer Name required"),
      department_type: Yup.string().required("Department Name is required"),
      expected_working_hours: Yup.number(),//.required("Expected Working Hours is required"),
      hours_worked: Yup.number()//.required("Hours Worked is required"),
    }),
    onSubmit: async (values, action) => {
      const {
        event_id,
        freelancer_id,
        department_type,
        expected_working_hours,
        hours_worked,
      } = values;
      const payload = {
        event_id,
        freelancer_id,
        department_type,
        expected_working_hours,
        hours_worked,
      };
      payload.expected_working_hours = parseInt(expected_working_hours) || undefined
      payload.hours_worked = parseInt(hours_worked) || undefined 

      if (values) {
        const res = await updateFreelancerAssignee(payload, id);
        if (res.success) {
          message.success("Updated Successfully");
          navigate("/admin/listoffreelancerassignedevent");
        }
      } else if (!values) {
        message.error("Some fields missing");
      } else {
        message.error("Something went wrong");
      }
    },
  });

  // console.log("current", currentEventData);
  return (
    <div className="w-[90%] mx-auto ">
      <h1>Update Deployed FreeLancer </h1>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col justify-center items-center  md:justify-between md:flex-row gap-x-5 flex-wrap">
          {/* <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Client Name
            </label>
            <select
              value={formik.values.client_id}
              name="client_id"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={""}>Select Client Name</option>
              {clientData.map((value) => (
                <option
                  value={value._id}
                  key={value._id}
                  selected={value._id == formik.values.client_id}
                >
                  {value.name}
                </option>
              ))}
            </select>
            {formik.errors.client_id && (
              <span className="text-red-500">{formik.errors.client_id}</span>
            )}
          </div> */}

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Event Name
            </label>
            <select
              value={formik.values.event_id}
              name="event_id"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={""}>Select Event</option>
              {allEvents?.map((value) => (
                <option value={value?._id} key={value?._id} >
                  {value?.event_title}
                </option>
              ))}

              {/* {allEvents
                ?.filter(
                  (items) => items.client_id._id === formik.values.client_id
                )
                .map((values) => (
                  <option value={values?._id} key={values?._id}>
                    {values?.event_title}
                  </option>
                ))} */}
            </select>
            {formik.errors.event_id && (
              <span className="text-red-500">{formik.errors.event_id}</span>
            )}
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Department Name
            </label>

            <select
              value={formik.values.department_type}
              name="department_type"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={""}>Select Department Name</option>
               {departmentData?.map((dept) => (
                <option value={dept?.value} key={dept._id}>
                  {dept.value}
                </option>
              ))}
              
              {/* {freelancerData
                ?.filter((items) => items._id == formik.values.freelancer_id)
                .map((values) => (
                  <option value={values?.department_type} key={values?._id}>
                    {values.department_type}
                  </option>
                ))} */}
            </select>

            {formik.errors.department_type && (
              <span className="text-red-500">
                {formik.errors.department_type}
              </span>
            )}
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Freelancer Name
            </label>
            <select
              value={formik.values.freelancer_id}
              name="freelancer_id"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={""}>Select Freelancer Name</option>
              {freelancerData
                ?.filter((items) => items?.department_type === formik.values.department_type)
                .map((values) => {
                  return (
                    <option value={values?._id} key={values._id}>
                      {values?.name}
                    </option>
                  );
                })}
                {/* {freelancerData.map((value) => (
                <option value={value._id} key={value._id}>
                  {value.name}
                </option>
              ))} */}
            </select>
            {formik.errors.freelancer_id && (
              <span className="text-red-500">
                {formik.errors.freelancer_id}
              </span>
            )}
          </div>
          
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Expected Working Hours
            </label>
            <input
              type="number"
              name="expected_working_hours"
              value={formik.values.expected_working_hours}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter time"
            />
            {formik.errors.expected_working_hours && (
              <span className="text-red-500">
                {formik.errors.expected_working_hours}
              </span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Hours Worked
            </label>
            <input
              type="number"
              name="hours_worked"
              value={formik.values.hours_worked}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter time"
            />
            {formik.errors.hours_worked && (
              <span className="text-red-500">{formik.errors.hours_worked}</span>
            )}
          </div>
        </div>
        <Link to={"/admin/listoffreelancerassignedevent"}>
          {/* <h1 className="text-lg text-black">Cancel</h1> */}
          <button type="button"
            className="mr-2 text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
            >Cancel</button>
        </Link>
        <button
          type="submit"
          className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Update
        </button>
      </form>
    </div>
  );
};

export default SidebarHeaderLayout(UpdateFreelancerAssignee);