import React from "react";
import { Button, Input, Popconfirm, Table, Typography } from "antd";
import EditArrival, { Arrival } from "./EditArrival";
import { ShouldRender } from "src/components/ShouldRender";
import {
  createArrivalMgmnt,
  deleteArrivalMgmnt,
  updateArrivalMgmnt,
} from "src/api-core/Ghms";
import { IArrival, IEvent, IGuestList, IVendorCar } from "src/types";
import moment from "moment";
import { TIME_FORMATS, TIME_PICKER_FORMAT } from "src/constants";

interface Props {
  arrival: IArrival[] | undefined;
  event: IEvent | undefined;
  guests: IGuestList[] | undefined;
  loading: boolean;
  update: () => Promise<void>;
  vendorCars: IVendorCar[];
}

export const ArrivalTable = ({
  arrival = [],
  event,
  guests = [],
  loading,
  update,
  vendorCars,
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const [recordIndex, setRecordIndex] = React.useState(-1);
  const [searchedText, setSearchedText] = React.useState("");

  const handleEdit = (index: number) => {
    setOpen(true);
    setRecordIndex(index);
  };

  const handleClose = () => {
    setOpen(false);
    setRecordIndex(-1);
  };
  // console.log("Arriaval ", arrival[0].guest_id);
  
  const handleOpen = () => setOpen(true);

  const handleSave = async (values: Arrival) => {
    if (recordIndex === -1) {
      if (event) {
        const { client_id, _id: event_id } = event;
        const payload = {
          client_id,
          event_id,
          ...values,
        };
        createArrivalMgmnt(payload).then(update).then(handleClose);
      }
    } else {
      const recordDetail = arrival[recordIndex];
      const {
        // car_id,
        date_of_arrival,
        guest_id,
        no_of_guest_arrived,
        // mode_of_arrival,
        // expected_arrival_time,
        arrived_at,
        // welcome_checklist,
        // special_note,
      } = values;
      const { client_id, event_id, _id } = recordDetail;
      const payload = {
        client_id,
        event_id,
        // guest_id,
        ...values
        // car_id,
        // date_of_arrival,
        // no_of_guest_arrived,
        // mode_of_arrival,
        // expected_arrival_time,
        // arrived_at,
        // welcome_checklist,
        // special_note,
      };
      updateArrivalMgmnt(payload, _id).then(update).then(handleClose);
    }
  };

  const handleDelete = (id: string) => {
    const ids = [id];
    deleteArrivalMgmnt(ids).then(update);
  };

  const defaultColumns = [
    {
      title: "Guest Name",
      dataIndex: "guest_name",
      key: "guest_name",
      filteredValue: [searchedText],
      onFilter: (value:any, record:any)=>{
        return  String(record.guest_mobile)
                .toLowerCase()
                .includes(value.toLowerCase()) ||
                // String(record.mode_of_arrival)
                // .toLowerCase()
                // .includes(value.toLowerCase()) ||
                String(record.guest_name)
                .toLowerCase()
                .includes(value.toLowerCase())
      },
      // render: (_: any, record: Arrival) => {
      //   const guestName = guests.find(({_id}) => _id === record.guest_id);
      //   return guestName?.guest_name || "NA";
      // },
    },
    {
      title: "Guest Mobile",
      dataIndex: "guest_mobile",
      key: "guest_mobile",
      render: (value: any) => {
        // const guestMobile = guests.find(({_id}) => _id === record.guest_id)?.guest_mobile || "NA";
        return  <a href={`tel:${value}`}> {`${ value}`}</a> 
      },
    },
    {
      title: "Date of Arrival",
      dataIndex: "date_of_arrival",
      key: "date_of_arrival",
      // render: (value: any) => value && moment(value).format(TIME_FORMATS.DATE),
    },
    // {
    //   title: "No. of guest arrived",
    //   dataIndex: "no_of_guest_arrived",
    //   key: "no_of_guest_arrived",
    // },
    // {
    //   title: "Mode of arrival",
    //   dataIndex: "mode_of_arrival",
    //   key: "mode_of_arrival",
    //   filteredValue: [searchedText],
    //   onFilter: (value:any, record:any)=>{
    //     return  String(record.date_of_arrival)
    //             .toLowerCase()
    //             .includes(value.toLowerCase()) ||
    //             String(record.mode_of_arrival)
    //             .toLowerCase()
    //             .includes(value.toLowerCase()) ||
    //             String(record.guest_name)
    //             .toLowerCase()
    //             .includes(value.toLowerCase())
    //   },
    // },
    // {
    //   title: "Expected Arrival Time",
    //   dataIndex: "expected_arrival_time",
    //   key: "expected_arrival_time",
    // },
    {
      title: "Arrived At",
      dataIndex: "arrived_at",
      key: "arrived_at",
      // render: (value: any) => moment(value).format(TIME_PICKER_FORMAT)
    },
    // {
    //   title: "Welcome Text",
    //   dataIndex: "welcome_checklist",
    //   key: "welcome_checklist",
    // },
    {
      title: "Arrival Location",
      dataIndex: "arrival_location",
      key: "arrival_location"
    },
    {
      title: "Conveyance Through",
      dataIndex: "conveyance_through",
      key: "conveyance_through"
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details",
      // render: (value: any) => value || "NA"
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: any) => {
        
        const index = arrival.findIndex((item) => item._id === record._id);
        
        return (
          <span>
            <Typography.Link
              disabled={recordIndex !== -1}
              onClick={() => handleEdit(index)}
              style={{ marginRight: 8 }}
            >
              Edit
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record._id)}
            >
              <Typography.Link>Delete</Typography.Link>
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  const dataSource = arrival?.map((items: IArrival) => ({
    _id: items._id,
    key: items._id,
    guest_id: guests?.find(
      (guest: IGuestList) => guest.guest_name && guest._id === items.guest_id
    )?._id,
    guest_name: guests?.find(
      (guest: IGuestList) => guest.guest_name && guest._id === items.guest_id
    )?.guest_name,
    guest_mobile: guests?.find(
      (guest: IGuestList) => guest.guest_name && guest._id === items.guest_id
    )?.guest_mobile,
    date_of_arrival: items.date_of_arrival && moment(items.date_of_arrival).format(TIME_FORMATS.DATE),
    arrived_at: items.arrived_at,
    arrival_location: items?.arrival_location,
    conveyance_through: items?.conveyance_through,
    details: items?.details,
  }));

  return (
    <React.Fragment>
      <div className="flex justify-end mb-3">
        <Button onClick={handleOpen}>Add Data</Button>
      </div>
      <Input.Search size="large" placeholder="search here..."
             style={{marginBottom: 8, width: 280}}
             onSearch={(value)=>{
              setSearchedText(value);
             }}
             onChange={(e)=>{
              setSearchedText(e.target.value);
             }}/>
      <Table
        columns={defaultColumns}
        dataSource={dataSource}
        // dataSource={arrival}
        key="_id"
        loading={loading}
      />
      <ShouldRender check={open}>
        <EditArrival
          open={open}
          arrival={arrival[recordIndex]}
          guests={guests}
          title={recordIndex === -1 ? "Add Arrival" : "Edit Arrival"}
          handleClose={handleClose}
          handleSave={handleSave}
          vendorCars={vendorCars}
        />
      </ShouldRender>
    </React.Fragment>
  );
};
