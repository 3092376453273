import React, { useEffect, useState } from "react";
import { Table } from "antd";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link, useParams } from "react-router-dom";
import { getSingleHotelRoomChecklist } from "../../../api-core/Hotel";

const SingleHotelRoomChecklist = () => {
  const { id } = useParams();
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    const fetchSingleHotelRoomChecklist = async () => {
      setloading(true);
      const res = await getSingleHotelRoomChecklist(id);
      console.log("res", res);

      setdata(res.hotelroomchecklist);
      setloading(false);
    };
    fetchSingleHotelRoomChecklist();
  }, []);


  const datas = data.map((item) => ({
    key: item._id,
    hotelName: item.hotel_id.hotel_name,
    roomTypeName: item.room_type_id.room_type_name,
    checklistName: item.checklist_name,
  }));
  // console.log("datas", datas);

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Hotel Name",
      dataIndex: "hotelName",
      key: "hotelName",
      ellipsis: true,
    },

    {
      title: "Room Type",
      dataIndex: "roomTypeName",
      key: "roomTypeName",

      ellipsis: true,
    },
    {
      title: "Checklist Name",
      dataIndex: "checklistName",
      key: "checklistName",
    },
  ];
  return (
    <div className="">
      <h1 className="text-center">List Of Hotel Room Checklist</h1>
      <div className="">
        <Table
          dataSource={datas}
          columns={columns}
          loading={loading}

          // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        />
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(SingleHotelRoomChecklist);
