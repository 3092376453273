import React, { useEffect, useState } from "react";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { createVendor } from "../../../api-core/Vendor";
import { getAllUser } from "../../../api-core/User";
import { getAllSubEvents } from "../../../api-core/Events";
import { deleteVendor, getAllVendors } from "../../../api-core/Vendor";
import { useFormik } from "formik";
import * as Yup from "yup";
const CreateVendorProdDecorChecklist = () => {
  const [clientData, setClientData] = useState([]);
  const [currentEventData, setCurrentEventData] = useState([]);
  const [loading, setloading] = useState(false);
  const [vendorData, setVendorData] = useState([]);
  useEffect(() => {
    const fetchAllVendors = async () => {
      setloading(true);
      const res = await getAllVendors();
      console.log("res", res.vendor);

      setVendorData(res.vendor);
      setloading(false);
    };
    fetchAllVendors();
  }, []);

  const initialValues = {
    venodr_id: "",
    checklist_name: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      venodr_id: Yup.string().required("Vendor Name is required"),
      checklist_name: Yup.string().required("Food Type name required"),
    }),
    onSubmit: async (values, action) => {
      console.log("values", values);

      // if (values) {
      //   const res = await createFreelancerAssignee(values);
      //   console.log("resFormData", res);
      //   if (res.success === true) {
      //     message.success("Submitted Successfully");
      //     action.resetForm();
      //   }
      // } else {
      //   console.log("error");
      // }
    },
  });

  return (
    <div className="w-[90%] mx-auto ">
      <h1>Create Vendor Prod Decor Checklist</h1>
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <div className="flex flex-col justify-center items-center  md:justify-between md:flex-row gap-x-5 flex-wrap">
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Vendor Name
            </label>
            <select
              value={formik.values.venodr_id}
              name="venodr_id"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={""}>Select Vendor Name</option>
              {vendorData.map((value) => (
                <option value={value._id} key={value._id}>
                  {value.vendor_name}
                </option>
              ))}
            </select>
            {formik.errors.venodr_id && (
              <span className="text-red-500">{formik.errors.venodr_id}</span>
            )}
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Checklist Name
            </label>
            <input
              type="text"
              name="checklist_name"
              value={formik.values.checklist_name}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter Checklist name"
            />
            {formik.errors.checklist_name && (
              <span className="text-red-500">
                {formik.errors.checklist_name}
              </span>
            )}
          </div>
        </div>
        <button
          type="submit"
          className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default SidebarHeaderLayout(CreateVendorProdDecorChecklist);
