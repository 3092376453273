import React, { useEffect, useState } from "react";
import { message, Popconfirm, Table } from "antd";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link } from "react-router-dom";
import { deleteHotel, getAllHotel } from "../../../api-core/Hotel";
import { deleteVendor, getAllVendors } from "../../../api-core/Vendor";

const ListOfVendors = () => {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    fetchAllVendors();
  }, []);
  const fetchAllVendors = async () => {
    setloading(true);
    const res = await getAllVendors();
    setdata(res.vendor);
    setloading(false);
  };
  const onDeleteRecord = async (record, id) => {
    const recordId = {
      ids: [record],
    };
    const res = await deleteVendor(recordId);
    if (res.success == true) {
      message.success(res.msgText);
      fetchAllVendors();
    }
  };

  const datas = data.map((item) => ({
    key: item._id,
    vendorName: item.vendor_name,
    vendorAddress: item.vendor_add,
    vendorMobile: item.vendor_mobile,
    vendorEmail: item.vendor_email,
    vendorWork: item.vendor_work,
    vendorScopeOfWork: item.vendor_scope_of_work,
  }));
  // console.log("datas", datas);

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Vendor Name",
      dataIndex: "vendorName",
      key: "vendorName",
      ellipsis: true,
    },
    {
      title: "Vendor Work",
      dataIndex: "vendorWork",
      key: "vendorWork",
      ellipsis: true,
    },
    {
      title: "Vendor Mobile",
      dataIndex: "vendorMobile",
      key: "vendorMobile",
    },
    {
      title: "Scope Of Work",
      dataIndex: "vendorScopeOfWork",
      key: "vendorScopeOfWork",
      ellipsis: true,
    },
    // {
    //   title: "Vendor Address",
    //   dataIndex: "vendorAddress",
    //   key: "vendorAddress",

    //   ellipsis: true,
    // },

    // {
    //   title: "Vendor Email",
    //   dataIndex: "vendorEmail",
    //   key: "vendorEmail",
    // },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex gap-x-2 ">
          {/* {console.log("rec", record)} */}
          <Link to={`/admin/singlevendor/${record.key}`}>
            <button className="text-blue-600 cursor-pointer">View</button>
          </Link>

          <Link to={`/admin/updatevendor/${record.key}`}>
            <button className="text-yellow-400 cursor-pointer">Edit</button>
          </Link>

          <Popconfirm
            title="Sure to delete?"
            onConfirm={(e) => onDeleteRecord(record.key, e)}
          >
            <span className="text-red-500 cursor-pointer">Delete</span>
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div className="">
      <Link to="/admin/createvendor">
        <button className="bg-blue-600 text-white p-3 mx-3">
          Create Vendors
        </button>
      </Link>
      <h1 className="text-center">List Of Vendors</h1>
      <div className="">
        <Table
          dataSource={datas}
          columns={columns}
          loading={loading}
          // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        />
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(ListOfVendors);
