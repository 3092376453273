import Axios from "../Axios";

export const getAllGuestlist = async () => {
  try {
    const response = await Axios.get(`/admin/ghmsguestlist`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const createGuestlist = async (data) => {
  try {
    const response = await Axios.post(`/admin/ghmsguestlist/create`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getSingleGuestlist = async (id) => {
  try {
    const response = await Axios.get(`/admin/ghmsguestlist/read/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const updateGuestlist = async (data, id) => {
  try {
    const response = await Axios.post(
      `/admin/ghmsguestlist/update/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deleteGuestlist = async (ids) => {
  const data = {
    ids,
  };
  try {
    const response = await Axios.post("/admin/ghmsguestlist/delete", data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getAllArrivalMgmnt = async () => {
  try {
    const response = await Axios.get(`/admin/ghmsarrival`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getSingleArrivalMgmnt = async (id) => {
  try {
    const response = await Axios.get(`/admin/ghmsarrival/read/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const createArrivalMgmnt = async (data) => {
  try {
    const response = await Axios.post(`/admin/ghmsarrival/create`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const updateArrivalMgmnt = async (data, id) => {
  try {
    const response = await Axios.post(`/admin/ghmsarrival/update/${id}`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deleteArrivalMgmnt = async (ids) => {
  const data = {
    ids,
  };
  try {
    const response = await Axios.post("/admin/ghmsarrival/delete/", data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAllDepartureMgmnt = async () => {
  try {
    const response = await Axios.get(`/admin/ghmsdeparture`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getSingleDepartureMgmnt = async (id) => {
  try {
    const response = await Axios.get(`/admin/ghmsdeparture/read/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const createDepartureMgmnt = async (data) => {
  try {
    const response = await Axios.post(`/admin/ghmsdeparture/create`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const updateDepartureMgmnt = async (data, id) => {
  try {
    const response = await Axios.post(
      `/admin/ghmsdeparture/update/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deleteDepartureMgmnt = async (ids) => {
  const data = {
    ids,
  };
  try {
    const response = await Axios.post("/admin/ghmsdeparture/delete", data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getAllLostAndFound = async () => {
  try {
    const response = await Axios.get(`/admin/ghmslostfound`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getSingleLostAndFound = async (id) => {
  try {
    const response = await Axios.get(`/admin/ghmslostfound/read/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deleteLostAndFound = async (ids) => {
  const data = {
    ids,
  };
  try {
    const response = await Axios.post("/admin/ghmslostfound/delete", data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const createLostAndFound = async (data) => {
  try {
    const response = await Axios.post(`/admin/ghmslostfound/create`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const updateLostAndFound = async (data, id) => {
  try {
    const response = await Axios.post(
      `/admin/ghmslostfound/update/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAllOutstationMgmnt = async () => {
  try {
    const response = await Axios.get(`/admin/ghmsoutstation`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getSingleOutstationMgmnt = async (id) => {
  try {
    const response = await Axios.get(`/admin/ghmsoutstation/read/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const createOutstationMgmnt = async (data) => {
  try {
    const response = await Axios.post(`/admin/ghmsoutstation/create`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const updateOutstationMgmnt = async (data, id) => {
  try {
    const response = await Axios.post(`/admin/ghmsoutstation/update/${id}`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deleteOutstationMgmnt = async (ids) => {
  const data = {
    ids,
  };
  try {
    const response = await Axios.post("/admin/ghmsoutstation/delete/", data);
    return response.data;
  } catch (error) {
    return error;
  }
};
