import React from "react";
import { Button, Input, Popconfirm, Table, Typography } from "antd";
import EditOutstation, { Outstation } from "./EditOutstation";
import { ShouldRender } from "src/components/ShouldRender";
import {
  createOutstationMgmnt,
  deleteOutstationMgmnt,
  updateOutstationMgmnt,
} from "src/api-core/Ghms";
import { IOutstation, IEvent, IGuestList } from "src/types";
import moment from "moment";
import { TIME_FORMATS, TIME_PICKER_FORMAT } from "src/constants";

interface Props {
  outstation: IOutstation[] | undefined;
  event: IEvent | undefined;
  guests: IGuestList[] | undefined;
  loading: boolean;
  update: () => Promise<void>;
}

export const OutstationTable = ({
  outstation = [],
  event,
  guests = [],
  loading,
  update,
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const [recordIndex, setRecordIndex] = React.useState(-1);
  const [searchedText, setSearchedText] = React.useState("");

  const handleEdit = (index: number) => {
    setOpen(true);
    setRecordIndex(index);
  };

  const handleClose = () => {
    setOpen(false);
    setRecordIndex(-1);
  };
  
  const handleOpen = () => setOpen(true);

  const handleSave = async (values: Outstation) => {
    if (recordIndex === -1) {
      if (event) {
        const { client_id, _id: event_id } = event;
        const payload = {
          client_id,
          event_id,
          ...values,
        };
        createOutstationMgmnt(payload).then(update).then(handleClose);
      }
    } else {
      const recordDetail = outstation[recordIndex];
      const { client_id, event_id, _id } = recordDetail;
      const payload = {
        client_id,
        event_id,
        ...values
      };
      updateOutstationMgmnt(payload, _id).then(update).then(handleClose);
    }
  };

  const handleDelete = (id: string) => {
    const ids = [id];
    deleteOutstationMgmnt(ids).then(update);
  };

  const defaultColumns = [
    {
      title: "Guest Name",
      dataIndex: "guest_name",
      key: "guest_name",
      filteredValue: [searchedText],
      onFilter: (value:any, record:any)=>{
        return  String(record.guest_name)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
            String(record.guest_mobile)
            .includes(value) ||
            String(record.arrival_location)
            .toLowerCase()
            .includes(value.toLowerCase())
      }
    },
    {
      title: "Guest Mobile",
      dataIndex: "guest_mobile",
      key: "guest_mobile",
      render: (value: any) => <a href={`tel:${value}`}> {`${ value}`}</a> 
    },
    {
      title: "Arrival Location",
      dataIndex: "arrival_location",
      key: "arrival_location"
    },
    {
      title: "Date And Time",
      dataIndex: "date_and_time",
      key: "date_and_time"
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes"
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: any) => {
        
        const index = outstation.findIndex((item) => item._id === record._id);
        
        return (
          <span>
            <Typography.Link
              disabled={recordIndex !== -1}
              onClick={() => handleEdit(index)}
              style={{ marginRight: 8 }}
            >
              Edit
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record._id)}
            >
              <Typography.Link>Delete</Typography.Link>
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  const dataSource = outstation?.map((items: IOutstation) => ({
    _id: items._id,
    key: items._id,
    guest_id: guests?.find(
      (guest: IGuestList) => guest.guest_name && guest._id === items.guest_id
    )?._id,
    guest_name: guests?.find(
      (guest: IGuestList) => guest.guest_name && guest._id === items.guest_id
    )?.guest_name,
    guest_mobile: guests?.find(
      (guest: IGuestList) => guest.guest_name && guest._id === items.guest_id
    )?.guest_mobile,
    date_and_time: items.date_and_time && moment(items.date_and_time).format(TIME_FORMATS.DATE_TIME),
    arrival_location: items?.arrival_location,
    notes: items?.notes,
  }));

  return (
    <React.Fragment>
      <div className="flex justify-end mb-3">
        <Button onClick={handleOpen}>Add Data</Button>
      </div>
      <Input.Search size="large" placeholder="search here..."
             style={{marginBottom: 8, width: 280}}
             onSearch={(value)=>{
              setSearchedText(value);
             }}
             onChange={(e)=>{
              setSearchedText(e.target.value);
             }}/>
      <Table
        columns={defaultColumns}
        dataSource={dataSource}
        // dataSource={arrival}
        key="_id"
        loading={loading}
      />
      <ShouldRender check={open}>
        <EditOutstation
          open={open}
          outstation={outstation[recordIndex]}
          guests={guests}
          title={recordIndex === -1 ? "Add Outstation" : "Edit Outstation"}
          handleClose={handleClose}
          handleSave={handleSave}
          // vendorCars={vendorCars}
        />
      </ShouldRender>
    </React.Fragment>
  );
};
