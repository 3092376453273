import React, { useState } from "react";
import { message } from "antd";
import { getSingleHotel, readAllCheckedHotelRoom } from "src/api-core/Hotel";
import { IHotelOption, IHotelRoom } from "src/types";

export const useFetchHotelDetail = (id?: string) => {
  const [hotel, setHotel] = useState<IHotelOption | null>(null);
  const [rooms, setRooms] = useState<IHotelRoom[]>([]);
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
    const fetchHotelRoomsDetails = async (id: string) => {
      try {
        const response = await readAllCheckedHotelRoom(id);

        return response?.hotelroom || [];
      } catch (error) {
        const messageText =
          (error as any)?.response?.data?.msgText || (error as any).message;
        message.error(messageText);
        return [];
      }
    };

    const fetchHotelDetails = async (id: string) => {
      try {
        const response = await getSingleHotel(id);
        if (response?.hotel) setHotel(response?.hotel[0]);
        const hotelrooms = await fetchHotelRoomsDetails(id);
        setRooms(hotelrooms);
      } catch (error) {
        const messageText =
          (error as any)?.response?.data?.msgText || (error as any).message;
        message.error(messageText);
      } finally {
        setLoading(false);
      }
    };
    if (id) fetchHotelDetails(id);
  }, [id]);

  const handleCreateRoomSuccess = (newRooms: IHotelRoom[]) => {
    setRooms((prev) => [...prev, ...newRooms]);
  };

  const handleDeleteRoomSuccess = (leftOverRooms: IHotelRoom[]) => {
    setRooms((prev) => [...leftOverRooms]);
  };

  return {
    handleCreateRoomSuccess,
    hotel,
    loading,
    rooms,
    handleDeleteRoomSuccess,
  };
};
