export const Navigation = {
  AdminMenu: [
    {
      id: 1,
      name: "OfferBanner",
      link: "/",
      hasSubOption: true,
      options: [
        {
          id: 1,
          name: "List OfferBanner",
          link: "/listofferbanner",
        },
        {
          id: 2,
          name: "Create OfferBanner",
          link: "/createofferbanner",
        },
      ],
    },
    {
      id: 2,
      name: "Trivia",
      link: "/trivia",
      hasSubOption: true,
      options: [
        {
          id: 1,
          name: "List Of Trivia",
          link: "/listoftrivia",
        },
        {
          id: 2,
          name: "Create Trivia",
          link: "/createtrivia",
        },
      ],
    },
    {
      id: 3,
      name: "Events",
      link: "/events",
      hasSubOption: true,
      options: [
        {
          id: 1,
          name: "List Of Events",
          link: "/listofevents",
        },
        {
          id: 2,
          name: "Create Events",
          link: "/createevents",
        },
      ],
    },
    {
      id: 4,
      name: "Gallery",
      link: "",
      hasSubOption: true,
      options: [
        {
          id: 1,
          name: "List Of Gallery",
          link: "/listofgallery",
        },
        {
          id: 2,
          name: "Create Gallery",
          link: "/creategallery",
        },
      ],
    },
  ],
};

export const TIME_FORMATS = {
  API_TIME: "YYYY-MM-DDTHH:mm:ssZ",
  DATE: "YYYY-MM-DD",
  TIME: "HH:mm",
  DATE_TIME: "YYYY-MM-DD HH:mm:ss"
};

export const TIME_PICKER_FORMAT = "HH:mm";

export const departmentData = [
  {
    id: 1,
    value: "Production",
  },
  {
    id: 2,
    value: "Entertainment",
  },
  {
    id: 3,
    value: "Cattering",
  },
  {
    id: 4,
    value: "Hospitality",
  },
  {
    id: 5,
    value: "Material",
  },
];

export const eventType = [
  {
    _id: "1",
    value: "Birthday",
  },
  {
    _id: "2",
    value: "Weekend Party",
  },
  {
    _id: "3",
    value: "Anniversary",
  },
  {
    _id: "4",
    value: "Wedding",
  },
  {
    _id: "5",
    value: "Reception Party",
  },
];
