import Axios, { overrideHeader } from "../Axios";

export const getAllOfferBanner = async () => {
  try {
    const response = await Axios.get(`/admin/offerBanner`);
    return response.data;
  } catch (error) {
    return error;
  }
};

/*Get according to row and pages */
export const getAllOfferBannerPage = async (row, pages) => {
  try {
    const response = await Axios.get(`/admin/offerBanner?r=${row}&p=${pages}`);

    return response.data;
  } catch (error) {
    return error;
  }
};

export const createOfferBanner = async (data) => {
  overrideHeader();
  try {
    const response = await Axios.post(`/admin/offerBanner/create`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getsingleOfferBanner = async (id) => {
  try {
    const response = await Axios.get(`/admin/offerBanner/read/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deleteOfferBanner = async (id) => {
  try {
    const response = await Axios.post(`/admin/offerBanner/delete`, id);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateOfferBanner = async (data, id) => {
  overrideHeader();
  try {
    const response = await Axios.post(`/admin/offerBanner/update/${id}`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAllEvents = async () => {
  try {
    const response = await Axios.post(`/admin/event`);
    return response.data;
  } catch (error) {
    return error;
  }
};
