import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Drawer } from "antd";
import { LogoutOutlined, MenuOutlined } from "@ant-design/icons";
import SidebarItem from "../Sidebar/SidebarItem";
import { Navigation } from "src/constants/sidebar";

const HeaderBar = () => {
  const [userName, setUserName] = useState("");
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("bbe-token");
    navigate("/");
  };

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("token"));
    setUserName(user.userName);
  }, []);

  const handleOpen = () => setShow(true);

  const handleClose = () => setShow(false);

  return (
    <React.Fragment>
      <div className="flex items-center justify-between py-2 md:justify-end">
        <div className="md:hidden">
          <Button icon={<MenuOutlined />} type="text" onClick={handleOpen} />
        </div>
        <div>
          <span>{userName && userName}</span>{" "}
          <Button
            danger
            icon={<LogoutOutlined />}
            type="text"
            onClick={logout}
          />
        </div>
      </div>
      <Drawer
        className="mobile-only-sidedrawer"
        placement="left"
        onClose={handleClose}
        open={show}
      >
        {Navigation.map((item, index) => (
          <SidebarItem key={index} item={item} />
        ))}
      </Drawer>
    </React.Fragment>
  );
};

export default HeaderBar;
