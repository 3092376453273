import Axios from "../Axios";

export const getAllTrivia = async () => {
  try {
    const response = await Axios.get(`/admin/trivia`);
    return response.data;
  } catch (error) {
    return error;
  }
};
/*Get all trivia according to page */

export const getAllTriviaPage = async (row, pages) => {
  try {
    const response = await Axios.get(`/admin/trivia?r=${row}&p=${pages}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const createTrivia = async (data) => {
  try {
    const response = await Axios.post(`/admin/trivia/create`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getSingleTrivia = async (id) => {
  try {
    const response = await Axios.get(`/admin/trivia/read/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deleteTrivia = async (id) => {
  try {
    const response = await Axios.post(`/admin/trivia/delete`, id);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateTrivia = async (data, id) => {
  try {
    const response = await Axios.post(`/admin/trivia/update/${id}`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
