import React, { useEffect, useState } from "react";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import {
  getAllGuestlist,
  getSingleArrivalMgmnt,
  updateArrivalMgmnt,
} from "../../../api-core/Ghms";
import {
  getAllVendorCar,
  getAllVendorDriver,
  getAllVendors,
} from "../../../api-core/Vendor";
import { useParams } from "react-router-dom";
import { formatDate } from "../../../utils/helper";
const UpdateArrivalMgmnt = () => {
  const { id } = useParams();
  const [guestIdData, setGuestIdData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [vendorCarData, setVendorCarData] = useState([]);
  const [vendorDriverData, setVendorDriverData] = useState([]);
  const [guestData, setGuestData] = useState([]);
  const [loading, setloading] = useState(false);
  const [inputs, setInputs] = useState({
    sub_event_id: "",
    guest_id: "",
    date_of_arrival: "",
    no_of_guest_arrived: "",
    mode_of_arrival: "",
    expected_arrival_time: "",
    arrived_at: "",
    picked_by: "",
    vendor_id: "",
    car_id: "",
    driver_id: "",
    welcome_checklist: "",
    special_note: "",
  });
  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    const fetchAllGuestList = async () => {
      setloading(true);
      const res = await getAllGuestlist();
      // console.log("res", res.ghmsguestlist);

      setGuestIdData(res.ghmsguestlist);
      setGuestData(res.ghmsguestlist);
      setloading(false);
    };
    fetchAllGuestList();
  }, []);

  useEffect(() => {
    const fetchAllVendors = async () => {
      setloading(true);
      const res = await getAllVendors();
      // console.log("res", res.vendor);

      setVendorData(res.vendor);
      setloading(false);
    };
    fetchAllVendors();
  }, []);
  useEffect(() => {
    const fetchAllVendors = async () => {
      setloading(true);
      const res = await getAllVendorCar();
      // console.log("res", res.vendorcar);

      setVendorCarData(res.vendorcar);
      setloading(false);
    };
    fetchAllVendors();
  }, []);
  useEffect(() => {
    const fetchAllVendorDriver = async () => {
      setloading(true);
      const res = await getAllVendorDriver();
      // console.log("res", res.vendordriver);

      setVendorDriverData(res.vendordriver);
      setloading(false);
    };
    fetchAllVendorDriver();
  }, []);
  useEffect(() => {
    const fetchSingleArrivalMgmnt = async () => {
      setloading(true);
      const res = await getSingleArrivalMgmnt(id);
      console.log("res", res.ghmsarrivalmgmt);

      setInputs({
        sub_event_id: res.ghmsarrivalmgmt[0].sub_event_id._id,
        guest_id: res.ghmsarrivalmgmt[0].guest_id._id,
        date_of_arrival: formatDate(
          res.ghmsarrivalmgmt[0].date_of_arrival,
          "yyyy-MM-DD"
        ),
        no_of_guest_arrived: res.ghmsarrivalmgmt[0].no_of_guest_arrived,
        mode_of_arrival: res.ghmsarrivalmgmt[0].mode_of_arrival,
        expected_arrival_time: res.ghmsarrivalmgmt[0].expected_arrival_time,
        arrived_at: res.ghmsarrivalmgmt[0].arrived_at,
        picked_by: res.ghmsarrivalmgmt[0].picked_by,
        vendor_id: res.ghmsarrivalmgmt[0].vendor_id._id,
        car_id: res.ghmsarrivalmgmt[0].car_id._id,
        driver_id: res.ghmsarrivalmgmt[0].driver_id._id,
        welcome_checklist: res.ghmsarrivalmgmt[0].welcome_checklist,
        special_note: res.ghmsarrivalmgmt[0].special_note,
      });
      setloading(false);
    };
    fetchSingleArrivalMgmnt();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("inputs", inputs);

    if (inputs) {
      const res = await updateArrivalMgmnt(inputs, id);
      console.log("resFormData", res);
    } else {
      console.log("error");
    }
  };
  return (
    <div className="w-[90%] mx-auto ">
      <h1>Update Arrival Management</h1>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="flex flex-col justify-center items-center  md:justify-between md:flex-row gap-x-5 flex-wrap">
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Sub Event Title
            </label>
            <select
              value={inputs.sub_event_id}
              name="sub_event_id"
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              {guestIdData.map((value) => (
                <option value={value.sub_event_id._id} key={value._id}>
                  {value.sub_event_id.subevent_title}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Guest Name
            </label>
            <select
              value={inputs.guest_id}
              name="guest_id"
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              {guestData.map((value) => (
                <option value={value._id} key={value._id}>
                  {value.guest_name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Date of Arrival
            </label>
            <input
              type="date"
              name="date_of_arrival"
              value={inputs.date_of_arrival}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter guest email "
            />
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              No of Guest Arrived
            </label>
            <input
              type="number"
              name="no_of_guest_arrived"
              value={inputs.no_of_guest_arrived}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter guest add"
            />
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Mode of Arrival
            </label>
            <input
              type="text"
              name="mode_of_arrival"
              value={inputs.mode_of_arrival}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter guest mobile"
            />
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Expected Arrival Time
            </label>
            <input
              type="time"
              name="expected_arrival_time"
              value={inputs.expected_arrival_time}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter guest invited"
            />
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Arrived At
            </label>
            <input
              type="time"
              name="arrived_at"
              value={inputs.arrived_at}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter guest outstation"
            />
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Picked By
            </label>
            <input
              type="text"
              name="picked_by"
              value={inputs.picked_by}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter  picked_by"
            />
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Vendor Name
            </label>
            <select
              value={inputs.vendor_id}
              name="vendor_id"
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              {vendorData.map((value) => (
                <option value={value._id} key={value._id}>
                  {value.vendor_name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Car Name
            </label>
            <select
              value={inputs.car_id}
              name="car_id"
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              {vendorCarData.map((value) => (
                <option value={value._id} key={value._id}>
                  {value.car_name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Driver Name
            </label>
            <select
              value={inputs.driver_id}
              name="driver_id"
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              {vendorDriverData.map((value) => (
                <option value={value._id} key={value._id}>
                  {value.driver_name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Welcome Checklist
            </label>
            <input
              type="text"
              name="welcome_checklist"
              value={inputs.welcome_checklist}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter guest date of arrival"
            />
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Special Note
            </label>
            <input
              type="text"
              name="special_note"
              value={inputs.special_note}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter guest date of arrival"
            />
          </div>
        </div>
        <button
          type="submit"
          className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Update
        </button>
      </form>
    </div>
  );
};

export default SidebarHeaderLayout(UpdateArrivalMgmnt);
