import axios from "axios";
import jwtDecode from "jwt-decode";
import { Auth } from "../components/ProtectedRoutes";
import config from "../config";

function createAxiosInstace() {
  return axios.create({
    baseURL: config.BASE_URL,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json, text/plain, */*",
    },
  });
}

const Axios = createAxiosInstace();

Axios.interceptors.request.use(function (config) {
  const isAuth = Auth();

  // if (isAuth) {
  //   const token = localStorage.getItem("token");
  //   const decodedToken = jwtDecode(token);
  //   const { exp } = decodedToken;
  //   const expTime = exp * 1000 - 60000;
  //   console.log(Date.now());
  //   console.log(expTime, "xp");
  //   if (Date.now() >= expTime) {
  //     localStorage.removeItem("token");
  //     window.location.href = "/";
  //   }
  // }

  config.headers.Authorization = isAuth ? isAuth : "";
  return config;
});

/* Interceptor response */
Axios.interceptors.response.use(function (response) {
  if (response.status === 401) {
    localStorage.removeItem("token");
    window.location.href = "/";
  }

  return response;
});

export const overrideHeader = () => {
  Axios.defaults.headers["Content-Type"] = "application/x-www-form-urlencoded";
};

export default Axios;
