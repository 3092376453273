import { Button, Checkbox, Col, Collapse, Modal, Row, Select } from "antd";
import { ErrorMessage, Formik } from "formik";
import { Label } from "src/components/Label";
import {
  FloorRooms,
  IHotel,
  IHotelFloorWithRooms,
  IHotelOption,
  IRoom,
  RequiredRooms,
} from "src/types";
import { ShouldRender } from "src/components/ShouldRender";
import React from "react";
import { clonseJSON } from "src/utils/json";

interface FormErrors {
  hotel_id: string;
  hotel_rooms_required: string;
}

interface Props {
  handleAddHotel: () => void;
  handleClose: () => void;
  handelCreateRoom: (id: string) => void;
  handleSave: (values: IHotel) => void;
  hotelOptions: IHotelOption[];
  hotelRooms: IHotelFloorWithRooms;
  hotelDetails?: IHotel;
  open: boolean;
  title: string;
  existingHotelIds: string[];
}

const EditHotel = ({
  handleAddHotel,
  handleClose,
  handelCreateRoom,
  handleSave,
  hotelOptions,
  hotelRooms,
  hotelDetails,
  open,
  title,
  existingHotelIds,
}: Props) => {
  const initalValues = {
    hotel_id: hotelDetails?.hotel_id,
    hotel_rooms_required: hotelDetails?.hotel_rooms_required.length
      ? hotelDetails.hotel_rooms_required.reduce((obj, item) => {
          return {
            ...obj,
            [item.floor_no]: item.room_nos,
          };
        }, {} as FloorRooms)
      : ({} as FloorRooms),
  };
  return (
    <Formik
      initialValues={initalValues}
      validate={(values) => {
        const errors = {} as FormErrors;
        if (!values.hotel_id?.trim()) {
          errors.hotel_id = "Hotel name is required";
        }
        if (Object.keys(values.hotel_rooms_required || {}).length === 0) {
          errors.hotel_rooms_required = 'please select alteast one room"';
        }
        return errors;
      }}
      onSubmit={({ hotel_id: hotelID, hotel_rooms_required }) => {
        const requiredRooms: RequiredRooms[] = [];
        Object.keys(hotel_rooms_required).forEach((floor) => {
          requiredRooms.push({
            floor_no: +floor,
            room_nos: hotel_rooms_required[floor],
          });
        });
        handleSave({
          hotel_id: hotelID!,
          hotel_rooms_required: requiredRooms,
        });
      }}
    >
      {({ setFieldValue, setValues, handleSubmit, values, errors }) => {
        const floors = values.hotel_id
          ? Object.keys(hotelRooms[values.hotel_id] || [])
          : [];
        let rooms = {} as {
          [floor_no: string]: IRoom[];
        };
        if (values?.hotel_id && floors.length) {
          floors.forEach((floor) => {
            rooms[floor] = hotelRooms?.[values.hotel_id!]?.[floor];
          });
        }
        return (
          <Modal
            centered
            open={open}
            title={title}
            onCancel={handleClose}
            onOk={() => handleSubmit()}
          >
            <div className="flex justify-end">
              <Button onClick={handleAddHotel}>Create Hotel</Button>
            </div>
            <div className="mb-3">
              <Label required text="Hotel Name" />
              <div className="flex items-center">
                <Select
                  className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                  placeholder="Select Hotel Name"
                  value={values.hotel_id}
                  onChange={(id) => {
                    setFieldValue("hotel_id", id);
                    setFieldValue("hotel_rooms_required", {});
                  }}
                >
                  {hotelOptions.map((item) => (
                    <Select.Option
                      key={item._id}
                      value={item._id}
                      disabled={existingHotelIds.includes(item._id)}
                    >
                      {item.hotel_name}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              {errors.hotel_id && (
                <div className="my-1 text-xs text-red-500">
                  <ErrorMessage name="hotel_id" />
                </div>
              )}
            </div>
            {values.hotel_id ? (
              <React.Fragment>
                <ShouldRender check={floors.length > 0}>
                  <Collapse>
                    {floors.map((floor_no) => (
                      <Collapse.Panel
                        key={`hotel-${values.hotel_id!}-floor-${floor_no}`}
                        header={`Floor ${floor_no}`}
                      >
                        <Row>
                          {hotelRooms[values.hotel_id!][floor_no].map(
                            (room) => (
                              <Col
                                key={`hotel-${values.hotel_id!}-floor-${floor_no}-room-${
                                  room.room_no
                                }`}
                                className="mb-2"
                                span={4}
                              >
                                <Checkbox
                                  checked={
                                    values.hotel_rooms_required[floor_no]?.find(
                                      (item) => item.room_no === room.room_no
                                    ) !== undefined
                                  }
                                  onChange={() => {
                                    const updatedValues = clonseJSON(values);
                                    const rooms =
                                      updatedValues.hotel_rooms_required[
                                        floor_no
                                      ] || [];
                                    const index = rooms.findIndex(
                                      (_room) => _room.room_no === room.room_no
                                    );
                                    if (index === -1) {
                                      rooms.push(room);
                                    } else {
                                      rooms.splice(index, 1);
                                    }
                                    updatedValues.hotel_rooms_required[
                                      floor_no
                                    ] = rooms;
                                    if (
                                      updatedValues.hotel_rooms_required[
                                        floor_no
                                      ].length === 0
                                    ) {
                                      delete updatedValues.hotel_rooms_required[
                                        floor_no
                                      ];
                                    }
                                    setValues(updatedValues);
                                  }}
                                >
                                  Room {room.room_no}
                                </Checkbox>
                              </Col>
                            )
                          )}
                        </Row>
                      </Collapse.Panel>
                    ))}
                  </Collapse>
                </ShouldRender>
                <ShouldRender check={floors.length === 0}>
                  <div className="flex flex-col items-center justify-center my-3">
                    <p className="font-semibold">
                      No records for floor and room found
                    </p>
                    <Button onClick={() => handelCreateRoom(values.hotel_id!)}>
                      Create Hotel Rooms
                    </Button>
                  </div>
                </ShouldRender>
              </React.Fragment>
            ) : (
              <div className="flex justify-center">
                <p className="font-semibold">
                  Select Hotel to display floor options
                </p>
              </div>
            )}
          </Modal>
        );
      }}
    </Formik>
  );
};

export default EditHotel;
