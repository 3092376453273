import moment from 'moment'

const formatDate = (date, format) => {
  const dateTemp = moment(date).format(format)
  return dateTemp
}

const viewCurrentDates = new Date().toISOString().slice(0, 10);

const phoneRegExp =
/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export { formatDate, phoneRegExp, viewCurrentDates}

