import React, { useEffect, useState } from "react";
import { Table, Descriptions, Image } from "antd";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { useParams } from "react-router-dom";
import { getSingleUser } from "../../../api-core/User";
import { formatDate } from "src/utils/helper";

const Singleuser = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    const fetchSingleUser = async () => {
      setloading(true);
      const res = await getSingleUser(id);
      setData(res.user);
      setloading(false);
    };
    fetchSingleUser();
  }, []);
  const datas = data.map((item) => ({
    key: item._id,
    name: item.name,
    mobile: item.mobile,
    email: item.email,
    role: item.role_id,
  }));
  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      // ellipsis: true,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      // ellipsis: true,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      // ellipsis: true,
    },
    {
      title: "Role ID",
      dataIndex: "role",
      key: "role",
      // ellipsis: true,
    },
  ];

  return (
    <div>
      <div>
        <Descriptions
          title={`${datas[0]?.role.role_name + " Details"}`}
          bordered
          layout="horizontal"
          column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
        >
          <Descriptions.Item
            label="Name"
            labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
          >
            <span className="text-lg"> {datas[0]?.name}</span>
          </Descriptions.Item>

          <Descriptions.Item
            label="Mobile"
            labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
          >
            <span className="text-lg">
              <a href={`tel:${datas[0]?.mobile}`} >{datas[0]?.mobile}</a>
            </span>
          </Descriptions.Item>
          <Descriptions.Item
            label="Email"
            labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
          >
            <span className="text-lg">{datas[0]?.email}</span>
          </Descriptions.Item>
        </Descriptions>
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(Singleuser);
