import { FC } from "react";

interface Props {
  text: string;
  className?: string;
  required?: boolean;
  optional?: boolean;
}

export const Label: FC<Props> = ({
  text,
  className = "",
  required = false,
  optional = false,
}) => {
  return (
    <label className={`m-0 flex ${className}`}>
      {text}
      {required && <span className="pl-1 text-lg text-red-500">*</span>}{" "}
      {optional && (
        <span className="flex-1 text-sm text-right text-gray-500">
          Optional
        </span>
      )}
    </label>
  );
};
