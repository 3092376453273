import React, { useEffect, useState } from "react";
import { message, Popconfirm, Table } from "antd";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link } from "react-router-dom";
import {
  deleteArrivalMgmnt,
  deleteGuestlist,
  deleteLostAndFound,
  getAllArrivalMgmnt,
  getAllGuestlist,
  getAllLostAndFound,
} from "../../../api-core/Ghms";
import { formatDate } from "../../../utils/helper";

const ListOfLostAndFound = () => {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    fetchAllLostAndFound();
  }, []);
  const fetchAllLostAndFound = async () => {
    setloading(true);
    const res = await getAllLostAndFound();
    setdata(res.ghmslostfound);
    setloading(false);
  };
  const onDeleteRecord = async (record, id) => {
    const recordId = {
      ids: [record],
    };
    const res = await deleteLostAndFound(recordId);
    if (res.success == true) {
      message.success(res.msgText);
      fetchAllLostAndFound();
    }
  };

  const datas = data.map((item) => ({
    key: item._id,
    subEventTitle: item.sub_event_id.subevent_title,
    guestName: item.guest_id.guest_name,
    itemName: item.item_name,
    itemIdentification: item.item_identification,
    lostPlace: item.lost_place,
    foundPlace: item.found_place,
    foundBy: item.found_by,
    deliverTpye: item.deliver_type,
  }));
  // console.log("datas", datas);

  const columns = [
    {
      title: "Sr.",
      dataIndex: "number",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Sub Event Title",
      dataIndex: "subEventTitle",
      key: "subEventTitle",
      ellipsis: true,
    },
    {
      title: "Guest Name",
      dataIndex: "guestName",
      key: "guestName",
      ellipsis: true,
    },

    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
      ellipsis: true,
    },

    {
      title: "Item Identification",
      dataIndex: "itemIdentification",
      key: "itemIdentification",
      ellipsis: true,
    },

    {
      title: "Lost Place",
      dataIndex: "lostPlace",
      key: "lostPlace",
      ellipsis: true,
    },

    {
      title: "Found Place",
      dataIndex: "foundPlace",
      key: "foundPlace",
      ellipsis: true,
    },

    {
      title: "Found By",
      dataIndex: "foundBy",
      key: "foundBy",
      ellipsis: true,
    },

    {
      title: "Deliver Type",
      dataIndex: "deliverTpye",
      key: "deliverTpye",
      ellipsis: true,
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex gap-x-2 ">
          {/* {console.log("rec", record)} */}
          <Link to={`/admin/singlelostandfound/${record.key}`}>
            <button className="text-blue-600 cursor-pointer">View</button>
          </Link>

          <Link to={`/admin/updatelostandfound/${record.key}`}>
            <button className="text-yellow-400 cursor-pointer">Edit</button>
          </Link>

          <Popconfirm
            title="Sure to delete?"
            onConfirm={(e) => onDeleteRecord(record.key, e)}
          >
            <span className="text-red-500 cursor-pointer">Delete</span>
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div className="">
      <Link to="/admin/createlostandfound">
        <button className="bg-blue-600 text-white p-3 mx-3">
          Create Lost And Found
        </button>
      </Link>
      <h1 className="text-center">List Of Lost And Found</h1>
      <div className="">
        <Table
          dataSource={datas}
          columns={columns}
          loading={loading}
          scroll={{
            y: "auto",
            x: "auto",
          }}
          // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        />
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(ListOfLostAndFound);
