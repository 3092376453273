import { Routes, Route } from "react-router-dom";
import "./App.css";
import "antd/dist/antd.min.css";
import "./index.css";
import ListOfOfferBanner from "./components/pages/OfferBanner/ListOfOfferBanner";
import CreateOfferBanner from "./components/pages/OfferBanner/CreateOfferBanner";
import Login from "./components/Login";
import ForgotPassword from "./components/ForgotPasword";
import SingleOfferBanner from "./components/pages/OfferBanner/SingleOfferBanner";
import UpdateOfferBanner from "./components/pages/OfferBanner/UpdateOfferBanner";
import ListOfTrivia from "./components/pages/Trivia/ListOfTrivia";
import Dashborad from "./components/Dashborad";
import CreateTrivia from "./components/pages/Trivia/CreateTrivia";
import SingleTrivia from "./components/pages/Trivia/SingleTrivia";
import UpdateTrivia from "./components/pages/Trivia/UpdateTrivia";
import ListOfEnquiry from "./components/pages/Enquiry/ListOfEnquiry";
import ListOfFreelancer from "./components/pages/Freelancer/ListOfFreelancer";
import SingleFreelancer from "./components/pages/Freelancer/SingleFreelancer";
import ListOfAllEvents from "./components/pages/Events/ListOfAllEvents";
import ListOfAllSubEvents from "./components/pages/Events/ListOfAllSubEvents";
import ListOfAllEventPhoto from "./components/pages/Gallery/ListOfAllEventPhoto";
import CreateEvent from "./components/pages/Events/CreateEvent";
import SingleEvent from "./components/pages/Events/SingleEvent";
import UpdateEvent from "./components/pages/Events/UpdateEvent";
import CreateSubEvent from "./components/pages/Events/CreateSubEvent";
import SingleSubEvent from "./components/pages/Events/SingleSubEvent";
import UpdateSubEvent from "./components/pages/Events/UpdateSubEvent";
import ProtectedRoutes from "./components/ProtectedRoutes";
import ListOfAllHotel from "./components/pages/Hotel/ListOfAllHotel";
import CreateHotelRoomType from "./components/pages/Hotel/CreateHotelRoomType";
import SingleHotelRoomType from "./components/pages/Hotel/SingleHotelRoomType";
import UpdateHotelRoomType from "./components/pages/Hotel/UpdateHotelRoomType";
import SingleHotelRoomChecklist from "./components/pages/Hotel/SingleHotelRoomChecklist";
import CreateHotelRoomChecklist from "./components/pages/Hotel/CreateHotelRoomChecklist";
import UpdateHotelRoomChecklist from "./components/pages/Hotel/UpdateHotelRoomChecklist";
import CreateHotelRoomAllotment from "./components/pages/Hotel/CreateHotelRoomAllotment";
import ListOfGuestlist from "./components/pages/GHMS/ListOfGuestlist";
import CreateGuestlist from "./components/pages/GHMS/CreateGuestlist";
import SingleGuestlist from "./components/pages/GHMS/SingleGuestlist";
import UpdateGuestlist from "./components/pages/GHMS/UpdateGuestlist";
import SingleHotelRoomAllotment from "./components/pages/Hotel/SingleHotelRoomAllotment";
import ListOfVendors from "./components/pages/Vendor/ListOfVendors";
import SingleVendor from "./components/pages/Vendor/SingleVendor";
import CreateVendor from "./components/pages/Vendor/CreateVendor";
import UpdateVendor from "./components/pages/Vendor/UpdateVendor";
import ListOfVendorCar from "./components/pages/Vendor/ListOfVendorCar";
import SingleVendorcar from "./components/pages/Vendor/SingleVendorcar";
import CreateVendorCar from "./components/pages/Vendor/CreateVendorCar";
import UpdateVendorCar from "./components/pages/Vendor/UpdateVendorCar";
import ListOfVendorDriver from "./components/pages/Vendor/ListOfVendorDriver";
import SingleVendorDriver from "./components/pages/Vendor/SingleVendorDriver";
import CreateVendorDriver from "./components/pages/Vendor/CreateVendorDriver";
import UpdateVendorDriver from "./components/pages/Vendor/UpdateVendorDriver";
import ListOfArrivalMgmnt from "./components/pages/GHMS/ListOfArrivalMgmnt";
import CreateArrivalMgmnt from "./components/pages/GHMS/CreateArrivalMgmnt";
import UpdateArrivalMgmnt from "./components/pages/GHMS/UpdateArrivalMgmnt";
import SingleArrivalMgmnt from "./components/pages/GHMS/SingleArrivalMgmnt";
import ListOfDepartureMgmnt from "./components/pages/GHMS/ListOfDepartureMgmnt";
import SingleDepartureMgmnt from "./components/pages/GHMS/SingleDepartureMgmnt";
import CreateDepartureMgmnt from "./components/pages/GHMS/CreateDepartureMgmnt";
import UpdateDepartureMgmnt from "./components/pages/GHMS/UpdateDepartureMgmnt";
import UpdateHotelRoomAllotment from "./components/pages/Hotel/UpdateHotelRoomAllotment";
import ListOfRoles from "./components/pages/RolesPermission/ListOfRoles";
import SingleRole from "./components/pages/RolesPermission/SingleRole";
import CreateRole from "./components/pages/RolesPermission/CreateRole";
import UpdateRole from "./components/pages/RolesPermission/UpdateRole";
import ListOfPermission from "./components/pages/RolesPermission/ListOfPermission";
import SinglePermission from "./components/pages/RolesPermission/SinglePermission";
import CreatePermission from "./components/pages/RolesPermission/CreatePermission";
import UpdatePermission from "./components/pages/RolesPermission/UpdatePermission";
import ListOfPermissionRole from "./components/pages/RolesPermission/ListOfPermissionRole";
import SinglePermissionRole from "./components/pages/RolesPermission/SinglePermissionRole";
import CreatePermissionRole from "./components/pages/RolesPermission/CreatePermissionRole";
import UpdatePermissionRole from "./components/pages/RolesPermission/UpdatePermissionRole";
import ListOfLostAndFound from "./components/pages/GHMS/ListOfLostAndFound";
import SingleLostAndFound from "./components/pages/GHMS/SingleLostAndFound";
import CreateLostAndFound from "./components/pages/GHMS/CreateLostAndFound";
import UpdateLostAndFound from "./components/pages/GHMS/UpdateLostAndFound";
import CreateEventPhoto from "./components/pages/Gallery/CreateEventPhoto";
import SingleEventPhoto from "./components/pages/Gallery/SingleEventPhoto";
import UpdateEventPhoto from "./components/pages/Gallery/UpdateEventPhoto";
import CreateFreelancer from "./components/pages/Freelancer/CreateFreelancer";
import CreateFreelancerAssignee from "./components/pages/Freelancer/CreateFreelancerAssignee";
import ListOfFreelancerAssignee from "./components/pages/Freelancer/ListOfFreelancerAssignee";
import SingleFreelancerAssignee from "./components/pages/Freelancer/SingleFreelancerAssignee";
import CreateVendorFoodBev from "./components/pages/Vendor/CreateVendorFoodBev";
import CreateVendorProdDecor from "./components/pages/Vendor/CreateVendorProdDecor";
import CreateVendorProdDecorChecklist from "./components/pages/Vendor/CreateVendorProdDecorChecklist";
import PageNotFound from "./components/PageNotFound";
import ListOfUsers from "./components/pages/Users/ListOfUsers";
import CreateUser from "./components/pages/Users/CreateUser";
import UpdateUser from "./components/pages/Users/UpdateUser";
import SingleUser from "./components/pages/Users/SingleUser";
import Event from "./components/pages/Event/eventList";
import CreateCurrentEvent from "./components/pages/Event/createCurrentEvent";
import ListOfAllCurrentEvents from "./components/pages/Event/ListOfAllCurrentEvents";
import ListOfPriotization from "./components/pages/Priotization/ListOfPriotization";
import CreatePriotization from "./components/pages/Priotization/CreatePriotization";
import UpdatePriotization from "./components/pages/Priotization/UpdatePriotization";
import HotelDetailPage from "./components/pages/Hotel/HotelDetailPage";
import UpdateFreelancer from "./components/pages/Freelancer/UpdateFreelancer";
import BlackListed from "./components/BlackListed";
import Cars from "./components/pages/Cars/CarList";
import AddCar from "./components/pages/Cars/AddCar";
import UpdateCar from "./components/pages/Cars/EditCar";
import UpdateFreelancerAssignee from "./components/pages/Freelancer/UpdateFreelancerAssignee";
import CreateBlackList from "./components/BlackListed/CreateBlackList";
import UpdateBlackList from "./components/BlackListed/UpdateBlackList";
import SingleEnquiry from "./components/pages/Enquiry/SingleEnquiry";
import SingleBlackList from "./components/BlackListed/SingleBlackList";
import SinglePriotization from "./components/Priotization/SinglePriotization";

const App = () => {
  return (
    <div className="">
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/forgotpassword" element={<ForgotPassword />} />
        <Route element={<ProtectedRoutes />}>
          <Route path="/admin/dashboard" element={<Dashborad />} />
          <Route
            path="/admin/listofferbanner"
            element={<ListOfOfferBanner />}
          />
          <Route
            path="/admin/singleofferbanner/:id"
            element={<SingleOfferBanner />}
          />
          <Route
            exact
            path="/admin/updateofferbanner/:id"
            element={<UpdateOfferBanner />}
          />
          <Route
            exact
            path="/admin/createofferbanner"
            element={<CreateOfferBanner />}
          />
          <Route path="/admin/listoftrivia" element={<ListOfTrivia />} />
          <Route path="/admin/createtrivia" element={<CreateTrivia />} />
          <Route path="/admin/singletrivia/:id" element={<SingleTrivia />} />
          <Route path="/admin/updatetrivia/:id" element={<UpdateTrivia />} />
          <Route path="/admin/listofenquiry" element={<ListOfEnquiry />} />
          <Route
            path={`/admin/singleenquiry/:id`}
            element={<SingleEnquiry />}
          />
          <Route
            path="/admin/listoffreelancer"
            element={<ListOfFreelancer />}
          />

          <Route
            path="/admin/updateFreelancerAssigness/:id"
            element={<UpdateFreelancerAssignee />}
          />

          <Route
            path="/admin/updatefreelancer/:id"
            element={<UpdateFreelancer />}
          ></Route>
          <Route
            path="/admin/singlefreelancer/:id"
            element={<SingleFreelancer />}
          />
          <Route
            exact
            path="/admin/createfreelancer"
            element={<CreateFreelancer />}
          />
          <Route
            exact
            path="/admin/createfreelancerassignedevent"
            element={<CreateFreelancerAssignee />}
          />
          <Route
            path="/admin/listoffreelancerassignedevent"
            element={<ListOfFreelancerAssignee />}
          />
          <Route
            path="/admin/singlefreelancerassignedevent/:id"
            element={<SingleFreelancerAssignee />}
          />
          <Route path="/admin/listofallevents" element={<ListOfAllEvents />} />
          <Route path="/admin/singleevent/:id" element={<SingleEvent />} />
          <Route path="/admin/updateevent/:id" element={<UpdateEvent />} />
          <Route path="/admin/createevent" element={<CreateEvent />} />
          <Route
            path="/admin/listofallsubevents"
            element={<ListOfAllSubEvents />}
          />
          <Route
            path="/admin/singlesubevent/:id"
            element={<SingleSubEvent />}
          />
          <Route path="/admin/createsubevent" element={<CreateSubEvent />} />
          <Route
            path="/admin/updatesubevent/:id"
            element={<UpdateSubEvent />}
          />
          <Route path="/admin/hotels" element={<ListOfAllHotel />} />
          <Route path="/admin/hotel/:id" element={<HotelDetailPage />} />
          <Route
            path="/admin/singlehotelroomtype/:id"
            element={<SingleHotelRoomType />}
          />
          <Route
            path="/admin/createhotelroomtype"
            element={<CreateHotelRoomType />}
          />
          <Route
            path="/admin/updatehotelroomtype/:id"
            element={<UpdateHotelRoomType />}
          />
          <Route
            path="/admin/singlehotelroomchecklist/:id"
            element={<SingleHotelRoomChecklist />}
          />
          <Route
            path="/admin/createhotelroomchecklist"
            element={<CreateHotelRoomChecklist />}
          />
          <Route
            path="/admin/updatehotelroomchecklist/:id"
            element={<UpdateHotelRoomChecklist />}
          />
          <Route
            path="/admin/singlehotelroomallotment/:id"
            element={<SingleHotelRoomAllotment />}
          />
          <Route
            path="/admin/createhotelroomallotment"
            element={<CreateHotelRoomAllotment />}
          />
          <Route
            path="/admin/updatehotelroomallotment/:id"
            element={<UpdateHotelRoomAllotment />}
          />
          <Route path="/admin/listofguestlist" element={<ListOfGuestlist />} />
          <Route
            path="/admin/singleguestlist/:id"
            element={<SingleGuestlist />}
          />
          <Route path="/admin/createguestlist" element={<CreateGuestlist />} />
          <Route
            path="/admin/updateguestlist/:id"
            element={<UpdateGuestlist />}
          />
          <Route path="/admin/listofvendors" element={<ListOfVendors />} />
          <Route path="/admin/singlevendor/:id" element={<SingleVendor />} />
          <Route path="/admin/createvendor" element={<CreateVendor />} />
          <Route path="/admin/updatevendor/:id" element={<UpdateVendor />} />
          <Route path="/admin/listofvendorcar" element={<ListOfVendorCar />} />
          <Route
            path="/admin/singlevendorcar/:id"
            element={<SingleVendorcar />}
          />
          <Route path="/admin/createvendorcar" element={<CreateVendorCar />} />
          <Route
            path="/admin/updatevendorcar/:id"
            element={<UpdateVendorCar />}
          />
          <Route
            path="/admin/listofvendordriver"
            element={<ListOfVendorDriver />}
          />
          <Route
            path="/admin/singlevendordriver/:id"
            element={<SingleVendorDriver />}
          />
          <Route
            path="/admin/createvendordriver"
            element={<CreateVendorDriver />}
          />
          <Route
            path="/admin/updatevendordriver/:id"
            element={<UpdateVendorDriver />}
          />
          <Route
            path="/admin/createvendorfoodbev"
            element={<CreateVendorFoodBev />}
          />
          <Route
            path="/admin/createvendorproddecor"
            element={<CreateVendorProdDecor />}
          />
          <Route
            path="/admin/createvendorproddecorchecklist"
            element={<CreateVendorProdDecorChecklist />}
          />
          <Route
            path="/admin/listofarrivalmgmnt"
            element={<ListOfArrivalMgmnt />}
          />
          <Route
            path="/admin/singlearrivalmgmnt/:id"
            element={<SingleArrivalMgmnt />}
          />
          <Route
            path="/admin/createarrivalmgmnt"
            element={<CreateArrivalMgmnt />}
          />
          <Route
            path="/admin/updatearrivalmgmnt/:id"
            element={<UpdateArrivalMgmnt />}
          />
          <Route
            path="/admin/listofdeparturemgmnt"
            element={<ListOfDepartureMgmnt />}
          />
          <Route
            path="/admin/singledeparturemgmnt/:id"
            element={<SingleDepartureMgmnt />}
          />
          <Route
            path="/admin/createdeparturemgmnt"
            element={<CreateDepartureMgmnt />}
          />
          <Route
            path="/admin/updatedeparturemgmnt/:id"
            element={<UpdateDepartureMgmnt />}
          />
          <Route path="/admin/listofroles" element={<ListOfRoles />} />
          <Route path="/admin/singlerole/:id" element={<SingleRole />} />
          <Route path="/admin/createrole" element={<CreateRole />} />
          <Route path="/admin/updaterole/:id" element={<UpdateRole />} />
          <Route
            path="/admin/listofpermissions"
            element={<ListOfPermission />}
          />
          <Route
            path="/admin/singlepermission/:id"
            element={<SinglePermission />}
          />
          <Route
            path="/admin/createpermission"
            element={<CreatePermission />}
          />
          <Route
            path="/admin/updatepermission/:id"
            element={<UpdatePermission />}
          />
          <Route
            path="/admin/listofpermissionrole"
            element={<ListOfPermissionRole />}
          />
          <Route
            path="/admin/singlepermissionrole/:id"
            element={<SinglePermissionRole />}
          />
          <Route
            path="/admin/createpermissionrole"
            element={<CreatePermissionRole />}
          />
          <Route
            path="/admin/updatepermissionrole/:id"
            element={<UpdatePermissionRole />}
          />
          <Route
            path="/admin/listoflostandfound"
            element={<ListOfLostAndFound />}
          />
          <Route
            path="/admin/singlelostandfound/:id"
            element={<SingleLostAndFound />}
          />
          <Route
            path="/admin/createlostandfound"
            element={<CreateLostAndFound />}
          />
          <Route
            path="/admin/updatelostandfound/:id"
            element={<UpdateLostAndFound />}
          />
          <Route
            path="/admin/listofalleventphoto"
            element={<ListOfAllEventPhoto />}
          />
          <Route
            path="/admin/createeventphoto"
            element={<CreateEventPhoto />}
          />
          <Route
            path="/admin/singleeventphoto/:id"
            element={<SingleEventPhoto />}
          />
          <Route
            path="/admin/updateventphoto/:id"
            element={<UpdateEventPhoto />}
          />
        </Route>

        <Route path="/admin/listofusers" element={<ListOfUsers />} />
        <Route path="/admin/createusers" element={<CreateUser />} />
        <Route path="/admin/updateuer/:id" element={<UpdateUser />} />
        <Route path="/admin/singleuser/:id" element={<SingleUser />} />
        <Route path="/admin/event/:id" element={<Event />} />
        <Route path="/admin/event" element={<ListOfAllCurrentEvents />} />
        <Route
          exact
          path="/admin/createCurrentEvent"
          element={<CreateCurrentEvent />}
        />
        <Route
          exact
          path="/admin/listofprioritisation"
          element={<ListOfPriotization />}
        />
        <Route
          exact
          path="/admin/createprioritisation"
          element={<CreatePriotization />}
        />
        <Route exact path="/admin/blacklisted" element={<BlackListed />} />
        <Route
          exact
          path="/admin/createblacklistedvendor"
          element={<CreateBlackList />}
        />
        <Route
          exact
          path="/admin/editblacklistedvendor/:id"
          element={<UpdateBlackList />}
        />
        <Route
          exact
          path="/admin/viewvendor/:id"
          element={<SingleBlackList />}
        />
        <Route
          exact
          path="/admin/updatepriotization/:id"
          element={<UpdatePriotization />}
        />
        <Route
          exact
          path="/admin/singlepriotization/:id"
          element={<SinglePriotization />}
        />
        <Route 
          exact 
          path="/admin/cars" 
          element={<Cars />} 
        />
        <Route 
          exact 
          path="/admin/cars/create" 
          element={<AddCar />} 
        />
        <Route 
          exact 
          path="/admin/cars/edit/:id" 
          element={<UpdateCar />} 
        />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
};

export default App;
