import React from "react";
import { Button, Input, Popconfirm, Table, Typography } from "antd";
import EditHotel from "./EditHotel";
import { ShouldRender } from "src/components/ShouldRender";
import {
  IEvent,
  IHotel,
  IHotelFloorWithRooms,
  IHotelOption,
  IHotelRoom,
} from "src/types";
import { clonseJSON } from "src/utils/json";
import { updateSingleEvent } from "src/api-core/Events";
import { CreateHotelModal, CreateHotelRoomModal } from "../../modals";

interface Props {
  event?: IEvent;
  update: () => Promise<void>;
  handleHotelCreateSuccess: (hotel: IHotelOption) => void;
  handleRoomCreateSuccess: (rooms: IHotelRoom[]) => void;
  hotelOptions: IHotelOption[];
  hotelRooms: IHotelFloorWithRooms;
  loading: boolean;
}

export const HotelTable = ({
  event,
  update,
  handleHotelCreateSuccess,
  handleRoomCreateSuccess,
  hotelOptions,
  hotelRooms,
  loading,
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [recordIndex, setRecordIndex] = React.useState<number>(-1);
  const [selectedHotelID, setSelectedHotelID] = React.useState<string>();
  const [visible, setVisible] = React.useState(false);
  const { hotels = [] } = event || {};
  const [searchedText, setSearchedText] = React.useState("");

  const handleEdit = (index: number) => {
    setOpen(true);
    setRecordIndex(index);
  };

  const handleClose = () => {
    setOpen(false);
    setRecordIndex(-1);
  };

  const handleOpen = () => setOpen(true);

  const handleUpdate = (data: Partial<IEvent>) => {
    if (event) {
      const {
        _id,
        client_id,
        event_type,
        event_title,
        event_start_date,
        is_hospitality_checklist_visible,
      } = event;
      const payload = {
        client_id,
        event_type,
        event_title,
        event_start_date,
        is_hospitality_checklist_visible,
        ...data,
      };

      updateSingleEvent(_id, payload).then(update).then(handleClose);
    }
  };
  const handleSave = (values: IHotel) => {
    if (!event) return;
    const updatedHotels = clonseJSON(hotels);
    if (recordIndex === -1) {
      updatedHotels.push(values);
    } else {
      updatedHotels[recordIndex] = values;
    }
    const data = {
      hotels: updatedHotels,
    };
    handleUpdate(data);
  };

  const handleDelete = (index: number) => {
    if (!event) return null;
    const updatedHotels = clonseJSON(hotels).filter((_, i) => i !== index);
    const data = {
      hotels: updatedHotels,
    };
    handleUpdate(data);
  };

  const defaultColumns = [
    {
      title: "Hotel Name",
      dataIndex: "hotel_name",
      key: "hotel_name",
      render: (_: any, record: IHotel) => {
        const hotel = hotelOptions.find((item) => item._id === record.hotel_id);
        return hotel?.hotel_name || "N/A";
      },
      filteredValue: [searchedText],
      onFilter: (value: any, record: any) => {
        return String(record.hotel_name)
          .toLowerCase()
          .includes(value.toLowerCase());
      },
    },
    {
      title: "Floor No",
      dataIndex: "floor_no",
      key: "floor_no",
      render: (_: any, record: IHotel) => {
        const floors = record.hotel_rooms_required
          .map((item) => item.floor_no)
          .join(", ");
        return floors || "N/A";
      },
    },
    {
      title: "Room Number",
      dataIndex: "room_nos",
      key: "room_nos",
      render: (_: any, record: IHotel) => {
        const rooms = record.hotel_rooms_required
          .flatMap((hotelRooms) =>
            hotelRooms.room_nos.map((item) => item.room_no).join(", ")
          )
          .join(", ");
        return rooms || "N/A";
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: IHotel) => {
        const index = hotels.findIndex(
          (hotel) => hotel.hotel_id === record.hotel_id
        );
        return (
          <span>
            <Typography.Link
              disabled={recordIndex !== -1}
              onClick={() => handleEdit(index)}
              style={{ marginRight: 8 }}
            >
              Edit
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(index)}
            >
              <Typography.Link>Delete</Typography.Link>
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  const handelCreateRoom = (id: string) => {
    setSelectedHotelID(id);
    setVisible(true);
  };

  return (
    <React.Fragment>
      <div className="flex justify-end mb-3">
        <Button onClick={handleOpen}>Add Data</Button>
      </div>
      {/* <Input.Search size="large" placeholder="search here..."
             style={{marginBottom: 8, width: 280}}
             onSearch={(value)=>{
              setSearchedText(value);
             }}
             onChange={(e)=>{
              setSearchedText(e.target.value);
             }}/> */}
      <Table columns={defaultColumns} dataSource={hotels} loading={loading} />
      <ShouldRender check={open}>
        <EditHotel
          handleAddHotel={() => setShow(true)}
          handleClose={handleClose}
          handelCreateRoom={handelCreateRoom}
          handleSave={handleSave}
          hotelOptions={hotelOptions}
          hotelRooms={hotelRooms}
          hotelDetails={hotels[recordIndex]}
          open={open}
          title={recordIndex === -1 ? "Add Hotel" : "Edit Hotel"}
          existingHotelIds={hotels.map((hotel) => hotel.hotel_id)}
        />
      </ShouldRender>
      <ShouldRender check={show}>
        <CreateHotelModal
          open={show}
          handleClose={() => setShow(false)}
          handleHotelCreateSuccess={handleHotelCreateSuccess}
          handleRoomCreateSuccess={handleRoomCreateSuccess}
          title="Create Hotel"
        />
      </ShouldRender>
      <ShouldRender check={visible}>
        <CreateHotelRoomModal
          open={visible}
          options={hotelOptions}
          hotel_id={selectedHotelID}
          handleClose={() => setVisible(false)}
          handleRoomCreateSuccess={handleRoomCreateSuccess}
          title="Create Hotel Rooms"
        />
      </ShouldRender>
    </React.Fragment>
  );
};
