import React, { useEffect, useState } from "react";
import { message, Popconfirm, Table } from "antd";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link } from "react-router-dom";
import { deleteGuestlist, getAllGuestlist } from "../../../api-core/Ghms";

const ListOfGuestlist = () => {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    fetchAllGuestList();
  }, []);
  const fetchAllGuestList = async () => {
    setloading(true);
    const res = await getAllGuestlist();
    setdata(res.ghmsguestlist);
    setloading(false);
  };
  const onDeleteRecord = async (record, id) => {
    const recordId = {
      ids: [record],
    };
    const res = await deleteGuestlist(recordId);
    if (res.success == true) {
      message.success(res.msgText);
      fetchAllGuestList();
    }
  };

  const datas = data.map((item) => ({
    key: item._id,
    subEventTitle: item.sub_event_id.subevent_title,
    guestDateArrival: item.guest_date_of_arrival,
    guestInvitationType: item.guest_invitation_type,
    expGuestNo: item.guest_expected_nos,
    guestOutStation: item.guest_outstation,
    guestInvited: item.guest_invited,
    guestAddress: item.guest_add,
    guestEmail: item.guest_email,
    guestMobile: item.guest_mobile,
    guestName: item.guest_name,
    hotelAddress: item.hotel_add,
  }));
  // console.log("datas", datas);

  const columns = [
    {
      title: "Sr.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Sub Event Title",
      dataIndex: "subEventTitle",
      key: "subEventTitle",
      ellipsis: true,
    },
    {
      title: "Guest Date of Arrival",
      dataIndex: "guestDateArrival",
      key: "guestDateArrival",
      ellipsis: true,
    },

    {
      title: "Guest Invitation type",
      dataIndex: "guestInvitationType",
      key: "guestInvitationType",
      ellipsis: true,
    },

    {
      title: "Expected Guest No",
      dataIndex: "expGuestNo",
      key: "expGuestNo",
      ellipsis: true,
    },

    {
      title: "Guest OutStation",
      dataIndex: "guestOutStation",
      key: "guestOutStation",
      ellipsis: true,
    },

    {
      title: "Guest Invited",
      dataIndex: "guestInvited",
      key: "guestInvited",
      ellipsis: true,
    },

    {
      title: "Guest Address",
      dataIndex: "guestAddress",
      key: "guestAddress",
      ellipsis: true,
    },

    {
      title: "Guest Email",
      dataIndex: "guestEmail",
      key: "guestEmail",
      ellipsis: true,
    },

    {
      title: "Guest Mobile",
      dataIndex: "guestMobile",
      key: "guestMobile",
      ellipsis: true,
    },

    {
      title: "Guest Name",
      dataIndex: "guestName",
      key: "guestName",
      ellipsis: true,
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex gap-x-2 ">
          {/* {console.log("rec", record)} */}
          <Link to={`/admin/singleguestlist/${record.key}`}>
            <button className="text-blue-600 cursor-pointer">View</button>
          </Link>

          <Link to={`/admin/updateguestlist/${record.key}`}>
            <button className="text-yellow-400 cursor-pointer">Edit</button>
          </Link>

          <Popconfirm
            title="Sure to delete?"
            onConfirm={(e) => onDeleteRecord(record.key, e)}
          >
            <span className="text-red-500 cursor-pointer">Delete</span>
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div className="">
      <Link to="/admin/createguestlist">
        <button className="bg-blue-600 text-white p-3 mx-3">
          Create Guest List
        </button>
      </Link>
      <h1 className="text-center">List Of Guest List</h1>
      <div className="">
        <Table
          dataSource={datas}
          columns={columns}
          loading={loading}
          scroll={{
            y: "auto",
            x: "auto",
          }}
          // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        />
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(ListOfGuestlist);
