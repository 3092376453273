import React, { useEffect, useState } from "react";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { createDepartureMgmnt, getAllGuestlist } from "../../../api-core/Ghms";
import {
  getAllVendorCar,
  getAllVendorDriver,
  getAllVendors,
} from "../../../api-core/Vendor";
import { useFormik } from "formik";
import * as Yup from "yup";
const CreateDepartureMgmnt = () => {
  const [guestIdData, setGuestIdData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [vendorCarData, setVendorCarData] = useState([]);
  const [vendorDriverData, setVendorDriverData] = useState([]);
  const [guestData, setGuestData] = useState([]);
  const [loading, setloading] = useState(false);
  const initialValues = {
    sub_event_id: "",
    guest_id: "",
    date_of_departure: "",
    no_of_guest_arrived: "",
    mode_of_departure: "",
    departure_time: "",
    dropped_by: "",
    vendor_id: "",
    car_id: "",
    driver_id: "",
    return_checklist: "",
    special_note: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      sub_event_id: Yup.string().required("Sub event name is required"),
      guest_id: Yup.string().required("guest name is required"),
      date_of_departure: Yup.string().required("date of departure required"),
      no_of_guest_arrived: Yup.string().required(
        "no of guest arrived is required"
      ),
      mode_of_departure: Yup.string().required("mode of departure is required"),
      departure_time: Yup.string().required(
        "departure time is required"
      ),
      dropped_by: Yup.string().required("droped time is required"),
      vendor_id: Yup.string().required("vendor name is required"),
      car_id: Yup.string().required("car name is required"),
      driver_id: Yup.string().required("driver name is required"),
      return_checklist: Yup.string().required("return checklist is required"),
      special_note: Yup.string().required("special note is required"),
    }),
    onSubmit: async (values, action) => {
      console.log("values", values);

      if (values) {
        const res = await createDepartureMgmnt(values);
        console.log("resFormData", res);
        action.resetForm();
      } else {
        console.log("error");
      }
    },
  });

  useEffect(() => {
    const fetchAllGuestList = async () => {
      setloading(true);
      const res = await getAllGuestlist();
      // console.log("res", res.ghmsguestlist);

      setGuestIdData(res.ghmsguestlist);
      setGuestData(res.ghmsguestlist);
      setloading(false);
    };
    fetchAllGuestList();
  }, []);

  useEffect(() => {
    const fetchAllVendors = async () => {
      setloading(true);
      const res = await getAllVendors();
      console.log("res", res.vendor);

      setVendorData(res.vendor);
      setloading(false);
    };
    fetchAllVendors();
  }, []);
  useEffect(() => {
    const fetchAllVendors = async () => {
      setloading(true);
      const res = await getAllVendorCar();
      console.log("res", res.vendorcar);

      setVendorCarData(res.vendorcar);
      setloading(false);
    };
    fetchAllVendors();
  }, []);
  useEffect(() => {
    const fetchAllVendorDriver = async () => {
      setloading(true);
      const res = await getAllVendorDriver();
      console.log("res", res.vendordriver);

      setVendorDriverData(res.vendordriver);
      setloading(false);
    };
    fetchAllVendorDriver();
  }, []);

  return (
    <div className="w-[90%] mx-auto ">
      <h1>Create Departure Management</h1>
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <div className="flex flex-col justify-center items-center  md:justify-between md:flex-row gap-x-5 flex-wrap">
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Sub Event Title
            </label>
            <select
              value={formik.values.sub_event_id}
              name="sub_event_id"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={""}>Select event</option>
              {guestIdData.map((value) => (
                <option value={value.sub_event_id._id} key={value._id}>
                  {value.sub_event_id.subevent_title}
                </option>
              ))}
            </select>
            {formik.errors.sub_event_id && (
              <span className="text-red-500">{formik.errors.sub_event_id}</span>
            )}
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Guest Name
            </label>
            <select
              value={formik.values.guest_id}
              name="guest_id"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              {guestData.map((value) => (
                <option value={value._id} key={value._id}>
                  {value.guest_name}
                </option>
              ))}
            </select>
            {formik.errors.guest_id && (
              <span className="text-red-500">{formik.errors.guest_id}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Date of Departure
            </label>
            <input
              type="date"
              name="date_of_departure"
              value={formik.values.date_of_departure}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter date of Arrival "
            />
            {formik.errors.date_of_departure && (
              <span className="text-red-500">{formik.errors.date_of_departure}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              No of Guest Arrived
            </label>
            <input
              type="number"
              name="no_of_guest_arrived"
              value={formik.values.no_of_guest_arrived}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter No of Guest Arrived"
            />
            {formik.errors.no_of_guest_arrived && (
              <span className="text-red-500">{formik.errors.no_of_guest_arrived}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Mode of Departure
            </label>
            <input
              type="text"
              name="mode_of_departure"
              value={formik.values.mode_of_departure}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter Mode of Arrival"
            />
            {formik.errors.mode_of_departure && (
              <span className="text-red-500">{formik.errors.mode_of_departure}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Departure Time
            </label>
            <input
              type="time"
              name="departure_time"
              value={formik.values.departure_time}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter Expected Arrival Time"
            />
            {formik.errors.departure_time && (
              <span className="text-red-500">{formik.errors.departure_time}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Dropped By
            </label>
            <input
              type="text"
              name="dropped_by"
              value={formik.values.dropped_by}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter  dropped_by"
            />
            {formik.errors.dropped_by && (
              <span className="text-red-500">{formik.errors.dropped_by}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Vendor Name
            </label>
            <select
              value={formik.values.vendor_id}
              name="vendor_id"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={""}>Select vendor Name</option>
              {vendorData.map((value) => (
                <option value={value._id} key={value._id}>
                  {value.vendor_name}
                </option>
              ))}
            </select>
            {formik.errors.vendor_id && (
              <span className="text-red-500">{formik.errors.vendor_id}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Car Name
            </label>
            <select
              value={formik.values.car_id}
              name="car_id"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              {vendorCarData.map((value) => (
                <option value={value._id} key={value._id}>
                  {value.car_name}
                </option>
              ))}
            </select>
            {formik.errors.car_id && (
              <span className="text-red-500">{formik.errors.car_id}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Driver Name
            </label>
            <select
              value={formik.values.driver_id}
              name="driver_id"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={""}>Select Driver</option>
              {vendorDriverData.map((value) => (
                <option value={value._id} key={value._id}>
                  {value.driver_name}
                </option>
              ))}
            </select>
            {formik.errors.driver_id && (
              <span className="text-red-500">{formik.errors.driver_id}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Welcome Checklist
            </label>
            <input
              type="text"
              name="return_checklist"
              value={formik.values.return_checklist}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter Welcome Checklist"
            />
            {formik.errors.return_checklist && (
              <span className="text-red-500">{formik.errors.return_checklist}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Special Note
            </label>
            <input
              type="text"
              name="special_note"
              value={formik.values.special_note}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter pecial Note"
            />
            {formik.errors.special_note && (
              <span className="text-red-500">{formik.errors.special_note}</span>
            )}
          </div>
        </div>
        <button
          type="submit"
          className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default SidebarHeaderLayout(CreateDepartureMgmnt);
