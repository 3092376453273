import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  message,
  Popconfirm,
  Table,
  DatePicker,
  Button,
} from "antd";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link } from "react-router-dom";
import {
  deleteSingleEnquiry,
  getAllEnquiry,
  getAllEnquiryPages,
} from "../../../api-core/Enquiry";
import { formatDate } from "../../../utils/helper";
import { useForm } from "rc-field-form";

const ListOfEnquiry = () => {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [row, setRow] = useState(10);
  const [pages, setPages] = useState(1);
  const [editingRow, setEditingRow] = useState(0);
  const [forms] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchedText, setSearchedText] = useState("");

  const { RangePicker } = DatePicker;

  const fetchAllEnquiry = async () => {
    setloading(true);
    const res = await getAllEnquiryPages(row, pages);

    setRow(res?.count);
    setdata(res?.enquiry);
    setloading(false);
  };
  useEffect(() => {
    fetchAllEnquiry();
  }, [row, pages]);

  const datas = data?.map((item) => {
    const res = {
      key: item._id,
      first_name: item.first_name,
      second_name: item.second_name,
      event_type: item.event_type,
      event_date: formatDate(item.event_date, "DD-MM-YYYY"),
      venue: item.venue,
      mobile: item.mobile,
    }
    if(!item.event_type)
    res.event_type = "NA";
    
    return res;
   
  });

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
      ellipsis: true,
      filteredValue: [searchedText],
      onFilter: (value, record)=>{
        return String(record.first_name)
                .toLowerCase()
                .includes(value.toLowerCase()) ||
                String(record.second_name)
                .toLowerCase()
                .includes(value.toLowerCase()) ||
                String(record.event_type)
                .toLowerCase()
                .includes(value.toLowerCase()) ||
                String(record.venue)
                .toLowerCase()
                .includes(value.toLowerCase())

      },
      render: (text, record) =>
        record.key == editingRow ? (
          <Form.Item
            name="first_name"
            rules={[
              {
                required: true,
                message: "First name cannot be empty",
              },
            ]}
            className="outline-none p-2 border rounded-md"
          >
            <Input
              type="text"
              placeholder="First name"
              style={{
                padding: "10px",

                outline: "none",
                border: "none",
                borderRadius: "10px",
              }}
              className="outline-none p-2 border rounded-md"
            />
          </Form.Item>
        ) : (
          text
        ),
    },
    {
      title: "Last Name",
      dataIndex: "second_name",
      key: "second_name",
      ellipsis: true,
      render: (text, record) =>
        record.key == editingRow ? (
          <Form.Item
            name="second_name"
            rules={[
              {
                required: true,
                message: "Second name cannot be empty",
              },
            ]}
            className="outline-none p-2 border rounded-md"
          >
            <Input
              type="text"
              placeholder="Second name"
              style={{
                padding: "10px",

                outline: "none",
                border: "none",
                borderRadius: "10px",
              }}
              className="outline-none p-2 border rounded-md"
            />
          </Form.Item>
        ) : (
          text
        ),
    },
    {
      title: "Event Type",
      dataIndex: "event_type",
      key: "event_type",
      ellipsis: true,
    },
    {
      title: "Event Date",
      dataIndex: "event_date",
      key: "event_date",
      ellipsis: true,
      render: (text, record) =>
        record.key == editingRow ? (
          <Form.Item
            name="event_date"
            rules={[
              {
                required: true,
                message: "Event Date cannot be empty",
              },
            ]}
            className="outline-none p-2 border rounded-md"
          >
            <Input
              type="date"
              placeholder="Event Date"
              style={{
                padding: "10px",

                outline: "none",
                border: "none",
                borderRadius: "10px",
              }}
              className="outline-none p-2 border rounded-md"
            />
          </Form.Item>
        ) : (
          text
        ),
    },
    {
      title: "Venue",
      dataIndex: "venue",
      key: "venue",
      ellipsis: true,
      render: (text, record) =>
        record.key == editingRow ? (
          <Form.Item
            name="venue"
            rules={[
              {
                required: true,
                message: "Venue cannot be empty",
              },
            ]}
            className="outline-none p-2 border rounded-md"
          >
            <Input
              type="text"
              placeholder="Venue"
              style={{
                padding: "10px",

                outline: "none",
                border: "none",
                borderRadius: "10px",
              }}
              className="outline-none p-2 border rounded-md"
            />
          </Form.Item>
        ) : (
          text
        ),
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      ellipsis: true,
      render: (text, record) =>
        record.key == editingRow ? (
          <Form.Item
            name="mobile"
            rules={[
              {
                required: true,
                message: "Mobile number cannot be empty",
              },
            ]}
            className="outline-none p-2 border rounded-md"
          >
            <Input
              type="number"
              placeholder="Mobile Number"
              style={{
                padding: "10px",

                outline: "none",
                border: "none",
                borderRadius: "10px",
              }}
              className="outline-none p-2 border rounded-md"
            />
          </Form.Item>
        ) : (
          <a href={`tel:${text}`}> {text} </a>
        ),
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex gap-x-2 ">
          {editingRow == record.key ? (
            <span
              className="text-red-600 cursor-pointer"
              onClick={() => setEditingRow(0)}
            >
              Cancel
            </span>
          ) : (
            <Link to={`/admin/singleenquiry/${record.key}`}>
              <button className="text-blue-600 cursor-pointer">View</button>
            </Link>
          )}
          {/* {record.key !== editingRow && (
            <button
              className="text-yellow-400 cursor-pointer"
              onClick={() => {
                setEditingRow(record.key);

                forms.setFieldsValue({
                  first_name: record.first_name,
                  second_name: record.second_name,
                  event_date: record.event_date,
                  venue: record.venue,
                  city_town: record.venue,
                  mobile: record.mobile,
                  ref_from: record.ref_from,
                });
              }}
            >
              Edit
            </button>
          )} */}

          {editingRow == record.key ? (
            <button
              className="text-blue-600 cursor-pointer"
              onClick={handleSubmit}
              htmlType="submit"
            >
              Save
            </button>
          ) : (
            <span className="text-red-500 cursor-pointer">
              <Popconfirm
                title="Are you sure to delete this enquiry"
                onConfirm={() => onDeleteRecord(record.key)}
                okText="Yes"
                cancelText="No"
              >
                Delete
              </Popconfirm>
            </span>
          )}
        </div>
      ),
    },
  ];
  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  
  const deleteAllList = async () => {
    if (selectedRowKeys) {
      const deletePayload = {
        ids: [...selectedRowKeys],
      };
      if (deletePayload) {
        const res = await deleteSingleEnquiry(deletePayload);
        // console.log("res Deleted", res);
        if (res.success == true) {
          message.success(
            `${selectedRowKeys.length} items deleted successfully`
          );
          setSelectedRowKeys(0);
          fetchAllEnquiry();
        }
      }
    }
  };

  const onDeleteRecord = async (record) => {
    const payload = {
      ids: [record],
    };

    const deleteEnquiry = await deleteSingleEnquiry(payload);

    if (deleteEnquiry.success) {
      fetchAllEnquiry();
      message.success("Deleted Successfully");
      // const updatedData = data.filter((items)=>items._id !== record)
      // setdata(updatedData)
    }
  };

  const handleSubmit = async (values) => {
    console.log("payload", values);
    message.error("API not available");
  };
  return (
    <div className="">
      {/* <Link to="/admin/createofferbanner">
        <button className="bg-blue-600 text-white p-3 mx-3">Add Offer</button>
      </Link> */}
      {selectedRowKeys.length > 1 && (
                <>
                  <Popconfirm
                    title={`Do you really wanted to delete ${selectedRowKeys.length} items`}
                    onConfirm={() => deleteAllList()}
                  >
                    {selectedRowKeys.length > 1 ? (
                      <Button danger type="primary">
                        Delete All ({selectedRowKeys.length})
                      </Button>
                    ) : (
                      ""
                    )}
                  </Popconfirm>
                </>
              )}
      <h1 className="text-center">List Of Enquiries</h1>
      <div>
        <Form form={forms} onFinish={handleSubmit}>
        <Input.Search size="large" placeholder="search here..."
             style={{marginBottom: 8, width: 280}}
             onSearch={(value)=>{
              setSearchedText(value);
             }}
             onChange={(e)=>{
              setSearchedText(e.target.value);
             }}/>
          <Table rowSelection={rowSelection} dataSource={datas} columns={columns} loading={loading} />
        </Form>
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(ListOfEnquiry);