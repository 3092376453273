import React, { useEffect, useState } from "react";
import { message, Popconfirm, Table } from "antd";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link } from "react-router-dom";
import { formatDate } from "../../../utils/helper";

import { deleteEventPhoto, getAllEventPhoto } from "../../../api-core/Events";

const ListOfAllEventPhoto = () => {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    fetchAllEventPhoto();
  }, []);
  const fetchAllEventPhoto = async () => {
    setloading(true);
    const res = await getAllEventPhoto();
    // console.log("res.eventphoto",res.eventphoto);
    setdata(res.eventphoto);
    setloading(false);
  };
  const onDeleteRecord = async (record, id) => {
    const recordId = {
      ids: [record],
    };
    const res = await deleteEventPhoto(recordId);
    if (res.success == true) {
      message.success(res.msgText);
      fetchAllEventPhoto();
    }
    // console.log("deData", res);
  };
// console.log("DATA - ",data[0].event_id.event_title);
  const datas = data?.map((item) => ({
    key: item._id,
    eventTitle: item.event_id?.event_title || "NA",
    eventDate: formatDate(item.event_date, "DD-MMM-YYYY"),
    galleryTitle: item.ep_title,
    eventDesc: item.ep_descp,
    // eventImage: item.ep_img[0].file,
  }));
  // console.log("datas", datas);

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Event Title",
      dataIndex: "eventTitle",
      key: "eventTitle",
      ellipsis: true,
    },
    {
      title: "Event Date",
      dataIndex: "eventDate",
      key: "eventDate",
      ellipsis: true,
    },
    {
      title: "Gallery Title",
      dataIndex: "galleryTitle",
      key: "galleryTitle",
      ellipsis: true,
    },

    // {
    //   title: "Event Desc",
    //   dataIndex: "eventDesc",
    //   key: "eventDesc",

    //   ellipsis: true,
    // },
    // {
    //   title: "Event Image",
    //   dataIndex: "eventImage",
    //   key: "eventImage",

    //   ellipsis: true,
    //   render: (_, record) => (
    //     <div className="w-10 h-10">
    //       <img src={record.eventImage} />
    //     </div>
    //   ),
    // },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex gap-x-2 ">
          {/* {console.log("rec", record)} */}
          {/* <Link to={`/admin/singleeventphoto/${record.key}`}>
            <button className="text-blue-600 cursor-pointer">View</button>
          </Link> */}

          <Link to={`/admin/updateventphoto/${record.key}`}>
            <button className="text-yellow-400 cursor-pointer">Edit</button>
          </Link>

          <Popconfirm
            title="Sure to delete?"
            onConfirm={(e) => onDeleteRecord(record.key, e)}
          >
            <span className="text-red-500 cursor-pointer">Delete</span>
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div className="">
      <Link to="/admin/createeventphoto">
        <button className="bg-blue-600 text-white p-3 mx-3">
          Add Photo to Gallery
        </button>
      </Link>
      <h1 className="text-center">List Of Gallery Photos</h1>
      <div className="">
        <Table
          dataSource={datas}
          columns={columns}
          loading={loading}
          pagination
        />
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(ListOfAllEventPhoto);
