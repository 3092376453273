import React, { useEffect, useState } from "react";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { useParams } from "react-router-dom";

import {
  createEvent,
  createSubEvent,
  getAllEvents,
  getSingleSubEvent,
  updateSubEvent,
} from "../../../api-core/Events";
import { formatDate } from "../../../utils/helper";
const UpdateSubEvent = () => {
  const { id } = useParams();
  const [eventData, setEventData] = useState([]);
  useEffect(() => {
    const fetchAllEvents = async () => {
      const res = await getAllEvents();
      setEventData(res.event);
      console.log("eventData", res);
    };
    fetchAllEvents();
  }, []);
  const [inputs, setInputs] = useState({
    event_id: "",
    subevent_title: "",
    subevent_descp: "",
    subevent_date: "",
  });
  useEffect(() => {
    const fetchSingleSubEvent = async () => {
      const res = await getSingleSubEvent(id);
      console.log("res", res);
      setInputs({
        event_id: res.subevent[0].event_id,
        subevent_title: res.subevent[0].subevent_title,
        subevent_descp: res.subevent[0].subevent_descp,
        subevent_date: formatDate(res.subevent[0].subevent_date,"yyyy-MM-DD"),
      });
      // setEventImg(res.event[0]?.event_img);
    };
    fetchSingleSubEvent();
  }, [id]);
  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("inputs", inputs);
    if (inputs) {
      const res = await updateSubEvent(inputs,id);
      console.log("resSubEvent", res);
    } else {
      console.log("error");
    }
  };
  return (
    <div className="w-[90%] mx-auto ">
      <h1>Update Sub Event</h1>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="flex flex-col justify-center items-center  md:justify-between md:flex-row gap-x-5 flex-wrap">
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Event Id
            </label>
            <select
              value={inputs.event_id}
              name="event_id"
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              {eventData.map((value) => (
                <option value={value._id} key={value._id}>
                  {value.event_title}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Event Title
            </label>
            <input
              type="text"
              name="subevent_title"
              value={inputs.subevent_title}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter banner title"
            />
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Event Desciption
            </label>
            <input
              type="text"
              name="subevent_descp"
              value={inputs.subevent_descp}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter banner desc"
            />
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Event Date
            </label>
            <input
              type="date"
              name="subevent_date"
              value={inputs.subevent_date}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter banner desc"
            />
          </div>
        </div>
        <button
          type="submit"
          className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Update
        </button>
      </form>
    </div>
  );
};

export default SidebarHeaderLayout(UpdateSubEvent);
