import React, { useEffect, useState } from "react";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";

import { createSubEvent, getAllEvents } from "../../../api-core/Events";
import { getAllUser } from "../../../api-core/User";
import { getAllHotel } from "../../../api-core/Hotel";
import { useFormik } from "formik";
import * as Yup from "yup";
const CreateSubEvent = () => {
  const [eventData, setEventData] = useState([]);
  const [clientData, setClientData] = useState([]);
  // const [floor_no,setFloor_no]=useState("")
  // const [room_nos,setRoomNo]=useState([])
  const [hotelData, setHotelData] = useState([]);
  const [addRoom, setAddRoom] = useState(["", ""]);
  const initialValues = {
    event_id: "",
    client_id: "",
    hotel_id: "",
    subevent_title: "",
    subevent_descp: "",
    subevent_date: "",
    hotel_rooms_required: "",
    floor_no: "",
    room_nos: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      event_id: Yup.string().required("event name is required"),
      client_id: Yup.string().required("Client Name is required"),
      hotel_id: Yup.string().required("Hotel Name is required"),
      subevent_title: Yup.string().required("SubEvent Title is required"),
      subevent_descp: Yup.string().required("SubEvent Descp is required"),
      subevent_date: Yup.string().required("SubEvent Date is required"),
    }),
    onSubmit: async (values, action) => {
      console.log("values", values);
      const { floor_no, room_nos, ...others } = values;
      const hotelRequired = {
        floor_no: values.floor_no,
        room_nos: [...values.room_nos.split(",")],
      };
      const newValues = {
        ...others,
        hotel_rooms_required: [{ ...hotelRequired }],
      };
      console.log("newValues", newValues);
      // if (values) {
      //   const res = await createSubEvent(values);
      //   console.log("resFormData", res);
      //   action.resetForm();
      // } else {
      //   console.log("error");
      // }
    },
  });
  useEffect(() => {
    const fetchAllEvents = async () => {
      const res = await getAllEvents();
      setEventData(res.event);
      // console.log("eventData", eventData);
    };
    fetchAllEvents();
  }, []);
  useEffect(() => {
    const fetchAllUser = async () => {
      const res = await getAllUser();
      setClientData(res.user);
      // console.log("clientData", clientData);
    };
    fetchAllUser();
  }, []);
  useEffect(() => {
    const fetchAllHotel = async () => {
      const res = await getAllHotel();
      setHotelData(res.hotel);
      // console.log("hotelData", hotelData);
    };
    fetchAllHotel();
  }, []);
  const localStoreData = JSON.parse(localStorage.getItem("bbe-token"));
  return (
    <div className="w-[90%] mx-auto ">
      <h1>Create Current Event</h1>
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <div className="flex flex-col justify-center items-center  md:justify-between md:flex-row gap-x-5 flex-wrap">
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Event Name
            </label>
            <select
              value={formik.values.event_id}
              name="event_id"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={""}>Select Event</option>
              {eventData.map((value) => (
                <option value={value._id} key={value._id}>
                  {value.event_title}
                </option>
              ))}
            </select>
            {formik.errors.event_id && (
              <span className="text-red-500">{formik.errors.event_id}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Client Name
            </label>
            <select
              value={formik.values.client_id}
              name="client_id"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={""}>Select Client</option>
              {clientData
                .filter((cId) => cId._id !== localStoreData.clientId)
                .map((value) => (
                  <option value={value._id} key={value._id}>
                    {value.name}
                  </option>
                ))}
            </select>
            {formik.errors.client_id && (
              <span className="text-red-500">{formik.errors.client_id}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Hotel Name
            </label>
            <select
              value={formik.values.hotel_id}
              name="hotel_id"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={""}>Select Hotel</option>
              {hotelData.map((value) => (
                <option value={value._id} key={value._id}>
                  {value.hotel_name}
                </option>
              ))}
            </select>
            {formik.errors.hotel_id && (
              <span className="text-red-500">{formik.errors.hotel_id}</span>
            )}
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Event Title
            </label>
            <input
              type="text"
              name="subevent_title"
              value={formik.values.subevent_title}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter banner title"
            />
            {formik.errors.subevent_title && (
              <span className="text-red-500">
                {formik.errors.subevent_title}
              </span>
            )}
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Event Desciption
            </label>
            <textarea
              type="text"
              name="subevent_descp"
              value={formik.values.subevent_descp}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter banner desc"
            />
            {formik.errors.subevent_descp && (
              <span className="text-red-500">
                {formik.errors.subevent_descp}
              </span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Event Date
            </label>
            <input
              type="date"
              name="subevent_date"
              value={formik.values.subevent_date}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter banner desc"
            />
            {formik.errors.subevent_date && (
              <span className="text-red-500">
                {formik.errors.subevent_date}
              </span>
            )}
          </div>

          {addRoom.map((item) => (
            <>
              <div className="mb-6 w-[95%] md:w-[45%] ">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Floor Number
                </label>
                <input
                  type="text"
                  name="floor_no"
                  value={formik.values.floor_no}
                  onChange={formik.handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="enter Floor No"
                />
                {formik.errors.floor_no && (
                  <span className="text-red-500">{formik.errors.floor_no}</span>
                )}
              </div>
              <div className="mb-6 w-[95%] md:w-[45%] ">
                <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
                  Room Numbers
                </label>
                <input
                  type="text"
                  name="room_nos"
                  value={formik.values.room_nos}
                  onChange={formik.handleChange}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="enter Room Nos like 201,202"
                />
                {formik.errors.room_nos && (
                  <span className="text-red-500">{formik.errors.room_nos}</span>
                )}
              </div>
            </>
          ))}
        </div>
        <button
          type=""
          className="text-white mr-2 bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Add More Rooms
        </button>
        <button
          type="submit"
          className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default SidebarHeaderLayout(CreateSubEvent);
