import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { getSingleEnquiry } from "src/api-core/Enquiry";
import { Descriptions } from "antd";
import { formatDate } from "src/utils/helper";
const SingleEnquiry = () => {
  const { id } = useParams();

  const [singleEnquiry, setSingleEnquiry] = useState([]);

  useEffect(() => {
    const fetchSingleEnquiry = async () => {
      const res = await getSingleEnquiry(id);
      if (res?.success) setSingleEnquiry(res?.enquiry);
    };
    fetchSingleEnquiry();
  }, [id]);

  const datas = singleEnquiry.map((items) => ({
    city_town: items?.city_town,
    created_at: formatDate(items?.created_at, "DD-MM-YYYY"),
    event_date: formatDate(items?.event_date, "DD-MM-YYYY"),
    event_type: items?.event_type,
    first_name: items?.first_name,
    mobile: items?.mobile,
    ref_from: items?.ref_from,
    second_name: items?.second_name,
    updatedAt: items?.updatedAt,
    venue: items?.venue,
    wedding_of: items?.wedding_of,
  }));

  

  return (
    <div>
      <Descriptions
        title="Enquiry Details"
        bordered
        layout="horizontal"
        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
      >
        <Descriptions.Item
          label="First Name"
          labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
        >
          {datas[0]?.first_name}
        </Descriptions.Item>

        <Descriptions.Item
          label="Second Name"
          labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
        >
          {datas[0]?.second_name}
        </Descriptions.Item>

        <Descriptions.Item
          label="Event Type"
          labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
        >
          {datas[0]?.event_type}
        </Descriptions.Item>

        <Descriptions.Item
          label="Event Date"
          labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
        >
          {datas[0]?.event_date}
        </Descriptions.Item>

        <Descriptions.Item
          label="Venue"
          labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
        >
          {datas[0]?.venue}
        </Descriptions.Item>

        <Descriptions.Item
          label="City Town"
          labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
        >
          {datas[0]?.city_town}
        </Descriptions.Item>

        <Descriptions.Item
          label="Mobile"
          labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
        >
          <a href={`tel:${datas[0]?.mobile}`} > {datas[0]?.mobile} </a>
        </Descriptions.Item>

        <Descriptions.Item
          label="Reference from"
          labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
        >
          {datas[0]?.ref_from}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default SidebarHeaderLayout(SingleEnquiry);
