import React, { useEffect, useState } from "react";
import { getsingleOfferBanner } from "../../../api-core/OfferBanner";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { useHistory, useParams } from "react-router-dom";
import { Table, Descriptions, Image } from "antd";
import { formatDate } from "src/utils/helper";

const SingleOfferBanner = () => {
  const { id } = useParams();
  const [data, setData] = useState([]);
  useEffect(() => {
    const fetchSingleOffer = async () => {
      const res = await getsingleOfferBanner(id);
      setData(res.offerbanner);
    };
    fetchSingleOffer();
  }, [id]);
  const datas = data.map((item) => ({
    key: item._id,
    bannertitle: item.banner_title,
    bannerdescp: item.banner_descp,
    offerstarts: item.offer_starts,
    offerends: item.offer_ends,
    price: item.price,
    bannerimage: item.banner_img,
    discount: item.discount,
  }));
  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Banner Title",
      dataIndex: "bannertitle",
      key: "bannertitle",
      // ellipsis: true,
    },
    {
      title: "Banner descp",
      dataIndex: "bannerdescp",
      key: "Bannerdescp",
      // ellipsis: true,
    },
    {
      title: "Offer Starts",
      dataIndex: "offerstarts",
      key: "offerstarts",
      ellipsis: true,
    },
    {
      title: "Offer Ends",
      dataIndex: "offerends",
      key: "offerends",
      ellipsis: true,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Banner Image",
      dataIndex: "bannerimage",
      key: "bannerimage",

      ellipsis: true,
      render: (_, record) => (
        <div className="w-10 h-10">
          <img src={record.bannerimage} />
        </div>
      ),
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
    },
  ];
  return (
    <div>
      <div>
        <Descriptions
          title="Offer Banner Details"
          bordered
          layout="horizontal"
          column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
        >
          <Descriptions.Item
            label="Banner Image"
            labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}

          >
            <Image width={100} height={100} src={datas[0]?.bannerimage}
            preview={false} />
          </Descriptions.Item>
          <Descriptions.Item
            label="Banner Title"
            labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
          >
            <span className="text-lg"> {datas[0]?.bannertitle}</span>
          </Descriptions.Item>

          <Descriptions.Item
            label="Banner Description"
            labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
          >
            <span className="text-lg">{datas[0]?.bannerdescp}</span>
          </Descriptions.Item>
          <Descriptions.Item
            label="Offer Starts on"
            labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
          >
            <span className="text-lg">
              {formatDate(datas[0]?.offerstarts, "DD-MM-YYYY")}
            </span>
          </Descriptions.Item>
          <Descriptions.Item
            label="Offer Ends on"
            labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
          >
            {" "}
            <span className="text-lg">
              {formatDate(datas[0]?.offerends, "DD-MM-YYYY")}
            </span>
          </Descriptions.Item>
          <Descriptions.Item
            label="Price"
            labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
          >
            <span className="text-lg">{datas[0]?.price}</span>
          </Descriptions.Item>
          <Descriptions.Item
            label="Discount"
            labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
          >
            <span className="text-lg">{datas[0]?.discount}</span>
          </Descriptions.Item>
        </Descriptions>
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(SingleOfferBanner);
