import { Modal, Select } from "antd";
import { ErrorMessage, Formik } from "formik";
import { FormInput } from "src/components/FormInput";
import * as Yup from "yup";
import { DeliveryType, IGuestList, ILostAndFound } from "src/types";
import { Label } from "src/components/Label";
import { ShouldRender } from "src/components/ShouldRender";

export type LostAndFound = Omit<
  ILostAndFound,
  "_id" | "client_id" | "event_id"
>;

interface Props {
  handleClose: () => void;
  handleSave: (values: LostAndFound) => void;
  data?: ILostAndFound;
  guests: IGuestList[];
  open: boolean;
  title: string;
}

const validationSchema = Yup.object({
  guest_id: Yup.string().required("Please select Guest"),
  lost_item: Yup.string().min(3,"must be at least 3 characters").required("Lost item is required"),
  delivery_type: Yup.string().min(3,"must be at least 3 characters").required("Delivery type is required"),
  // item_identification: Yup.string().min(3,"Item identification must be at least 3 characters").required(
  //   "Item identification  is required"
  // ),
  // item_name: Yup.string().min(3,"Item name must be at least 3 characters").required("Item name is required"),
  // lost_place: Yup.string().min(3,"Lost place must be at least 3 characters").required("Lost place is required"),
  // found_by: Yup.string().min(3, "Found by must be at least 3 characters").required("Found by is required"),
  // found_place: Yup.string().min(3, "Found place must be at least 3 characters").required("Found place is required"),
  // deliver_type: Yup.string().min(3,"Delivery type must be at least 3 characters").required("Deliver type is required"),
});

const EditLostAndFound = ({
  handleClose,
  handleSave,
  data,
  guests,
  open,
  title,
}: Props) => {
  const initalValues = {
    guest_id: data?.guest_id || "",
    lost_item: data?.lost_item || "",
    delivery_type: data?.delivery_type || "",
    // item_identification: data?.item_identification,
    // item_name: data?.item_name,
    // lost_place: data?.lost_place,
    // found_by: data?.found_by,
    // found_place: data?.found_by,
    // deliver_type: data?.deliver_type,
  };
  console.log(data);
  
  return (
    <Formik
      initialValues={initalValues}
      validationSchema={validationSchema}
      onSubmit={(values) =>
        handleSave(values)
        // handleSave({
        //   deliver_type: values.deliver_type!,
        //   found_by: values.found_by!,
        //   found_place: values.found_place!,
        //   item_identification: values.item_identification!,
        //   item_name: values.item_name!,
        //   lost_place: values.lost_place!,
        //   guest_id: values.guest_id!,
        // })
      }
    >
      {({ handleSubmit, values, setFieldValue, errors }) => {
        return (
          <Modal
            centered
            open={open}
            title={title}
            onCancel={handleClose}
            onOk={() => handleSubmit()}
          >
            {/* <ShouldRender check={!!data}> */}
              <div className="mb-3">
                <Label required text="Guest" />
                <Select
                  className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                  onChange={(id) => setFieldValue("guest_id", id)}
                  placeholder="Select Guest"
                  value={values.guest_id}
                >
                  {guests.map((guest) => (
                    <Select.Option key={guest._id} value={guest._id}>
                      {guest.guest_name}
                    </Select.Option>
                  ))}
                </Select>
                {errors.guest_id && (
                  <div className="my-1 text-xs text-red-500">
                    <ErrorMessage name="guest_id" />
                  </div>
                )}
              </div>
            {/* </ShouldRender> */}
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: true, text: "Lost item" }}
                name="lost_item"
                placeholder="enter lost item"
              />
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: true, text: "Delivery Type" }}
                name="delivery_type"
                placeholder="enter delivery type"
              />
            </div>
            {/* <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: true, text: "Item identification" }}
                name="item_identification"
                placeholder="enter item identification"
              />
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: true, text: "Item name" }}
                name="item_name"
                placeholder="enter item name"
              />
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: true, text: "Lost place" }}
                name="lost_place"
                placeholder="enter lost place"
              />
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: true, text: "Found by" }}
                name="found_by"
                placeholder="enter found by"
              />
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: true, text: "Found place" }}
                name="found_place"
                placeholder="enter found place"
              />
            </div>
            <div className="mb-3">
              <Label required text="Delivery Type" />
              <Select
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                placeholder="Select Hotel Name"
                value={values.deliver_type}
                onChange={(value) => {
                  setFieldValue("deliver_type", value);
                }}
              >
                <Select.Option value={DeliveryType.Courier}>
                  Courier
                </Select.Option>
                <Select.Option value={DeliveryType.Self}>Self</Select.Option>
              </Select>
              {errors.deliver_type && (
                <div className="my-1 text-xs text-red-500">
                  <ErrorMessage name="deliver_type" />
                </div>
              )}
            </div> */}
          </Modal>
        );
      }}
    </Formik>
  );
};

export default EditLostAndFound;
