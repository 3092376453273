import React, { useEffect, useState } from "react";
import { Button, Input, message, Popconfirm, Table } from "antd";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link } from "react-router-dom";
import {
  deleteFreelancerAssignee,
  getAllFreelancerAssignee,
  getAllFreelancerAssigneePages,
} from "../../../api-core/Freelancer";

const ListOfFreelancerAssignee = () => {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [row, setRow] = useState(10);
  const [pages, setPages] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchedText, setSearchedText] = useState("");

  useEffect(() => {
    fetchAllFreelancerAssignee();
  }, [row, pages]);
  const fetchAllFreelancerAssignee = async () => {
    setloading(true);
    const res = await getAllFreelancerAssigneePages(row, pages);
    setRow(res?.count);

    setdata(res?.freelancerassignedevent);
    setloading(false);
  };

  const onDeleteRecord = async (record) => {
    const recordId = {
      ids: [record],
    };
    const res = await deleteFreelancerAssignee(recordId);
    if (res.success == true) {
      message.success(res.msgText);
      fetchAllFreelancerAssignee();
      // const updatedData = data.filter((items)=>items._id !== record)
      // setdata(updatedData)
    }
  };
const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  
  const deleteAllList = async () => {
    if (selectedRowKeys) {
      const deletePayload = {
        ids: [...selectedRowKeys],
      };
      if (deletePayload) {
        const res = await deleteFreelancerAssignee(deletePayload);
        // console.log("res Deleted", res);
        if (res.success == true) {
          message.success(
            `${selectedRowKeys.length} items deleted successfully`
          );
          setSelectedRowKeys(0);
          fetchAllFreelancerAssignee();
        }
      }
    }
  };
  const datas = data?.map((item) => ({
    key: item._id,
    eventName: item?.event_id?.event_title,
    freelancerName: item?.freelancer_id?.name,
    departmentName: item?.department_type,
    expectedWorkHours: item?.expected_working_hours,
    workedHours: item?.hours_worked,
  }));

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    // {
    //   title: "Client Name",
    //   dataIndex: "clientName",
    //   key: "clientName",
    // },
    {
      title: "Event Name",
      dataIndex: "eventName",
      key: "eventName",
      render: (_, record) => record?.eventName || "N/A",
      filteredValue: [searchedText],
      onFilter: (value, record)=>{
        return String(record.eventName)
                .toLowerCase()
                .includes(value.toLowerCase()) ||
                String(record.freelancerName)
                .toLowerCase()
                .includes(value.toLowerCase()) ||
                String(record.departmentName)
                .toLowerCase()
                .includes(value.toLowerCase())

      },

    },
    {
      title: "Freelancer Name",
      dataIndex: "freelancerName",
      key: "freelancerName",
      render: (_, record) => record?.freelancerName || "N/A"
    },
    {
      title: "Department Name",
      dataIndex: "departmentName",
      key: "departmentName",
    },
    {
      title: "Expected Working Hours",
      dataIndex: "expectedWorkHours",
      key: "expectedWorkHours",
    },
    {
      title: "Worked Hours",
      dataIndex: "workedHours",
      key: "workedHours",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex gap-x-2 ">
          <Link to={`/admin/singlefreelancerassignedevent/${record.key}`}>
            <button className="text-blue-600 cursor-pointer">View</button>
          </Link>

          <Link to={`/admin/updateFreelancerAssigness/${record.key}`}>
            <button className="text-yellow-400 cursor-pointer">Edit</button>
          </Link>

          <Popconfirm
            title="Sure to delete?"
            onConfirm={(e) => onDeleteRecord(record.key, e)}
          >
            <span className="text-red-500 cursor-pointer">Delete</span>
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div className="">
      <Link to="/admin/createfreelancerassignedevent">
        <button className="bg-blue-600 text-white p-3 mx-3">
          Add Deployed Freelancer
        </button>
      </Link>
      {selectedRowKeys.length > 1 && (
                <>
                  <Popconfirm
                    title={`Do you really wanted to delete ${selectedRowKeys.length} items`}
                    onConfirm={() => deleteAllList()}
                  >
                    {selectedRowKeys.length > 1 ? (
                      <Button danger type="primary">
                        Delete All ({selectedRowKeys.length})
                      </Button>
                    ) : (
                      ""
                    )}
                  </Popconfirm>
                </>
              )}
      <h1 className="text-center">Deployed Freelancers</h1>
      <div>
      <Input.Search size="large" placeholder="search here..."
             style={{marginBottom: 8, width: 280}}
             onSearch={(value)=>{
              setSearchedText(value);
             }}
             onChange={(e)=>{
              setSearchedText(e.target.value);
             }}/>
        <Table rowSelection={rowSelection} dataSource={datas} columns={columns} loading={loading} />
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(ListOfFreelancerAssignee);
