import { useEffect, useState } from "react";
import { Descriptions, Image, Table } from "antd";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { useParams } from "react-router-dom";
import { formatDate } from "../../../utils/helper";

import { getSingleSubEventPhoto } from "../../../api-core/Events";

const SingleEventPhoto = () => {
  const { id } = useParams();
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    const fetchSingleEventPhoto = async (id) => {
      setloading(true);
      const res = await getSingleSubEventPhoto(id);
      console.log("res", res.eventphoto);

      setdata(res.eventphoto);
      setloading(false);
    };
    if (id) fetchSingleEventPhoto(id);
  }, [id]);

  const datas = data.map((item) => ({
    key: item._id,
    subEventTitle: item.event_id?.event_title || "NA",
    eventDate: formatDate(item.event_date, "DD-MMM-YYYY"),
    eventTitle: item.ep_title,
    eventDesc: item.ep_descp,
    eventImage: item.ep_img,
  }));

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Event Title",
      dataIndex: "subEventTitle",
      key: "subEventTitle",
      ellipsis: true,
    },
    {
      title: "Event Date",
      dataIndex: "eventDate",
      key: "eventDate",
      ellipsis: true,
    },
    {
      title: "Gallery Title",
      dataIndex: "eventTitle",
      key: "eventTitle",
      ellipsis: true,
    },

    {
      title: "Event Desc",
      dataIndex: "eventDesc",
      key: "eventDesc",

      ellipsis: true,
    },
    {
      title: "Event Image",
      dataIndex: "eventImage",
      key: "eventImage",

      ellipsis: true,
      render: (_, record) => (
        <div className="w-10 h-10">
          <img alt="event" src={record.eventImage} />
        </div>
      ),
    },
  ];
  console.log("DATAS ", datas);
  return (
    <div className="">
      {/* <h1 className="text-center"> Event Photo</h1> */}
      <Descriptions
       title="Gallery Details"
       bordered
       layout="horizontal"
       column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
       >
      <Descriptions.Item
            label="Event Title"
            labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}>

            <span className="text-lg"> {datas[0]?.subEventTitle || "NA"}</span>
      </Descriptions.Item>
      <Descriptions.Item
            label="Event Date"
            labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}>

            <span className="text-lg"> {datas[0]?.eventDate}</span>
      </Descriptions.Item>
      <Descriptions.Item
            label="Gallery Title"
            labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}>

            <span className="text-lg"> {datas[0]?.eventTitle}</span>
      </Descriptions.Item>
      <Descriptions.Item
            label="Gallery Description"
            labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}>

            <span className="text-lg"> {datas[0]?.eventDesc}</span>
      </Descriptions.Item>

      <Descriptions.Item
            label="Photo"
            labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}>
            <Image width={100} height={100} src={datas[0]?.eventImage[0].file}
            preview={false} />
      </Descriptions.Item>

      </Descriptions>

    </div>
  );
};

export default SidebarHeaderLayout(SingleEventPhoto);
