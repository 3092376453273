import React, { useEffect, useState } from "react";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";

import { createEvent } from "../../../api-core/Events";
import { createHotel } from "../../../api-core/Hotel";
import { createRole, getSingleRole } from "../../../api-core/RolesPermissions";
import { useParams } from "react-router-dom";
const UpdateRole = () => {
  const { id } = useParams();
  const [loading, setloading] = useState(false);
  const [inputs, setInputs] = useState({
    role_name: "",
  });
  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const fetchAllRoles = async () => {
      setloading(true);
      const res = await getSingleRole(id);
      setInputs({
        role_name: res.role[0].role_name,
      });
      setloading(false);
    };
    fetchAllRoles();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (inputs) {
      const res = await createRole(inputs);
    } else {
      console.log("error");
    }
  };
  return (
    <div className="w-[90%] mx-auto ">
      <h1>Update Role</h1>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="flex flex-col justify-center items-center  md:justify-between md:flex-row gap-x-5 flex-wrap">
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Role Name
            </label>
            <input
              type="text"
              name="role_name"
              value={inputs.role_name}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter role name"
            />
          </div>
        </div>
        <button
          type="submit"
          className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Update
        </button>
      </form>
    </div>
  );
};

export default SidebarHeaderLayout(UpdateRole);
