import React from "react";
import { Button, Input, Popconfirm, Table, Typography } from "antd";
import EditDeparture, { Departure } from "./EditDeparture";
import { ShouldRender } from "src/components/ShouldRender";
import {
  createDepartureMgmnt,
  deleteDepartureMgmnt,
  updateDepartureMgmnt,
} from "src/api-core/Ghms";
import { IDeparture, IEvent, IGuestList, IVendorCar } from "src/types";

interface Props {
  departure: IDeparture[] | undefined;
  event: IEvent | undefined;
  guests: IGuestList[] | undefined;
  loading: boolean;
  update: () => Promise<void>;
  vendorCars: IVendorCar[];
}

export const DepartureTable = ({
  departure = [],
  event,
  guests = [],
  loading,
  update,
  vendorCars,
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const [recordIndex, setRecordIndex] = React.useState(-1);
  const [searchedText, setSearchedText] = React.useState("");

  const handleEdit = (index: number) => {
    setOpen(true);
    setRecordIndex(index);
  };

  const handleClose = () => {
    setOpen(false);
    setRecordIndex(-1);
  };

  const handleOpen = () => setOpen(true);

  const handleSave = async (values: Departure) => {
    if (recordIndex === -1) {
      if (event) {
        const { client_id, _id: event_id } = event;
        const payload = {
          client_id,
          event_id,
          ...values,
        };
        createDepartureMgmnt(payload).then(update).then(handleClose);
      }
    } else {
      const recordDetail = departure[recordIndex];
      // const {
      //   // car_id,
      //   // date_of_departure,
      //   guest_id,
      //   // no_of_guest_arrived,
      //   // mode_of_departure,
      //   departure_time,
      //   // return_checklist,
      //   // special_note,
      // } = values;
      const { client_id, event_id, _id } = recordDetail;
      const payload = {
        client_id,
        event_id,
        ...values
        // guest_id,
        // car_id,
        // date_of_departure,
        // no_of_guest_arrived,
        // mode_of_departure,
        // departure_time,
        // return_checklist,
        // special_note,
      };
      updateDepartureMgmnt(payload, _id).then(update).then(handleClose);
    }
  };

  const handleDelete = (id: string) => {
    const ids = [id];
    deleteDepartureMgmnt(ids).then(update);
  };

  const defaultColumns = [
    {
      title: "Guest Name",
      dataIndex: "guest_name",
      key: "guest_name",
      filteredValue: [searchedText],
      onFilter: (value:any, record:any)=>{
        return  String(record.guest_name)
                .toLowerCase()
                .includes(value.toLowerCase()) ||
                String(record.guest_mobile)
                .includes(value) ||
                String(record.departure_location)
                .toLowerCase()
                .includes(value.toLowerCase())
      },
      // render: (_: any, record: Departure) => {
      //   const guestName = guests.find(({_id}) => _id === record.guest_id);
      //   return guestName?.guest_name || "NA";
      // }
    },
    {
      title: "Guest Mobile",
      dataIndex: "guest_mobile",
      key: "guest_mobile",
      render: (value: any) => <a href={`tel:${value}`}> {`${ value}`}</a>
    },
    // {
    //   title: "Date of Departure",
    //   dataIndex: "date_of_departure",
    //   key: "date_of_departure",
    //   render: (value: any) => value && moment(value).format(TIME_FORMATS.DATE)
    // },
    // {
    //   title: "No. of guest arrived",
    //   dataIndex: "no_of_guest_arrived",
    //   key: "no_of_guest_arrived",
    // },
    // {
    //   title: "Mode of departure",
    //   dataIndex: "mode_of_departure",
    //   key: "mode_of_departure",
    //   filteredValue: [searchedText],
    //   onFilter: (value:any, record:any)=>{
    //     return  String(record.date_of_departure)
    //             .toLowerCase()
    //             .includes(value.toLowerCase()) ||
    //             String(record.mode_of_departure)
    //             .toLowerCase()
    //             .includes(value.toLowerCase()) ||
    //             String(record.guest_name)
    //             .toLowerCase()
    //             .includes(value.toLowerCase())
    //   },
    // },
    {
      title: "Departure Time",
      dataIndex: "departure_time",
      key: "departure_time",
    },
    {
      title: "Departure Location",
      dataIndex: "departure_location",
      key: "departure_location",
    },
    {
      title: "Departure Conveyance",
      dataIndex: "departure_conveyance",
      key: "departure_conveyance",
    },
    {
      title: "Details",
      dataIndex: "details",
      key: "details"
    },

    // {
    //   title: "Return Text",
    //   dataIndex: "return_checklist",
    //   key: "return_checklist",
    // },
    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: any) => {
        const index = departure.findIndex((item) => item._id === record._id);
        return (
          <span>
            <Typography.Link
              disabled={recordIndex !== -1}
              onClick={() => handleEdit(index)}
              style={{ marginRight: 8 }}
            >
              Edit
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record._id)}
            >
              <Typography.Link>Delete</Typography.Link>
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  const dataSource = departure?.map((items: IDeparture) => ({
    _id: items?._id,
    key: items?._id,
    guest_name: guests?.find(
      (guest: IGuestList) => guest.guest_name && guest._id === items.guest_id
    )?.guest_name,
    guest_id: guests?.find(
      (guest: IGuestList) => guest.guest_name && guest._id === items.guest_id
    )?._id,
    guest_mobile: guests?.find(
      (guest: IGuestList) => guest.guest_name && guest._id === items.guest_id
    )?.guest_mobile,
    departure_time: items?.departure_time,
    departure_location: items.departure_location,
    departure_conveyance: items.departure_conveyance,
    details: items.details,
  }));

  return (
    <React.Fragment>
      <div className="flex justify-end mb-3">
        <Button onClick={handleOpen}>Add Data</Button>
      </div>
      <Input.Search size="large" placeholder="search here..."
             style={{marginBottom: 8, width: 280}}
             onSearch={(value)=>{
              setSearchedText(value);
             }}
             onChange={(e)=>{
              setSearchedText(e.target.value);
             }}/>
      <Table
        columns={defaultColumns}
        // dataSource={departure}
        dataSource={dataSource}
        key="_id"
        loading={loading}
      />
      <ShouldRender check={open}>
        <EditDeparture
          open={open}
          departure={departure[recordIndex]}
          guests={guests}
          title={recordIndex === -1 ? "Add Departure" : "Edit Departure"}
          handleClose={handleClose}
          handleSave={handleSave}
          vendorCars={vendorCars}
        />
      </ShouldRender>
    </React.Fragment>
  );
};
