import React, { useEffect, useState } from "react";
import { Button, Form, Input, message, Popconfirm, Radio, Table } from "antd";
import SidebarHeaderLayout from "../HOC/SidebarHeaderLayout";
import { Link, useNavigate } from "react-router-dom";
import { deleteVendor, getAllVendors } from "../../api-core/Vendor";
import { IBlacklistedVendors } from "src/types";

const BlackListed = () => {
  const [data, setdata] = useState<any[]>([]);
  const [loading, setloading] = useState(false);
  const [row, setRow] = useState(10);
  const [pages, setPages] = useState(1);
  const [open, setOpen] = useState(false);
  const [searchedText, setSearchedText] = useState<any>("");
  const [selectedRowKeys, setSelectedRowKeys] = useState<any>([]);

  const fetchAllVendor = async () => {
    setloading(true);
    const res = await getAllVendors();

    setRow(res?.count);
    setdata(res?.vendor);
    setloading(false);
  };
  useEffect(() => {
    fetchAllVendor();
  }, [row, pages]);
  
  const datas = data?.map((item) => ({
    key: item._id,
    vendor_name: item.vendor_name,
    vendor_mobile: item.vendor_mobile,
    vendor_work: item.vendor_work,
    reason_for_blacklist: item.reason_for_blacklist,
  }));

  const onDeleteRecord = async (
    record: any,
    id: React.MouseEvent<HTMLElement, MouseEvent> | undefined
  ) => {
    const recordId = {
      ids: [record],
    };
    const res = await deleteVendor(recordId);
    if (res.success == true) {
      message.success(res.msgText);
      fetchAllVendor();
    }
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const onSelectChange = (newSelectedRowKeys: any) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const deleteAllList = async () => {
    if (selectedRowKeys) {
      const deletePayload = {
        ids: [...selectedRowKeys],
      };
      if (deletePayload) {
        const res = await deleteVendor(deletePayload);

        if (res.success == true) {
          message.success(
            `${selectedRowKeys.length} items deleted successfully`
          );
          setSelectedRowKeys(0);
          fetchAllVendor();
        }
      }
    }
  };

  const columns: any = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      sorter: (a: { id: number }, b: { id: number }) => a.id - b.id,
      render: (id: any, record: any, index: number) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Vendor Name",
      dataIndex: "vendor_name",
      key: "vendor_name",
      ellipsis: true,
      filteredValue: [searchedText],
      onFilter: (value: any, record: any) => {
        return (
          String(record.vendor_name)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.vendor_mobile)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.vendor_work).toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
        title: "Vendor's mobile",
        dataIndex: "vendor_mobile",
        key: "vendor_mobile",
        ellipsis: true,
        render: (vendor_mobile: IBlacklistedVendors) => (
          <a href={`tel:${vendor_mobile}`}>{`${vendor_mobile}`}</a>
        )
    },
    {
      title: "Vendor's work",
      dataIndex: "vendor_work",
      key: "vendor_work",
      ellipsis: true,
    },
    {
      title: "Reason for Blacklisted",
      dataIndex: "reason_for_blacklist",
      key: "reason_for_blacklist",
      ellipsis: true,
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: { key: any }) => (
        <div className="flex gap-x-2 ">
          <Link to={`/admin/editblacklistedvendor/${record.key}`}>
            <button className="text-blue-600 cursor-pointer">Edit</button>
          </Link>

          <Link to={`/admin/viewvendor/${record.key}`}>
            <button className="text-yellow-400 cursor-pointer">View</button>
          </Link>

          <Popconfirm
            title="Sure to delete?"
            onConfirm={(e) => onDeleteRecord(record.key, e)}
          >
            <span className="text-red-500 cursor-pointer">Delete</span>
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div className="">
      <Link to="/admin/createblacklistedvendor">
        <button className="bg-blue-600 text-white p-3 mx-3">
          Add Blacklist Vendor
        </button>
      </Link>
      {selectedRowKeys.length > 1 && (
        <>
          <Popconfirm
            title={`Do you really wanted to delete ${selectedRowKeys.length} items`}
            onConfirm={() => deleteAllList()}
          >
            {selectedRowKeys.length > 1 ? (
              <Button danger type="primary">
                Delete All ({selectedRowKeys.length})
              </Button>
            ) : (
              ""
            )}
          </Popconfirm>
        </>
      )}

      <h1 className="text-center">List Of Blacklisted Vendors</h1>
      <div>
        <Input.Search
          size="large"
          placeholder="search here..."
          style={{ marginBottom: 8, width: 280 }}
          onSearch={(value) => {
            setSearchedText(value);
          }}
          onChange={(e) => {
            setSearchedText(e.target.value);
          }}
        />
        <Table
          rowSelection={rowSelection}
          dataSource={datas}
          columns={columns}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(BlackListed);
