import React from "react";
import { Button, Image, Input, Popconfirm, Table, Typography } from "antd";
import EditProduction from "./EditFreelancer";
import { ShouldRender } from "src/components/ShouldRender";
import { IEvent, IDeployedFreelancer, IEventProdDecor } from "src/types";
import moment from "moment";
import { TIME_FORMATS } from "src/constants";
import { updateSingleEvent } from "src/api-core/Events";
import { clonseJSON } from "src/utils/json";
import EditFreelancer from "./EditFreelancer";
import { useParams } from "react-router-dom";
import {
  createFreelancerAssignee,
  deleteFreelancerAssignee,
  updateFreelancerAssignee,
} from "src/api-core/Freelancer";

interface Props {
  event?: IDeployedFreelancer;
  update: () => Promise<void>;
  loading: boolean;
}

export const FreelancerTable = ({ event, update, loading }: any) => {
  const { deployedfreelancers = [] } = event || {};
  const [open, setOpen] = React.useState(false);
  const [recordIndex, setRecordIndex] = React.useState<number>(-1);
  const [searchedText, setSearchedText] = React.useState("");
  const { id } = useParams();

  const handleEdit = (index: number) => {
    setOpen(true);
    setRecordIndex(index);
  };

  const handleClose = () => {
    setOpen(false);
    setRecordIndex(-1);
  };

  const handleOpen = () => setOpen(true);

  // console.log("recordIndex - ", recordIndex);

  const handleUpdate = (data: Partial<IEvent>) => {
    if (event) {
      const {
        event_id,
        freelancer_id,
        department_type,
        hours_worked,
        expected_working_hours,
        _id,
      } = deployedfreelancers[recordIndex];

      

      const payload: any = {
        event_id,
        freelancer_id: event?.deployedfreelancers[recordIndex].freelancer_id,
        hours_worked,
        expected_working_hours,
        department_type:
          event?.deployedfreelancers[recordIndex].department_type,

        ...data,
      };

      delete payload?._id;
      delete payload.freelancer_name;

      if (recordIndex >= 0) {
        return updateFreelancerAssignee(payload, _id)
          .then(update)
          .then(handleClose);
      } else {
        return createFreelancerAssignee(payload).then(update).then(handleClose);
      }
    }
  };

  const handleSave = (values: IDeployedFreelancer) => {
    let updatedProduction = clonseJSON(deployedfreelancers);
    if (recordIndex === -1) {
      updatedProduction.push(values);
    } else {
      updatedProduction[recordIndex] = values;
    }

    const data = {
      deployedfreelancers: updatedProduction.map(
        (item: IDeployedFreelancer[]) => ({
          // event_id: id,
          ...item,
        })
      ),
    };

    data.deployedfreelancers[data.deployedfreelancers.length - 1].event_id = id;

    if (recordIndex == -1)
      return createFreelancerAssignee(
        data.deployedfreelancers[data.deployedfreelancers.length - 1]
      )
        .then(update)
        .then(handleClose);

    handleUpdate(data.deployedfreelancers[recordIndex])
      ?.then(update)
      .then(handleClose);
  };

  const handleDelete = (index: any) => {
    const ids = [index];

    deleteFreelancerAssignee(ids).then(update).then(handleClose);
  };

  const defaultColumns = [
    {
      title: "Freelancer Name",
      dataIndex: "freelancer_name",
      key: "freelancer_name",
    },
    {
      title: "Department Name",
      dataIndex: "department_type",
      key: "department_type",
    },
    {
      title: "Hours worked",
      dataIndex: "hours_worked",
      key: "hours_worked",
    },
    {
      title: "Expected Working Hours",
      dataIndex: "expected_working_hours",
      key: "expected_working_hours",
      filteredValue: [searchedText],
      onFilter: (value: any, record: any) => {
        return (
          String(record.freelancer_name)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.department_type)
            .toLowerCase()
            .includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: IDeployedFreelancer) => {
        const index = deployedfreelancers.findIndex(
          (item: any) => JSON.stringify(item) === JSON.stringify(record)
        );

        return (
          <span>
            <Typography.Link
              onClick={() => handleEdit(index)}
              style={{ marginRight: 8 }}
            >
              Edit
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record._id)}
            >
              <Typography.Link>Delete</Typography.Link>
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="flex justify-end mb-3">
        <Button onClick={handleOpen}>Add Data</Button>
      </div>
      <Input.Search
        size="large"
        placeholder="search here..."
        style={{ marginBottom: 8, width: 280 }}
        onSearch={(value) => {
          setSearchedText(value);
        }}
        onChange={(e) => {
          setSearchedText(e.target.value);
        }}
      />
      <Table
        columns={defaultColumns}
        dataSource={deployedfreelancers}
        loading={loading}
      />
      <ShouldRender check={open}>
        <EditFreelancer
          open={open}
          data={deployedfreelancers[recordIndex]}
          title={recordIndex === -1 ? "Add Freelancer" : "Edit Freelancer"}
          handleClose={handleClose}
          handleSave={handleSave}
        />
      </ShouldRender>
    </React.Fragment>
  );
};
