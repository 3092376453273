import React, { useEffect, useState } from "react";
import { Table } from "antd";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link, useParams } from "react-router-dom";
import { deleteHotel, getAllHotel } from "../../../api-core/Hotel";
import {
  deleteVendorCar,
  getAllVendorDriver,
  getSingleVendorDriver,
} from "../../../api-core/Vendor";

const SingleVendorDriver = () => {
  const { id } = useParams();
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    const fetchSingleVendorDriver = async () => {
      setloading(true);
      const res = await getSingleVendorDriver(id);
      console.log("res", res.vendordriver);

      setdata(res.vendordriver);
      setloading(false);
    };
    fetchSingleVendorDriver();
  }, []);

  const datas = data.map((item) => ({
    key: item._id,
    vendorName: item.vendor_id.vendor_name,
    vendorType: item.vendor_id.vendor_type,
    driverName: item.driver_name,
    driverEmail: item.driver_email,
    driverMobile: item.driver_mobile,
    driverAddress: item.driver_add,
  }));
  // console.log("datas", datas);

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Vendor Name",
      dataIndex: "vendorName",
      key: "vendorName",
      ellipsis: true,
    },
    {
      title: "Vendor Type",
      dataIndex: "vendorType",
      key: "vendorType",
      ellipsis: true,
    },
    {
      title: "Driver Name",
      dataIndex: "driverName",
      key: "driverName",

      ellipsis: true,
    },
    {
      title: "Driver Email",
      dataIndex: "driverEmail",
      key: "driverEmail",

      ellipsis: true,
    },
    {
      title: "Driver Mobile",
      dataIndex: "driverMobile",
      key: "driverMobile",

      ellipsis: true,
    },
    {
      title: "Driver Address",
      dataIndex: "driverAddress",
      key: "driverAddress",

      ellipsis: true,
    },
  ];
  return (
    <div className="">
      <h1 className="text-center">List Of Vendor Driver</h1>
      <div className="">
        <Table
          dataSource={datas}
          columns={columns}
          loading={loading}
          // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        />
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(SingleVendorDriver);
