import React from "react";
import { Button, Input, Popconfirm, Table, Typography } from "antd";
import EditGuest, { Guest } from "./EditGuest";
import { ShouldRender } from "src/components/ShouldRender";
import {
  createGuestlist,
  deleteGuestlist,
  updateGuestlist,
} from "src/api-core/Ghms";
import { IArrival, IEvent, IGuestList } from "src/types";
import moment from "moment";
import { TIME_FORMATS } from "src/constants";

interface Props {
  event: IEvent | undefined;
  guests: IGuestList[] | undefined;
  loading: boolean;
  update: () => Promise<void>;
}

export const GuestTable = ({ event, guests = [], loading, update }: Props) => {
  const [open, setOpen] = React.useState(false);
  const [recordIndex, setRecordIndex] = React.useState(-1);
  const [searchedText, setSearchedText] = React.useState("");

  const handleEdit = (index: number) => {
    setOpen(true);
    setRecordIndex(index);
  };

  const handleClose = () => {
    setOpen(false);
    setRecordIndex(-1);
  };

  const handleOpen = () => setOpen(true);

  const handleSave = async (values: Guest) => {
    const guestDetail = guests[recordIndex];    
    
    if (recordIndex === -1) {
      if (event) {        
        const { client_id, _id: event_id } = event;
        const payload = {
          client_id,
          event_id,
          ...values,
        };        
        createGuestlist(payload).then(update).then(handleClose).catch(err => console.log(err));
      }
    } else {
      const guestDetail = guests[recordIndex];
      
      // const { guest_add, guest_email, guest_mobile, guest_name, guest_expected_nos, guest_invitation_type, guest_invited, guest_date_of_arrival } = values;
      const {
        client_id,
        event_id,
        // guest_date_of_arrival,
        // guest_expected_nos,
        // guest_invited,
        // guest_invitation_type,
        // guest_outstation,
        _id,
      } = guestDetail;
      const payload = {
        client_id,
        event_id,
        ...values
        // guest_add,
        // guest_email,
        // guest_mobile,
        // guest_name,
        // guest_date_of_arrival,
        // guest_expected_nos,
        // guest_invited,
        // guest_invitation_type,
        // guest_outstation,
      };
      updateGuestlist(payload, _id).then(update).then(handleClose);
    }
  };

  const handleDelete = (id: string) => {
    const ids = [id];
    deleteGuestlist(ids).then(update);
  };

  const defaultColumns = [
    {
      title: "Name",
      dataIndex: "guest_name",
      key: "guest_name",
    },
    // {
    //   title: "Email",
    //   dataIndex: "guest_email",
    //   key: "guest_email",
    // },
    {
      title: "Mobile",
      dataIndex: "guest_mobile",
      key: "guest_mobile",
      filteredValue: [searchedText],
      onFilter: (value:any, record:any)=>{
        return  String(record.guest_name)
                .toLowerCase()
                .includes(value.toLowerCase()) ||
                // String(record.guest_email)
                // .toLowerCase()
                // .includes(value.toLowerCase()) ||
                String(record.guest_mobile)
                .includes(value)
      },
      render: (guest_mobile: IGuestList) => (
        <a href={`tel:${guest_mobile}`}> {`${ guest_mobile}`}</a>
      )
    },
    {
      title: "OutStation",
      dataIndex: "guest_outstation",
      key: "guest_outstation",
    },
    {
      title: "Digital Invitation",
      dataIndex: "digital_invitation",
      key: "digital_invitation",
      render: (value: any) => (
        <p>
          { value ? "Yes" : "No" }
        </p>
      )
    },
    {
      title: "Notes",
      dataIndex: "notes",
      key: "notes",
    },

    // {
    //   title: "Date of arrival",
    //   dataIndex: "guest_date_of_arrival",
    //   key: "guest_date_of_arrival",
    //   render: (_: any, record: IArrival) => {
    //     // @ts-ignore
    //     return moment(record.guest_date_of_arrival).format(TIME_FORMATS.DATE);
    //   },
    // },
    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: any) => {
        const index = guests.findIndex((guest) => guest._id === record._id);
        return (
          <span>
            <Typography.Link
              disabled={recordIndex !== -1}
              onClick={() => handleEdit(index)}
              style={{ marginRight: 8 }}
            >
              Edit
            </Typography.Link>
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => handleDelete(record._id)}
            >
              <Typography.Link>Delete</Typography.Link>
            </Popconfirm>
          </span>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <div className="flex justify-end mb-3">
        <Button onClick={handleOpen}>Add Data</Button>
      </div>
      <Input.Search size="large" placeholder="search here..."
             style={{marginBottom: 8, width: 280}}
             onSearch={(value)=>{
              setSearchedText(value);
             }}
             onChange={(e)=>{
              setSearchedText(e.target.value);
             }}/>
      <Table
        // @ts-ignore: Object is possibly 'null'
        columns={defaultColumns}
        dataSource={guests}
        key="_id"
        loading={loading}
      />
      <ShouldRender check={open}>
        <EditGuest
          open={open}
          guest={guests[recordIndex]}
          title={recordIndex === -1 ? "Add Guestlist" : "Edit Guestlist"}
          handleClose={handleClose}
          handleSave={handleSave}
        />
      </ShouldRender>
    </React.Fragment>
  );
};
