import { DatePicker, Modal, Select } from "antd";
import { ErrorMessage, Formik } from "formik";
import { FormInput } from "src/components/FormInput";
import * as Yup from "yup";
import { GuestOutstation, IGuestList, IOutstation } from "src/types";
import { Label } from "src/components/Label";
import moment from "moment";

export type Outstation = Omit<IOutstation, "_id" | "client_id" | "event_id">;

interface Props {
  handleClose: () => void;
  handleSave: (values: Outstation) => void;
  outstation?: Outstation;
  guests: IGuestList[];
  open: boolean;
  title: string;
}

const validationSchema = Yup.object({
  guest_id: Yup.string().required("Please select Guest"),
  date_and_time: Yup.string().required("Date of arrival is required"),
  arrival_location: Yup.string().min(3, "must be atleast 3 chars").required("Outstation location at is required"),
  notes: Yup.string().min(3, "must be atleast 3 chars"),
});

const EditOutstation = ({
  handleClose,
  handleSave,
  outstation,
  guests,
  open,
  title
}: Props) => {
  const initalValues = {
    guest_id: outstation?.guest_id || "",
    date_and_time: outstation?.date_and_time
      ? moment(outstation?.date_and_time)
      : undefined,
    arrival_location: outstation?.arrival_location || "",
    notes: outstation?.notes || ""
  };
  return (
    <Formik
      initialValues={initalValues}
      validationSchema={validationSchema}
      onSubmit={(values: any) => {
        handleSave(values)
      }}
    >
      {({ handleSubmit, values, setFieldValue, errors }) => {
        return (
          <Modal
            centered
            open={open}
            title={title}
            onCancel={handleClose}
            onOk={() => handleSubmit()}
          >
            <div className="mb-3">
              <Label required text="Guest" />
              <Select
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                placeholder="Select Guest"
                onChange={(id) => setFieldValue("guest_id", id)}
                value={values.guest_id}
              >
                {guests?.filter((guest) => guest.guest_outstation === GuestOutstation.Outstation).map((guest) => (
                  <Select.Option key={guest._id} value={guest._id}>
                    {guest.guest_name}
                  </Select.Option>
                ))}
              </Select>
              {errors.guest_id && (
                <div className="my-1 text-xs text-red-500">
                  <ErrorMessage name="guest_id" />
                </div>
              )}
            </div>
            <div className="mb-3">
              <Label required text="Date and Time" />
              <DatePicker
                showTime
                disabledDate={(current) => current.isBefore(moment().subtract(1,"day"))}
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                value={values.date_and_time}
                onChange={(value) => setFieldValue("date_and_time", value)}
              />
              {errors.date_and_time && (
                <div className="my-1 text-xs text-red-500">
                  <ErrorMessage name="date_and_time" />
                </div>
              )}
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: true, text: "Arrival Location" }}
                name="arrival_location"
                placeholder="enter arrival location"
              />
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ text: "Notes" }}
                name="notes"
                placeholder="enter notes"
              />
            </div>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default EditOutstation;
