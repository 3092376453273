import React, { useEffect, useState } from "react";
import { getsingleOfferBanner } from "../../../api-core/OfferBanner";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link, useHistory, useParams } from "react-router-dom";
import { Table } from "antd";

import { getSingleEvent } from "../../../api-core/Events";

const SingleEvent = () => {
  const { id } = useParams();
  console.log("parms", id);
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    const fetchSingleEvent = async () => {
      setloading(true);
      const res = await getSingleEvent(id);
      console.log("res", res.event[0]);

      setdata(res.event);
      setloading(false);
    };
    fetchSingleEvent();
  }, []);

  const datas = data.map((item) => ({
    key: item._id,
    eventTitle: item.event_title,
    eventDescp: item.event_descp,
    eventPhoto: item.event_img,
  }));
  // console.log("datas", datas);

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Event Title",
      dataIndex: "eventTitle",
      key: "eventTitle",
    },
    {
      title: "Event Descp",
      dataIndex: "eventDescp",
      key: "eventDescp",
      ellipsis: true,
    },

    {
      title: "Event Photo",
      dataIndex: "eventPhoto",
      key: "eventPhoto",
      render: (_, record) => (
        <div className="w-10 h-10">
          <img src={record.eventPhoto} />
        </div>
      ),
    },
  ];
  return (
    <div>
      SingleFreelancer
      <Table dataSource={datas} columns={columns} loading={loading} />
    </div>
  );
};

export default SidebarHeaderLayout(SingleEvent);
