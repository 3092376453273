import { useEffect, useState } from "react";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { useParams } from "react-router-dom";
import {
  createHotelRoomType,
  getAllHotel,
  getSingleHotelRoomType,
} from "../../../api-core/Hotel";
const UpdateHotelRoomType = () => {
  const { id } = useParams();
  const [hotels, setHotels] = useState([]);
  const [inputs, setInputs] = useState({
    hotel_id: "",
    beds: "",
    max_occupancy: "",
    room_type_name: "",
    cost_per_night: "",
  });

  useEffect(() => {
    const fetchAllHotel = async () => {
      const res = await getAllHotel();
      setHotels(res.hotel);
    };
    fetchAllHotel();
  }, []);

  useEffect(() => {
    const fetchSingleHotelRoomType = async (id) => {
      const res = await getSingleHotelRoomType(id);
      // console.log(res.hotelroomtype);
      setInputs({
        hotel_id: res.hotelroomtype[0].hotel_id._id,
        beds: res.hotelroomtype[0].beds,
        max_occupancy: res.hotelroomtype[0].max_occupancy,
        room_type_name: res.hotelroomtype[0].room_type_name,
        cost_per_night: res.hotelroomtype[0].cost_per_night,
      });
    };
    if (id) fetchSingleHotelRoomType(id);
  }, [id]);

  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("inputs", inputs);

    if (inputs) {
      const res = await createHotelRoomType(inputs);
      console.log("resHotelRoom", res);
    } else {
      console.log("error");
    }
  };
  return (
    <div className="w-[90%] mx-auto ">
      <h1>Update Hotel Room Type</h1>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="flex flex-col flex-wrap items-center justify-center md:justify-between md:flex-row gap-x-5">
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Hotel Id
            </label>
            <select
              value={inputs.hotel_id}
              name="hotel_id"
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              {hotels.map((value) => (
                <option value={value._id} key={value._id}>
                  {value.hotel_name}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Beds
            </label>
            <input
              type="text"
              name="beds"
              value={inputs.beds}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter Beds"
            />
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Max Occupancy
            </label>
            <input
              type="number"
              name="max_occupancy"
              value={inputs.max_occupancy}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter Occupancy"
            />
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Room Type
            </label>
            <input
              type="text"
              name="room_type_name"
              value={inputs.room_type_name}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter RoomType"
            />
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Room Cost Per Night
            </label>
            <input
              type="number"
              name="cost_per_night"
              value={inputs.cost_per_night}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Room Cost"
            />
          </div>
        </div>
        <button
          type="submit"
          className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default SidebarHeaderLayout(UpdateHotelRoomType);
