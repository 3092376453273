import React, { useEffect, useState } from 'react'
import SidebarHeaderLayout from 'src/components/HOC/SidebarHeaderLayout'
import CarForm from './CarForm'
import { useParams } from 'react-router-dom'
import { IVendorCar } from 'src/types'
import { getSingleVendorCar } from 'src/api-core/Vendor'
import { Spin } from 'antd'

const EditCar = () => {
  const {id} = useParams()
  const[isLoading, setIsLoading] = useState(false)
  const[car, setCar] = useState<IVendorCar>()

  useEffect(() => {
    const fetchCar = async () => {
      setIsLoading(true);
      const res = await getSingleVendorCar(id);      
      setCar(res?.vendorcar[0]);
      setIsLoading(false);
    };
    fetchCar();
  },[id])

  if(isLoading){
    return (
      <div className="flex justify-center items-center h-[80vh]">
        <Spin />
      </div>
    )
  }
  
  return (
    <div>
      <CarForm 
        title="Edit Car" 
        car={car}
      />
    </div>
  )
}

export default SidebarHeaderLayout(EditCar)