import React, { useEffect, useState } from "react";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { createVendor } from "../../../api-core/Vendor";
import { getAllUser } from "../../../api-core/User";
import { getAllSubEvents } from "../../../api-core/Events";
import { useFormik } from "formik";
import * as Yup from "yup";
const CreateVendorProdDecor = () => {
  const [clientData, setClientData] = useState([]);
  const [currentEventData, setCurrentEventData] = useState([]);
  const [prodDecorImg, setProdDecorImg] = useState("");
  useEffect(() => {
    const fetchAllUser = async () => {
      const res = await getAllUser();
      setClientData(res.user);
      // console.log("eventData", res);
    };
    fetchAllUser();
  }, []);
  useEffect(() => {
    const fetchAllSubEvents = async () => {
      const res = await getAllSubEvents();
      setCurrentEventData(res.subevent);
      // console.log("eventData", res);
    };
    fetchAllSubEvents();
  }, []);
  const initialValues = {
    client_id: "",
    sub_event_id: "",
    img_title: "",
    decor_date: "",
    expected_decor_time: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      client_id: Yup.string().required("Dept Name is required"),
      sub_event_id: Yup.string().required("Event Name is required"),
      img_title: Yup.string().required("image name required"),
      decor_date: Yup.string().required("Decor date is required"),
      expected_decor_time: Yup.string().required(
        "Expected Decor time is required"
      ),
    }),
    onSubmit: async (values, action) => {
      console.log("values", values);
      const formData = new FormData();
      formData.append("prod_decor_img", prodDecorImg);
      formData.append("client_id", values.client_id);
      formData.append("sub_event_id", values.sub_event_id);
      formData.append("img_title", values.img_title);
      formData.append("decor_date", values.decor_date);
      formData.append("expected_decor_time", values.expected_decor_time);
      // if (formData) {
      //   const res = await createFreelancer(formData);
      //   // console.log("resFormData", res);
      //   if (res.success === true) {
      //     message.success("Submitted Successfully");
      //     action.resetForm();
      //   }
      // } else {
      //   console.log("error");
      // }
    },
  });

  return (
    <div className="w-[90%] mx-auto ">
      <h1>Create Vendor Food Bev</h1>
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <div className="flex flex-col justify-center items-center  md:justify-between md:flex-row gap-x-5 flex-wrap">
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Client Name
            </label>
            <select
              value={formik.values.client_id}
              name="client_id"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={""}>Select Client Name</option>
              {clientData.map((value) => (
                <option value={value._id} key={value._id}>
                  {value.name}
                </option>
              ))}
            </select>
            {formik.errors.client_id && (
              <span className="text-red-500">{formik.errors.client_id}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Current Event Name
            </label>
            <select
              value={formik.values.sub_event_id}
              name="sub_event_id"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={""}>Select Current Event Name</option>
              {currentEventData.map((value) => (
                <option value={value.event_id._id} key={value._id}>
                  {value.event_id.event_title}
                </option>
              ))}
            </select>
            {formik.errors.sub_event_id && (
              <span className="text-red-500">{formik.errors.sub_event_id}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Decor Date
            </label>
            <input
              type="date"
              name="decor_date"
              value={formik.values.decor_date}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter Vendor name"
            />
            {formik.errors.decor_date && (
              <span className="text-red-500">{formik.errors.decor_date}</span>
            )}
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Expected Decor Time
            </label>
            <input
              type="time"
              name="expected_decor_time"
              value={formik.values.expected_decor_time}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter vendor add"
            />
            {formik.errors.expected_decor_time && (
              <span className="text-red-500">
                {formik.errors.expected_decor_time}
              </span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Image Title
            </label>
            <input
              type="text"
              name="img_title"
              value={formik.values.img_title}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter Vendor Mobile"
            />
            {formik.errors.img_title && (
              <span className="text-red-500">{formik.errors.img_title}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Prod Decor Image
            </label>
            <input
              type="file"
              name="prod_decor_img"
              onChange={(e) => setProdDecorImg(e.target.files[0])}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Prod Decor Image"
              required
            />
          </div>
        </div>
        <button
          type="submit"
          className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default SidebarHeaderLayout(CreateVendorProdDecor);
