import { useFormik } from 'formik';
import * as Yup from "yup";
import React, { useState } from 'react'
import { IVendorCar } from 'src/types'
import { Link, useNavigate } from 'react-router-dom';
import { createVendorCar, updateVendorCar } from 'src/api-core/Vendor';
import { message } from 'antd';

const CarForm = ({ title, car }: { title: string, car?: IVendorCar | null }) => {

  const[isLoading, setIsLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  
  const initialValues: IVendorCar = {
    owner_name: car ? car.owner_name : '',
    car_model: car ? car.car_model : '',
    car_number: car ? car.car_number : '',
    car_reg: car ? car.car_reg : ''
  }
  
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      owner_name: Yup.string().required("owner name is required").min(3,"must contains 3 char"),
      car_model: Yup.string().required("car model is required").min(3, 'must contains 3 char'),
      car_reg: Yup.string().required("car reg is required").min(3, 'must contains 3 char'),
      car_number: Yup.string().required("car number is required").min(3, 'must contains 3 char'),
    }),
    onSubmit: async (values) => {
      setIsLoading(true)
      try {
        let data
        if(car) {
          data = await updateVendorCar(values, car._id);
          if(!data.success) return message.error("Failed to update!")
          message.success("Updated!")
        } else {
          data = await createVendorCar(values);
          if(!data.success) return message.error("Failed to create!")
          message.success("Created!")
        }
        navigate("/admin/cars")
      } catch (error) {
        message.error("An unexpected error occurred!")
      } finally {
        setIsLoading(false)
      }
    },
  });

  return (
    <div className="w-[90%] mx-auto ">
      <h1>{title}</h1>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col justify-center items-center  md:justify-between md:flex-row gap-x-5 flex-wrap">
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Owner Name
            </label>
            <input
              type="text"
              name="owner_name"
              value={formik.values.owner_name}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="e.g Deepak"
            />
            {formik.errors.owner_name && (
              <span className="text-red-500">{formik.errors.owner_name}</span>
            )}
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Car Model
            </label>
            <input
              type="text"
              name="car_model"
              value={formik.values.car_model}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="e.g Ford Figo"
            />
            {formik.errors.car_model && (
              <span className="text-red-500">{formik.errors.car_model}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Car Registration
            </label>
            <input
              type="text"
              name="car_reg"
              value={formik.values.car_reg}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="e.g Bhopal"
            />
            {formik.errors.car_reg && (
              <span className="text-red-500">{formik.errors.car_reg}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Car Number
            </label>
            <input
              type="text"
              name="car_number"
              value={formik.values.car_number}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="e.g MP89 FG0987"
            />
            {formik.errors.car_number && (
              <span className="text-red-500">{formik.errors.car_number}</span>
            )}
          </div>
        </div>
        <Link to={"/admin/cars"}>
          <button 
            type="button"
            className="mr-2 text-white bg-red-600 disabled:bg-gray-300 disabled:cursor-not-allowed hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
            disabled={isLoading}
          >
            Cancel
          </button>
        </Link>
        <button
          type="submit"
          className="text-white bg-blue-600 disabled:bg-gray-300 disabled:cursor-not-allowed hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          disabled={isLoading}
        >
          {isLoading ? "Submitting" : "Submit"}
        </button>
      </form>
    </div>
  );
}

export default CarForm