import React, { useEffect, useState } from "react";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import {
  createPermission,
  createPermissionRole,
  getAllPermissions,
  getAllRoles,
} from "../../../api-core/RolesPermissions";
import { message } from "antd";
const CreatePermissionRole = () => {
  const [roleData, setRoleData] = useState([]);
  const [permData, setPermData] = useState([]);
  const [loading, setloading] = useState(false);
  const [inputs, setInputs] = useState({
    role_id: "",
    perm_id: "",
  });
  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    const fetchAllRoles = async () => {
      setloading(true);
      const res = await getAllRoles();
      setRoleData(res.role);
      setloading(false);
    };
    fetchAllRoles();
  }, []);

  useEffect(() => {
    const fetchAllPermissions = async () => {
      setloading(true);
      const res = await getAllPermissions();

      setPermData(res.permission);
      setloading(false);
    };
    fetchAllPermissions();
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (inputs) {
      const res = await createPermissionRole(inputs);
      if (res.success == true) {
        message.success("Created Successfully");
      }
    } else {
      console.log("error");
    }
  };
  return (
    <div className="w-[90%] mx-auto ">
      <h1>Create Permission Role</h1>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="flex flex-col justify-center items-center  md:justify-between md:flex-row gap-x-5 flex-wrap">
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Role Name
            </label>
            <select
              value={inputs.role_id}
              name="role_id"
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              {roleData.map((value) => (
                <option value={value._id} key={value._id}>
                  {value.role_name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Permission Name
            </label>
            <select
              value={inputs.perm_id}
              name="perm_id"
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              {permData.map((value) => (
                <option value={value._id} key={value._id}>
                  {value.perm_name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <button
          type="submit"
          className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default SidebarHeaderLayout(CreatePermissionRole);
