import React, { useEffect, useState } from "react";
import { Button, Input, message, Popconfirm, Table } from "antd";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link } from "react-router-dom";
import {
  deletePriotization,
  getAllPriotization,
  getAllPriotizationPages,
} from "../../../api-core/Priotization";
import { formatDate } from "../../../utils/helper";

const ListOfPriotization = () => {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [row, setRow] = useState(10);
  const [pages, setPages] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchedText, setSearchedText] = useState("");

  useEffect(() => {
    fetchAllPriotization();
  }, [row, pages]);
  const fetchAllPriotization = async () => {
    setloading(true);
    const res = await getAllPriotizationPages(row, pages);
    setRow(res?.count);

    setdata(res.priortizationlist);
    setloading(false);
  };

  const onDeleteRecord = async (record, id) => {
    const recordId = {
      ids: [record],
    };
    const res = await deletePriotization(recordId);
    if (res.success == true) {
      message.success(res.msgText);
      fetchAllPriotization();
      // const updatedData = data.filter((items)=>items._id !== record)
      // setdata(updatedData)
    }
  };

  const datas = data?.map((item) => ({
    key: item._id,
    client_name: item.client_name,
    event_name: item.event_name,
    title: item.title,
    // descp: item.descp,
    contact: item.contact,
    deadline_date: formatDate(item.deadline_date, "DD-MM-YYYY"),
  }));

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const deleteAllList = async () => {
    if (selectedRowKeys) {
      const deletePayload = {
        ids: [...selectedRowKeys],
      };
      if (deletePayload) {
        const res = await deletePriotization(deletePayload);

        if (res.success == true) {
          message.success(
            `${selectedRowKeys.length} items deleted successfully`
          );
          setSelectedRowKeys(0);
          fetchAllPriotization();
        }
      }
    }
  };

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Client Name",
      dataIndex: "client_name",
      key: "client_name",
      ellipsis: true,
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          String(record.client_name)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.event_name)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.title).toLowerCase().includes(value.toLowerCase()) ||
          String(record.contact).toLowerCase().includes(value.toLowerCase())
        );
      },
    },
    {
      title: "Event Name",
      dataIndex: "event_name",
      key: "event_name",
      ellipsis: true,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      ellipsis: true,
    },
    // {
    //   title: "Description",
    //   dataIndex: "descp",
    //   key: "descp",
    //   ellipsis: true,
    // },
    {
      title: "Contact Number",
      dataIndex: "contact",
      key: "contact",
      render: (contact) => (
        <a href={`tel:${contact}`} >{contact}</a>
      )
    },
    {
      title: "Deadline Date",
      dataIndex: "deadline_date",
      key: "deadline_date",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex gap-x-2 ">
          <Link to={`/admin/singlepriotization/${record.key}`}>
            <button className="text-blue-600 cursor-pointer">View</button>
          </Link>

          <Link to={`/admin/updatepriotization/${record.key}`}>
            <button className="text-yellow-400 cursor-pointer">Edit</button>
          </Link>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={(e) => onDeleteRecord(record.key, e)}
          >
            <span className="text-red-500 cursor-pointer">Delete</span>
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div className="">
      <Link to="/admin/createprioritisation">
        <button className="bg-blue-600 text-white p-3 mx-3">
          Add Prioritisation
        </button>
      </Link>
      {selectedRowKeys.length > 1 && (
        <>
          <Popconfirm
            title={`Do you really wanted to delete ${selectedRowKeys.length} items`}
            onConfirm={() => deleteAllList()}
          >
            {selectedRowKeys.length > 1 ? (
              <Button danger type="primary">
                Delete All ({selectedRowKeys.length})
              </Button>
            ) : (
              ""
            )}
          </Popconfirm>
        </>
      )}
      <h1 className="text-center">List Of Prioritisation</h1>
      <Input.Search
        size="large"
        placeholder="search here..."
        style={{ marginBottom: 8, width: 280 }}
        onSearch={(value) => {
          setSearchedText(value);
        }}
        onChange={(e) => {
          setSearchedText(e.target.value);
        }}
      />
      <Table
        rowSelection={rowSelection}
        dataSource={datas}
        columns={columns}
        loading={loading}
        // size="middle"
        // pagination={{ total: row, pageSize: 10 }}
      />
    </div>
  );
};

export default SidebarHeaderLayout(ListOfPriotization);
