import React, { useEffect, useState } from "react";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { Upload, message, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import {
  updateEventPhoto,
  getAllEvents,
  getSingleSubEventPhoto,
} from "../../../api-core/Events";
import { formatDate } from "../../../utils/helper";

const UpdateEventPhoto = () => {
  const { id } = useParams();
  const [deleteImages, setDeleteImages] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [loading, setloading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [initialValues, setInitialValues] = useState({
    event_id: "",
    ep_title: "",
    ep_descp: "",
    event_date: "",
    deleted_img: "",
  });
  useEffect(() => {
    const fetchAllEvents = async () => {
      const res = await getAllEvents(1, 1000);
      setEventData(res.event);
      // console.log("subeventData", res);
    };
    fetchAllEvents();
  }, []);
  useEffect(() => {
    const fetchSingleEventPhoto = async () => {
      setloading(true);
      const res = await getSingleSubEventPhoto(id);
      setInitialValues({
        event_id: res?.eventphoto[0].event_id?._id || "",
        ep_title: res?.eventphoto[0].ep_title || "",
        ep_descp: res?.eventphoto[0].ep_descp || "",
        event_date:
          formatDate(res?.eventphoto[0].event_date, "yyyy-MM-DD") || "",
      });
      const uploadedEventPhotos = res?.eventphoto[0].ep_img || "";
      const previewEventPhotos = uploadedEventPhotos.map((photo) => {
        const name = photo.filename;
        const status = "done";
        const url = photo.file;
        return { name, status, url };
      });
      setFileList(previewEventPhotos);
      setloading(false);
    };
    fetchSingleEventPhoto();
  }, [id]);

  const getBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const uploadProps = {
    accept: "image/*",
    multiple: true,
    maxCount: 15,
    listType: "picture-card",
    fileList: fileList,
    beforeUpload: (file) => {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error(`${file.name} must smaller than 2MB!`);
        return Upload.LIST_IGNORE;
      }
      return false;
    },
    onPreview: handlePreview,
    onRemove: (file) => {
      if (!file.originFileObj) {
        setDeleteImages((current) => [...current, file.name]);
      }
    },
    onChange: ({ fileList: newFileList }) => {
      setFileList(newFileList);
    },
  };

  const navigate = useNavigate();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: Yup.object({
      event_id: Yup.string().required("Event Name is required"),
      ep_title: Yup.string()
        .required("Event photo title is required")
        .min(3, "must contain atleast 3 chars"),
      ep_descp: Yup.string()
        .required("Event Description is required")
        .min(3, "must contain atleast 3 chars"),
      event_date: Yup.string().required("Event photo date is required"),
    }),
    onSubmit: async (values, action) => {
      const formData = new FormData();

      if (fileList.length === 0) {
        message.error("File is required!");
      } else {
        for (let i = 0; i < fileList.length; i++) {
          if (fileList[i]["originFileObj"]) {
            formData.append("ep_img", fileList[i]["originFileObj"]);
          }
        }
        for (let i = 0; i < deleteImages.length; i++) {
          formData.append("deleted_img[]", deleteImages[i]);
        }
        formData.append("event_id", values.event_id);
        formData.append("ep_title", values.ep_title);
        formData.append("ep_descp", values.ep_descp);
        formData.append("event_date", values.event_date);

        if (formData) {
          const res = await updateEventPhoto(formData, id);
          if (res.success === true) {
            message.success("Submitted Successfully");
            action.resetForm();
            navigate("/admin/listofalleventphoto");
          } else {
            message.error(res.message);
          }
          // action.resetForm();
        } else {
          console.log("error");
        }
      }
    },
  });

  return (
    <div className="w-[90%] mx-auto ">
      <h1>Update Event Photo</h1>
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <div className="flex flex-col justify-center items-center  md:justify-between md:flex-row gap-x-5 flex-wrap">
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Event Name
            </label>
            <select
              value={formik.values.event_id}
              name="event_id"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              {eventData?.map((event) => (
                <option value={event._id} key={event._id}>
                  {event.event_title}
                </option>
              ))}
            </select>
            {formik.errors.event_id && (
              <span className="text-red-500">{formik.errors.event_id}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Event Photo Title
            </label>
            <input
              type="text"
              name="ep_title"
              value={formik.values.ep_title}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter event photo title"
            />
            {formik.errors.ep_title && (
              <span className="text-red-500">{formik.errors.ep_title}</span>
            )}
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Event Photo Description
            </label>
            <textarea
              type="text"
              name="ep_descp"
              value={formik.values.ep_descp}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter event photo desc"
            />
            {formik.errors.ep_descp && (
              <span className="text-red-500">{formik.errors.ep_descp}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Event Date
            </label>
            <input
              type="date"
              name="event_date"
              value={formik.values.event_date}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter offer date"
              required
            />
            {formik.errors.event_date && (
              <span className="text-red-500">{formik.errors.event_date}</span>
            )}
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Event Photos
            </label>
            <Upload {...uploadProps}>
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            </Upload>
            <Modal
              open={previewOpen}
              title={previewTitle}
              footer={null}
              onCancel={handleCancel}
            >
              <img
                alt="example"
                style={{
                  width: "100%",
                }}
                src={previewImage}
              />
            </Modal>
          </div>
        </div>
        <Link to={"/admin/listofalleventphoto"}>
          {/* <h1 className="text-lg text-black">Cancel</h1> */}
          <button
            type="button"
            className="mr-2 text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
          >
            Cancel
          </button>
        </Link>
        <button
          type="submit"
          className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default SidebarHeaderLayout(UpdateEventPhoto);
