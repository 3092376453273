import React, { useEffect, useState } from "react";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import {
  createArrivalMgmnt,
  getAllGuestlist,
  getSingleDepartureMgmnt,
  updateDepartureMgmnt,
} from "../../../api-core/Ghms";
import {
  getAllVendorCar,
  getAllVendorDriver,
  getAllVendors,
} from "../../../api-core/Vendor";
import { useParams } from "react-router-dom";
const UpdateDepartureMgmnt = () => {
  const { id } = useParams();
  const [guestIdData, setGuestIdData] = useState([]);
  const [vendorData, setVendorData] = useState([]);
  const [vendorCarData, setVendorCarData] = useState([]);
  const [vendorDriverData, setVendorDriverData] = useState([]);
  const [guestData, setGuestData] = useState([]);
  const [loading, setloading] = useState(false);
  const [inputs, setInputs] = useState({
    sub_event_id: "",
    guest_id: "",
    date_of_departure: "",
    no_of_guest_arrived: "",
    mode_of_departure: "",
    departure_time: "",
    dropped_by: "",
    vendor_id: "",
    car_id: "",
    driver_id: "",
    return_checklist: "",
    special_note: "",
  });
  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    const fetchAllGuestList = async () => {
      setloading(true);
      const res = await getAllGuestlist();
      // console.log("res", res.ghmsguestlist);

      setGuestIdData(res.ghmsguestlist);
      setGuestData(res.ghmsguestlist);
      setloading(false);
    };
    fetchAllGuestList();
  }, []);

  useEffect(() => {
    const fetchAllVendors = async () => {
      setloading(true);
      const res = await getAllVendors();
      console.log("res", res.vendor);

      setVendorData(res.vendor);
      setloading(false);
    };
    fetchAllVendors();
  }, []);
  useEffect(() => {
    const fetchAllVendors = async () => {
      setloading(true);
      const res = await getAllVendorCar();
      console.log("res", res.vendorcar);

      setVendorCarData(res.vendorcar);
      setloading(false);
    };
    fetchAllVendors();
  }, []);
  useEffect(() => {
    const fetchAllVendorDriver = async () => {
      setloading(true);
      const res = await getAllVendorDriver();
      console.log("res", res.vendordriver);

      setVendorDriverData(res.vendordriver);
      setloading(false);
    };
    fetchAllVendorDriver();
  }, []);
  useEffect(() => {
    const fetchSingleDepartureMgmnt = async () => {
      setloading(true);
      const res = await getSingleDepartureMgmnt(id);
      console.log("res", res.ghmsdeparutremgmt);

      setInputs({
        sub_event_id: res.ghmsdeparutremgmt[0].sub_event_id._id,
        guest_id: res.ghmsdeparutremgmt[0].guest_id._id,
        date_of_departure: res.ghmsdeparutremgmt[0].date_of_departure,
        no_of_guest_arrived: res.ghmsdeparutremgmt[0].no_of_guest_arrived,
        mode_of_departure: res.ghmsdeparutremgmt[0].mode_of_departure,
        departure_time: res.ghmsdeparutremgmt[0].departure_time,
        dropped_by: res.ghmsdeparutremgmt[0].dropped_by,
        vendor_id: res.ghmsdeparutremgmt[0].vendor_id._id,
        car_id: res.ghmsdeparutremgmt[0]?.car_id?._id,
        driver_id: res.ghmsdeparutremgmt[0].driver_id._id,
        return_checklist: res.ghmsdeparutremgmt[0].return_checklist,
        special_note: res.ghmsdeparutremgmt[0].special_note,
      });
      setloading(false);
    };
    fetchSingleDepartureMgmnt();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("inputs", inputs);

    if (inputs) {
      const res = await updateDepartureMgmnt(inputs, id);
      console.log("resFormData", res);
    } else {
      console.log("error");
    }
  };
  return (
    <div className="w-[90%] mx-auto ">
      <h1>Update Departure Management</h1>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="flex flex-col justify-center items-center  md:justify-between md:flex-row gap-x-5 flex-wrap">
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Sub Event Title
            </label>
            <select
              value={inputs.sub_event_id}
              name="sub_event_id"
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              {guestIdData.map((value) => (
                <option value={value.sub_event_id._id} key={value._id}>
                  {value.sub_event_id.subevent_title}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Guest Name
            </label>
            <select
              value={inputs.guest_id}
              name="guest_id"
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              {guestData.map((value) => (
                <option value={value._id} key={value._id}>
                  {value.guest_name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Date of Departure
            </label>
            <input
              type="date"
              name="date_of_departure"
              value={inputs.date_of_departure}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter date of Arrival "
            />
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              No of Guest Arrived
            </label>
            <input
              type="number"
              name="no_of_guest_arrived"
              value={inputs.no_of_guest_arrived}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter No of Guest Arrived"
            />
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Mode of Departure
            </label>
            <input
              type="text"
              name="mode_of_departure"
              value={inputs.mode_of_departure}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter Mode of Arrival"
            />
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Departure Time
            </label>
            <input
              type="time"
              name="departure_time"
              value={inputs.departure_time}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter Expected Arrival Time"
            />
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Dropped By
            </label>
            <input
              type="text"
              name="dropped_by"
              value={inputs.dropped_by}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter  dropped_by"
            />
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Vendor Name
            </label>
            <select
              value={inputs.vendor_id}
              name="vendor_id"
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              {vendorData.map((value) => (
                <option value={value._id} key={value._id}>
                  {value.vendor_name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Car Name
            </label>
            <select
              value={inputs.car_id}
              name="car_id"
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              {vendorCarData.map((value) => (
                <option value={value._id} key={value._id}>
                  {value.car_name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Driver Name
            </label>
            <select
              value={inputs.driver_id}
              name="driver_id"
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              {vendorDriverData.map((value) => (
                <option value={value._id} key={value._id}>
                  {value.driver_name}
                </option>
              ))}
            </select>
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Welcome Checklist
            </label>
            <input
              type="text"
              name="return_checklist"
              value={inputs.return_checklist}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter Welcome Checklist"
            />
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Special Note
            </label>
            <input
              type="text"
              name="special_note"
              value={inputs.special_note}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter pecial Note"
            />
          </div>
        </div>
        <button
          type="submit"
          className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default SidebarHeaderLayout(UpdateDepartureMgmnt);
