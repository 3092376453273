import React from "react";
import { Button, Checkbox, Col, Collapse, Form, Row, Select } from "antd";
import { FilterFilled, MinusCircleOutlined } from "@ant-design/icons";
import { Label } from "src/components/Label";
import { ShouldRender } from "src/components/ShouldRender";
import { FieldArray, Formik } from "formik";
import { clonseJSON } from "src/utils/json";
import { CreateHotelModal, CreateHotelRoomModal } from "../modals";
import {
  FloorRooms,
  IHotel,
  IHotelFloorWithRooms,
  IHotelOption,
  IHotelRoom,
  RequiredRooms,
} from "src/types";

interface FormError {
  [index: number]: {
    hotel_id: string;
    hotel_rooms_required: string;
  };
}

type Hotel = Pick<IHotel, "hotel_id"> & {
  hotel_rooms_required: FloorRooms;
};

interface HoteDetailForm {
  hotels: Partial<Hotel>[];
}

interface Props {
  defaultHotels?: IHotel[];
  hotelOptions: IHotelOption[];
  hotelRooms: IHotelFloorWithRooms;
  handleHotelCreateSuccess: (hotel: IHotelOption) => void;
  handleRoomCreateSuccess: (rooms: IHotelRoom[]) => void;
  onFinish: (values: IHotel[]) => void;
}

export const HotelDetail = ({
  defaultHotels = [],
  handleHotelCreateSuccess,
  handleRoomCreateSuccess,
  hotelRooms,
  hotelOptions,
  onFinish,
}: Props) => {
  const [open, setOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const [hotelList, setHotelList] = React.useState<IHotelOption[]>([]);
  // if(hotelList.length === 0) setHotelList(hotelOptions)
  const [selectedHotelID, setSelectedHotelID] = React.useState<string>();

  const getInitalValues = () => {
    const values: HoteDetailForm = {
      hotels: [],
    };
    if (defaultHotels.length) {
      defaultHotels.forEach(({ hotel_id, hotel_rooms_required }) => {
        values.hotels.push({
          hotel_id,
          hotel_rooms_required: hotel_rooms_required.reduce((obj, item) => {
            return {
              ...obj,
              [item.floor_no]: item.room_nos,
            };
          }, {} as FloorRooms),
        });
      });
    } else {
      values.hotels.push({
        hotel_id: undefined,
        hotel_rooms_required: {},
      });
    }
    return values;
  };

  const getFloorOptions = (hotelID: string) => {
    return Object.keys(hotelRooms[hotelID] || {});
  };

  const renderHotelRooms = (
    hotel: Partial<Hotel>,
    values: HoteDetailForm,
    errors: FormError,
    index: number,
    setValues: (values: HoteDetailForm) => void
  ) => {
    if (hotel.hotel_id) {
      return (
        <div>
          <ShouldRender
            check={Object.keys(hotelRooms[hotel.hotel_id] || {}).length > 0}
          >
            <Collapse>
              {getFloorOptions(hotel.hotel_id).map((floor_no) => (
                <Collapse.Panel
                  key={`hotel-${hotel.hotel_id!}-floor-${floor_no}`}
                  header={`Floor ${floor_no}`}
                >
                  <Row>
                    {hotelRooms[hotel.hotel_id!][floor_no].map((room) => (
                      <Col
                        key={`hotel-${hotel.hotel_id!}-floor-${floor_no}-room-${
                          room.room_no
                        }`}
                        className="mb-2"
                        span={4}
                      >
                        <Checkbox
                          checked={
                            hotel?.hotel_rooms_required?.[floor_no]?.find(
                              (item) => item.room_no === room.room_no
                            ) !== undefined
                          }
                          onChange={() => {
                            const updatedValues = clonseJSON(values);
                            const updatedHotel = clonseJSON(hotel);
                            const rooms =
                              updatedHotel.hotel_rooms_required?.[floor_no] ||
                              [];
                            const matchingIndex = rooms.findIndex(
                              (item) => item.room_no === room.room_no
                            );
                            if (matchingIndex === -1) {
                              rooms.push(room);
                            } else {
                              rooms.splice(matchingIndex, 1);
                            }
                            updatedHotel.hotel_rooms_required![floor_no] =
                              rooms;
                            if (
                              updatedHotel.hotel_rooms_required![floor_no]
                                ?.length === 0
                            ) {
                              delete updatedHotel.hotel_rooms_required![
                                floor_no
                              ];
                            }
                            updatedValues.hotels[index] = updatedHotel;
                            setValues(updatedValues);
                          }}
                        >
                          Room {room.room_no}
                        </Checkbox>
                      </Col>
                    ))}
                  </Row>
                </Collapse.Panel>
              ))}
            </Collapse>
            {errors[index]?.hotel_rooms_required && (
              <div className="my-2 text-xs text-red-500">
                {errors[index]?.hotel_rooms_required}
              </div>
            )}
          </ShouldRender>
          <ShouldRender
            check={!Object.keys(hotelRooms[hotel.hotel_id] || {}).length}
          >
            <div className="flex flex-col p-3">
              <h5 className="mx-auto text-lg font-bold">No rooms found</h5>
              <Button
                className="mx-auto"
                type="dashed"
                onClick={() => {
                  setSelectedHotelID(hotel.hotel_id!);
                  setShow(true);
                }}
              >
                Add Rooms
              </Button>
            </div>
          </ShouldRender>
        </div>
      );
    } else {
      return (
        <div className="flex justify-center">
          <p>Select Hotel to display rooms</p>
        </div>
      );
    }
  };

  return (
    <>
      <ShouldRender check={open}>
        <CreateHotelModal
          open={open}
          handleClose={() => setOpen(false)}
          handleHotelCreateSuccess={handleHotelCreateSuccess}
          handleRoomCreateSuccess={handleRoomCreateSuccess}
          title="Create Hotel"
        />
      </ShouldRender>
      <ShouldRender check={show}>
        <CreateHotelRoomModal
          open={show}
          options={hotelOptions}
          hotel_id={selectedHotelID}
          handleClose={() => setShow(false)}
          handleRoomCreateSuccess={handleRoomCreateSuccess}
          title="Create Hotel Rooms"
        />
      </ShouldRender>
      <div className="flex justify-end">
        <Button className="mr-3" onClick={() => setOpen(true)}>
          Create Hotel
        </Button>
        <Button onClick={() => setShow(true)}>Create Hotel Room</Button>
      </div>
      <Formik
        initialValues={getInitalValues()}
        validate={(values) => {
          const errors = {} as FormError;
          values.hotels.forEach((value, index) => {
            const initalError = { hotel_id: "", hotel_rooms_required: "" };
            if (!value.hotel_id) {
              initalError.hotel_id = "Hotel name is required";
            }
            if (Object.keys(value.hotel_rooms_required || {}).length === 0) {
              initalError.hotel_rooms_required =
                "Please select alteast one room";
            }
            if (
              initalError.hotel_id === "" &&
              initalError.hotel_rooms_required === ""
            ) {
              delete errors[index];
            } else {
              errors[index] = initalError;
            }
          });
          return errors;
        }}
        onSubmit={(values) => {
          const hotels: IHotel[] = [];
          values.hotels.forEach(({ hotel_id, hotel_rooms_required }) => {
            const requiredRooms: RequiredRooms[] = [];
            Object.keys(hotel_rooms_required || {}).forEach((floor) => {
              requiredRooms.push({
                floor_no: +floor,
                room_nos: hotel_rooms_required![floor],
              });
            });
            hotels.push({
              hotel_id: hotel_id!,
              hotel_rooms_required: requiredRooms,
            });
          });
          onFinish(hotels);
        }}
      >
        {({ errors, values, setValues, handleSubmit }) => {
          return (
            <Form>
              <FieldArray name="hotels">
                {({ push, remove }) => (
                  <div>
                    {values.hotels.map((hotel, index) => {
                      return (
                        <fieldset className="row" key={index}>
                          <legend>
                            <div className="flex items-center justify-between">
                              Hotel
                              <ShouldRender check={index > 0}>
                                <MinusCircleOutlined
                                  onClick={() => {
                                    // const lastHotel = values.hotels.slice(-1);
                                    // if(lastHotel[0].hotel_id) {
                                    //   const isMatched =  hotelOptions.find( hotel => hotel._id === lastHotel[0].hotel_id)
                                    //   if(isMatched) setHotelList(current => [...current, isMatched])
                                    // }
                                    remove(index);
                                  }}
                                  rev={undefined}
                                />
                              </ShouldRender>
                            </div>
                          </legend>
                          <div className="mb-3">
                            <Label required text="Hotel" />
                            <Select
                              className="min-w-[300px]"
                              placeholder="Select Hotel"
                              value={hotel.hotel_id}
                              onChange={(id) => {
                                const updatedValues = clonseJSON(values);
                                updatedValues.hotels[index].hotel_id = id;
                                setValues(updatedValues);
                              }}
                            >
                              {hotelOptions.map((item) => (
                                <Select.Option key={item._id} value={item._id}>
                                  {item.hotel_name}
                                </Select.Option>
                              ))}
                            </Select>
                            {(errors as FormError)?.[index]?.hotel_id && (
                              <div className="my-2 text-xs text-red-500">
                                {(errors as FormError)?.[index]?.hotel_id}
                              </div>
                            )}
                          </div>
                          {renderHotelRooms(
                            hotel,
                            values,
                            errors as FormError,
                            index,
                            setValues
                          )}
                        </fieldset>
                      );
                    })}
                    <Form.Item>
                      <Button
                        block
                        type="dashed"
                        onClick={() => {
                          // let filteredHotels=[]
                          // const lastHotel = values.hotels.slice(-1);
                          // if(lastHotel[0].hotel_id) {
                          //   filteredHotels = hotelList.filter(hotel => hotel._id !== lastHotel[0].hotel_id)
                          //   setHotelList(filteredHotels);
                          // }

                          push({
                            hotel_id: undefined,
                            hotel_rooms_required: {},
                          });
                        }}
                      >
                        Add Hotel
                      </Button>
                    </Form.Item>
                  </div>
                )}
              </FieldArray>
              <div className="flex justify-end">
                <Button type="primary" onClick={() => handleSubmit()}>
                  Continue
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
