import { Form, Modal, Select, TimePicker } from "antd";
import { ErrorMessage, Formik } from "formik";
import { FormInput } from "src/components/FormInput";
import { Label } from "src/components/Label";
import * as Yup from "yup";
import { IEventVendor, IVendor } from "src/types";
import moment, { min } from "moment";
import { TIME_PICKER_FORMAT } from "src/constants";
// import TextArea from "antd/lib/input/TextArea";

interface Props {
  handleClose: () => void;
  handleSave: (values: IEventVendor) => void;
  vendorOptions: IVendor[];
  vendor?: IEventVendor;
  open: boolean;
  title: string;
}

const validationSchema = Yup.object({
  vendor_name: Yup.string()
    .min(3, "Vendor name must be at least 3 characters")
    .required("Vendor name is required"),
  vendor_mobile: Yup.number()
    .test(
      "len",
      "Must be exactly 10 digits",
      (val) => val?.toString().length === 10
    )
    .required("Contact number is reqiured"),
  vendor_work: Yup.string()
    .min(3, "Vendor Work must be at least 3 characters")
    .required("Type of work is required"),
  arriving_time: Yup.date().required("Time is required"),
  
  scope_of_work: Yup.string()
    .min(3, "Scope of work must be at least 3 characters")
    .required("Scope of work is required"),
  
});

const EditVendor = ({
  handleClose,
  handleSave,
  vendorOptions,
  vendor,
  open,
  title,
}: Props) => {
  const initalValues = {
    vendor_name: vendor?.vendor_name,
    vendor_mobile: vendor?.vendor_mobile,
    arriving_time: vendor?.arriving_time
      ? moment(vendor.arriving_time, TIME_PICKER_FORMAT)
      : undefined,
    due_amount: vendor?.due_amount,
    paid_amount: vendor?.paid_amount,
    scope_of_work: vendor?.scope_of_work,
    total_package: vendor?.total_package,
    vendor_work: vendor?.vendor_work,
  };



  return (
    <Formik
      initialValues={initalValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        
        handleSave({
          vendor_name: values.vendor_name!,
          vendor_mobile: values.vendor_mobile!.toString(),
          vendor_work: values.vendor_work!,
          arriving_time:
            values.arriving_time! == undefined
              ? "00:00"
              : values.arriving_time!.format(TIME_PICKER_FORMAT),
          due_amount: values.due_amount! ? values.due_amount!.toString() : "0",
          paid_amount: values.paid_amount!
            ? values.paid_amount!.toString()
            : "0",
          scope_of_work: values.scope_of_work!,
          total_package: values.total_package!
            ? values.total_package!.toString()
            : "0",
        });
      }}
    >
      {({ setFieldValue, handleSubmit, values, errors }) => {
        return (
          <Modal
            centered
            open={open}
            title={title}
            onCancel={handleClose}
            onOk={() => handleSubmit()}
          >
            <div className="mb-3">
              {/* <Select
                  className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                  placeholder="Select Vendor Name"
                  value={values.vendor_id}
                  onChange={(id) => setFieldValue("vendor_id", id)}
                >
                  {vendorOptions.map((item) => (
                    <Select.Option key={item._id} value={item._id}>
                      {item.vendor_name}
                    </Select.Option>
                  ))}
                </Select> */}
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                name="vendor_name"
                label={{ required: true, text: "Vendor Name" }}
                placeholder="enter vendor name"
              />
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                name="vendor_mobile"
                label={{ required: true, text: "Vendor mobile" }}
                type="number"
                placeholder="enter vendor mobile"
              />
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                name="vendor_work"
                label={{ required: true, text: "Vendor work" }}
                placeholder="enter vendor work"
              />

              {/* {errors.vendor_work && (
                <div className="my-1 text-xs text-red-500">
                  <ErrorMessage name="vendor_work" />
                </div>
              )} */}
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                name="scope_of_work"
                label={{ required: true, text: "Vendor scope of work" }}
                placeholder="enter vendor's scope of work"
              />
              {/* <label>Description</label>
              <TextArea
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                // label={{ required: true, text: "Description" }}
                required={true}
                name="descp"
                placeholder="enter descprition"
              /> */}
            </div>
            <div className="mb-3">
              <Label required text="Arriving Time" />
              <TimePicker
                format={TIME_PICKER_FORMAT}
                value={values.arriving_time}
                onChange={(value) => setFieldValue("arriving_time", value)}
              />
              {errors.arriving_time && (
                <div className="my-1 text-xs text-red-500">
                  <ErrorMessage name="arriving_time" />
                </div>
              )}
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: false, text: "Due Amount" }}
                name="due_amount"
                type="number"
                min={0}
                placeholder="enter due amount"
              />
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: false, text: "Paid Amount" }}
                name="paid_amount"
                type="number"
                min={0}
                placeholder="enter paid amount"
              />
            </div>
            {/* <div className="mb-3">
            <Form.Item
            label={"Vendor Scope Of Work"}
            name="scope_of_work"
            rules={[
              {
                required: true,
                message: "Vendor Scope of work is required",
              },
              {
                validator: (_ , value) => {
                  if(value && value.length < 3) {
                    return Promise.reject('Must be atleast 3 chars')
                  } else {
                    return Promise.resolve()
                  }
                }
              }
            ]}
          >
            <div className="mb-3">
              <TextArea placeholder="enter vendor scope of work" />
            </div>
          </Form.Item>
            </div> */}
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: false, text: "Total Package" }}
                name="total_package"
                type="number"
                min={0}
                placeholder="enter total package"
              />
            </div>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default EditVendor;
