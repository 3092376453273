import React, { useEffect, useState } from "react";
import { message, Popconfirm, Table } from "antd";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link } from "react-router-dom";
import {
  deletePermission,
  deleteRole,
  getAllPermissions,
  getAllPermissionsPages,
} from "../../../api-core/RolesPermissions";

const ListOfPermission = () => {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [row, setRow] = useState(10);
  const [pages, setPages] = useState(1);
  useEffect(() => {
    fetchAllPermissions();
  }, [row, pages]);
  const fetchAllPermissions = async () => {
    setloading(true);
    const res = await getAllPermissionsPages(row, pages);
    setRow(res?.count);
    setdata(res.permission);
    setloading(false);
  };
  const onDeleteRecord = async (record, id) => {
    const recordId = {
      ids: [record],
    };
    const res = await deletePermission(recordId);
    if (res.success == true) {
      message.success(res.msgText);
      fetchAllPermissions();
    }
  };

  const datas = data?.map((item) => ({
    key: item._id,
    permName: item.perm_name,
  }));

  const columns = [
    {
      title: "Sr",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Permission Name",
      dataIndex: "permName",
      key: "permName",
      ellipsis: true,
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex gap-x-2 ">
          {/* <Link to={`/admin/singlepermission/${record.key}`}>
            <button className="text-blue-600 cursor-pointer">View</button>
          </Link> */}

          <Link to={`/admin/updatepermission/${record.key}`}>
            <button className="text-yellow-400 cursor-pointer">Edit</button>
          </Link>

          <Popconfirm
            title="Sure to delete?"
            onConfirm={(e) => onDeleteRecord(record.key, e)}
          >
            <span className="text-red-500 cursor-pointer">Delete</span>
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div className="">
      <Link to="/admin/createpermission">
        <button className="bg-blue-600 text-white p-3 mx-3">
          Create Permission
        </button>
      </Link>
      <h1 className="text-center">List Of Permission</h1>
      <div className="">
        <Table
          dataSource={datas}
          columns={columns}
          loading={loading}
          // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
          pagination={{ total: row, pageSize: 10 }}
        />
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(ListOfPermission);
