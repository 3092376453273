import Axios from "../Axios";

export const getAllVendors = async () => {
  try {
    const response = await Axios.get(`/admin/vendor`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getSingleVendor = async (id) => {
  try {
    const response = await Axios.get(`/admin/vendor/read/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const createVendor = async (data) => {
  try {
    const response = await Axios.post(`/admin/vendor/create`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const updateVendor = async (data, id) => {
  try {
    const response = await Axios.post(`/admin/vendor/update/${id}`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deleteVendor = async (id) => {
  try {
    const response = await Axios.post(`/admin/vendor/delete`,id);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getAllVendorCar = async (page=1, row=10) => {
  try {
    const response = await Axios.get(`/admin/vendorcar?p=${page}&r=${row}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getAllVendorProdDecor = async () => {
  try {
    const response = await Axios.get(`/admin/vendorproddecor`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getSingleVendorCar = async (id) => {
  try {
    const response = await Axios.get(`/admin/vendorcar/read/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const createVendorCar = async (data) => {
  try {
    const response = await Axios.post(`/admin/vendorcar/create`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const updateVendorCar = async (data, id) => {
  try {
    const response = await Axios.post(`/admin/vendorcar/update/${id}`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deleteVendorCar = async (id) => {
  try {
    const response = await Axios.post(`/admin/vendorcar/delete`,id);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getAllVendorDriver = async () => {
  try {
    const response = await Axios.get(`/admin/vendordriver`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getSingleVendorDriver = async (id) => {
  try {
    const response = await Axios.get(`/admin/vendordriver/read/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const createVendorDriver = async (data) => {
  try {
    const response = await Axios.post(`/admin/vendordriver/create`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const updateVendorDriver = async (data, id) => {
  try {
    const response = await Axios.post(`/admin/vendordriver/update/${id}`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deleteVendorDriver = async (id) => {
  try {
    const response = await Axios.post(`/admin/vendordriver/delete`,id);
    return response.data;
  } catch (error) {
    return error;
  }
};