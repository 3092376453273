import React, { useEffect, useState } from "react";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { message } from "antd";
import { phoneRegExp } from "src/utils/helper";
import { getAllEvents } from "../../../api-core/OfferBanner";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  createFreelancer,
  getsingleFreelancer,
  updateFreelancer,
} from "../../../api-core/Freelancer";
import { departmentData } from "src/constants";

const UpdateFreelancer = () => {
  const [passPortPhoto, setPassPortPhoto] = useState("");
  const [preview, setPreview] = useState("");
  const [previousPic, setPreviousPic] = useState("");
  const [passPortPicSelected, setPassPortSelected] = useState("");
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSingleFreelancer = async () => {
      const res = await getsingleFreelancer(id);
      setInitialValues({
        department_type: res?.freelancer[0].department_type,
        name: res?.freelancer[0].name,
        city: res?.freelancer[0].city,
        current_city: res?.freelancer[0].current_city,
        alt_contact_no: res?.freelancer[0].alt_contact_no,
        wa_contact_no: res?.freelancer[0].wa_contact_no,
        gender: res?.freelancer[0].gender,
        course: res?.freelancer[0].course,
        experience: res?.freelancer[0].experience,
        tshirt_size: res?.freelancer[0].tshirt_size,
        coordination: res?.freelancer[0].coordination,
        work_of_shadow: res?.freelancer[0].work_of_shadow,
      });
      setPreviousPic(res?.freelancer[0].pass_size_pic);
      setPassPortSelected(res?.freelancer[0].filename);
    };
    fetchSingleFreelancer();
  }, [id]);

  const handleChange = (e) => {
    const { files } = e.target;
    if(e.target.files[0] && e.target.files[0].size > 2 * 1024 *1024 ) {
      e.target.value = null;
      return message.error("File exceeds the limit of 2MB")
    }

    setPassPortPhoto(files[0]);

    if (passPortPicSelected) {
      setPreview(URL.createObjectURL(e.target.files[0]));
      setPreviousPic("");
    }
  };

  const [initialValues, setInitialValues] = useState({
    department_type: "",
    name: "",
    city: "",
    current_city: "",
    alt_contact_no: "",
    wa_contact_no: "",
    gender: "",
    course: "",
    experience: "",
    tshirt_size: "",
    coordination: "",
    work_of_shadow: "",
  });
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object({
      department_type: Yup.string().required("Dept Name is required"),
      name: Yup.string()
        .min(3,"must contains 3 char")
        .required("Name is required"),
      city: Yup.string().required("City is required").min(3,"must contains 3 char"),
      current_city: Yup.string().required("Current City is required").min(3,"must contains 3 char"),
      alt_contact_no: Yup.string()
        .required("contact no is required")
        .matches(phoneRegExp, "Phone number is not valid")
        .min(10, "too short")
        .max(10, "too long"),
      wa_contact_no: Yup.string()
        .required("whataApp no is required")
        .matches(phoneRegExp, "number is not valid")
        .min(10, "too short")
        .max(10, "too long"),
      gender: Yup.string().required("gender is required"),
      course: Yup.string().required("course is required"),
      experience: Yup.string().required("experience is required"),
      coordination: Yup.string().required("coordination filed is required"),
      work_of_shadow: Yup.string().required("work of shadow field is required"),
      tshirt_size: Yup.string().required("tshirt size field is required"),
    }),
    onSubmit: async (values, action) => {
      const formData = new FormData();
      formData.append(
        "pass_size_pic",
        passPortPhoto !== "" ? passPortPhoto : passPortPicSelected
      );
      formData.append("department_type", values.department_type);
      formData.append("name", values.name);
      formData.append("city", values.city);
      formData.append("current_city", values.current_city);
      formData.append("alt_contact_no", values.alt_contact_no);
      formData.append("wa_contact_no", values.wa_contact_no);
      formData.append("gender", values.gender);
      formData.append("course", values.course);
      formData.append("experience", values.experience);
      formData.append("tshirt_size", values.tshirt_size);
      formData.append("coordination", values.coordination);
      formData.append("work_of_shadow", values.work_of_shadow);

      if (formData) {
        const res = await updateFreelancer(formData, id);
        if (res.success == true) {
          message.success("Submitted Successfully");
          action.resetForm();
          navigate("/admin/listoffreelancer");
        }
      } else {
        console.log("error");
      }
    },
  });

  return (
    <div className="w-[90%] mx-auto ">
      <h1>Edit FreeLancer</h1>
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <div className="flex flex-col justify-center items-center  md:justify-between md:flex-row gap-x-5 flex-wrap">
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Department Name
            </label>
            <select
              value={formik.values.department_type}
              name="department_type"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={""}>Select Department</option>
              {departmentData?.map((dept) => (
                <option value={dept?.value} key={dept._id}>
                  {dept.value}
                </option>
              ))}
            </select>
            {formik.errors.department_type && (
              <span className="text-red-500">
                {formik.errors.department_type}
              </span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Name
            </label>
            <input
              type="text"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter Name"
            />
            {formik.errors.name && (
              <span className="text-red-500">{formik.errors.name}</span>
            )}
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              WhatsApp Contact Number
            </label>
            <input
              type="number"
              name="wa_contact_no"
              value={formik.values.wa_contact_no}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter whatsapp number"
            />
            {formik.errors.wa_contact_no && (
              <span className="text-red-500">
                {formik.errors.wa_contact_no}
              </span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Alt Contact Number
            </label>
            <input
              type="number"
              name="alt_contact_no"
              value={formik.values.alt_contact_no}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter  Alt number"
              required
            />
            {formik.errors.alt_contact_no && (
              <span className="text-red-500">
                {formik.errors.alt_contact_no}
              </span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Gender
            </label>
            <select
              value={formik.values.gender}
              name="gender"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={""}>Select Gender</option>
              {["Male", "Female", "Others"].map((value) => (
                <option value={value} key={value}>
                  {value}
                </option>
              ))}
            </select>
            {formik.errors.gender && (
              <span className="text-red-500">{formik.errors.gender}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              City
            </label>
            <input
              type="text"
              name="city"
              value={formik.values.city}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter your city"
              required
            />
            {formik.errors.city && (
              <span className="text-red-500">{formik.errors.city}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Current City
            </label>
            <input
              type="text"
              name="current_city"
              value={formik.values.current_city}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter your current city"
              required
            />
            {formik.errors.current_city && (
              <span className="text-red-500">{formik.errors.current_city}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Experience
            </label>
            <input
              type="number"
              name="experience"
              value={formik.values.experience}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
              placeholder="enter experience"
            />
            {formik.errors.experience && (
              <span className="text-red-500">{formik.errors.experience}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              T Shirt Size
            </label>
            <select
              value={formik.values.tshirt_size}
              name="tshirt_size"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={""}>Select T Shirt Size</option>
              {["S", "M", "L", "XL", "XXL"].map((value) => (
                <option value={value} key={value}>
                  {value}
                </option>
              ))}
            </select>
            {formik.errors.tshirt_size && (
              <span className="text-red-500">{formik.errors.tshirt_size}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Course
            </label>
            <select
              value={formik.values.course}
              name="course"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={""}>Choose Option</option>
              {["Yes", "No"].map((value) => (
                <option value={value} key={value}>
                  {value}
                </option>
              ))}
            </select>
            {formik.errors.course && (
              <span className="text-red-500">{formik.errors.course}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Coordination
            </label>
            <select
              value={formik.values.coordination}
              name="coordination"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={""}>Choose Option</option>
              {["Yes", "No"].map((value) => (
                <option value={value} key={value}>
                  {value}
                </option>
              ))}
            </select>
            {formik.errors.coordination && (
              <span className="text-red-500">{formik.errors.coordination}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Work Of Shadow
            </label>
            <select
              value={formik.values.work_of_shadow}
              name="work_of_shadow"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={""}>Choose Option</option>
              {["Yes", "No"].map((value) => (
                <option value={value} key={value}>
                  {value}
                </option>
              ))}
            </select>
            {formik.errors.work_of_shadow && (
              <span className="text-red-500">
                {formik.errors.work_of_shadow}
              </span>
            )}
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              PassPortSize Photo
            </label>

            {previousPic == "" ? (
              <img src={preview} alt="preview" width={100} height={100} />
            ) : (
              <img
                src={previousPic}
                alt="previous_pic"
                width={100}
                height={100}
              />
            )}

            <input
              type="file"
              name="pass_size_pic"
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="PassPortSize Photo"
            />
          </div>
        </div>
        <Link to={"/admin/listoffreelancer"}>
          {/* <h1 className="text-lg text-black">Cancel</h1> */}
          <button type="button"
            className="mr-2 text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
            >Cancel</button>
        </Link>
        <button
          type="submit"
          className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default SidebarHeaderLayout(UpdateFreelancer);
