import { DatePicker, Modal } from "antd";
import { ErrorMessage, Formik } from "formik";
import { FormInput } from "src/components/FormInput";
import * as Yup from "yup";
import { IPrioritization } from "src/types";
import { Label } from "src/components/Label";
import moment from "moment";
import { TIME_FORMATS } from "src/constants";
import TextArea from "antd/lib/input/TextArea";

export type Priority = Pick<
  IPrioritization,
  "title" | "descp" | "deadline_date" | "contact"
>;

interface Props {
  handleClose: () => void;
  handleSave: (values: Priority) => void;
  priority?: IPrioritization;
  open: boolean;
  title: string;
}

const validationSchema = Yup.object({
  title: Yup.string().min(3,"Title must be at least 3 characters").required("Title  is required"),
  descp: Yup.string().min(3,"Description must be at least 3 characters").required("Description is required"),
  deadline_date: Yup.date().min(new Date()).required("Deadline date is required"),
  contact: Yup.number().test('len', 'Must be exactly 10 digits', val => val?.toString().length === 10).required("Mobile is reqiured"),
});

const EditPriortization = ({
  handleClose,
  handleSave,
  priority,
  open,
  title,
}: Props) => {
  const initalValues = {
    title: priority?.title,
    descp: priority?.descp,
    deadline_date: priority?.deadline_date
      ? moment(priority?.deadline_date)
      : undefined,
    contact: priority?.contact,
  };
// console.log("priority",priority?.descp);

  return (
    <Formik
      initialValues={initalValues}
      validationSchema={validationSchema}
      onSubmit={(values) =>
        handleSave({
          title: values.title!,
          deadline_date: values.deadline_date!.format(TIME_FORMATS.API_TIME),
          descp: values.descp!,
          contact: values.contact!,
        })
      }
    >
      {({ handleSubmit, values, setFieldValue, errors }) => {
        // console.log("values",values);
        // console.log("FORMIK");
        
        
        return (
          <Modal
            centered
            open={open}
            title={title}
            onCancel={handleClose}
            onOk={() => handleSubmit()}
          >
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: true, text: "Title" }}
                name="title"
                placeholder="enter title"
              />
            </div>
            <div className="mb-3">
            <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: true, text: "Description" }}
                required={true}
                name="descp"
                placeholder="enter descprition"
              />
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: true, text: "Contact" }}
                type="number"
                name="contact"
                placeholder="enter contact"
              />
            </div>
            <div className="mb-3">
              <Label required text="Deadline Date" />
              <DatePicker
                disabledDate={(current) => current.isBefore(moment().subtract(1,"day"))}
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                value={values.deadline_date}
                onChange={(value) => setFieldValue("deadline_date", value)}
              />
              {errors.deadline_date && (
                <div className="my-1 text-xs text-red-500">
                  <ErrorMessage name="deadline_date" />
                </div>
              )}
            </div>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default EditPriortization;
