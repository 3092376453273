import { Modal } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";
import { FormInput } from "src/components/FormInput";
import { IHotelOption, IHotelRoom } from "src/types";
import { phoneRegExp } from "src/utils/helper";

const validationSchema = Yup.object({
  hotel_name: Yup.string().required("Hotel name is required").min(3,"must contains 3 char"),
  hotel_add: Yup.string().required("Hotel Address is required").min(3,"must contains 3 char"),
  hotel_mob: Yup.string()
    .required("Hotel Number is required")
    .matches(phoneRegExp, "Number is not valid")
    .min(10, "too short")
    .max(10, "too long"),
});

export type IHotelRoomResponse = Omit<IHotelRoom, "hotel_id"> & {
  hotel_id: string;
};

interface Props {
  open: boolean;
  handleClose: () => void;
  handleSave: (
    hotel_add: string,
    hotel_mob: string,
    hotel_name: string
  ) => Promise<void>;
  hotelDetails?: IHotelOption;
  title: string;
}

const HotelModal = ({
  handleClose,
  handleSave,
  hotelDetails,
  open,
  title,
}: Props) => {
  const initialValues = {
    hotel_name: hotelDetails?.hotel_name,
    hotel_add: hotelDetails?.hotel_add,
    hotel_mob: hotelDetails?.hotel_mob
      ? hotelDetails?.hotel_mob.toString()
      : undefined,
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        const { hotel_add, hotel_mob, hotel_name } = values;
        if (hotel_add && hotel_mob && hotel_name) {
          handleSave(hotel_add, hotel_mob, hotel_name);
        }
      }}
    >
      {({ handleSubmit, errors, values, setValues, touched }) => {
        return (
          <Modal
            open={open}
            centered
            onOk={() => handleSubmit()}
            onCancel={handleClose}
            title={title}
          >
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: true, text: "Hotel Name" }}
                name="hotel_name"
                placeholder="Enter Hotel Name"
              />
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: true, text: "Hotel Address" }}
                name="hotel_add"
                placeholder="Enter Hotel Address"
              />
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: true, text: "Contact Number" }}
                name="hotel_mob"
                placeholder="Enter Mobile Number"
              />
            </div>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default HotelModal;
