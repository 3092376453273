import React, { useEffect, useState } from "react";
import { Table } from "antd";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link, useParams } from "react-router-dom";
import {
  deleteGuestlist,
  getAllArrivalMgmnt,
  getAllGuestlist,
  getSingleArrivalMgmnt,
} from "../../../api-core/Ghms";
import { formatDate } from "../../../utils/helper";

const ListOfArrivalMgmnt = () => {
  const { id } = useParams();
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    const fetchSingleArrivalMgmnt = async () => {
      setloading(true);
      const res = await getSingleArrivalMgmnt(id);
      console.log("res", res.ghmsarrivalmgmt);

      setdata(res.ghmsarrivalmgmt);
      setloading(false);
    };
    fetchSingleArrivalMgmnt();
  }, []);

  const datas = data.map((item) => ({
    key: item._id,
    subEventTitle: item.sub_event_id.subevent_title,
    guestName: item?.guest_id?.guest_name,
    dateOfArrival: formatDate(item.date_of_arrival, "DD-MMM-YYYY"),
    noOfGuestArrived: item.no_of_guest_arrived,
    modeOfArrival: item.mode_of_arrival,
    expctArrivalTime: item.expected_arrival_time,
    arrivedAt: item.arrived_at,
    pickedBy: item.picked_by,
    vendorName: item.vendor_id.vendor_name,
    carName: item.car_id.car_name,
    carModel: item.car_id.car_model,
    carNumber: item.car_id.car_number,
    driverName: item.driver_id.driver_name,
    welcomeChecklist: item.welcome_checklist,
    specialNote: item.special_note,
  }));
  // console.log("datas", datas);

  const columns = [
    {
      title: "Sr.",
      dataIndex: "number",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Sub Event Title",
      dataIndex: "subEventTitle",
      key: "subEventTitle",
      ellipsis: true,
    },
    {
      title: "Guest Name",
      dataIndex: "guestName",
      key: "guestName",
      ellipsis: true,
    },

    {
      title: "Date of Arrival",
      dataIndex: "dateOfArrival",
      key: "dateOfArrival",
      ellipsis: true,
    },

    {
      title: "No of Guest Arrived",
      dataIndex: "noOfGuestArrived",
      key: "noOfGuestArrived",
      ellipsis: true,
    },

    {
      title: "Mode Of Arrival",
      dataIndex: "modeOfArrival",
      key: "modeOfArrival",
      ellipsis: true,
    },

    {
      title: "Expected Arrival Time",
      dataIndex: "expctArrivalTime",
      key: "expctArrivalTime",
      ellipsis: true,
    },

    {
      title: "Arrived At",
      dataIndex: "arrivedAt",
      key: "arrivedAt",
      ellipsis: true,
    },

    {
      title: "Picked By",
      dataIndex: "pickedBy",
      key: "pickedBy",
      ellipsis: true,
    },

    {
      title: "Vendor Name",
      dataIndex: "vendorName",
      key: "vendorName",
      ellipsis: true,
    },

    {
      title: "Car Name",
      dataIndex: "carName",
      key: "carName",
      ellipsis: true,
    },
    {
      title: "Car Model",
      dataIndex: "carModel",
      key: "carModel",
      ellipsis: true,
    },
    {
      title: "Car Number",
      dataIndex: "carNumber",
      key: "carNumber",
      ellipsis: true,
    },
    {
      title: "Driver Name",
      dataIndex: "driverName",
      key: "driverName",
      ellipsis: true,
    },
    {
      title: "Welcome Checklist",
      dataIndex: "welcomeChecklist",
      key: "welcomeChecklist",
      ellipsis: true,
    },
    {
      title: "Special Note",
      dataIndex: "specialNote",
      key: "specialNote",
      ellipsis: true,
    },
  ];
  return (
    <div className="">
      <h1 className="text-center">List Arrival Management</h1>
      <div className="">
        <Table
          dataSource={datas}
          columns={columns}
          loading={loading}
          scroll={{
            y: "auto",
            x: "auto",
          }}
          // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        />
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(ListOfArrivalMgmnt);
