import React, { useEffect, useState } from "react";
import { Table } from "antd";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link, useParams } from "react-router-dom";
import {
  deleteHotelRoomChecklist,
  getAllHotelRoomAllotment,
  getAllHotelRoomChecklist,
  getSingleHotelRoomAllotment,
} from "../../../api-core/Hotel";

const SingleHotelRoomAllotment = () => {
  const { id } = useParams();
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    const fetchSingleHotelRoomAllotment = async () => {
      setloading(true);
      const res = await getSingleHotelRoomAllotment(id);
      console.log("res", res);

      setdata(res.roomallotment);
      setloading(false);
    };
    fetchSingleHotelRoomAllotment();
  }, []);

  const onDeleteRecord = async (record, id) => {
    const res = await deleteHotelRoomChecklist(record);
    // console.log("deData", res);
  };

  const datas = data.map((item) => ({
    key: item._id,
    clientName: item.client_id.name,
    subEventTitle: item.sub_event_id.subevent_title,
    hotelRoomNo: item.hotel_room_id.room_no,
    guestName: item.guest_id.guest_name,
    remarks: item.remarks,
  }));
  // console.log("datas", datas);

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Client Name",
      dataIndex: "clientName",
      key: "clientName",
      ellipsis: true,
    },
    {
      title: "Sub Event Title",
      dataIndex: "subEventTitle",
      key: "subEventTitle",
      ellipsis: true,
    },
    {
      title: "Hotel Room No",
      dataIndex: "hotelRoomNo",
      key: "hotelRoomNo",
      ellipsis: true,
    },

    {
      title: "Guest Name",
      dataIndex: "guestName",
      key: "guestName",

      ellipsis: true,
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
  ];
  return (
    <div className="">
      <h1 className="text-center">List Of Hotel Room Allotment</h1>
      <div className="">
        <Table
          dataSource={datas}
          columns={columns}
          loading={loading}

          // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        />
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(SingleHotelRoomAllotment);
