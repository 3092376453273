import moment from "moment";
import * as Yup from "yup";
import { DatePicker, Modal, Select, TimePicker } from "antd";
import { ErrorMessage, Formik } from "formik";
import { FormInput } from "src/components/FormInput";
import { Label } from "src/components/Label";
import { FoodType, IEventFoodNBeverages } from "src/types";
import ImageUploader from "src/components/ImageUploader";
import { TIME_FORMATS, TIME_PICKER_FORMAT } from "src/constants";
import { viewCurrentDates } from "src/utils/helper";

interface Props {
  open: boolean;
  data?: IEventFoodNBeverages;
  handleClose: () => void;
  handleSave: (values: IEventFoodNBeverages) => void;
  title: string;
}

const validationSchema = Yup.object({
 food_type: Yup.string().required("Food type is required"),
  menu: Yup.array(
    Yup.object({
      file: Yup.string().required("Menu image is required"),
    })
  ),
  serve_date: Yup.date().min(new Date()).required("Serve date is required"),
  serve_end_time: Yup.date().nullable(),//.required("Serve end time is required"),
  serve_start_time: Yup.date().nullable(),//.required("Serve start time is required"),
  plates_guaranteed : Yup.string().required("Plates Guaranteed is required")
});

const EditFoodBev = ({ handleClose, handleSave, data, open, title }: Props) => {
  const initalValues = {
    food_type: data?.food_type,
    menu: data?.menu,
    plates_guaranteed: data?.plates_guaranteed,
    plates_remaining: data?.plates_remaining,
    plates_added: data?.plates_added,
    plates_used: data?.plates_used,
    serve_date: data?.serve_date ? moment(data?.serve_date) : undefined,
    serve_end_time: data?.serve_end_time
      ? moment(data?.serve_end_time, TIME_PICKER_FORMAT)
      : undefined,
    serve_start_time: data?.serve_start_time
      ? moment(data?.serve_start_time, TIME_PICKER_FORMAT)
      : undefined,
  };

  return (
    <Formik
      initialValues={initalValues}
      validationSchema={validationSchema}
      onSubmit={(values) =>
        handleSave({
          food_type: values.food_type!,
          menu: values.menu!,
          plates_guaranteed: values.plates_guaranteed?.toString(),
          plates_used: values.plates_used?.toString(),
          plates_remaining: values.plates_remaining,
          plates_added: values.plates_added,
          serve_date: values.serve_date!.format(TIME_FORMATS.API_TIME),
          serve_end_time: values.serve_end_time
            ? values.serve_end_time.format(TIME_PICKER_FORMAT)
            : undefined,
          serve_start_time: values.serve_start_time
            ? values.serve_start_time.format(TIME_PICKER_FORMAT)
            : undefined,
        })
      }
    >
      {({ setFieldValue, handleSubmit, values, errors }) => {
        return (
          <Modal
            centered
            open={open}
            title={title}
            onCancel={handleClose}
            onOk={() => handleSubmit()}
          >
            <div className="mb-3">
              <Label required text="Food Type" />
              <div className="flex items-center">
                <Select
                  className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                  placeholder="Select Food Type"
                  value={values.food_type}
                  onChange={(value) => {
                    setFieldValue("food_type", value);
                  }}
                >
                  <Select.Option value="">Select Option</Select.Option>
                  <Select.Option value={FoodType.Breakfast}>
                    Breakfast
                  </Select.Option>
                  <Select.Option value={FoodType.Dinner}>Dinner</Select.Option>
                  <Select.Option value={FoodType.HiTea}>Hi Tea</Select.Option>
                  <Select.Option value={FoodType.Lunch}>Lunch</Select.Option>
                </Select>
              </div>
              {errors.food_type && (
                <div className="my-1 text-xs text-red-500">
                  <ErrorMessage name="food_type" />
                </div>
              )}
            </div>
            <div className="mb-3">
              <Label text="Menu" />
              <div className="flex justify-center">
                <ImageUploader
                  handleChange={(file) =>
                    setFieldValue("menu", [
                      {
                        file,
                        filename: file,
                      },
                    ])
                  }
                  src={values.menu?.[0].file}
                />
              </div>
              {errors.menu && (
                <div className="my-1 text-xs text-red-500">
                  <ErrorMessage name="menu" />
                </div>
              )}
            </div>
            <div className="mb-3">
              <Label required text="Serve date" />
              <DatePicker
                disabledDate={(current) => current.isBefore(moment().subtract(1,"day"))}
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                value={values.serve_date}
                onChange={(value) => setFieldValue("serve_date", value)}
              />
              {errors.serve_date && (
                <div className="my-1 text-xs text-red-500">
                  <ErrorMessage name="serve_date" />
                </div>
              )}
            </div>
            <div className="grid grid-cols-2 gap-3 mb-3">
              <div className="m-auto">
                <Label text="Serve Start Time" />
                <TimePicker
                  format={TIME_PICKER_FORMAT}
                  value={values.serve_start_time}
                  name="serve_start_time"
                  onChange={(value) => setFieldValue("serve_start_time", value)}
                />
                {/* {errors.serve_start_time && (
                  <div className="my-1 text-xs text-red-500">
                    <ErrorMessage name="serve_start_time" />
                  </div>
                )} */}
              </div>
              <div className="m-auto">
                <Label text="Serve End Time" />
                <TimePicker
                  name="serve_end_time"
                  format={TIME_PICKER_FORMAT}
                  value={values.serve_end_time}
                  onChange={(value) => setFieldValue("serve_end_time", value)}
                />
                {/* {errors.serve_end_time && (
                  <div className="my-1 text-xs text-red-500">
                    <ErrorMessage name="serve_end_time" />
                  </div>
                )} */}
              </div>
            </div>

            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: true, text: "Plates guaranteed" }}
                name="plates_guaranteed"
                type="number"
                min="0"
                placeholder="enter guaranteed plates"
              />
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: false, text: "Plates used" }}
                name="plates_used"
                type="number"
                min="0"
                placeholder="enter used plates"
              />
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ text: "Plates added" }}
                name="plates_added"
                type="number"
                min="0"
                placeholder="enter plates added"
              />
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ text: "Plates remaining" }}
                name="plates_remaining"
                type="number"
                min="0"
                placeholder="enter remaining plates"
              />
            </div>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default EditFoodBev;
