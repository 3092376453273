import { Tabs } from "antd";
import { IEvent, IGHMS, IVendorCar } from "../../../types";
import {
  ArrivalTable,
  DepartureTable,
  GuestTable,
  LostAndFoundTable,
  OutstationTable
} from "./tables";

interface Props {
  event: IEvent | undefined;
  ghms: IGHMS | undefined;
  update: () => Promise<void>;
  loading: boolean;
  vendorCars: IVendorCar[];
}

const GHMS = ({ event, ghms, loading, update, vendorCars }: Props) => {
  const { arrival, departure, guestlist, lostandfound, outstation  } = ghms || {};

  return (
    <div>
      <Tabs>
        <Tabs.TabPane tab="Guest List" key="guestlist">
          <GuestTable
            event={event}
            guests={guestlist}
            loading={loading}
            update={update}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Arrival" key="arrival">
          <ArrivalTable
            arrival={arrival}
            event={event}
            guests={guestlist}
            loading={loading}
            update={update}
            vendorCars={vendorCars}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Departure" key="departure">
          <DepartureTable
            departure={departure}
            event={event}
            guests={guestlist}
            loading={loading}
            update={update}
            vendorCars={vendorCars}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Lost and Found" key="lostandfound">
          <LostAndFoundTable
            event={event}
            guests={guestlist}
            lostAndFound={lostandfound}
            loading={loading}
            update={update}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Outstation" key="outstation">
          <OutstationTable
            event={event}
            guests={guestlist}
            outstation={outstation}
            loading={loading}
            update={update}
          />
        </Tabs.TabPane>
      </Tabs>
    </div>
  );
};

export default GHMS;
