import { useState } from "react";

export default function SidebarItem({ item }) {
  const [open, setOpen] = useState(false);

  const openAndClose = () => setOpen((prevState) => !prevState);

  const mapChildren = () => (
    <div className="sidebar-content">
      {item.childrens.map((child, index) => (
        <SidebarItem key={index} item={child} />
      ))}
    </div>
  );

  const displayAllItems = () => (
    <div className={open ? "sidebar-item open" : "sidebar-item"}>
      <div onClick={openAndClose} className="cursor-pointer sidebar-title">
        <span>
          {item.icon && <i className={item.icon}></i>}
          {item.title}
        </span>
        <span className="cursor-pointer">{open ? "v" : "^"}</span>
      </div>
      {open && mapChildren()}
    </div>
  );

  const displayItemWithoutChildren = () => (
    <a href={item.path || "#"} className="sidebar-item plain">
      {item.icon && <i className={item.icon}></i>}
      {item.title}
    </a>
  );

  return item.childrens ? displayAllItems() : displayItemWithoutChildren();
}
