import React, { useEffect, useState } from "react";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { createVendor } from "../../../api-core/Vendor";
import { getAllUser } from "../../../api-core/User";
import { getAllSubEvents } from "../../../api-core/Events";
import { useFormik } from "formik";
import * as Yup from "yup";
const CreateVendorFoodBev = () => {
  const [clientData, setClientData] = useState([]);
  const [currentEventData, setCurrentEventData] = useState([]);
  useEffect(() => {
    const fetchAllUser = async () => {
      const res = await getAllUser();
      setClientData(res.user);
      // console.log("eventData", res);
    };
    fetchAllUser();
  }, []);
  useEffect(() => {
    const fetchAllSubEvents = async () => {
      const res = await getAllSubEvents();
      setCurrentEventData(res.subevent);
      // console.log("eventData", res);
    };
    fetchAllSubEvents();
  }, []);
  const initialValues = {
    client_id: "",
    sub_event_id: "",
    food_type: "",
    food_sub_type: "",
    dish_name: "",
    serve_date: "",
    serve_start_time: "",
    serve_end_time: "",
    plates_guaranteed: "",
    plates_added: "",
    plates_remaining: "",
    plates_used: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      client_id: Yup.string().required("Dept Name is required"),
      sub_event_id: Yup.string().required("Event Name is required"),
      food_type: Yup.string().required("Food Type name required"),
      food_sub_type: Yup.string().required("Sub Food Type  Name required"),
      dish_name: Yup.string().required("Dish Name required"),
      serve_date: Yup.string().required("Serve Date required"),
      serve_start_time: Yup.string().required("Serve Start time is required"),
      serve_end_time: Yup.string().required("Serve End time Is required"),
      plates_guaranteed: Yup.string().required("Plates Guarnteed is required"),
      plates_added: Yup.string().required("paltes Added  required"),

      plates_remaining: Yup.string().required("Plates Remaining is Required is required"),
      plates_used: Yup.string().required("Plates used is required"),
    }),
    onSubmit: async (values, action) => {
      console.log("values", values);

      // if (values) {
      //   const res = await createFreelancerAssignee(values);
      //   console.log("resFormData", res);
      //   if (res.success === true) {
      //     message.success("Submitted Successfully");
      //     action.resetForm();
      //   }
      // } else {
      //   console.log("error");
      // }
    },
  });

  return (
    <div className="w-[90%] mx-auto ">
      <h1>Create Vendor Food Bev</h1>
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <div className="flex flex-col justify-center items-center  md:justify-between md:flex-row gap-x-5 flex-wrap">
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Client Name
            </label>
            <select
              value={formik.values.client_id}
              name="client_id"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={""}>Select Client Name</option>
              {clientData.map((value) => (
                <option value={value._id} key={value._id}>
                  {value.name}
                </option>
              ))}
            </select>
            {formik.errors.client_id && (
              <span className="text-red-500">{formik.errors.client_id}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Current Event Name
            </label>
            <select
              value={formik.values.sub_event_id}
              name="sub_event_id"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={""}>Select Current Event Name</option>
              {currentEventData.map((value) => (
                <option value={value.event_id._id} key={value._id}>
                  {value.event_id.event_title}
                </option>
              ))}
            </select>
            {formik.errors.sub_event_id && (
              <span className="text-red-500">{formik.errors.sub_event_id}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Food Type
            </label>
            <input
              type="text"
              name="food_type"
              value={formik.values.food_type}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter Vendor name"
            />
            {formik.errors.food_type && (
              <span className="text-red-500">{formik.errors.food_type}</span>
            )}
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Food Sub Type
            </label>
            <input
              type="text"
              name="food_sub_type"
              value={formik.values.food_sub_type}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter vendor add"
            />
            {formik.errors.food_sub_type && (
              <span className="text-red-500">{formik.errors.food_sub_type}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Dish Name
            </label>
            <input
              type="text"
              name="dish_name"
              value={formik.values.dish_name}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter Vendor Mobile"
            />
            {formik.errors.dish_name && (
              <span className="text-red-500">{formik.errors.dish_name}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Serve Date
            </label>
            <input
              type="date"
              name="serve_date"
              value={formik.values.serve_date}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter Vendor email"
            />
            {formik.errors.serve_date && (
              <span className="text-red-500">{formik.errors.serve_date}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Serve Start time
            </label>
            <input
              type="time"
              name="serve_start_time"
              value={formik.values.serve_start_time}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter Vendor email"
            />
            {formik.errors.serve_start_time && (
              <span className="text-red-500">{formik.errors.serve_start_time}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Serve End time
            </label>
            <input
              type="time"
              name="serve_end_time"
              value={formik.values.serve_end_time}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter Vendor email"
            />
            {formik.errors.serve_end_time && (
              <span className="text-red-500">{formik.errors.serve_end_time}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Plates Guarntees
            </label>
            <input
              type="text"
              name="plates_guaranteed"
              value={formik.values.plates_guaranteed}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter Vendor email"
            />
            {formik.errors.plates_guaranteed && (
              <span className="text-red-500">{formik.errors.plates_guaranteed}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Plates Added
            </label>
            <input
              type="text"
              name="plates_added"
              value={formik.values.plates_added}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter Vendor email"
            />
            {formik.errors.plates_added && (
              <span className="text-red-500">{formik.errors.plates_added}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Plates Remaining
            </label>
            <input
              type="text"
              name="plates_remaining"
              value={formik.values.plates_remaining}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter Vendor email"
            />
            {formik.errors.plates_remaining && (
              <span className="text-red-500">{formik.errors.plates_remaining}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Plates Used
            </label>
            <input
              type="text"
              name="plates_used"
              value={formik.values.plates_used}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter Vendor email"
            />
            {formik.errors.plates_used && (
              <span className="text-red-500">{formik.errors.plates_used}</span>
            )}
          </div>
        </div>
        <button
          type="submit"
          className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default SidebarHeaderLayout(CreateVendorFoodBev);
