import { IHotelFloorWithRooms, IHotelRoom } from "src/types";

export const getFormattedHotelRooms = (
  rooms: IHotelRoom[],
  initialValues?: IHotelFloorWithRooms
) => {
  const hotelRooms: IHotelFloorWithRooms = { ...initialValues } || {};
  
  rooms.forEach((room) => {

    
    const { hotel_id, floor_no, room_no, _id ,room_type_id } = room;
    const hotelId = hotel_id;
    if (!hotelRooms[hotelId]) {
      hotelRooms[hotelId] = {};
    }
    if (!hotelRooms[hotelId][floor_no]) {
      hotelRooms[hotelId][floor_no] = [];
    }
    hotelRooms[hotelId][floor_no].push({
      room_no,
      hotel_room_id: _id,
      isBooked: 0,
      room_type_id
    });
  });
  return hotelRooms;
};
