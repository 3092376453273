import Axios from "../Axios";

export const getAllRoles = async () => {
  try {
    const response = await Axios.get(`/admin/role`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getSingleRole = async (id) => {
  try {
    const response = await Axios.get(`/admin/role/read/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const createRole = async (data) => {
  try {
    const response = await Axios.post(`/admin/role/create`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const updateRole = async (data, id) => {
  try {
    const response = await Axios.post(`/admin/role/update/${id}`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deleteRole = async (id) => {
  try {
    const response = await Axios.post(`/admin/role/delete`, id);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getAllPermissions = async () => {
  try {
    const response = await Axios.get(`/admin/permission`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAllPermissionsPages = async (row, pages) => {
  try {
    const response = await Axios.get(`/admin/permission?r=${row}&p=${pages}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getSinglePermission = async (id) => {
  try {
    const response = await Axios.get(`/admin/permission/read/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const createPermission = async (data) => {
  try {
    const response = await Axios.post(`/admin/permission/create`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const updatePermission = async (data, id) => {
  try {
    const response = await Axios.post(`/admin/permission/update/${id}`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deletePermission = async (id) => {
  try {
    const response = await Axios.post(`/admin/permission/delete`, id);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getAllPermissionRole = async () => {
  try {
    const response = await Axios.get(`/admin/permissionrole`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getAllPermissionRolePages = async (row, pages) => {
  try {
    const response = await Axios.get(
      `/admin/permissionrole?r=${row}&p=${pages}`
    );
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getSinglePermissionRole = async (id) => {
  try {
    const response = await Axios.get(`/admin/permissionrole/read/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const createPermissionRole = async (data) => {
  try {
    const response = await Axios.post(`/admin/permissionrole/create`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deletePermissionRole = async (id) => {
  try {
    const response = await Axios.post(`/admin/permissionrole/delete`, id);
    return response.data;
  } catch (error) {
    return error;
  }
};
