import React, { useEffect, useState } from "react";
import { Table } from "antd";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link } from "react-router-dom";
import { deleteHotel, getAllHotel } from "../../../api-core/Hotel";
import { getAllVendors, getSingleVendor } from "../../../api-core/Vendor";
import { useParams } from "react-router-dom";

const SingleVendor = () => {
  const { id } = useParams();
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    const fetchSingleVendor = async () => {
      setloading(true);
      const res = await getSingleVendor(id);
      // console.log("res", res);

      setdata(res.vendor);
      setloading(false);
    };
    fetchSingleVendor();
  }, []);

  const onDeleteRecord = async (record, id) => {
    const res = await deleteHotel(record);
    // console.log("deData", res);
  };

  const datas = data.map((item) => ({
    key: item._id,
    vendorName: item.vendor_name,
    vendorAddress: item.vendor_add,
    vendorMobile: item.vendor_mobile,
    vendorEmail: item.vendor_email,
  }));
  // console.log("datas", datas);

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Vendor Name",
      dataIndex: "vendorName",
      key: "vendorName",
      ellipsis: true,
    },

    {
      title: "Vendor Address",
      dataIndex: "vendorAddress",
      key: "vendorAddress",

      ellipsis: true,
    },
    {
      title: "Vendor Mobile",
      dataIndex: "vendorMobile",
      key: "vendorMobile",
    },
    {
      title: "Vendor Email",
      dataIndex: "vendorEmail",
      key: "vendorEmail",
    },
  ];
  return (
    <div className="">
      <h1 className="text-center">List Of Vendor</h1>
      <div className="">
        <Table
          dataSource={datas}
          columns={columns}
          loading={loading}
          // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        />
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(SingleVendor);
