import { useEffect, useState } from "react";
import moment from "moment";
import { Button, Input, message, Popconfirm, Table } from "antd";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useGetevents from "src/hooks/fetchEvents";

import {
  deleteEvent,
  getAllEvents,
  // getAllEvents2,
} from "../../../api-core/Events";
import { TIME_FORMATS } from "src/constants";

const ListOfAllCurrentEvents = () => {
  const [pages, setPages] = useState(1);
  const [row, setTotal] = useState(10);
  const [searchedText, setSearchedText] = useState("");
  const { data, loading, fetchAllEvents } = useGetevents(pages, row, setTotal);
  const { t } = useTranslation();

  const onDeleteRecord = async (id) => {
    const res = await deleteEvent(id);
    if (res.success === true) {
      message.success(res.msgText);
      fetchAllEvents();
      // const updatedData = data.filter((items)=>items._id !== id)
      // setdata(updatedData)
    }
  };

  const handleSearch = (val) => {
    setSearchedText(val);
  };

  const handleChange = (e) => {
    setSearchedText(e.target.value);
  };

  const datas = data?.map((item) => ({
    key: item._id,
    ...item,
  }));

  const columns = [
    {
      title: t("SERIAL_NO"),
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: t("CLIENT_NAME"),
      dataIndex: "name",
      key: "name",
      ellipsis: true,
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return (
          String(record.name).toLowerCase().includes(value.toLowerCase()) ||
          String(record.event_type)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.event_title)
            .toLowerCase()
            .includes(value.toLowerCase()) ||
          String(record.hotel_name).toLowerCase().includes(value.toLowerCase())
        );
      },
      render: (_, record) => record?.client_id?.name || "N/A",
    },
    {
      title: t("EVENT_TYPE"),
      dataIndex: "event_type",
      key: "event_type",
      ellipsis: true,
    },
    {
      title: t("EVENT_TITLE"),
      dataIndex: "event_title",
      key: "event_title",
      ellipsis: true,
    },
    {
      title: t("EVENT_START_DATE"),
      dataIndex: "event_start_date",
      key: "event_start_date",
      ellipsis: true,
      render: (_, record) =>
        record?.event_start_date
          ? moment(record?.event_start_date).format(TIME_FORMATS.DATE)
          : "N/A",
    },
    {
      title: t("EVENT_END_DATE"),
      dataIndex: "event_end_date",
      key: "event_end_date",
      ellipsis: true,
      render: (_, record) =>
        record?.event_end_date
          ? moment(record?.event_end_date).format(TIME_FORMATS.DATE)
          : "N/A",
    },
    {
      title: t("HOTELS"),
      dataIndex: "hotel_name",
      key: "hotel_name",
      ellipsis: true,
      render: (text, record) => {
        const filteredHotels = (record.hotels || []).filter(
          (item) => item?.hotel_id?.hotel_name
        );
        return filteredHotels.length
          ? filteredHotels.map((item) => item?.hotel_id?.hotel_name).join(", ")
          : "N/A";
      },
    },
    {
      title: t("ACTION"),
      key: "action",
      render: (text, record) => (
        <div className="flex flex-wrap gap-x-2">
          <Link to={`/admin/event/${record.key}`}>
            <button className="text-blue-600 cursor-pointer">View</button>
          </Link>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => onDeleteRecord(record.key)}
          >
            <span className="text-red-500 cursor-pointer">Delete</span>
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div className="">
      <Link to="/admin/createCurrentEvent">
        <button className="p-3 mx-3 text-white bg-blue-600">
          {t("CREATE_EVENT")}
        </button>
      </Link>

      <div>
        <h1 className="text-center">{t("LIST_OF_EVENTS")}</h1>
        <Input.Search
          size="large"
          placeholder={t("SEARCH_HERE")}
          style={{ marginBottom: 8, width: 280 }}
          onSearch={
            handleSearch
            //   (value) => {
            //   setSearchedText(value);
            // }
          }
          onChange={
            handleChange
            //   (e) => {
            //   setSearchedText(e.target.value);
            // }
          }
        />
        <Table
          dataSource={datas}
          columns={columns}
          loading={loading}
          style={{}}
          pagination={{ pageSize: 10, total: row }}
        />
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(ListOfAllCurrentEvents);
