import SidebarItem from "./SidebarItem";
import { Navigation } from "src/constants/sidebar";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Sidebar() {
  const { t } = useTranslation();
  const sidebarHeading = () => (
    <div className="text-center  mb-5 mt-2 bg-[#898787]">
      <h1 className="text-xl text-white">{t("BLUE_BIRD_EVENTS")}</h1>
    </div>
  );

  const dashboardLink = () => (
    <div>
      <Link to={"/admin/dashboard"}>
        <h1 className="text-lg text-center text-white">{t("DASHBOARD")}</h1>
      </Link>
    </div>
  );

  return (
    <div className="hidden sidebar md:block">
      {sidebarHeading()}
      {dashboardLink()}
      {Navigation.map((item, index) => (
        <SidebarItem key={index} item={item} />
      ))}
    </div>
  );
}
