import React from "react";
import { message, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { ShouldRender } from "../ShouldRender";
import { eventUploadImageFile } from "src/api-core/Events";
import type { RcFile } from "antd/es/upload";

const { Dragger } = Upload;

interface Props {
  maxCount?: number;
  src?: string;
  handleChange: (file: string) => void;
}

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const ImageUploader = ({ handleChange, maxCount = 1, src }: Props) => {
  const [previewImage, setPreviewImage] = React.useState("");

  return (
    <React.Fragment>
      <Dragger
        accept={"image/png, image/jpg, image/jpeg"}
        maxCount={maxCount}
        showUploadList={false}
        customRequest={async ({ file, onError, onSuccess }) => {
          //@ts-ignore
          if (file && file.size > 2 * 1024 * 1024) {
            return message.error("File exceeds the limit of 2MB");
          }
          const formData = new FormData();
          formData.append("img_files", file);
          eventUploadImageFile(formData)
            .then((response) => {
              if (onSuccess) {
                onSuccess({
                  menuFile: response.files[0].file,
                });
              }
            })
            .catch(onError);
        }}
        onChange={(info) => {
          if (info.file.status === "done") {
            if (info.file.originFileObj) {
              getBase64(info.file.originFileObj).then(setPreviewImage);
            }
            if (info.file.response) {
              handleChange(info.file.response?.menuFile);
            }
          }
        }}
      >
        <div className="flex justify-center w-full px-4">
          <ShouldRender check={src}>
            <img
              className="hover:opacity-75"
              alt="menu"
              src={previewImage || src}
            />
          </ShouldRender>
          <ShouldRender check={!src}>
            <div>
              <span className="flex justify-center py-2">
                <UploadOutlined className="text-white" rev={undefined} />
              </span>
              <p className="w-56 font-medium ant-upload-hint ">
                Upload a file or drag and drop. JPG & PNG up to 2mb
              </p>
            </div>
          </ShouldRender>
        </div>
      </Dragger>
    </React.Fragment>
  );
};

export default ImageUploader;
