import React, { useEffect, useState } from "react";
import { Table } from "antd";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link, useParams } from "react-router-dom";
import { deleteHotel, getAllHotel } from "../../../api-core/Hotel";
import { getAllRoles, getSingleRole } from "../../../api-core/RolesPermissions";

const SingleRole = () => {
  const { id } = useParams();
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    const fetchAllRoles = async () => {
      setloading(true);
      const res = await getSingleRole(id);
      setdata(res.role);
      setloading(false);
    };
    fetchAllRoles();
  }, []);

  const onDeleteRecord = async (record, id) => {
    const res = await deleteHotel(record);
  };

  const datas = data.map((item) => ({
    key: item._id,
    roleName: item.role_name,
  }));

  const columns = [
    {
      title: "Sr",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Role Name",
      dataIndex: "roleName",
      key: "roleName",
      ellipsis: true,
    },
  ];
  return (
    <div className="">
      <h1 className="text-center">List Of Role</h1>
      <div className="">
        <Table
          dataSource={datas}
          columns={columns}
          loading={loading}
          // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        />
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(SingleRole);
