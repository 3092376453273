import React, { useEffect, useState } from "react";
import { Button, Input, message, Popconfirm, Table } from "antd";
import {
  deleteTrivia,
  getAllTrivia,
  getAllTriviaPage,
} from "../../../api-core/Trivia";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link } from "react-router-dom";

const ListOfTrivia = () => {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [row, setRow] = useState(10);
  const [pages, setPages] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchedText, setSearchedText] = useState("");

  useEffect(() => {
    fetchAllTrivia();
  }, [row, pages]);
  const fetchAllTrivia = async () => {
    setloading(true);
    const res = await getAllTrivia();
    if (res.success === true) {
    }
    const res2 = await getAllTriviaPage(row, pages);
    setRow(res2?.count);
    setdata(res2?.trivia);

    setloading(false);
  };

  const onDeleteRecord = async (record, id) => {
    const recordId = {
      ids: [record],
    };
    const res = await deleteTrivia(recordId);
    if (res.success == true) {
      message.success(res.msgText);
      fetchAllTrivia();
      // const updatedData = data.filter((items)=>items._id !== record)
      // setdata(updatedData)
    }
  };

  const datas = data?.map((item) => ({
    key: item._id,
    description: item.descp,
    title: item.title,
  }));

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const deleteAllList = async () => {
    if (selectedRowKeys) {
      const deletePayload = {
        ids: [...selectedRowKeys],
      };
      if (deletePayload) {
        const res = await deleteTrivia(deletePayload);

        if (res.success == true) {
          message.success(
            `${selectedRowKeys.length} items deleted successfully`
          );
          setSelectedRowKeys(0);
          fetchAllTrivia();
        }
      }
    }
  };

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Trivia Title",
      dataIndex: "title",
      key: "title",
      filteredValue: [searchedText],
      onFilter: (value, record) => {
        return String(record.title).toLowerCase().includes(value.toLowerCase());
        // ellipsis: true,
      },
    },
    {
      title: "Trivia Description",
      dataIndex: "description",
      key: "description",
      // ellipsis: true,
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex gap-x-2 ">
          <Link to={`/admin/singletrivia/${record.key}`}>
            <button className="text-blue-600 cursor-pointer">View</button>
          </Link>

          <Link to={`/admin/updatetrivia/${record.key}`}>
            <button className="text-yellow-400 cursor-pointer">Edit</button>
          </Link>

          <Popconfirm
            title="Sure to delete?"
            onConfirm={(e) => onDeleteRecord(record.key, e)}
          >
            <span className="text-red-500 cursor-pointer">Delete</span>
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div>
      <Link to="/admin/createtrivia">
        <button className="bg-blue-600 text-white p-3 mx-3">Add Trivia</button>
      </Link>
      {selectedRowKeys.length > 1 && (
        <>
          <Popconfirm
            title={`Do you really wanted to delete ${selectedRowKeys.length} items`}
            onConfirm={() => deleteAllList()}
          >
            {selectedRowKeys.length > 1 ? (
              <Button danger type="primary">
                Delete All ({selectedRowKeys.length})
              </Button>
            ) : (
              ""
            )}
          </Popconfirm>
        </>
      )}
      <h1 className="text-center">List Of Trivia</h1>
      <Input.Search
        size="large"
        placeholder="search here..."
        style={{ marginBottom: 8, width: 280 }}
        onSearch={(value) => {
          setSearchedText(value);
        }}
        onChange={(e) => {
          setSearchedText(e.target.value);
        }}
      />
      <Table
        rowSelection={rowSelection}
        dataSource={datas}
        columns={columns}
        loading={loading}
        pagination={{ total: row, pageSize: 10 }}
      />
    </div>
  );
};

export default SidebarHeaderLayout(ListOfTrivia);
