import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Input, message, Popconfirm, Table } from "antd";

import SidebarHeaderLayout from "src/components/HOC/SidebarHeaderLayout";
import HotelModal from "./HotelModal";
import { ShouldRender } from "src/components/ShouldRender";

import { useFetchHotels } from "src/hooks";
import { createHotel, getAllHotelPages, updateHotel } from "src/api-core/Hotel";
import { IHotelOption } from "src/types";

const ListOfAllHotel = () => {
  // const [hotel, setHotel] = useState([]);
  // const [row, setRow] = useState(10);
  // const [pages, setPages] = useState(1);
  const {
    handleDeleteHotel,
    handleHotelCreateSuccess,
    handleHotelUpdateSuccess,
    hotels,
    loading,
    row,
    pages,
  } = useFetchHotels(true);
  const [open, setOpen] = React.useState(false);
  const [recordIndex, setRecordIndex] = React.useState(-1);
  const [searchedText, setSearchedText] = useState("");

  const onDeleteRecord = async (id: string) => handleDeleteHotel(id);

  const handleClose = () => {
    setOpen(false);
    setRecordIndex(-1);
  };



  const handleOpen = () => setOpen(true);

  const handleEdit = (index: number) => {
    setRecordIndex(index);
    handleOpen();
  };

  const handleSave = async (
    hotel_add: string,
    hotel_mob: string,
    hotel_name: string
  ) => {
    if (recordIndex === -1) {
      const data = {
        hotel_add,
        hotel_mob,
        hotel_name,
      };
      const res = await createHotel(data);
      if (res.success === true) {
        message.success("Created Successfully");
        console.log(res,'res')
        handleHotelCreateSuccess(res?.hotel);
        
        console.log('created')
      }
    } else {
      const selectedHotel = hotels[recordIndex];
      const data = {
        hotel_add,
        hotel_mob,
        hotel_name,
      };
      const res = await updateHotel(data, selectedHotel._id);
      if (res.success === true) {
        handleHotelUpdateSuccess(recordIndex, {
          ...selectedHotel,
          hotel_add,
          hotel_mob: +hotel_mob,
          hotel_name,
        });
      }
    }
    handleClose();
  };

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      sorter: (a: any, b: any) => a.id - b.id,
      render: (id: any, record: any, index: any) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Hotel Name",
      dataIndex: "hotel_name",
      key: "hotel_name",
      ellipsis: true,
      filteredValue: [searchedText],
      onFilter: (value:any, record:any)=>{
        return String(record.hotel_name)
                .toLowerCase()
                .includes(value.toLowerCase()) ||
                String(record.hotel_add)
                .toLowerCase()
                .includes(value.toLowerCase()) ||
                String(record.hotel_mob)
                .toLowerCase()
                .includes(value.toLowerCase())

      },
      render: (value: any) => <span className="capitalize">{value}</span>,
    },

    {
      title: "Hotel Address",
      dataIndex: "hotel_add",
      key: "hotel_add",

      ellipsis: true,
    },
    {
      title: "Hotel Mobile",
      dataIndex: "hotel_mob",
      key: "hotel_mob",
      render: (hotel_mob: IHotelOption) => (
        <a href={`tel:${hotel_mob}`} >{`${hotel_mob}`}</a>
      )
    },

    {
      title: "Action",
      key: "action",
      render: (_: any, record: IHotelOption) => {
        const index = hotels.findIndex((item) => item._id === record._id);
        return (
          <div className="flex flex-wrap gap-x-2">
            <Link to={`/admin/hotel/${record._id}`}>
              <button className="text-blue-600 cursor-pointer">View</button>
            </Link>

            <button
              className="text-yellow-400 cursor-pointer"
              onClick={() => handleEdit(index)}
            >
              Edit
            </button>

            {/* <Popconfirm
              title="Sure to delete?"
              onConfirm={(e) => onDeleteRecord(record._id)}
            >
              <span className="text-red-500 cursor-pointer">Delete</span>
            </Popconfirm> */}
          </div>
        );
      },
    },
  ];

  return (
    <div className="mt-4">
      {/* <div className="flex justify-end"> */}
        <button className="p-3 mx-3 text-white bg-blue-600" onClick={handleOpen}>
          Create Hotel
        </button>
      {/* </div> */}
      <h1 className="text-center">List Of All Hotels</h1>
      <div>
      <Input.Search size="large" placeholder="search here..."
             style={{marginBottom: 8, width: 280}}
             onSearch={(value)=>{
              setSearchedText(value);
             }}
             onChange={(e)=>{
              setSearchedText(e.target.value);
             }}/>

        <Table
          key="_id"
          dataSource={hotels}
          columns={columns}
          loading={loading}
          pagination={{ total: row, pageSize: 10 }}
        />
      </div>
      <ShouldRender check={open}>
        <HotelModal
          open={open}
          handleClose={handleClose}
          handleSave={handleSave}
          hotelDetails={hotels[recordIndex]}
          title={recordIndex === -1 ? "Create Hotel" : "Update Hotel"}
        />
      </ShouldRender>
    </div>
  );
};

export default SidebarHeaderLayout(ListOfAllHotel);
