import React, { useEffect, useState } from "react";
import { message, Popconfirm, Table } from "antd";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link } from "react-router-dom";
import {
  deletePermissionRole,
  getAllPermissionRole,
  getAllPermissionRolePages,
} from "../../../api-core/RolesPermissions";

const ListOfPermissionRole = () => {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [row, setRow] = useState(10);
  const [pages, setPages] = useState(1);

  useEffect(() => {
    fetchAllPermissionRole();
  }, [row, pages]);
  const fetchAllPermissionRole = async () => {
    setloading(true);
    const res = await getAllPermissionRolePages(row, pages);

    setdata(res?.permrole);
    setRow(res?.count);
    setloading(false);
  };

  const onDeleteRecord = async (record, id) => {
    const recordId = {
      ids: [record],
    };
    const res = await deletePermissionRole(recordId);
    if (res.success == true) {
      message.success(res.msgText);
      fetchAllPermissionRole();
    }
  };

  const datas = data?.map((item) => ({
    key: item._id,
    permName: item.permission_id?.perm_name,
    roleName: item.role_id.role_name,
  }));

  const columns = [
    {
      title: "Sr",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Permission Name",
      dataIndex: "permName",
      key: "permName",
      ellipsis: true,
    },
    {
      title: "Role Name",
      dataIndex: "roleName",
      key: "roleName",
      ellipsis: true,
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex gap-x-2 ">
          {/* <Link to={`/admin/singlepermissionrole/${record.key}`}>
            <button className="text-blue-600 cursor-pointer">View</button>
          </Link> */}

          <Link to={`/admin/updatepermissionrole/${record.key}`}>
            <button className="text-yellow-400 cursor-pointer">Edit</button>
          </Link>

          <Popconfirm
            title="Sure to delete?"
            onConfirm={(e) => onDeleteRecord(record.key, e)}
          >
            <span className="text-red-500 cursor-pointer">Delete</span>
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div className="">
      <Link to="/admin/createpermissionrole">
        <button className="bg-blue-600 text-white p-3 mx-3">
          Create Permission Role
        </button>
      </Link>
      <h1 className="text-center">List Of Permission Role</h1>
      <div className="">
        <Table dataSource={datas} columns={columns} loading={loading} scroll />
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(ListOfPermissionRole);
