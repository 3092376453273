import { Button, Modal, Popconfirm, message } from "antd";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import SidebarHeaderLayout from "src/components/HOC/SidebarHeaderLayout";
import LoadingSpinner from "src/components/Loading";
import { ShouldRender } from "src/components/ShouldRender";
import { useFetchHotels } from "src/hooks";
import { useFetchHotelDetail } from "src/hooks/useFetchHotelDetail";
import { FloorRooms } from "src/types";
import { CreateHotelRoomModal } from "../../Event/modals";
import { HotelRoomsRequired } from "../../Event/modals/CreateHotelRoom";
import { deleteHotelRoom, getAllHotelRoomType } from "src/api-core/Hotel";
import { DeleteOutlined } from "@ant-design/icons";

const HotelDetailPage = () => {
  const { id } = useParams();
  const {
    handleCreateRoomSuccess,
    hotel,
    loading,
    rooms,
    handleDeleteRoomSuccess,
  } = useFetchHotelDetail(id);
  const { hotels } = useFetchHotels(true);
  const [open, setOpen] = useState(false);
  const [roomType, setRoomType] = useState<any>([]);
  const [standard, setStandard] = useState<any>("");
  const [deluxe, setDeluxe] = useState<any>("");
  const [suite, setSuite] = useState<any>("");
  const [deleted, setDeleted] = useState("");
  const [validateRoomNumber, setValidateRoomNumber] = useState<any>([]);

  useEffect(() => {
    const fetchAllRoomTypes = async () => {
      const res = await getAllHotelRoomType();

      setRoomType(res?.hotelroomtype);
    };
    fetchAllRoomTypes();
  }, [id]);

  useEffect(() => {
    setStandard(
      roomType?.filter((i: any) => i.room_type == "Standard")[0]?._id
    );

    setDeluxe(roomType?.filter((i: any) => i.room_type == "Deluxe")[0]?._id);

    setSuite(roomType?.filter((i: any) => i.room_type == "Suite")[0]?._id);
  }, [roomType]);

  const handleClose = () => setOpen(false);

  const handleOpen = () => setOpen(true);

  const floorWithRooms = useMemo(() => {
    const object = {} as FloorRooms;
    setValidateRoomNumber(rooms);

    if (rooms) {
      rooms.forEach(({ floor_no, room_no, _id, room_type_id }) => {
        if (object[floor_no]) {
          object[floor_no].push({
            room_no,
            hotel_room_id: _id,
            isBooked: 0,
            room_type_id,
          });
        } else {
          object[floor_no] = [
            {
              room_no,
              hotel_room_id: _id,
              isBooked: 0,
              room_type_id,
            },
          ];
        }
      });
    }

    return object;
  }, [rooms]);

  const hotelRoomsRequired = useMemo(() => {
    const hotel_rooms_required: HotelRoomsRequired[] = [];

    Object.keys(floorWithRooms).forEach((floor) => {
      hotel_rooms_required.push({
        floor_no: +floor,
        // room_nos: floorWithRooms[floor].map((room) => room.room_no).join(", "),
        room_nos: {
          standard: floorWithRooms[floor]
            .map((room) => room.room_no)
            .join(", "),
          deluxe: floorWithRooms[floor].map((room) => room.room_no).join(", "),
          suite: floorWithRooms[floor].map((room) => room.room_no).join(", "),
        },
      });
    });

    return hotel_rooms_required;
  }, [floorWithRooms]);

  const onDeleteRoom = async (id: string) => {
    setDeleted(id);

    const payload = {
      ids: [id],
    };

    const res = await deleteHotelRoom(payload);
    const updatedRooms = rooms.filter((items) => items._id !== id);
    handleDeleteRoomSuccess(updatedRooms);
  };

  return (
    <div className="flex-1 p-3 text-center">
      <ShouldRender check={!loading}>
        <ShouldRender check={hotel}>
          <div className="flex flex-col h-full mx-auto lg:max-w-3xl xl:max-w-4xl">
            <h3 className="mb-4 text-4xl font-bold">{hotel?.hotel_name}</h3>
            <div className="flex flex-col justify-between mt-5 md:flex-row text-start">
              <p className="max-w-md text-xl">
                Located At -
                <span className="font-semibold">{hotel?.hotel_add}</span>
              </p>
              <p className="text-xl">
                Contact At -{" "}
                <span className="font-semibold">
                  <a href={`tel:${hotel?.hotel_mob}`}>{hotel?.hotel_mob}</a>
                </span>
              </p>
              <div className="flex gap-2">
                <span className="p-2 font-semibold bg-slate-600 border-2 border-black rounded-xl">
                  Standard
                </span>
                <span className="p-2 font-semibold bg-slate-300 border-2 border-black rounded-xl">
                  Deluxe
                </span>
                <span className="p-2 font-semibold bg-slate-500 border-2 border-black rounded-xl">
                  Suite
                </span>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center ">
              <p className="text-2xl font-bold">Checked Rooms</p>
              <ShouldRender check={rooms?.length > 0}>
                {Object.keys(floorWithRooms).map((floor) => (
                  <div className="my-5 text-start " key={`floor-${floor} `}>
                    <p className="text-xl font-semibold">Floor {floor}</p>

                    <div className="grid grid-cols-2 gap-4 sm:grid-cols-4 lg:grid-cols-6">
                      {/* {floorWithRooms[floor].map((room) => (
                        <span
                          className="p-2 font-semibold bg-white border-2 border-black rounded-xl"
                          key={`floor-${floor}-room-${room.room_no}`}
                        >
                          Room {room.room_no}
                        </span>
                      ))} */}
                      {floorWithRooms[floor]
                        .filter((items: any) => items.room_type_id == standard)
                        .map((values: any) => (
                          <div className="flex flex-col  justify-center items-center border-2 border-black bg-slate-600 rounded-xl">
                            <Popconfirm
                              title={`Are you sure to delete Room no ${values.room_no}`}
                              onConfirm={() =>
                                onDeleteRoom(values.hotel_room_id)
                              }
                              okText="Yes"
                              cancelText="No"
                            >
                              <div className="relative left-6 cursor-pointer">
                                <DeleteOutlined
                                  style={{ color: "red" }}
                                  rev={undefined}
                                />
                                {/* <div className="opacity-0 hover:opacity-100 duration-300 absolute inset-0 z-10 flex justify-center items-center text-sm text-white font-semibold">
                                Delete
                              </div> */}
                              </div>
                            </Popconfirm>
                            <div
                              key={`floor-${floor}-room-${values.room_no}`}
                              className="text-white w-[4.5rem] h-[2rem] font-semibold rounded-xl bg-slate-600 flex justify-center items-center"
                            >
                              {values.room_no}
                            </div>
                          </div>
                        ))}
                      {floorWithRooms[floor]
                        .filter((items: any) => items.room_type_id == deluxe)
                        .map((values: any) => (
                          <div className="flex flex-col  justify-center items-center border-2 border-black bg-slate-300 rounded-xl">
                            <Popconfirm
                              title={`Are you sure to delete Room no ${values.room_no}`}
                              onConfirm={() =>
                                onDeleteRoom(values.hotel_room_id)
                              }
                              okText="Yes"
                              cancelText="No"
                            >
                              <div className="relative left-6 cursor-pointer">
                                <DeleteOutlined
                                  style={{ color: "red" }}
                                  rev={undefined}
                                />
                                {/* <div className="opacity-0 hover:opacity-100 duration-300 absolute inset-0 z-10 flex justify-center items-center text-sm text-white font-semibold">
                                Delete
                              </div> */}
                              </div>
                            </Popconfirm>
                            <div
                              key={`floor-${floor}-room-${values.room_no}`}
                              className="text-white w-[4.5rem] h-[2rem] font-semibold rounded-xl bg-slate-300 flex justify-center items-center"
                            >
                              {values.room_no}
                            </div>
                          </div>
                        ))}
                      {floorWithRooms[floor]
                        .filter((items: any) => items.room_type_id == suite)
                        .map((values: any) => (
                          <div className="flex flex-col  justify-center items-center border-2 border-black bg-slate-500 rounded-xl">
                            <Popconfirm
                              title={`Are you sure to delete Room no ${values.room_no}`}
                              onConfirm={() =>
                                onDeleteRoom(values.hotel_room_id)
                              }
                              okText="Yes"
                              cancelText="No"
                            >
                              <div className="relative left-6 cursor-pointer">
                                <DeleteOutlined
                                  style={{ color: "red" }}
                                  rev={undefined}
                                />
                                {/* <div className="opacity-0 hover:opacity-100 duration-300 absolute inset-0 z-10 flex justify-center items-center text-sm text-white font-semibold">
                                Delete
                              </div> */}
                              </div>
                            </Popconfirm>
                            <div
                              key={`floor-${floor}-room-${values.room_no}`}
                              className="text-white w-[4.5rem] h-[2rem] font-semibold rounded-xl bg-slate-500 flex justify-center items-center"
                            >
                              {values.room_no}
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
                <div className="my-4">
                  <Button onClick={handleOpen}>Add Rooms</Button>
                </div>
              </ShouldRender>

              <ShouldRender check={!rooms?.length}>
                <p className="text-lg">
                  No rooms found, add rooms to the hotel
                </p>
                <Button onClick={handleOpen}>Add Rooms</Button>
              </ShouldRender>
            </div>
          </div>
        </ShouldRender>
      </ShouldRender>
      <ShouldRender check={loading}>
        <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full">
          <LoadingSpinner />
        </div>
      </ShouldRender>
      <ShouldRender check={open}>
        <CreateHotelRoomModal
          handleClose={handleClose}
          handleRoomCreateSuccess={handleCreateRoomSuccess}
          hotel_id={id}
          hotelRoomsRequired={hotelRoomsRequired}
          open={open}
          options={hotels}
          title={
            hotelRoomsRequired.length ? "Add Hotel Rooms" : "Create Hotel Rooms"
          }
        />
      </ShouldRender>
    </div>
  );
};

export default SidebarHeaderLayout(HotelDetailPage);
