import Axios from "../Axios";

export const getAllEnquiry = async () => {
  try {
    const response = await Axios.get(`/admin/enquiry`);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAllEnquiryPages = async (row, pages) => {
  try {
    const response = await Axios.get(`/admin/enquiry?r=${row}&p=${pages}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

/* Get single Enquiery */

export const getSingleEnquiry = async (id) => {
  try {
    const response = await Axios.get(`/admin/enquiry/read/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

/* Delete Single Record */

export const deleteSingleEnquiry = async (id) => {
  try {
    const response = await Axios.post(`/admin/enquiry/delete`, id);
    return response.data;
  } catch (error) {
    return error;
  }
};

/* Update Enquiry*/

export const updateEnquiry = async (data, id) => {
  try {
    const response = await Axios.post(``, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
