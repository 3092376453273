import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import CreateUser from "./CreateUsers";
const CreateUsers = () => {
  return (
    <div>
      <CreateUser />
    </div>
  );
};

export default SidebarHeaderLayout(CreateUsers);
