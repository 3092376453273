import React, { useEffect, useState } from "react";
import { Button, Input, message, Modal } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";
import { FormInput } from "src/components/FormInput";
import { IHotelOption, hospitality_checklist, IHotelRoom } from "src/types";
import {
  createHotel,
  createHotelRoom,
  getAllHotelRoomType,
} from "src/api-core/Hotel";
import { Label } from "src/components/Label";
import { clonseJSON } from "src/utils/json";
import { ShouldRender } from "src/components/ShouldRender";
import { phoneRegExp } from "src/utils/helper";

type FormErrors = {
  hotel_name: string;
  hotel_add: string;
  hotel_mob: string;
  hotel_rooms_required: {
    floor_no: string;
    room_nos: {
      standard?: string | undefined;
      deluxe?: string | undefined;
      suite?: string | undefined;
    };
  }[];
};

// type FormErrors = {
//   hotel_name: string;
//   hotel_add: string;
//   hotel_mob: string;
//   hotel_rooms_required: {
//     floor_no: string;
//     room_nos: {
//       standard: any;
//       deluxe: any;
//       suite: any;
//     };
//   }[];
// };

const validationSchema = Yup.object().shape({
  hotel_name: Yup.string()
    .required("Hotel name is required")
    .min(3, "must contains 3 char"),
  hotel_add: Yup.string()
    .required("Hotel Address is required")
    .min(3, "must contains 3 char"),
  hotel_mob: Yup.string()
    .required("Hotel Number is required")
    .matches(phoneRegExp, "Number is not valid")
    .min(10, "too short")
    .max(10, "too long"),

  // hotel_rooms_required: Yup.array(
  //   Yup.object({
  //     floor_no: Yup.number().required("Floor Number is required"),
  //     // room_nos: Yup.string().required("Please enter rooms"),

  //   })
  // ).min(1),

  // hotel_rooms_required: Yup.array(
  //   Yup.object({
  //     floor_no: Yup.number().required("Floor Number is required"),
  //     room_nos: Yup.object({
  //       standard: Yup.string(),
  //       deluxe: Yup.string(),
  //       suite: Yup.string(),
  //     }),
  //   })
  // ),
});

export type IHotelRoomResponse = Omit<IHotelRoom, "hotel_id"> & {
  hotel_id: string;
};

interface Props {
  open: boolean;
  handleClose: () => void;
  handleHotelCreateSuccess?: (hotel: IHotelOption) => void;
  handleRoomCreateSuccess?: (hotel: IHotelRoom[]) => void;
  title: string;
}

export const CreateHotelModal = ({
  handleClose,
  handleHotelCreateSuccess,
  handleRoomCreateSuccess,
  open,
  title,
}: Props) => {
  const initialValues = {
    hotel_name: undefined as string | undefined,
    hotel_add: undefined as string | undefined,
    hotel_mob: undefined as string | undefined,
    hotel_rooms_required: [
      {
        floor_no: undefined as number | undefined,
        room_nos: {
          standard: "" as any | undefined,
          deluxe: "" as any | undefined,
          suite: "" as any | undefined,
        },
      },
    ],
  };

  const [roomType, setRoomType] = useState<any>([]);
  const [duplicateRoom, setDuplicateRoom] = useState(false);

  useEffect(() => {
    const fetchAllHotelType = async () => {
      const res = await getAllHotelRoomType();
      setRoomType(res?.hotelroomtype);
    };
    fetchAllHotelType();
  }, []);

  /* 
  
   const initialValues = {
    hotel_name: undefined as string | undefined,
    hotel_add: undefined as string | undefined,
    hotel_mob: undefined as string | undefined,
    hotel_rooms_required: [
      {
        floor_no: undefined as number | undefined,
        room_nos: "",
      },
    ],
  };
  
  */

  const handleCreateHotel = async (
    hotel_add: string,
    hotel_mob: string,
    hotel_name: string
  ): Promise<IHotelOption | undefined> => {
    const data = {
      hotel_add,
      hotel_mob,
      hotel_name,
    };
    const res = !duplicateRoom
      ? await createHotel(data)
      : message.error("Same room is not allowed");
    if (duplicateRoom) {
      return;
    } else {
      if (res.success === true) {
        if (handleHotelCreateSuccess) handleHotelCreateSuccess(res.hotel);
        message.success("Created Succcessfully!");

        //   message.success("Created Successfully");
        // if (handleHotelCreateSuccess) {
        //   handleHotelCreateSuccess(res.hotel);
        // }
        return res?.hotel;
      }
    }
  };

  const handleCreateRoom = async (
    hotelroom: Omit<IHotelRoomResponse, "_id" | "room_type">[]
  ) => {
    const payload = {
      hotelroom,
    };
    const response = await createHotelRoom(payload);
    if (response.success === true) {
      // message.success("Created Successfully");
      const hotelRooms = (
        (response?.hotelroom || []) as IHotelRoomResponse[]
      ).map((item) => item);
      // .map((item) => ({
      //   ...item,
      //   // hotel_id: {
      //   //   _id: item.hotel_id,
      //   //   hotel_name: "",
      //   // },
      // }));
      console.log("from create hotel", hotelRooms);
      if (handleRoomCreateSuccess) handleRoomCreateSuccess(hotelRooms);
      return hotelRooms;
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={async (values) => {
        const { hotel_add, hotel_mob, hotel_name, hotel_rooms_required } =
          values;

        const roomValidation = values?.hotel_rooms_required.filter(
          (items) =>
            items.room_nos.deluxe !== "" ||
            items.room_nos.suite !== "" ||
            items.room_nos.standard !== ""
        );
        if (Object.keys(roomValidation).length !== 0) {
          if (hotel_add && hotel_mob && hotel_name) {
            const hotel = await handleCreateHotel(
              hotel_add,
              hotel_mob,
              hotel_name
            );
            if (hotel?._id) {
              // const checkListedRooms: Omit<
              //   IHotelRoomResponse,
              //   "_id" | "room_type"
              // >[] = [];
              const checkListedRooms: any = [];

              // hotel_rooms_required.forEach((items, index) => {
              //   items.room_nos
              //     .standard!.split(",")
              //     .map((room: any) => room.trim())
              //     .forEach((room: any, i: any) => {
              //       const [start, end]: any = room
              //         .split(",")
              //         .map((item: any) => Number(item.trim()));

              //       if (start && end) {
              //         console.log("hello", start);
              //         for (let i = start; i < end; i++) {
              //           checkListedRooms.push({
              //             hotel_id: hotel._id,
              //             floor_no: items.floor_no!,
              //             room_no: i,
              //             hospitality_checklist,
              //           });
              //           console.log(checkListedRooms, "checklistRooms");
              //         }
              //       } else {
              //         const roomNumber = Number(room);
              //         if (!Number.isNaN(roomNumber))
              //           checkListedRooms.push({
              //             hotel_id: hotel._id,
              //             floor_no: items.floor_no!,
              //             room_no: roomNumber,
              //             hospitality_checklist,
              //           });
              //       }
              //     });
              // });

              /*Standard */

              hotel_rooms_required.forEach((item, index) => {
                item.room_nos
                  .standard!.split(",")
                  .map((room: any) => room.trim())
                  .forEach((room: any) => {
                    if (/-/.test(room)) {
                      const [start, end] = room
                        .split("-")
                        .map((item: any) => Number(item.trim()));

                      if (start && end) {
                        for (let i = start; i <= end; i++) {
                          checkListedRooms.push({
                            hotel_id: hotel._id,
                            floor_no: item.floor_no!,
                            room_no: i,
                            hospitality_checklist,
                            room_type_id: roomType?.filter(
                              (items: any) => items.room_type == "Standard"
                            )[0]._id,
                          });
                        }
                      }
                    } else {
                      const roomNumber = Number(room == 0 ? undefined : room);
                      console.log(roomNumber, "roomNumber");
                      if (!Number.isNaN(roomNumber))
                        checkListedRooms.push({
                          hotel_id: hotel._id,
                          floor_no: item.floor_no!,
                          room_no: roomNumber!,
                          hospitality_checklist,
                          room_type_id: roomType?.filter(
                            (items: any) => items.room_type == "Standard"
                          )[0]._id,
                        });
                    }
                  });
              });
              /*Deluxe */
              hotel_rooms_required.forEach((item, index) => {
                item.room_nos
                  .deluxe!.split(",")
                  .map((room: any) => room.trim())
                  .forEach((room: any) => {
                    if (/-/.test(room)) {
                      const [start, end] = room
                        .split("-")
                        .map((item: any) => Number(item.trim()));

                      if (start && end) {
                        console.log(start, "start", end, "end");
                        for (let i = start; i <= end; i++) {
                          checkListedRooms.push({
                            hotel_id: hotel._id,
                            floor_no: item.floor_no!,
                            room_no: i,
                            hospitality_checklist,
                            room_type_id: roomType?.filter(
                              (items: any) => items.room_type == "Deluxe"
                            )[0]._id,
                          });
                          console.log(checkListedRooms, "checkListedRooms");
                        }
                      }
                    } else {
                      const roomNumber = Number(room == 0 ? undefined : room);
                      if (!Number.isNaN(roomNumber))
                        checkListedRooms.push({
                          hotel_id: hotel._id,
                          floor_no: item.floor_no!,
                          room_no: roomNumber,
                          hospitality_checklist,
                          room_type_id: roomType?.filter(
                            (items: any) => items.room_type == "Deluxe"
                          )[0]._id,
                        });
                    }
                  });
              });

              /* Suiute */

              hotel_rooms_required.forEach((item, index) => {
                item.room_nos
                  .suite!.split(",")
                  .map((room: any) => room.trim())
                  .forEach((room: any) => {
                    if (/-/.test(room)) {
                      const [start, end] = room
                        .split("-")
                        .map((item: any) => Number(item.trim()));

                      if (start && end) {
                        console.log(start, "start", end, "end");
                        for (let i = start; i <= end; i++) {
                          checkListedRooms.push({
                            hotel_id: hotel._id,
                            floor_no: item.floor_no!,
                            room_no: i,
                            hospitality_checklist,
                            room_type_id: roomType?.filter(
                              (items: any) => items.room_type == "Suite"
                            )[0]._id,
                          });
                        }
                      }
                    } else {
                      const roomNumber = Number(room == 0 ? undefined : room);
                      if (!Number.isNaN(roomNumber))
                        checkListedRooms.push({
                          hotel_id: hotel._id,
                          floor_no: item.floor_no!,
                          room_no: roomNumber,
                          hospitality_checklist,
                          room_type_id: roomType?.filter(
                            (items: any) => items.room_type == "Suite"
                          )[0]._id,
                        });
                    }
                  });
              });
              handleCreateRoom(checkListedRooms).then(handleClose);
              // console.log("checkListedRooms", checkListedRooms);
            }
          }
        } else {
          message.error("Either of room type is required");
        }
      }}
    >
      {({ handleSubmit, errors, values, setValues, touched }) => {
        const valuesExtractor = values?.hotel_rooms_required.map(
          (items: any) => items.room_nos
        );

        function unwind(arr: any) {
          let emptyArr = [];
          for (let i = 0; i < arr.length; i++) {
            emptyArr.push(arr[i]);
          }
          let standard = emptyArr
            .map((i) => i.standard.split(","))
            .flat()
            .filter((e) => e);
          let deluxe = emptyArr
            .map((i) => i.deluxe.split(","))
            .flat()
            .filter((e) => e);
          let suite = emptyArr
            .map((i) => i.suite.split(","))
            .flat()
            .filter((e) => e);

          let concatedArray = standard.concat(deluxe, suite);
          console.log(concatedArray, "concatedArray");
          let whiteSpace = concatedArray.filter((e) => e);

          let findDuplicate = whiteSpace.filter(
            (item, index) => whiteSpace.indexOf(item) !== index
          );

          console.log("findDublicate", findDuplicate);
          if (findDuplicate.length) {
            setDuplicateRoom(true);
          } else {
            setDuplicateRoom(false);
            return undefined;
          }

          return concatedArray;

          // let c = b.replaceAll(" ", "").split(",");
        }

        console.log(unwind(valuesExtractor), "extractor");
        return (
          <Modal
            open={open}
            centered
            onOk={() => handleSubmit()}
            onCancel={handleClose}
            title={title}
          >
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: true, text: "Hotel Name" }}
                name="hotel_name"
                placeholder="Enter Hotel Name"
              />
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: true, text: "Hotel Address" }}
                name="hotel_add"
                placeholder="Enter Hotel Address"
              />
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ required: true, text: "Contact Number" }}
                name="hotel_mob"
                placeholder="Enter Mobile Number"
              />
            </div>
            <FieldArray name="hotel_rooms_required">
              {({ push, remove }) => {
                return (
                  <React.Fragment>
                    {values.hotel_rooms_required.map((item, index) => {
                      return (
                        <div className="relative py-2" key={index}>
                          <ShouldRender check={index > 0}>
                            <MinusCircleOutlined
                              className="absolute right-0"
                              onClick={() => remove(index)}
                              rev={undefined}
                            />
                          </ShouldRender>
                          <div className="mb-3">
                            <Label text="Floor" />
                            <Input
                              type="number"
                              min="1"
                              value={item?.floor_no}
                              placeholder="Enter Floor Number"
                              onChange={(e) => {
                                const value = e.target.value;
                                const updatedValues = clonseJSON(values);
                                updatedValues.hotel_rooms_required[
                                  index
                                ].floor_no = +value;
                                setValues(updatedValues);
                              }}
                            />
                            {(errors as FormErrors)?.hotel_rooms_required?.[
                              index
                            ]?.floor_no &&
                              touched?.hotel_rooms_required?.[index]
                                ?.floor_no && (
                                <div className="my-1 text-xs text-red-500">
                                  {
                                    (errors as FormErrors).hotel_rooms_required[
                                      index
                                    ].floor_no
                                  }
                                </div>
                              )}
                          </div>
                          <div className="mb-3">
                            <Label text="Standard" />
                            <Input
                              placeholder="Enter Room Number i.e. 1,2,3 and so on"
                              value={item.room_nos.standard}
                              onChange={(e) => {
                                const value = e.target.value;
                                const updatedValues = clonseJSON(values);
                                updatedValues.hotel_rooms_required[
                                  index
                                ].room_nos.standard = value;
                                setValues(updatedValues);
                              }}
                            />
                            {(errors as FormErrors)?.hotel_rooms_required?.[
                              index
                            ]?.room_nos.standard &&
                              touched?.hotel_rooms_required?.[index]?.room_nos
                                ?.standard && (
                                <div className="my-1 text-xs text-red-500">
                                  {
                                    (errors as FormErrors).hotel_rooms_required[
                                      index
                                    ].room_nos.standard
                                  }
                                </div>
                              )}
                          </div>

                          {/*Trial */}

                          <div className="mb-3">
                            <Label text="Deluxe" />
                            <Input
                              placeholder="Enter Room Number i.e. 1,2,3 and so on"
                              value={item.room_nos.deluxe}
                              onChange={(e) => {
                                const value = e.target.value;
                                const updatedValues = clonseJSON(values);
                                updatedValues.hotel_rooms_required[
                                  index
                                ].room_nos.deluxe = value;
                                setValues(updatedValues);
                              }}
                            />
                            {(errors as FormErrors)?.hotel_rooms_required?.[
                              index
                            ]?.room_nos.deluxe &&
                              touched?.hotel_rooms_required?.[index]?.room_nos
                                ?.deluxe && (
                                <div className="my-1 text-xs text-red-500">
                                  {
                                    (errors as FormErrors).hotel_rooms_required[
                                      index
                                    ].room_nos.deluxe
                                  }
                                </div>
                              )}
                          </div>

                          <div className="mb-3">
                            <Label text="Suite" />
                            <Input
                              placeholder="Enter Room Number i.e. 1,2,3 and so on"
                              value={item.room_nos.suite}
                              onChange={(e) => {
                                const value = e.target.value;
                                const updatedValues = clonseJSON(values);
                                updatedValues.hotel_rooms_required[
                                  index
                                ].room_nos.suite = value;

                                setValues(updatedValues);
                              }}
                            />
                            {(errors as FormErrors)?.hotel_rooms_required?.[
                              index
                            ]?.room_nos.suite &&
                              touched?.hotel_rooms_required?.[index]?.room_nos
                                ?.suite && (
                                <div className="my-1 text-xs text-red-500">
                                  {
                                    (errors as FormErrors).hotel_rooms_required[
                                      index
                                    ].room_nos.suite
                                  }
                                </div>
                              )}
                          </div>

                          {/*Trial  */}
                        </div>
                      );
                    })}
                    {/* <Button
                      onClick={() =>
                        push({
                          room_nos: {
                            deluxe: "",
                          },
                        })
                      }
                    >
                      Deluxe
                    </Button>
                    <Button>Suite</Button> */}
                    <div
                      className="mb-3"
                      onClick={() =>
                        push({
                          floor_no: undefined,
                          room_nos: {
                            standard: "",
                            deluxe: "",
                            suite: "",
                          },
                        })
                      }
                    >
                      <Button block>Add Floor</Button>
                    </div>
                  </React.Fragment>
                );
              }}
            </FieldArray>
          </Modal>
        );
      }}
    </Formik>
  );
};

/* 

   Yup.object({
      floor_no: Yup.number().required("Floor Number is required"),
      room_nos: Yup.object({
        standard: Yup.string(),
        deluxe: Yup.string(),
        suite: Yup.string().when(["standard", "deluxe"], {
          is: (standard: string, deluxe: string) => {
            return standard || deluxe;
          },
          then: Yup.string(),
          otherwise: Yup.string().required("Must Not be Empty"),
        }),
      }),
    })

*/
