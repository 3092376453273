import React, { useEffect, useState } from "react";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { useParams } from "react-router-dom";
import { getSingleEvent, updateEvent } from "../../../api-core/Events";

const UpdateEvent = () => {
  const { id } = useParams();
  const [eventImg, setEventImg] = useState("");
  const [inputs, setInputs] = useState({
    event_title: "",
    event_descp: "",
  });
  const handleChange = (e) => {
    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };
  useEffect(() => {
    const fetchSingleEvent = async () => {
      const res = await getSingleEvent(id);
      console.log("res", res);
      setInputs({
        event_title: res.event[0]?.event_title,
        event_descp: res.event[0]?.event_descp,
      });
      setEventImg(res.event[0]?.event_img);
    };
    fetchSingleEvent();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("event_img", eventImg);
    formData.append("event_title", inputs.event_title);
    formData.append("event_descp", inputs.event_descp);

    if (formData) {
      const res = await updateEvent(formData, id);
      console.log("resFormData", res);
    } else {
      console.log("error");
    }
  };
  return (
    <div className="w-[90%] mx-auto ">
      <h1>Create Event</h1>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="flex flex-col flex-wrap items-center justify-center md:justify-between md:flex-row gap-x-5">
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Event Title
            </label>
            <input
              type="text"
              name="event_title"
              value={inputs.event_title}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter banner title"
            />
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Event Desciption
            </label>
            <input
              type="text"
              name="event_descp"
              value={inputs.event_descp}
              onChange={handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter banner desc"
            />
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <div>
              <img className="w-20 h-20" src={eventImg} />
            </div>
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Event Image
            </label>
            <input
              type="file"
              name="event_img"
              onChange={(e) => setEventImg(e.target.files[0])}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
              placeholder="Banner Image"
            />
          </div>
        </div>
        <button
          type="submit"
          className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default SidebarHeaderLayout(UpdateEvent);
