import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Tabs } from "antd";
import { getSingleEvent } from "src/api-core/Events";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import {
  FoodBevTable,
  HotelTable,
  PriortizationTable,
  ProductionTable,
  VendorTable,
} from "./tables";
import GHMS from "./GHMS";
import { IAdminEvent, IVendor, IVendorCar } from "src/types";
import { getAllVendorCar, getAllVendors } from "src/api-core/Vendor";
import { useFetchHotels } from "src/hooks";
import GalleryTable from "./tables/Gallery";
import Cars from "./tables/Cars";
import { FreelancerTable } from "./tables/Freelancer";
import EditCurrentEvent from "./EditCurrentEvent";

const EventList = () => {
  const [selectTab, setSelectTab] = useState("event");
  const { id } = useParams();
  const [adminEventsData, setAdminEventsData] = useState<IAdminEvent>();
  const [loading, setloading] = useState(true);
  const [vendors, setVendors] = useState<IVendor[]>([]);
  const [vendorCars, setVendorCars] = useState<IVendorCar[]>([]);

  const {
    handleHotelCreateSuccess,
    handleRoomCreateSuccess,
    hotelRooms,
    hotels,
  } = useFetchHotels();

  // fetch all hotels and vendors
  useEffect(() => {
    const fetchAllVendors = async () => {
      const res = await getAllVendors();
      // setVendors(res.vendor);
      const res2 = await getSingleEvent(id);

      setVendors(res2?.data.event?.event_vendors);
      setVendorCars(res2?.data?.event?.event_vendors?.cars);
    };
    fetchAllVendors();
  }, [selectTab]);

  useEffect(() => {
    const fetchSingleEvent = async () => {
      const res = await getSingleEvent(id);

      setAdminEventsData(res.data);
      setloading(false);
    };
    if (id) fetchSingleEvent();
  }, [id, loading]);

  const handleUpdate = async () => {
    const res = await getSingleEvent(id);

    setAdminEventsData(res.data);
  };

  const renderTabs = (tabname: string) => {
    switch (tabname) {
      case "GHMS":
        //ghms code block
        return (
          <GHMS
            event={adminEventsData?.event}
            ghms={adminEventsData?.ghms}
            loading={loading}
            update={handleUpdate}
            vendorCars={vendorCars}
          />
        );

      case "event":
        //ghms code block
        return (
          <EditCurrentEvent
            event={adminEventsData?.event}
            loading={loading}
            update={handleUpdate}
          />
        );
      case "gallery":
        //gallery code block
        return (
          <div>
            <Tabs>
              <Tabs.TabPane tab="Gallery List" key="gallery">
                <GalleryTable
                  event={adminEventsData?.gallery}
                  update={handleUpdate}
                  loading={false}
                />
              </Tabs.TabPane>
            </Tabs>
          </div>
        );
      case "hotel":
        //hotel code block
        return (
          <div>
            <Tabs>
              <Tabs.TabPane tab="Hotel List" key="hotel">
                Hotel details
                <HotelTable
                  event={adminEventsData?.event}
                  handleHotelCreateSuccess={handleHotelCreateSuccess}
                  handleRoomCreateSuccess={handleRoomCreateSuccess}
                  hotelOptions={hotels}
                  hotelRooms={hotelRooms}
                  loading={loading}
                  update={handleUpdate}
                />
              </Tabs.TabPane>
            </Tabs>
          </div>
        );
      case "priortization":
        //priortization code block
        return (
          <div>
            <Tabs>
              <Tabs.TabPane tab="Prioritisation" key="priortization">
                <PriortizationTable
                  event={adminEventsData?.event}
                  loading={loading}
                  priortization={adminEventsData?.priortization}
                  update={handleUpdate}
                />
              </Tabs.TabPane>
            </Tabs>
          </div>
        );
      case "vendor":
        //vendor code block
        return (
          <div>
            <Tabs>
              <Tabs.TabPane tab="Vendor List" key="vendor">
                <VendorTable
                  event={adminEventsData?.event}
                  loading={loading}
                  vendorOptions={vendors}
                  update={handleUpdate}
                />
              </Tabs.TabPane>

              {/* <Tabs.TabPane tab="Cars" key="cars">
                <Cars
                  event={adminEventsData?.event?.event_vendors}
                  events={adminEventsData?.event}
                  loading={loading}
                  update={handleUpdate}
                />
              </Tabs.TabPane> */}
            </Tabs>
          </div>
        );
      case "foodbev":
        //vendor code block
        return (
          <div>
            <Tabs>
              <Tabs.TabPane tab="Food & Bev List" key="foodbev">
                <FoodBevTable
                  event={adminEventsData?.event}
                  loading={loading}
                  update={handleUpdate}
                />
              </Tabs.TabPane>
            </Tabs>
          </div>
        );
      case "production":
        //vendor code block
        return (
          <div>
            <Tabs>
              <Tabs.TabPane tab="Production List" key="prod">
                <ProductionTable
                  event={adminEventsData?.event}
                  loading={loading}
                  update={handleUpdate}
                />
              </Tabs.TabPane>
            </Tabs>
          </div>
        );

      case "deploy":
        //vendor code block
        return (
          <div>
            <Tabs>
              <Tabs.TabPane tab="Deployed Freelancer" key="deploy">
                <FreelancerTable
                  event={adminEventsData}
                  loading={loading}
                  update={handleUpdate}
                />
              </Tabs.TabPane>
            </Tabs>
          </div>
        );
      // case "cars":
      //   //vendor code block
      //   return (
      //     <div>
      //       <Tabs>
      //         <Tabs.TabPane tab="Cars" key="cars">
      //           <Cars
      //             event={adminEventsData?.event?.event_vendors}
      //             events={adminEventsData?.event}
      //             loading={loading}
      //             update={handleUpdate}
      //           />
      //         </Tabs.TabPane>
      //       </Tabs>
      //     </div>
      //   );

      default:
        break;
    }
  };
  const handleOption = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setSelectTab(value);
  };
  return (
    <div className="">
      <h1 className="mt-2 font-serif font-bold text-center text-black">
        {adminEventsData?.event?.event_title}
      </h1>
      <div className="flex justify-end "></div>
      {true ? (
        <>
          <div className="w-auto max-w-fit">
            <div>
              <select
                value={selectTab}
                onChange={handleOption}
                className="px-6 py-3 font-bold text-black bg-white outline-none"
              >
                <option>Select</option>
                <option value={"event"}>Event</option>
                <option value={"GHMS"}>GHMS</option>
                <option value={"gallery"}>Gallery</option>
                <option value={"hotel"}>Hotel</option>
                <option value={"priortization"}>Prioritisation</option>
                <option value={"vendor"}>Vendor</option>
                <option value={"foodbev"}>Food & Bev</option>
                <option value={"production"}>Production</option>
                <option value={"deploy"}>Deployed Freelancer</option>
                {/* <option value={"cars"}>Cars</option> */}
              </select>
            </div>
          </div>
          <div className="mt-24">{renderTabs(selectTab)}</div>
        </>
      ) : (
        <div className="flex items-center justify-center">No Record Found</div>
      )}
    </div>
  );
};

export default SidebarHeaderLayout(EventList);
