import React, { useEffect, useState } from "react";
import { message, Popconfirm, Table } from "antd";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link } from "react-router-dom";
import { deleteHotel, getAllHotel } from "../../../api-core/Hotel";
import {
  deleteVendorCar,
  deleteVendorDriver,
  getAllVendorDriver,
} from "../../../api-core/Vendor";

const ListOfVendorDriver = () => {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    fetchAllVendorDriver();
  }, []);
  const fetchAllVendorDriver = async () => {
    setloading(true);
    const res = await getAllVendorDriver();
    setdata(res.vendordriver);
    setloading(false);
  };
  const onDeleteRecord = async (record, id) => {
    const recordId = {
      ids: [record],
    };
    const res = await deleteVendorDriver(recordId);
    if (res.success == true) {
      message.success(res.msgText);
      fetchAllVendorDriver();
    }
  };

  const datas = data.map((item) => ({
    key: item._id,
    vendorName: item.vendor_id.vendor_name,
    vendorType: item.vendor_id.vendor_type,
    driverName: item.driver_name,
    driverEmail: item.driver_email,
    driverMobile: item.driver_mobile,
    driverAddress: item.driver_add,
  }));
  // console.log("datas", datas);

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Vendor Name",
      dataIndex: "vendorName",
      key: "vendorName",
      ellipsis: true,
    },
    {
      title: "Vendor Type",
      dataIndex: "vendorType",
      key: "vendorType",
      ellipsis: true,
    },
    {
      title: "Driver Name",
      dataIndex: "driverName",
      key: "driverName",

      ellipsis: true,
    },
    {
      title: "Driver Email",
      dataIndex: "driverEmail",
      key: "driverEmail",

      ellipsis: true,
    },
    {
      title: "Driver Mobile",
      dataIndex: "driverMobile",
      key: "driverMobile",

      ellipsis: true,
    },
    {
      title: "Driver Address",
      dataIndex: "driverAddress",
      key: "driverAddress",

      ellipsis: true,
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex gap-x-2 ">
          {/* {console.log("rec", record)} */}
          <Link to={`/admin/singlevendordriver/${record.key}`}>
            <button className="text-blue-600 cursor-pointer">View</button>
          </Link>

          <Link to={`/admin/updatevendordriver/${record.key}`}>
            <button className="text-yellow-400 cursor-pointer">Edit</button>
          </Link>

          <Popconfirm
            title="Sure to delete?"
            onConfirm={(e) => onDeleteRecord(record.key, e)}
          >
            <span className="text-red-500 cursor-pointer">Delete</span>
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div className="">
      <Link to="/admin/createvendordriver">
        <button className="bg-blue-600 text-white p-3 mx-3">
          Create Vendors Driver
        </button>
      </Link>
      <h1 className="text-center">List Of Vendor Drivers</h1>
      <div className="">
        <Table
          dataSource={datas}
          columns={columns}
          loading={loading}
          // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        />
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(ListOfVendorDriver);
