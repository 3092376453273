import { ErrorMessage, Field, FieldHookConfig, useField } from "formik";
import { ShouldRender } from "../ShouldRender";
import { Label } from "../Label";

interface LabelProps {
  text: string;
  className?: string;
  required?: boolean;
  optional?: boolean;
}
type TFormInputProps = {
  className?: string;
  label?: LabelProps;
} & FieldHookConfig<string>;

export const FormInput: React.FC<TFormInputProps> = ({
  className,
  label,
  ...props
}) => {
  const [field, meta] = useField(props);

  const { text, className: labelClassName, optional, required} = { ...label };
  return (
    <>
      <ShouldRender check={label?.text}>
        <Label
          className={labelClassName}
          optional={optional}
          required={required}
          text={text!}
        />
      </ShouldRender>
      <Field
        className={className}
        {...field}
        {...props}
        aria-invalid={!!meta.error}
      />
      <div className="my-1 text-xs text-red-500">
        <ErrorMessage name={field.name} />
      </div>
    </>
  );
};
