import React, { useEffect, useState } from "react";
import { message, Popconfirm, Table } from "antd";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link } from "react-router-dom";
import {
  deleteDepartureMgmnt,
  deleteGuestlist,
  getAllDepartureMgmnt,
  getAllGuestlist,
} from "../../../api-core/Ghms";
import { formatDate } from "../../../utils/helper";

const ListOfDepartureMgmnt = () => {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    fetchAllDepartureMgmnt();
  }, []);
  const fetchAllDepartureMgmnt = async () => {
    setloading(true);
    const res = await getAllDepartureMgmnt();
    setdata(res.ghmsdeparutremgmt);
    setloading(false);
  };
  const onDeleteRecord = async (record, id) => {
    const recordId = {
      ids: [record],
    };
    const res = await deleteDepartureMgmnt(recordId);
    if (res.success == true) {
      message.success(res.msgText);
      fetchAllDepartureMgmnt();
    }
  };

  const datas = data.map((item) => ({
    key: item._id,
    subEventTitle: item.sub_event_id.subevent_title,
    guestName: item.guest_id.guest_name,
    dateOfDeparture: formatDate(item.date_of_departure, "DD-MM-YYYY"),
    noOfGuestArrived: item.no_of_guest_arrived,
    modeOfDeparture: item.mode_of_departure,
    departureTime: item.departure_time,
    dropedBy: item.dropped_by,
    vendorName: item.vendor_id.vendor_name,
    carName: item?.car_id?.car_name,
    driverName: item.driver_id.driver_name,
    returnChecklist: item.return_checklist,
    specialNote: item.special_note,
  }));
  // console.log("datas", datas);

  const columns = [
    {
      title: "Sr.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Sub Event Title",
      dataIndex: "subEventTitle",
      key: "subEventTitle",
      ellipsis: true,
    },
    {
      title: "Guest Name",
      dataIndex: "guestName",
      key: "guestName",
      ellipsis: true,
    },
    {
      title: "Date of Departure",
      dataIndex: "dateOfDeparture",
      key: "dateOfDeparture",
      ellipsis: true,
    },

    {
      title: "No Of Guest Arrived",
      dataIndex: "noOfGuestArrived",
      key: "noOfGuestArrived",
      ellipsis: true,
    },

    {
      title: "Mode Of Departure",
      dataIndex: "modeOfDeparture",
      key: "modeOfDeparture",
      ellipsis: true,
    },

    {
      title: "Departure Time",
      dataIndex: "departureTime",
      key: "departureTime",
      ellipsis: true,
    },

    {
      title: "Droped By",
      dataIndex: "dropedBy",
      key: "dropedBy",
      ellipsis: true,
    },

    {
      title: "Vendor name",
      dataIndex: "vendorName",
      key: "vendorName",
      ellipsis: true,
    },
    {
      title: "Car Name",
      dataIndex: "carName",
      key: "carName",
      ellipsis: true,
    },

    {
      title: "Driver Name",
      dataIndex: "driverName",
      key: "driverName",
      ellipsis: true,
    },

    {
      title: "Return Checklist",
      dataIndex: "returnChecklist",
      key: "returnChecklist",
      ellipsis: true,
    },

    {
      title: "Special Note",
      dataIndex: "specialNote",
      key: "specialNote",
      ellipsis: true,
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex gap-x-2 ">
          {/* {console.log("rec", record)} */}
          <Link to={`/admin/singledeparturemgmnt/${record.key}`}>
            <button className="text-blue-600 cursor-pointer">View</button>
          </Link>

          <Link to={`/admin/updatedeparturemgmnt/${record.key}`}>
            <button className="text-yellow-400 cursor-pointer">Edit</button>
          </Link>

          <Popconfirm
            title="Sure to delete?"
            onConfirm={(e) => onDeleteRecord(record.key, e)}
          >
            <span className="text-red-500 cursor-pointer">Delete</span>
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div className="">
      <Link to="/admin/createdeparturemgmnt">
        <button className="bg-blue-600 text-white p-3 mx-3">
          Create Departure Management
        </button>
      </Link>
      <h1 className="text-center">List Of Departure Management</h1>
      <div className="">
        <Table
          dataSource={datas}
          columns={columns}
          loading={loading}
          scroll={{
            y: "auto",
            x: "auto",
          }}
          // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        />
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(ListOfDepartureMgmnt);
