import React, { useEffect, useState } from "react";
import { Table } from "antd";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link, useParams } from "react-router-dom";
import { deleteHotel, getAllHotel } from "../../../api-core/Hotel";
import {
  getAllVendorCar,
  getAllVendors,
  getSingleVendorCar,
} from "../../../api-core/Vendor";

const SingleVendorCar = () => {
  const { id } = useParams();
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    const fetchAllVendors = async () => {
      setloading(true);
      const res = await getSingleVendorCar(id);
      console.log("res", res.vendorcar);

      setdata(res.vendorcar);
      setloading(false);
    };
    fetchAllVendors();
  }, []);

  const onDeleteRecord = async (record, id) => {
    const res = await deleteHotel(record);
    // console.log("deData", res);
  };

  const datas = data.map((item) => ({
    key: item._id,
    vendorName: item.vendor_id.vendor_name,
    vendorType: item.vendor_id.vendor_type,
    carName: item.car_name,
    carModel: item.car_model,
    carNumber: item.car_number,
  }));
  // console.log("datas", datas);

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Vendor Name",
      dataIndex: "vendorName",
      key: "vendorName",
      ellipsis: true,
    },
    {
      title: "Vendor Type",
      dataIndex: "vendorType",
      key: "vendorType",
      ellipsis: true,
    },
    {
      title: "Car Name",
      dataIndex: "carName",
      key: "carName",

      ellipsis: true,
    },
    {
      title: "Car Model",
      dataIndex: "carModel",
      key: "carModel",

      ellipsis: true,
    },
    {
      title: "Car Number",
      dataIndex: "carNumber",
      key: "carNumber",

      ellipsis: true,
    },
  ];
  return (
    <div className="">
      <h1 className="text-center">List Of Vendor car</h1>
      <div className="">
        <Table
          dataSource={datas}
          columns={columns}
          loading={loading}
          // rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
        />
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(SingleVendorCar);
