import React, { useEffect, useState } from "react";
import { getsingleOfferBanner } from "../../../api-core/OfferBanner";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link, useHistory, useParams } from "react-router-dom";
import { Table } from "antd";

import { getSingleEvent, getSingleSubEvent } from "../../../api-core/Events";
import { formatDate } from "../../../utils/helper";

const SingleSubEvent = () => {
  const { id } = useParams();
  // console.log("parms", id);
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    const fetchSingleSubEvent = async () => {
      setloading(true);
      const res = await getSingleSubEvent(id);
      // console.log("res", res.subevent[0]);

      setdata(res.subevent);
      setloading(false);
    };
    fetchSingleSubEvent();
  }, []);

  const datas = data.map((item) => ({
    key: item._id,
    subEventDate: formatDate(item.subevent_date, "DD-MMM-YYYY"),
    subEventTitle: item.subevent_title,
    subEventDescp: item.subevent_descp,
  }));
  // console.log("datas", datas);

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Sub Event Date",
      dataIndex: "subEventDate",
      key: "subEventDate",
    },
    {
      title: "Sub Event Title",
      dataIndex: "subEventTitle",
      key: "subEventTitle",
    },
    {
      title: "Sub Event Descp",
      dataIndex: "subEventDescp",
      key: "subEventDescp",
      ellipsis: true,
    },
  ];
  return (
    <div>
      Single Sub Event
      <Table dataSource={datas} columns={columns} loading={loading} />
    </div>
  );
};

export default SidebarHeaderLayout(SingleSubEvent);
