import { viewCurrentDates } from "src/utils/helper";

const todaysDate  = viewCurrentDates

export const generalchecklistData = {
  general_checklist: [
    {
      checklist_type: "Prod",
      generalchecklist_text: "Haldi Setup",
      checklist: [
        {
          check_id: 1,
          check_name: "Artificial FLower",
        },
        {
          check_id: 2,
          check_name: "Real Flower",
        },
        {
          check_id: 3,
          check_name: "Tapping",
        },
        {
          check_id: 4,
          check_name: "Carpet Color",
        },
        {
          check_id: 5,
          check_name: "Carpet Cleaning",
        },
        {
          check_id: 6,
          check_name: "Sofa",
        },
      ],
    },
    {
      checklist_type: "Food",
      generalchecklist_text: "Dinner",
      generalchecklist_date: todaysDate,
      checklist: [
        {
          check_id: 1,
          check_name: "Clear Roll",
        },
        {
          check_id: 2,
          check_name: "Tissue Paper",
        },
        {
          check_id: 3,
          check_name: "650",
        },
        {
          check_id: 4,
          check_name: "Decor",
        },
        {
          check_id: 5,
          check_name: "5",
        },
        {
          check_id: 6,
          check_name: "Clean Spoons",
        },
      ],
    },
    {
      checklist_type: "Food",
      generalchecklist_text: "Lunch",
      generalchecklist_date: todaysDate,
      checklist: [
        {
          check_id: 1,
          check_name: "Clear Roll",
        },
        {
          check_id: 2,
          check_name: "Tissue Paper",
        },
        {
          check_id: 3,
          check_name: "650",
        },
        {
          check_id: 4,
          check_name: "Decor",
        },
        {
          check_id: 5,
          check_name: "5",
        },
        {
          check_id: 6,
          check_name: "Clean Spoons",
        },
      ],
    },
    {
      checklist_type: "Food",
      generalchecklist_text: "Hi Tea",
      generalchecklist_date: todaysDate,
      checklist: [
        {
          check_id: 1,
          check_name: "Clear Roll",
        },
        {
          check_id: 2,
          check_name: "Tissue Paper",
        },
        {
          check_id: 3,
          check_name: "650",
        },
        {
          check_id: 4,
          check_name: "Decor",
        },
        {
          check_id: 5,
          check_name: "5",
        },
        {
          check_id: 6,
          check_name: "Clean Spoons",
        },
      ],
    },
    {
      checklist_type: "Food",
      generalchecklist_text: "Breakfast",
      generalchecklist_date: todaysDate,
      checklist: [
        {
          check_id: 1,
          check_name: "Clear Roll",
        },
        {
          check_id: 2,
          check_name: "Tissue Paper",
        },
        {
          check_id: 3,
          check_name: "650",
        },
        {
          check_id: 4,
          check_name: "Decor",
        },
        {
          check_id: 5,
          check_name: "5",
        },
        {
          check_id: 6,
          check_name: "Clean Spoons",
        },
      ],
    },
    {
      checklist_type: "L&C",
      checklist: [
        {
          check_id: 1,
          check_name: "10kms",
        },
        {
          check_id: 4,
          check_name: "Outside",
        },
      ],
    },
  ],
};
