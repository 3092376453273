import moment from "moment";
import * as Yup from "yup";
import { DatePicker, Modal, TimePicker } from "antd";
import { ErrorMessage, Formik } from "formik";
import { FormInput } from "src/components/FormInput";
import { Label } from "src/components/Label";
import { IEventProdDecor } from "src/types";
import ImageUploader from "src/components/ImageUploader";
import { TIME_FORMATS, TIME_PICKER_FORMAT } from "src/constants";
import { viewCurrentDates } from "src/utils/helper";

interface Props {
  open: boolean;
  data?: IEventProdDecor;
  handleClose: () => void;
  handleSave: (values: IEventProdDecor) => void;
  title: string;
}


const validationSchema = Yup.object({
  decor_title: Yup.string().min(3,"Decor Title must be at least 3 characters").required("Decor title is required"),
  decor_img: Yup.array(
    Yup.object({
      file: Yup.string().required("Decor image is required"),
    })
  ),
  decor_date: Yup.date().min(new Date()).required("Decor date is required"),
  expected_decor_time: Yup.date().required("expected decor time is required"),
});

const EditProduction = ({
  handleClose,
  handleSave,
  data,
  open,
  title,
}: Props) => {
  const initalValues = {
    decor_title: data?.decor_title,
    decor_img: data?.decor_img,
    decor_date: data?.decor_date ? moment(data?.decor_date) : undefined,
    expected_decor_time: data?.expected_decor_time
      ? moment(data?.expected_decor_time, TIME_PICKER_FORMAT)
      : undefined,
  };

  return (
    <Formik
      initialValues={initalValues}
      validationSchema={validationSchema}
      onSubmit={(values) =>
        handleSave({
          decor_title: values.decor_title!,
          decor_img: values.decor_img!,
          decor_date: values.decor_date!.format(TIME_FORMATS.API_TIME),
          expected_decor_time:
            values.expected_decor_time!.format(TIME_PICKER_FORMAT),
        })
      }
    >
      {({ setFieldValue, handleSubmit, values, errors }) => {
        return (
          <Modal
            centered
            open={open}
            title={title}
            onCancel={handleClose}
            onOk={() => handleSubmit()}
          >
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ text: "Decor Title", required: true }}
                name="decor_title"
                placeholder="enter decor title"
              />
            </div>
            <div className="mb-3">
              <Label required text="Decor Image" />
              <div className="flex justify-center">
                <ImageUploader
                  handleChange={(file) =>
                    setFieldValue("decor_img", [
                      {
                        file,
                        filename: file,
                      },
                    ])
                  }
                  src={values.decor_img?.[0].file}
                />
              </div>
              {errors.decor_img && (
                <div className="my-1 text-xs text-red-500">
                  <ErrorMessage name="decor_img" />
                </div>
              )}
            </div>
            <div className="mb-3">
              <Label required text="Decoration date" />
              <DatePicker
                disabledDate={(current) => current.isBefore(moment().subtract(1,"day"))}
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                value={values.decor_date}
                onChange={(value) => setFieldValue("decor_date", value)}
              />
              {errors.decor_date && (
                <div className="my-1 text-xs text-red-500">
                  <ErrorMessage name="decor_date" />
                </div>
              )}
            </div>
            <div className="mb-3">
              <Label required text="Expected Decoration Time" />
              <TimePicker
                format={TIME_PICKER_FORMAT}
                value={values.expected_decor_time}
                onChange={(value) =>
                  setFieldValue("expected_decor_time", value)
                }
              />
              {errors.expected_decor_time && (
                <div className="my-1 text-xs text-red-500">
                  <ErrorMessage name="expected_decor_time" />
                </div>
              )}
            </div>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default EditProduction;
