import moment from "moment";
import * as Yup from "yup";
import { DatePicker, Modal, Select, TimePicker } from "antd";
import { ErrorMessage, Formik } from "formik";
import { FormInput } from "src/components/FormInput";
import { Label } from "src/components/Label";
import { IDeployedFreelancer, IEventProdDecor } from "src/types";
import ImageUploader from "src/components/ImageUploader";
import {
  departmentData,
  TIME_FORMATS,
  TIME_PICKER_FORMAT,
} from "src/constants";
import { viewCurrentDates } from "src/utils/helper";
import { useEffect, useState } from "react";
import { getAllFreelancer } from "src/api-core/Freelancer";

interface Props {
  open: boolean;
  data?: IDeployedFreelancer;
  handleClose: () => void;
  handleSave: (values: IDeployedFreelancer) => void;
  title: string;
}

const validationSchema = Yup.object({
  freelancer_id: Yup.string().required("Freelancer name is required"),
  department_type: Yup.string()
    .min(3, "Department name must be at least 3 characters")
    .required("Department name is required"),
  hours_worked: Yup.number().nullable(),
  expected_working_hours: Yup.number().nullable(),
});

const EditFreelancer = ({
  handleClose,
  handleSave,
  data,
  open,
  title,
}: Props) => {
  const [freelancerData, setFreelancerData] = useState([]);

  useEffect(() => {
    const fetchgetAllFreelancer = async () => {
      const res = await getAllFreelancer();

      setFreelancerData(res.freelancer);
    };
    fetchgetAllFreelancer();
  }, []);

  const initalValues = {
    department_type: data?.department_type || "",
    hours_worked: data?.hours_worked || null,
    expected_working_hours: data?.expected_working_hours || null,
    freelancer_id: data?.freelancer_id,
  };

  return (
    <Formik
      initialValues={initalValues}
      validationSchema={validationSchema}
      onSubmit={(values) => {     
          
        handleSave({
          department_type: values?.department_type,
          expected_working_hours: Number(values?.expected_working_hours),
          freelancer_id: values.freelancer_id,
          hours_worked: Number(values.hours_worked),
        });
        
      }}
    >
      {({ setFieldValue, handleSubmit, values, errors }) => {
        return (
          <Modal
            centered
            open={open}
            title={title}
            onCancel={handleClose}
            onOk={(values: any) => handleSubmit(values)}
          >
            <div className="mb-3">
              <Label required text="Freelancer Name"></Label>
              <Select
                className="border border-gray-300bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                onChange={(value) => setFieldValue("freelancer_id", value)}
                value={values.freelancer_id}
              >
                <Select.Option value="">Select Freelancer Name</Select.Option>
                {freelancerData.map((items: any) => (
                  <Select.Option value={items._id} key={items._id}>
                    {items.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="mb-3">
              <Label
                text="Department Name"
                required={true}
                key="department_type"
              ></Label>
              <Select
                className="border border-gray-300bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                onChange={(value) => setFieldValue("department_type", value)}
                value={values.department_type}
              >
                <Select.Option value="">Select Department Type</Select.Option>
                {departmentData?.map((items) => (
                  <Select.Option value={items.value} key={items.id}>
                    {items.value}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ text: "Hours worked", required: false }}
                name="hours_worked"
                placeholder="enter hours worked"
              />
            </div>
            <div className="mb-3">
              <FormInput
                className="border border-gray-300 bg-white shadow-sm rounded-md py-2.5 px-3 w-full mt-1 focus-visible:outline-none"
                label={{ text: "Expected working hours", required: false }}
                name="expected_working_hours"
                placeholder="enter expected working hours"
              />
            </div>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default EditFreelancer;
