import React, { useEffect, useState } from "react";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { createGuestlist, getAllGuestlist } from "../../../api-core/Ghms";
import { useFormik } from "formik";
import * as Yup from "yup";
const CreateGuestlist = () => {
  const [guestIdData, setGuestIdData] = useState([]);
  const [loading, setloading] = useState(false);
  const initialValues = {
    sub_event_id: "",
    guest_name: "",
    guest_email: "",
    guest_add: "",
    guest_mobile: "",
    guest_invited: "",
    guest_outstation: "",
    guest_expected_nos: "",
    guest_invitation_type: "",
    guest_date_of_arrival: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      sub_event_id: Yup.string().required("Sub event name is required"),
      guest_name: Yup.string().required("guest name is required"),
      guest_email: Yup.string().required("guest email descp is required"),
      guest_add: Yup.string().required("guest add is required"),
      guest_mobile: Yup.string().required("guest mobile is required"),
      guest_invited: Yup.string().required(
        "number of guest invited date is required"
      ),
      guest_outstation: Yup.string().required("guest outstation is required"),
      guest_expected_nos: Yup.string().required(
        "expected no of guest is required"
      ),
      guest_invitation_type: Yup.string().required(
        "invitation type is required"
      ),
      guest_date_of_arrival: Yup.string().required(
        "guest date of arrival is required"
      ),
    }),
    onSubmit: async (values, action) => {
      console.log("values", values);

      if (values) {
        const res = await createGuestlist(values);
        console.log("resFormData", res);
        action.resetForm();
      } else {
        console.log("error");
      }
    },
  });

  useEffect(() => {
    const fetchAllGuestList = async () => {
      setloading(true);
      const res = await getAllGuestlist();
      // console.log("res", res.ghmsguestlist);

      setGuestIdData(res.ghmsguestlist);
      setloading(false);
    };
    fetchAllGuestList();
  }, []);
  return (
    <div className="w-[90%] mx-auto ">
      <h1>Create Guest List</h1>
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <div className="flex flex-col justify-center items-center  md:justify-between md:flex-row gap-x-5 flex-wrap">
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Sub Event Title
            </label>
            <select
              value={formik.values.sub_event_id}
              name="sub_event_id"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={""}>Select event</option>
              {guestIdData.map((value) => (
                <option value={value.sub_event_id._id} key={value._id}>
                  {value.sub_event_id.subevent_title}
                </option>
              ))}
            </select>
            {formik.errors.sub_event_id && (
              <span className="text-red-500">{formik.errors.sub_event_id}</span>
            )}
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Guest Name
            </label>
            <input
              type="text"
              name="guest_name"
              value={formik.values.guest_name}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter guest name"
            />
            {formik.errors.guest_name && (
              <span className="text-red-500">{formik.errors.guest_name}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Guest Email
            </label>
            <input
              type="text"
              name="guest_email"
              value={formik.values.guest_email}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter guest email "
            />
            {formik.errors.guest_email && (
              <span className="text-red-500">{formik.errors.guest_email}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Guest Address
            </label>
            <input
              type="text"
              name="guest_add"
              value={formik.values.guest_add}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter guest add"
            />
            {formik.errors.guest_add && (
              <span className="text-red-500">{formik.errors.guest_add}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Guest Mobile
            </label>
            <input
              type="text"
              name="guest_mobile"
              value={formik.values.guest_mobile}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter guest mobile"
            />
            {formik.errors.guest_mobile && (
              <span className="text-red-500">{formik.errors.guest_mobile}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Guest invited
            </label>
            <input
              type="text"
              name="guest_invited"
              value={formik.values.guest_invited}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter guest invited"
            />
            {formik.errors.guest_invited && (
              <span className="text-red-500">
                {formik.errors.guest_invited}
              </span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Guest Outstation
            </label>
            <input
              type="text"
              name="guest_outstation"
              value={formik.values.guest_outstation}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter guest outstation"
            />
            {formik.errors.guest_outstation && (
              <span className="text-red-500">
                {formik.errors.guest_outstation}
              </span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Guest Expected no
            </label>
            <input
              type="text"
              name="guest_expected_nos"
              value={formik.values.guest_expected_nos}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter  guest_expected_nos"
            />
            {formik.errors.guest_expected_nos && (
              <span className="text-red-500">
                {formik.errors.guest_expected_nos}
              </span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Guest Invitation Type
            </label>
            <input
              type="text"
              name="guest_invitation_type"
              value={formik.values.guest_invitation_type}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter guest invitation type"
            />
            {formik.errors.guest_invitation_type && (
              <span className="text-red-500">
                {formik.errors.guest_invitation_type}
              </span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Guest Date of Arrival
            </label>
            <input
              type="date"
              name="guest_date_of_arrival"
              value={formik.values.guest_date_of_arrival}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter guest date of arrival"
            />
            {formik.errors.guest_date_of_arrival && (
              <span className="text-red-500">
                {formik.errors.guest_date_of_arrival}
              </span>
            )}
          </div>
        </div>
        <button
          type="submit"
          className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default SidebarHeaderLayout(CreateGuestlist);
