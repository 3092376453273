import { Table, Image, Button, Descriptions } from "antd";
import React, { useEffect, useState } from "react";
import { IEvent, IEventGallery } from "../../../../../types/event";
import { Link, useParams } from "react-router-dom";
import { getAllEventPhoto } from "src/api-core/Events";
import { IGallery } from "src/types";

const defaultColumns = [
  {
    title: "Event Name",
    dataIndex: "event_name",
    key: "event_name",
  },
  {
    title: "Event date",
    dataIndex: "event_date",
    key: "event_date",
  },
  {
    title: "Event Description",
    dataIndex: "event_desc",
    key: "event_desc",
  },
  {
    title: "Event Photos",
    dataIndex: "event_photos",
    key: "event_photos",
    render: (_: any, record: IEventGallery) => (
      <div>
        <Image alt="menu" rootClassName="event-image-preview" src="" />
      </div>
    ),
  },
  {
    title: "Action",
    dataIndex: "action",
    key: "action",
    render: (_: any, record: IEventGallery) => {
      return <Button>Click</Button>;
    },
  },
];

interface Props {
  event?: IGallery;
  update: () => Promise<void>;
  loading: boolean;
}

const handleUpdate = () => {};

const GalleryTable = ({ event, update, loading }: Props) => {
  const [eventPhotos, setEventPhotos] = useState<any>([]);
  const { id } = useParams();
  // console.log("event - " , event);
  // console.log("update - ", update);
  
  
  useEffect(() => {
    const fetchAllEventPhotos = async () => {
      // const res = await getAllEventPhoto();
      // console.log("res", res.eventphoto);
      // const result = res.eventphoto.filter((items: any) => items?.event_id?._id == id)
      // console.log("61 ",result);
      
      setEventPhotos(event);
    };
    fetchAllEventPhotos();
  }, [id]);
// const regex = /^(?!.*(\b\d+\b).*\b\1\b)\d+(,\d+)*$/
// console.log("eventPhotos",eventPhotos);

  return (
    <> 
    {/* <div className="flex justify-end mb-3">
      <Link to="/admin/createeventphoto">
        <Button className="bg-blue-600 text-white p-3 mx-3">
          Add Photo to Gallery
        </Button>
      </Link>
    </div> */}
    <React.Fragment>
        {/* <Table columns={defaultColumns} dataSource={[]} loading={false}></Table> */}
        <div className="flex ">
        {eventPhotos?.ep_img?.length > 0 ? (
          eventPhotos?.ep_img.map((items: any, index: number) => (
            <div className="grid grid-cols-1 gap-2 m-2 ">
              <img
                src={items.file}
                alt={index.toString()}
                className="w-48 h-48"
              />
            </div>
          ))
        ) : (
          <><><p>No Photos! &nbsp; </p><Link to="/admin/createeventphoto">
                <p>Add photos &nbsp;</p>
              </Link></><p>in Gallery</p></>
        )}
      </div>
      </React.Fragment></>
  );
};

export default GalleryTable;
