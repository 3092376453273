import React, { useEffect, useState } from "react";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { message } from "antd";
import { createOfferBanner, getAllEvents } from "../../../api-core/OfferBanner";
import { useFormik } from "formik";
import * as Yup from "yup";
import { viewCurrentDates } from "src/utils/helper";
import { eventType } from "src/constants";

const CreateOfferBanner = () => {
  const [data, setData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [bannerImg, setBannerImg] = useState("");

  const navigate = useNavigate();

  const imgHandler = async (e) => {
    if (e.target.files[0] && e.target.files[0].size > 2 * 1024 * 1024) {
      e.target.value = null;
      return message.error("File exceeds the limit of 2MB");
    }
    setBannerImg(e.target.files[0]);
  };
  useEffect(() => {
    const fetchAllEvents = async () => {
      const res = await getAllEvents();
      setEventData(res.event);
    };
    fetchAllEvents();
  }, []);

  const initialValues = {
    event_type: "",
    banner_title: "",
    banner_descp: "",
    offer_starts: "",
    offer_ends: "",
    price: "",
    discount: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      event_type: Yup.string().required("Event Name is required"),
      banner_title: Yup.string()
        .required("Banner title is required")
        .min(3, "must contains 3 char"),
      banner_descp: Yup.string()
        .required("Banner Description is required")
        .min(3, "must contains 3 char"),
      offer_starts: Yup.date().required("start offer date is required"),
      offer_ends: Yup.date().required("end offer date is required"),
      price: Yup.number().required("Price is required"),
      discount: Yup.string().required("Discount is required"),
    }),
    onSubmit: async (values, action) => {
      const formData = new FormData();

      formData.append("banner_img", bannerImg);
      formData.append("event_type", values.event_type);
      formData.append("banner_title", values.banner_title);
      formData.append("banner_descp", values.banner_descp);
      formData.append("offer_starts", values.offer_starts);
      formData.append("offer_ends", values.offer_ends);
      formData.append("price", values.price);
      formData.append("discount", values.discount);

      if (formData) {
        const res = await createOfferBanner(formData);
        if (res.success == true) {
          message.success("Submitted Successfully");
          action.resetForm();
          navigate("/admin/listofferbanner");
        } else if (res?.response?.data) {
          for (let key in res.response.data) {
            message.error(res.response.data[key]);
          }
        }
      }
    },
  });

  return (
    <div className="w-[90%] mx-auto ">
      <h1>Create Offer Banner</h1>
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <div className="flex flex-col justify-center items-center  md:justify-between md:flex-row gap-x-5 flex-wrap">
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Event Type
            </label>
            <select
              value={formik.values.event_type}
              name="event_type"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={""}>Select Event</option>

              {eventType.map((items) => (
                <option value={items.value} key={items._id}>
                  {items.value}
                </option>
              ))}
            </select>
            {formik.errors.event_type && (
              <span className="text-red-500">{formik.errors.event_type}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Banner Title
            </label>
            <input
              type="text"
              name="banner_title"
              value={formik.values.banner_title}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter banner title"
            />
            {formik.errors.banner_title && (
              <span className="text-red-500">{formik.errors.banner_title}</span>
            )}
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Banner Description
            </label>
            <textarea
              type="text"
              name="banner_descp"
              value={formik.values.banner_descp}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter banner desc"
            />
            {formik.errors.banner_descp && (
              <span className="text-red-500">{formik.errors.banner_descp}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Offer Start
            </label>
            <input
              type="date"
              name="offer_starts"
              value={formik.values.offer_starts}
              min={viewCurrentDates}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter offer date"
              required
            />
            {formik.errors.offer_starts && (
              <span className="text-red-500">{formik.errors.offer_starts}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Offer End
            </label>
            <input
              type="date"
              name="offer_ends"
              value={formik.values.offer_ends}
              min={formik.values.offer_starts}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter offer end date"
              required
            />
            {formik.errors.offer_ends && (
              <span className="text-red-500">{formik.errors.offer_ends}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Price
            </label>
            <input
              type="number"
              name="price"
              value={formik.values.price}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter price"
              required
            />
            {formik.errors.price && (
              <span className="text-red-500">{formik.errors.price}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Discount
            </label>
            <input
              type="text"
              name="discount"
              value={formik.values.discount}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
              placeholder="enter discount"
            />
            {formik.errors.discount && (
              <span className="text-red-500">{formik.errors.discount}</span>
            )}
          </div>
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Banner Image
            </label>
            <input
              type="file"
              name="banner_img"
              onChange={(e) => imgHandler(e)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              required
              placeholder="Banner Image"
            />
          </div>
        </div>
        <Link to={"/admin/listofferbanner"}>
          <button
            type="button"
            className="mr-2 text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
          >
            Cancel
          </button>
        </Link>
        <button
          type="submit"
          className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default SidebarHeaderLayout(CreateOfferBanner);
