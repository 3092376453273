import React, { useEffect, useState } from "react";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import { createTrivia } from "../../../api-core/Trivia";
import { message } from "antd";
const CreateTrivia = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const initialValues = {
    title: "",
    descp: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required").min(3).max(100),
      descp: Yup.string().required("Description is required").min(3).max(1500),
    }),
    onSubmit: async (values, action) => {
      if (values) {
        const res = await createTrivia(values);
        if (res.success == true) {
          message.success("Created Successfully");
          action.resetForm();
          navigate("/admin/listoftrivia")
        }
        if (res.response.data) {
          for (let key in res.response.data) {
            message.error(res.response.data[key]);
          }
        }
      }
    },
  });

  return (
    <div className="w-[90%] mx-auto ">
      <h1>Create Trivia</h1>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col justify-center items-center  md:justify-between md:flex-row gap-x-5 flex-wrap">
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Trivia Title
            </label>
            <input
              type="text"
              name="title"
              value={formik.values.title}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter trivia title"
            />
            {formik.errors.title && (
              <span className="text-red-500">{formik.errors.title}</span>
            )}
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Trivia Desciption
            </label>
            <textarea
              type="text"
              name="descp"
              value={formik.values.descp}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter trivia desc"
            />
            {formik.errors.descp && (
              <span className="text-red-500">{formik.errors.descp}</span>
            )}
          </div>
        </div>
        <Link to={"/admin/listoftrivia"}>
          {/* <h1 className="text-lg text-black">Cancel</h1> */}
          <button type="button"
            className="mr-2 text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
            >Cancel</button>
        </Link>
        <button
          type="submit"
          className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default SidebarHeaderLayout(CreateTrivia);
