import { useTranslation } from "react-i18next";
import HeaderBar from "./HeaderBar";
import Sidebar from "./Sidebar";

const SidebarHeaderLayout = (WrappedComponent) => {
  const SidebarHeader = ({ ...props }) => {
    const { t } = useTranslation();
    return (
      <div className="bg-[#f6f2ef] min-h-screen min-w-fit flex">
        <Sidebar />
        <div className="relative flex flex-col flex-1 p-4">
          <HeaderBar />
          <WrappedComponent />
        </div>
      </div>
    );
  };
  return SidebarHeader;
};

export default SidebarHeaderLayout;
