import React, { useEffect, useState } from "react";
import { Button, Input, message, Popconfirm, Table } from "antd";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link } from "react-router-dom";
import {
  deleteOfferBanner,
  getAllOfferBanner,
  getAllOfferBannerPage,
} from "../../../api-core/OfferBanner";
import { formatDate } from "src/utils/helper";

const ListOfOfferBanner = () => {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [row, setRow] = useState(10);
  const [pages, setPages] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchedText, setSearchedText] = useState("");

  useEffect(() => {
    fetchAllOfferBanner();
  }, [row, pages]);
  const fetchAllOfferBanner = async () => {
    setloading(true);
    const res = await getAllOfferBanner();

    const res2 = await getAllOfferBannerPage(row, pages);
    setRow(res2.count);

    setdata(res2?.offerbanner);
    setloading(false);
  };

  const onDeleteRecord = async (record, id) => {
    const recordId = {
      ids: [record],
    };
    const res = await deleteOfferBanner(recordId);
    if (res.success == true) {
      message.success(res.msgText);
      fetchAllOfferBanner();
      // const updatedData = data.filter((items)=>items._id !== record)
      // setdata(updatedData)
    }
  };

  const datas = data?.map((item) => ({
    key: item._id,
    bannertitle: item.banner_title,
    bannerdescp: item.banner_descp,
    offerstarts: formatDate(item.offer_starts, "DD-MM-YYYY"),
    offerends: formatDate(item.offer_ends, "DD-MM-YYYY"),
    price: item.price,
    bannerimage: item.banner_img,
    discount: item.discount,
  }));

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const deleteAllList = async () => {
    if (selectedRowKeys) {
      const deletePayload = {
        ids: [...selectedRowKeys],
      };
      if (deletePayload) {
        const res = await deleteOfferBanner(deletePayload);
        // console.log("res Deleted", res);
        if (res.success == true) {
          message.success(
            `${selectedRowKeys.length} items deleted successfully`
          );
          setSelectedRowKeys(0);
          fetchAllOfferBanner();
        }
      }
    }
  };

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Banner Title",
      dataIndex: "bannertitle",
      key: "bannertitle",
      ellipsis: true,
      filteredValue: [searchedText],
      onFilter: (value, record)=>{
        return String(record.bannertitle)
                .toLowerCase()
                .includes(value.toLowerCase())

      }
    },
    // {
    //   title: "Banner descp",
    //   dataIndex: "bannerdescp",
    //   key: "Bannerdescp",
    //   ellipsis: true,
    // },
    {
      title: "Offer Starts",
      dataIndex: "offerstarts",
      key: "offerstarts",
      ellipsis: true,
    },
    {
      title: "Offer Ends",
      dataIndex: "offerends",
      key: "offerends",
      ellipsis: true,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
    },
    {
      title: "Banner Image",
      dataIndex: "bannerimage",
      key: "bannerimage",

      ellipsis: true,
      render: (_, record) => (
        <div className="w-10 h-10">
          <img src={record.bannerimage} />
        </div>
      ),
    },
    {
      title: "Discount",
      dataIndex: "discount",
      key: "discount",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex gap-x-2 ">
          <Link to={`/admin/singleofferbanner/${record.key}`}>
            <button className="text-blue-600 cursor-pointer">View</button>
          </Link>

          <Link to={`/admin/updateofferbanner/${record.key}`}>
            <button className="text-yellow-400 cursor-pointer">Edit</button>
          </Link>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={(e) => onDeleteRecord(record.key, e)}
          >
            <span className="text-red-500 cursor-pointer">Delete</span>
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div className="">
      <Link to="/admin/createofferbanner">
        <button className="bg-blue-600 text-white p-3 mx-3">Add Offer</button>
      </Link>
      {selectedRowKeys.length > 1 && (
                <>
                  <Popconfirm
                    title={`Do you really wanted to delete ${selectedRowKeys.length} items`}
                    onConfirm={() => deleteAllList()}
                  >
                    {selectedRowKeys.length > 1 ? (
                      <Button danger type="primary">
                        Delete All ({selectedRowKeys.length})
                      </Button>
                    ) : (
                      ""
                    )}
                  </Popconfirm>
                </>
        )}
      <h1 className="text-center">List Of Offer Banner</h1>
      <Input.Search size="large" placeholder="search here..."
             style={{marginBottom: 8, width: 280}}
             onSearch={(value)=>{
              setSearchedText(value);
             }}
             onChange={(e)=>{
              setSearchedText(e.target.value);
             }}/>
      <Table
        rowSelection={rowSelection}
        dataSource={datas}
        columns={columns}
        loading={loading}
        // size="middle"
        // pagination={{ pageSize: 10, total: row }}
      />
    </div>
  );
};

export default SidebarHeaderLayout(ListOfOfferBanner);
