import React, { useEffect, useState } from "react";
import { getsingleOfferBanner } from "../../../api-core/OfferBanner";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link, useHistory, useParams } from "react-router-dom";
import { Table, Descriptions, Image } from "antd";
import {
  deleteFreelancer,
  getsingleFreelancer,
} from "../../../api-core/Freelancer";

const SingleFreelancer = () => {
  const { id } = useParams();
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const fetchSingleFreelancer = async () => {
      setloading(true);
      const res = await getsingleFreelancer(id);

      setdata(res.freelancer);
      setloading(false);
    };
    fetchSingleFreelancer();
  }, [id]);

  const datas = data?.map((item) => ({
    key: item?._id,
    name: item?.name,
    deptId: item?.department_type,
    whatsAppCon: item?.wa_contact_no,
    mobileNo: item?.alt_contact_no,
    gender: item?.gender,
    city: item?.city,
    currentCity: item?.current_city,
    experience: item?.experience,
    photo: item?.pass_size_pic,
    tShirtSize: item?.tshirt_size,
    course: item?.course,
    coordination: item?.coordination,
    workOfShadow: item?.work_of_shadow,
  }));

  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Dep Type",
      dataIndex: "deptId",
      key: "deptId",
      ellipsis: true,
    },
    {
      title: "WhatsApp No",
      dataIndex: "whatsAppCon",
      key: "whatsAppCon",
      ellipsis: true,
    },
    {
      title: "Mobile No",
      dataIndex: "mobileNo",
      key: "mobileNo",
      ellipsis: true,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      ellipsis: true,
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Current City",
      dataIndex: "currentCity",
      key: "currentCity",
      ellipsis: true,
    },
    {
      title: "Experience",
      dataIndex: "experience",
      key: "experience",
    },
    {
      title: "Photo",
      dataIndex: "photo",
      key: "photo",
      render: (_, record) => (
        <div className="w-10 h-10">
          <img src={record.photo} />
        </div>
      ),
    },
    {
      title: "T-Shirt-Size",
      dataIndex: "tShirtSize",
      key: "tShirtSize",
    },

    {
      title: "Course",
      dataIndex: "course",
      key: "course",
    },
    {
      title: "Coordination",
      dataIndex: "coordination",
      key: "coordination",
    },
    {
      title: "Work Of Shadow",
      dataIndex: "workOfShadow",
      key: "workOfShadow",
    },
  ];

  return (
    <div>
      <Descriptions
        title="Freelancer Details"
        bordered
        layout="horizontal"
        style={{ width: "70%" }}
        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
      >
        <Descriptions.Item
          label="Photo"
          labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
        >
          <Image
            width={100}
            height={100}
            src={datas[0]?.photo}
            style={{ borderRadius: "50%" }}
            preview={false}
          />
        </Descriptions.Item>
        <Descriptions.Item
          label="Name"
          labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
        >
          <span className="text-lg"> {datas[0]?.name}</span>
        </Descriptions.Item>

        <Descriptions.Item
          label="Department Type"
          labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
        >
          <span className="text-lg">
            {datas[0]?.deptId ? datas[0]?.deptId : "N/A"}
          </span>
        </Descriptions.Item>
        <Descriptions.Item
          label="WhatsApp No."
          labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
        >
          <span className="text-lg">
            <a href={`tel:${datas[0]?.whatsAppCon}`} >{datas[0]?.whatsAppCon}</a>
          </span>
        </Descriptions.Item>
        <Descriptions.Item
          label="Mobile No."
          labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
        >
          {" "}
          <span className="text-lg">
            <a href={`tel:${datas[0]?.mobileNo}`} >{datas[0]?.mobileNo}</a>
          </span>
        </Descriptions.Item>
        <Descriptions.Item
          label="Gender"
          labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
        >
          <span className="text-lg">{datas[0]?.gender}</span>
        </Descriptions.Item>
        <Descriptions.Item
          label="City"
          labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
        >
          <span className="text-lg">{datas[0]?.city}</span>
        </Descriptions.Item>
        <Descriptions.Item
          label="Current City"
          labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
        >
          <span className="text-lg">{datas[0]?.currentCity}</span>
        </Descriptions.Item>
        <Descriptions.Item
          label="Experience"
          labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
        >
          <span className="text-lg">{datas[0]?.experience}</span>
        </Descriptions.Item>

        <Descriptions.Item
          label="T-Shirt"
          labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
        >
          <span className="text-lg">{datas[0]?.tShirtSize}</span>
        </Descriptions.Item>
        <Descriptions.Item
          label="Course"
          labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
        >
          <span className="text-lg">{datas[0]?.course}</span>
        </Descriptions.Item>
        <Descriptions.Item
          label="Co Ordination"
          labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
        >
          <span className="text-lg">{datas[0]?.coordination}</span>
        </Descriptions.Item>
        <Descriptions.Item
          label="Work of shadow"
          labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
        >
          <span className="text-lg">{datas[0]?.workOfShadow}</span>
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default SidebarHeaderLayout(SingleFreelancer);
