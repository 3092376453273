import React, { useEffect, useState } from "react";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link } from "react-router-dom";
import { Button, Input, message, Popconfirm, Table, TableColumnsType } from "antd";
import { deleteVendorCar, getAllVendorCar } from "../../../api-core/Vendor";
import { IVendorCar } from "src/types";
import { TableRowSelection } from "antd/lib/table/interface";

const Cars = () => {
  const [allCars, setAllCars] = useState<IVendorCar[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [searchedText, setSearchedText] = useState("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const itemsPerPage: number = 10;

  // const fetchAllCars = useCallback(async () => {
  //     setIsLoading(true)
  //     const res = await getAllVendorCar(currentPage, itemsPerPage);
  //     setAllCars(res?.vendorcar);
  //     setIsLoading(false)
  // }, [currentPage]);

  useEffect(() => {
    const fetchAllCars = async () => {
      setIsLoading(true);
      const res = await getAllVendorCar(currentPage, itemsPerPage);
      setAllCars(res?.vendorcar);
      setIsLoading(false);
    };
  
    fetchAllCars();
  }, [currentPage, itemsPerPage]);

  const dataSource: IVendorCar[] = allCars
    ?.map((items) => ({
      key: items._id,
      _id: items._id,
      owner_name: items.owner_name,
      car_reg: items.car_reg,
      car_model: items.car_model,
      car_number: items.car_number,
    //   car_type: items.car_type,
    //   driver_name: items.driver_name,
    //   driver_mobile: items.driver_mobile,
    }))
    // .filter((items) => items.car_type === "Rental");

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
      console.log({newSelectedRowKeys});
      setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection: TableRowSelection<IVendorCar> = {
      selectedRowKeys,
      onChange: onSelectChange,
    };

    const onDeleteVendorCar = async(id: string) => {
      const response = await deleteVendorCar({ ids: [id]})
      if(!response.success) return message.error("Failed to delete!")
      message.success("Deleted!")
      setAllCars(
        allCars?.filter((car => car._id !== id))
      )      
    }

    const deleteVendorCars = async(deletePayload: { ids: React.Key[] | string[] }) => (
      await deleteVendorCar(deletePayload) 
    )
    
    const deleteAllList = async () => {
      if (selectedRowKeys) {
        const deletePayload = {
          ids: [...selectedRowKeys],
        };
        const res = await deleteVendorCars(deletePayload);
        if (!res.success) return message.error("Failed to delete!")
        message.success(
          `${selectedRowKeys.length} items deleted successfully!`
        );
        setSelectedRowKeys([]);
        setAllCars(
          allCars?.filter((car => !selectedRowKeys.includes(car._id!)))
        )
      }
    };

  const columns:TableColumnsType<IVendorCar> = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
    //   sorter: (a, b) => a.id - b.id,
      render: (id: number, record: any, index: number) => {
        ++index;
        return (currentPage - 1) * itemsPerPage + index;
      },
    //   showSorterToolTip: false,
    },

    {
      title: "Owner Name.",
      dataIndex: "owner_name",
      key: "owner_name",
      filteredValue: [searchedText],
      onFilter: (value: any, record) => (
        String (record.owner_name)
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        String(record.car_reg)
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        String(record.car_number)
          .toLowerCase()
          .includes(value.toLowerCase()) ||
        String(record.car_model)
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    },
    {
      title: "Car Reg.",
      dataIndex: "car_reg",
      key: "car_reg",
    },
    {
      title: "Car Model.",
      dataIndex: "car_model",
      key: "car_model",
    },

    {
      title: "Car No.",
      dataIndex: "car_number",
      key: "car_number",
    },
    // {
    //   title: "Car Type",
    //   dataIndex: "car_type",
    //   key: "car_type",
    // },
    // {
    //   title: "Driver Name",
    //   dataIndex: "driver_name",
    //   key: "driver_name",
    // },
    // {
    //   title: "Driver Mobile",
    //   dataIndex: "driver_mobile",
    //   key: "driver_mobile",
    // },
    {
      title: "Action",
      dataIndex: "action",
      render: (value: any, record) => (
        // console.log({value, record});
        
        // return (
        <div className="flex gap-2">
          <Link to={`/admin/cars/edit/${record._id}`}>
            Edit
          </Link>
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => onDeleteVendorCar(record._id!)}
            // onConfirm={async() => {
            //   const res = await deleteVendorCars({ ids :[record._id!] })
            //   if(!res.success) return message.error("Failed to delete!")
            //   message.success("Deleted!")
            //   setAllCars(
            //     allCars?.filter((car => car._id !== record._id))
            //   )
            // }}
          >
            <span className="text-red-500 cursor-pointer">Delete</span>
          </Popconfirm>
        </div>
        // )
      )
    },
    // },
  ];

  return (
    <div className="w-full overflow-auto">
      <Link to="/admin/cars/create">
        <button className="bg-blue-600 text-white p-3 mx-3">
          Add Car
        </button>
      </Link>
      {selectedRowKeys.length > 1 && (
        <>
          <Popconfirm
            title={`Do you really wanted to delete ${selectedRowKeys.length} items`}
            onConfirm={() => deleteAllList()}
          >
            {selectedRowKeys.length > 1 ? (
              <Button danger type="primary">
                Delete All ({selectedRowKeys.length})
              </Button>
            ) : (
              ""
            )}
          </Popconfirm>
        </>
      )}
      <h1 className="text-center">List Of Cars</h1>
      <div className="overflow-y-hidden w-full">
        <Input.Search
          size="large"
          placeholder="search here..."
          style={{ marginBottom: 8, width: 280 }}
          onSearch={(value) => setSearchedText(value)}
          onChange={(e) => setSearchedText(e.target.value)}
        />
        {/* <div style={{ overflowX: 'auto' }}> */}
          <Table<IVendorCar>
            rowSelection={rowSelection}
            dataSource={dataSource}
            columns={columns}
            // scroll={false}
            loading={isLoading}
            pagination={{
              pageSize: itemsPerPage,
              current: currentPage,
              onChange: (page) => setCurrentPage(page),
            }}
          //   size="large"
          // scroll={{ x: "max-content" }}
          />
        {/* </div> */}
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(Cars);
