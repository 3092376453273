import React, { useEffect, useState } from "react";
import SidebarHeaderLayout from "../HOC/SidebarHeaderLayout";
import { Link, useNavigate, useParams } from "react-router-dom";
import { message } from "antd";
import { getAllEvents } from "../../api-core/OfferBanner";
import { useFormik } from "formik";
import * as Yup from "yup";
import { createVendor } from "../../api-core/Vendor";
const CreateBlackList = () => {
  const [data, setData] = useState([]);
  const [eventData, setEventData] = useState([]);
  const [passPortPhoto, setPassPortPhoto] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAllEvents = async () => {
      const res = await getAllEvents();
      setEventData(res.event);
    };
    fetchAllEvents();
  }, []);
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const initialValues = {
    vendor_name: "",
    vendor_work: "",
    vendor_mobile: "",
    reason_for_blacklist: "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      vendor_name: Yup.string()
        .min(3, "must be atleast 3 chars")
        .required("Name is required"),
      vendor_work: Yup.string()
        .min(3, "must be atleast 3 chars")
        .required("Work is required"),
      reason_for_blacklist: Yup.string()
        .min(3, "must be atleast 3 chars")
        .required("Reason is required"),
      vendor_mobile: Yup.string()
        .required("mobile no is required")
        .matches(phoneRegExp, "number is not valid")
        .min(10, "too short")
        .max(10, "too long"),
    }),
    onSubmit: async (values, action) => {
      const formData = new FormData();
      formData.append("vendor_name", values.vendor_name);
      formData.append("vendor_work", values.vendor_work);
      formData.append("vendor_mobile", values.vendor_mobile);
      formData.append("reason_for_blacklist", values.reason_for_blacklist);

      if (formData) {
        const res = await createVendor(formData);
        if (res.success == true) {
          message.success("Submitted Successfully");
          action.resetForm();
          navigate("/admin/blacklisted");
        }
      } else {
        message.error("Something went wrong");
      }
    },
  });

  return (
    <div className="w-[90%] mx-auto ">
      <h1>Create Blacklist Vendor</h1>
      <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
        <div className="flex flex-col justify-center items-center  md:justify-between md:flex-row gap-x-5 flex-wrap">
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Vendor's Name
            </label>
            <input
              type="text"
              name="vendor_name"
              value={formik.values.vendor_name}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Name"
            />
            {formik.errors.vendor_name && (
              <span className="text-red-500">{formik.errors.vendor_name}</span>
            )}
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Vendor's work
            </label>
            <input
              type="text"
              name="vendor_work"
              value={formik.values.vendor_work}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Work"
            />
            {formik.errors.vendor_work && (
              <span className="text-red-500">{formik.errors.vendor_work}</span>
            )}
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Contact Number
            </label>
            <input
              type="number"
              name="vendor_mobile"
              value={formik.values.vendor_mobile}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter number"
            />
            {formik.errors.vendor_mobile && (
              <span className="text-red-500">
                {formik.errors.vendor_mobile}
              </span>
            )}
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Reason for Blacklisting
            </label>
            <textarea
              name="reason_for_blacklist"
              value={formik.values.reason_for_blacklist}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Reason"
            />
            {formik.errors.reason_for_blacklist && (
              <span className="text-red-500">
                {formik.errors.reason_for_blacklist}
              </span>
            )}
          </div>
        </div>
        <Link to={"/admin/blacklisted"}>
          {/* <h1 className="text-lg text-black">Cancel</h1> */}
          <button
            type="button"
            className="mr-2 text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
          >
            Cancel
          </button>
        </Link>
        <button
          type="submit"
          className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default SidebarHeaderLayout(CreateBlackList);
