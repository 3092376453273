import Axios from "../Axios";

export const getAllHotel = async () => {
  try {
    const response = await Axios.get(`/admin/hotel`);
    return response.data;
  } catch (error) {
    return error;
  }
};

/*Get Hotel according to page and rows */

export const getAllHotelPages = async (rows, pages) => {
  try {
    const response = await Axios.get(`/admin/hotel?r=${rows}&p=${pages}`);

    return response.data;
  } catch (error) {}
};

export const getSingleHotel = async (id) => {
  try {
    const response = await Axios.get(`/admin/hotel/read/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const createHotel = async (data) => {
  try {
    const response = await Axios.post(`/admin/hotel/create`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const updateHotel = async (data, id) => {
  try {
    const response = await Axios.post(`/admin/hotel/update/${id}`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const createHotelRoom = async (data) => {
  try {
    const response = await Axios.post(`/admin/hotelroom/create`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const updateHotelRoom = async (data, id) => {
  try {
    const response = await Axios.post(`/admin/hotelroom/update/${id}`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getSingleHotelRoom = async (id) =>
  Axios.get(`/admin/hotelroom/read/${id}`);
export const deleteHotel = async (id) => {
  try {
    const response = await Axios.post(`/admin/hotel/delete`, id);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAllHotelRoom = async () => {
  try {
    const response = await Axios.get(`/admin/hotelroom`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deleteHotelRoom = async (id) => {
  try {
    const response = await Axios.post(`/admin/hotelroom/delete`, id);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAllHotelRoomType = async () => {
  try {
    const response = await Axios.get(`/admin/hotelroomtype`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getSingleHotelRoomType = async (id) => {
  try {
    const response = await Axios.get(`/admin/hotelroomtype/read/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const createHotelRoomType = async (data) => {
  try {
    const response = await Axios.post(`/admin/hotelroomtype/create`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deleteHotelRoomType = async (id) => {
  try {
    const response = await Axios.post(`/admin/hotelroomtype/delete`, id);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getAllHotelRoomChecklist = async () => {
  try {
    const response = await Axios.get(`/admin/hotelroomchecklist`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getSingleHotelRoomChecklist = async (id) => {
  try {
    const response = await Axios.get(`/admin/hotelroomchecklist/read/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const createHotelRoomChecklist = async (data) => {
  try {
    const response = await Axios.post(`/admin/hotelroomchecklist/create`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const deleteHotelRoomChecklist = async (id) => {
  try {
    const response = await Axios.post(`admin/hotelroomchecklist/delete`, id);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const getAllHotelRoomAllotment = async () => {
  try {
    const response = await Axios.get(`/admin/roomallotment`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const getSingleHotelRoomAllotment = async (id) => {
  try {
    const response = await Axios.get(`/admin/roomallotment/read/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const createHotelRoomAllotment = async (data) => {
  try {
    const response = await Axios.post(`/admin/roomallotment/create`, data);
    return response.data;
  } catch (error) {
    return error;
  }
};
export const updateHotelRoomAllotment = async (data, id) => {
  try {
    const response = await Axios.post(
      `/admin/roomallotment/update/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const readAllCheckedHotelRoom = (hotelID) => {
  return Axios.get(`/admin/hotelroom/checked/${hotelID}`).then(
    (response) => response.data
  );
};
