import React, { useEffect, useState } from "react";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getAllRoles } from "../../../api-core/RolesPermissions";
import { createUser, getSingleUser, updateUser } from "../../../api-core/User";
import { message } from "antd";
import { phoneRegExp } from "src/utils/helper";

const Updateuser = () => {
  const { id } = useParams();
  const [roleData, setRoleData] = useState([]);
  const [loading, setloading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchSingleUser = async () => {
      setloading(true);
      const res = await getSingleUser(id);

      setInitialValues({
        name: res?.user[0].name,
        mobile: res?.user[0].mobile,
        role_id: res?.user[0].role_id._id,
      });
      setloading(false);
    };
    fetchSingleUser();
  }, [id]);

  useEffect(() => {
    fetchAllRoles();
  }, [id]);
  const fetchAllRoles = async () => {
    setloading(true);
    const res = await getAllRoles();
    res.role.pop();
    setRoleData(res?.role);
    setloading(false);
  };

  const [initialValues, setInitialValues] = useState({
    name: "",
    mobile: "",
    role_id: "",
  });
  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object({
      name: Yup.string().required("name is required").min(3,"must contains 3 char"),
      mobile: Yup.string()
        .required("Mobile number is required")
        .matches(phoneRegExp, "Mobile number is not valid")
        .min(10, "too short")
        .max(10, "too long"),
      role_id: Yup.string().required("role is required"),
    }),
    onSubmit: async (values, action) => {
      values.mobile = values.mobile.toString()
      if (values) {
        const res = await updateUser(id, values);
        if (res.success == true) {
          message.success("Updated Successfully");
          navigate(`/admin/listofusers`);
        }
      }
    },
    enableReinitialize: true,
  });

  return (
    <div className="w-[90%] mx-auto ">
      <h1>Edit User</h1>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex flex-col justify-center items-center  md:justify-between md:flex-row gap-x-5 flex-wrap">
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Name
            </label>
            <input
              type="text"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Name"
            />
            {formik.errors.name && (
              <span className="text-red-500">{formik.errors.name}</span>
            )}
          </div>

          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Mobile Number
            </label>
            <input
              type="number"
              name="mobile"
              value={formik.values.mobile}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Mobile Number"
            />
            {formik.errors.mobile && (
              <span className="text-red-500">{formik.errors.mobile}</span>
            )}
          </div>

          {/* <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Email
            </label>
            <input
              type="email"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Enter Email"
            />
            {formik.errors.email && (
              <span className="text-red-500">{formik.errors.email}</span>
            )}
          </div> */}
          {/* <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              password
            </label>
            <input
              type="password"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="enter trivia desc"
            />
            {formik.errors.password && (
              <span className="text-red-500">{formik.errors.password}</span>
            )}
          </div> */}
          <div className="mb-6 w-[95%] md:w-[45%] ">
            <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Role
            </label>
            <select
              value={formik.values.role_id}
              name="role_id"
              onChange={formik.handleChange}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value={""}>Select Role</option>
              {roleData.map((value, index) => (
                <option
                  value={value._id}
                  key={value.role_id}
                  selected={value._id && value._id == formik.values.role_id}
                >
                  {value.role_name}
                </option>
              ))}
            </select>
            {formik.errors.role_id && (
              <span className="text-red-500">{formik.errors.role_id}</span>
            )}
          </div>
        </div>
        <Link to={"/admin/listofusers"}>
          {/* <h1 className="text-lg text-black">Cancel</h1> */}
          <button type="button"
            className="mr-2 text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
            >Cancel</button>
        </Link>
        <button
          type="submit"
          className="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-[50%] sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default SidebarHeaderLayout(Updateuser);
