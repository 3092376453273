import { message } from "antd";
import { useEffect, useState } from "react";
import {
  deleteHotel,
  getAllHotel,
  getAllHotelPages,
  readAllCheckedHotelRoom,
} from "src/api-core/Hotel";
import { getFormattedHotelRooms } from "src/components/pages/Event/services/hotel";
import { IHotelFloorWithRooms, IHotelOption, IHotelRoom } from "src/types";

export const useFetchHotels = (fetchHotelsOnly = false) => {
  const [hotels, setHotels] = useState<IHotelOption[]>([]);
  const [hotelRooms, setHotelRooms] = useState<IHotelFloorWithRooms>({});
  const [loading, setLoading] = useState(true);
  const [row, setRow] = useState(10);
  const [pages, setPages] = useState(1);

  useEffect(() => {
    const fetchAllHotelRooms = async (id: string): Promise<IHotelRoom[]> => {
      try {
        const response = await readAllCheckedHotelRoom(id);
        return response?.hotelroom || [];
      } catch (error) {
        return [];
      }
    };
    const fetchAllHotel = async () => {
      // const res = await getAllHotel();
      const res = await getAllHotelPages(row, pages);

      setRow(res?.count);
      const hotels: IHotelOption[] = res?.hotel || [];
      if (fetchHotelsOnly === false) {
        const data = await Promise.all(
          hotels.map((hotel) => fetchAllHotelRooms(hotel._id))
        );
        const rooms = data.flatMap((room) => room).reverse();
        const hotelRooms = getFormattedHotelRooms(rooms);
        setHotelRooms(hotelRooms);
      }
      setHotels(hotels);

      setLoading(false);
    };
    fetchAllHotel();
  }, [fetchHotelsOnly, row, pages]);

  const handleHotelCreateSuccess = (hotel: IHotelOption) => {
    const updatedHotels = [...hotels];
    updatedHotels.unshift(hotel);

    setHotels(updatedHotels);
  };

  const handleHotelUpdateSuccess = (index: number, hotel: IHotelOption) => {
    const updatedHotels = [...hotels];
    updatedHotels[index] = hotel;
    setHotels(updatedHotels);
  };

  const handleRoomCreateSuccess = (rooms: IHotelRoom[]) => {
    const updatedHotelRooms = getFormattedHotelRooms(rooms, hotelRooms);
    setHotelRooms(updatedHotelRooms);
  };

  const handleDeleteHotel = async (id: string) => {
    const data = {
      ids: [id],
    };
    const res = await deleteHotel(data);
    if (res.success === true) {
      message.success(res.msgText);
      const updatedHotels = [...hotels].filter((hotel) => hotel._id !== id);

      setHotels(updatedHotels);
    }
  };

  return {
    hotels,
    hotelRooms,
    handleDeleteHotel,
    handleHotelCreateSuccess,
    handleHotelUpdateSuccess,
    handleRoomCreateSuccess,
    loading,
    row,
    pages,
  };
};
