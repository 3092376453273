import React, { useEffect, useState } from "react";
import { Descriptions, Image } from "antd";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link, useParams } from "react-router-dom";
import {
  deleteFreelancer,
  getSingleFreelancerAssignee,
} from "../../../api-core/Freelancer";

const SingleFreelancerAssignee = () => {
  const { id } = useParams();
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    const fetchSingleFreelancerAssignee = async () => {
      setloading(true);
      const res = await getSingleFreelancerAssignee(id);
      setdata(res.freelancerassignedevent);
      setloading(false);
    };
    fetchSingleFreelancerAssignee();
  }, []);

  const onDeleteRecord = async (record, id) => {
    const res = await deleteFreelancer(record);
  };

  const datas = data?.map((item) => ({
    key: item._id,

    subEventName: item?.event_id?.event_title,
    freelancerName: item?.freelancer_id?.name,
    departmentName: item?.department_type,
    expectedWorkHours: item?.expected_working_hours,
    workedHours: item?.hours_worked,
  }));

  // const columns = [
  //   {
  //     title: "Serial No.",
  //     dataIndex: "id",
  //     key: "id",
  //     sorter: (a, b) => a.id - b.id,
  //     render: (id, record, index) => {
  //       ++index;
  //       return index;
  //     },
  //     showSorterTooltip: false,
  //   },
  //   {
  //     title: "Client Name",
  //     dataIndex: "clientName",
  //     key: "clientName",
  //   },
  //   {
  //     title: "SubEvent Name",
  //     dataIndex: "subEventName",
  //     key: "subEventName",
  //   },
  //   {
  //     title: "Freelancer Name",
  //     dataIndex: "freelancerName",
  //     key: "freelancerName",
  //   },
  //   {
  //     title: "Department Name",
  //     dataIndex: "departmentName",
  //     key: "departmentName",
  //   },
  //   {
  //     title: "Expected Working Hours",
  //     dataIndex: "expectedWorkHours",
  //     key: "expectedWorkHours",
  //   },
  //   {
  //     title: "Worked Hours",
  //     dataIndex: "workedHours",
  //     key: "workedHours",
  //   },
  // ];
  return (
    <div className="">
      <div className="">
        <div>
          <div>
            <Descriptions
              title="Freelancer Deployed Details"
              bordered
              layout="horizontal"
              column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
            >
              <Descriptions.Item
                label="Freelancer Name"
                labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
              >
                <span className="text-lg"> {datas[0]?.freelancerName}</span>
              </Descriptions.Item>

              <Descriptions.Item
                label="Event Name"
                labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
              >
                <span className="text-lg">{datas[0]?.subEventName}</span>
              </Descriptions.Item>

              <Descriptions.Item
                label="Department Name"
                labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
              >
                <span className="text-lg">{datas[0]?.departmentName}</span>
              </Descriptions.Item>
              <Descriptions.Item
                label="Expected Hours"
                labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
              >
                <span className="text-lg">{datas[0]?.expectedWorkHours}</span>
              </Descriptions.Item>
              <Descriptions.Item
                label="Worked Hours"
                labelStyle={{ fontSize: "1.5em", fontWeight: "600" }}
              >
                <span className="text-lg">{datas[0]?.workedHours}</span>
              </Descriptions.Item>
            </Descriptions>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(SingleFreelancerAssignee);
