import { useEffect, useState } from "react";
import { getAllEvents } from "src/api-core/Events";

const useGetevents = (pages, row, setTotal) => {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);

  const fetchAllEvents = async () => {
    setloading(true);
    const res = await getAllEvents(pages, row);

    setTotal(res.count);

    setdata(res?.event);
    setloading(false);
  };

  useEffect(() => {
    fetchAllEvents();
  }, [row, pages]);

  return { data, loading, fetchAllEvents };
};

export default useGetevents;
