import React, { useEffect, useState } from "react";
import { Table } from "antd";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link, useParams } from "react-router-dom";
import {
  deletePermission,
  deleteRole,
  getAllPermissionRole,
  getAllPermissions,
  getSinglePermissionRole,
} from "../../../api-core/RolesPermissions";

const SinglePermissionRole = () => {
  const { id } = useParams();
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  useEffect(() => {
    const fetchSinglePermissionRole = async () => {
      setloading(true);
      const res = await getSinglePermissionRole(id);
      setdata(res.permrole);
      setloading(false);
    };
    fetchSinglePermissionRole();
  }, []);

  const datas = data.map((item) => ({
    key: item._id,
    permName: item.permission_id?.perm_name,
    roleName: item.role_id.role_name,
  }));
  const columns = [
    {
      title: "Sr",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Permission Name",
      dataIndex: "permName",
      key: "permName",
      ellipsis: true,
    },
    {
      title: "Role Name",
      dataIndex: "roleName",
      key: "roleName",
      ellipsis: true,
    },
  ];
  return (
    <div className="">
      <h1 className="text-center">Single Permission Role</h1>
      <div className="">
        <Table dataSource={datas} columns={columns} loading={loading} scroll />
      </div>
    </div>
  );
};

export default SidebarHeaderLayout(SinglePermissionRole);
