import React, { useEffect, useState } from "react";
import { Button, Input, message, Popconfirm, Table } from "antd";
import { deleteTrivia, getAllTrivia } from "../../../api-core/Trivia";
import SidebarHeaderLayout from "../../HOC/SidebarHeaderLayout";
import { Link } from "react-router-dom";
import {
  deleteUser,
  getAllUser,
  getAllUserPages,
} from "../../../api-core/User";

const ListOfUsers = () => {
  const [data, setdata] = useState([]);
  const [loading, setloading] = useState(false);
  const [row, setRow] = useState(10);
  const [pages, setPages] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchedText, setSearchedText] = useState("");

  useEffect(() => {
    fetchAllUsers();
  }, [row, pages]);
  const fetchAllUsers = async () => {
    setloading(true);
    const res = await getAllUserPages(row, pages);
    if (res.success === true) {
      setdata(res?.user);
      setRow(res?.count);
    }
    setloading(false);
  };
  const onDeleteRecord = async (record, id) => {
    const recordId = {
      ids: [record],
    };
    const res = await deleteUser(recordId);
    if (res.success == true) {
      message.success(res.msgText);
      fetchAllUsers();
      // const updatedData = data.filter((items)=>items._id !== record)
      // setdata(updatedData)
    }
  };

  const datas = data?.map((item) => ({
    key: item._id,
    name: item.name,
    mobile: item.mobile,
    email: item.email,
    role: item.role_id.role_name,
  }));

  const result = datas.filter((datas) => {
    return (
      datas.email &&
      datas.key &&
      datas.mobile &&
      datas.name &&
      datas.role != "Admin"
    );
  });

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };
  
  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };
  
  const deleteAllList = async () => {
    if (selectedRowKeys) {
      const deletePayload = {
        ids: [...selectedRowKeys],
      };
      if (deletePayload) {
        const res = await deleteUser(deletePayload);
        // console.log("res Deleted", res);
        if (res.success == true) {
          message.success(
            `${selectedRowKeys.length} items deleted successfully`
          );
          setSelectedRowKeys(0);
          fetchAllUsers();
        }
      }
    }
  };

  // console.log("Datas --> ", datas[9].role.role_name);
  // console.log("Data ", datas);
  const columns = [
    {
      title: "Serial No.",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      render: (id, record, index) => {
        ++index;
        return index;
      },
      showSorterTooltip: false,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      filteredValue: [searchedText],
      onFilter: (value, record)=>{
        return String(record.name)
                .toLowerCase()
                .includes(value.toLowerCase()) ||
                String(record.mobile)
                .toLowerCase()
                .includes(value.toLowerCase()) ||
                String(record.email)
                .toLowerCase()
                .includes(value.toLowerCase())

      }
      // ellipsis: true,
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
      // ellipsis: true,
      render: (mobile) => (
        <a href={`tel:${mobile}`} >{mobile}</a>
      )
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      // ellipsis: true,
    },
    {
      title: "Role Name",
      dataIndex: "role",
      key: "role",
      // ellipsis: true,
    },

    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex gap-x-2 ">
          <Link to={`/admin/singleuser/${record.key}`}>
            <button className="text-blue-600 cursor-pointer">View</button>
          </Link>

          <Link to={`/admin/updateuer/${record.key}`}>
            <button className="text-yellow-400 cursor-pointer">Edit</button>
          </Link>

          <Popconfirm
            title="Sure to delete?"
            onConfirm={(e) => onDeleteRecord(record.key, e)}
          >
            <span className="text-red-500 cursor-pointer">Delete</span>
          </Popconfirm>
        </div>
      ),
    },
  ];
  return (
    <div>
      <Link to="/admin/createusers">
        <button className="bg-blue-600 text-white p-3 mx-3">Add User</button>
      </Link>
      {selectedRowKeys.length > 1 && (
                <>
                  <Popconfirm
                    title={`Do you really wanted to delete ${selectedRowKeys.length} items`}
                    onConfirm={() => deleteAllList()}
                  >
                    {selectedRowKeys.length > 1 ? (
                      <Button danger type="primary">
                        Delete All ({selectedRowKeys.length})
                      </Button>
                    ) : (
                      ""
                    )}
                  </Popconfirm>
                </>
              )}
      <h1 className="text-center">List Of Users</h1>
      <Input.Search size="large" placeholder="search here..."
             style={{marginBottom: 8, width: 280}}
             onSearch={(value)=>{
              setSearchedText(value);
             }}
             onChange={(e)=>{
              setSearchedText(e.target.value);
             }}/>

      <Table
        rowSelection={rowSelection}
        dataSource={result}
        columns={columns}
        loading={loading}
        scroll
        pagination={{ total: row, pageSize: 10 }}
      />
    </div>
  );
};

export default SidebarHeaderLayout(ListOfUsers);
