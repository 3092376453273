import React from 'react'
import SidebarHeaderLayout from 'src/components/HOC/SidebarHeaderLayout'
import CarForm from './CarForm'

const AddCar = () => {
  return (
    <div>
      <CarForm title="Add Car"/>
    </div>
  )
}

export default SidebarHeaderLayout(AddCar)